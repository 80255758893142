/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from '../images/Images';

type TheHungerButtonProps = {
  labelKey: string,
  color?: 'green' | 'red'
} & HTMLAttributes<HTMLDivElement>;

const TheHungerButton: FC<TheHungerButtonProps> = ({ labelKey, color, ...props}) => {
  const { t } = useTranslation()

  return <div { ...props } css={ [buttonStyle(color)] }>
    <div css={ labelStyle } >{ t(labelKey) }</div>
  </div>
};

const buttonStyle = (color?: string) => css`
  position: absolute;
  background-image: url(${color === 'red'? Images.TheHungerButtonRed: Images.TheHungerButtonGreen});
  background-size: 100% 100%;
  cursor: pointer;
  display: inline;
  padding-left: 0.7em;
  padding-right: 0.7em;
  filter: drop-shadow(0 0 0.08em white);
  height: 8%;
  z-index: 101;

  &:active {
    filter: unset;
  }
`;

const labelStyle = css`
  color: white;
  top: 50%;
  left: 50%;
  font-size: 0.7em;
  transform: translate(-50%, -50%);
  font-weight: bold;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
`

export {
  TheHungerButton
}
