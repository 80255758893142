import GameView from '@gamepark/the-hunger/GameView';
import Vampire from '@gamepark/the-hunger/player/Vampire';

export type SelectVampire = {
  type: 'SelectVampire'
  vampire?: Vampire
}

export const selectVampireMove = (vampire?: Vampire): SelectVampire => ({
  type: 'SelectVampire', vampire
});

export function selectVampire(state: GameView, move: SelectVampire) {
  state.selectedVampire = move.vampire;
}
