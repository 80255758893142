/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CSSProperties, FC } from 'react';
import {
  cardRatio,
  catalogCardWidth,
  catalogSwipeAreaHeight,
  catalogSwipeAreaRatio,
  catalogSwipeAreaWidth,
  catalogTop,
  popupBackgroundStyle,
} from '../../utils/Style';
import { HuntCard } from './HuntCard';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { createPortal } from 'react-dom';
import useKeyDown from '../../utils/useKeyDown';
import Scrollbars from 'react-custom-scrollbars-2';
import { HuntCardHelp } from './HuntCardHelp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export type HuntCardsCatalogProps = {
  hunts: number[];
  topComponents?: any[];
  onClose?: (event?: any) => void;
  onHuntClick?: (event: any, hunt: number) => void;
  isClickable?: (hunt: number) => boolean;
  getCardOverlay?: (hunt: number) => any | undefined;
  initialIndex?: number;
  ignoreTopComponentArea?: boolean;
  hideActions?: boolean;
  onEndPress?: () => void;
};

const HuntCardsCatalog: FC<HuntCardsCatalogProps> = ({
  hunts,
  onClose,
  isClickable,
  onHuntClick,
  topComponents,
  getCardOverlay,
  ignoreTopComponentArea,
  hideActions,
  onEndPress
}) => {
  useKeyDown('Escape', () => onClose && onClose());
  const { t } = useTranslation();

  const canBeClicked = (hunt: number) => {
    return (!isClickable && onHuntClick) || (isClickable && isClickable(hunt));
  };

  const selectHunt = (event: any, hunt: number) => {
    event.stopPropagation();

    if (canBeClicked(hunt) && onHuntClick) {
      onHuntClick(event, hunt);
      return;
    }
  };

  const cards = hunts.map((hunt, index) => (
    <HuntCard
      key={`card-${index}`}
      hunt={GameDeck[hunt]}
      css={[
        cardStyle,
        cardPosition(index),
        hunts.length < 4 && fewCards(hunts.length),
        canBeClicked(hunt) && selectableCard,
      ]}
      cardOverlay={getCardOverlay && getCardOverlay(hunt)}
      onClick={(event: any) => selectHunt(event, hunt)}
    />
  ));

  const closeCatalog = () => {
    if (onClose) {
      onClose();
    }
  };

  const catalog = (
    <>
        <>
          {!hideActions && (
          <button
            key="card-button"
            css={button(hunts.length === 1, !!topComponents?.length, ignoreTopComponentArea)}
            onClick={closeCatalog}
          >
            <FontAwesomeIcon icon={faTimes} />
            {t('Close')}
          </button>
          )}
          <div css={[popupBackgroundStyle]} onClick={closeCatalog} onMouseUp={onEndPress} onTouchEnd={onEndPress} />
        </>
      <Scrollbars
        hideTracksWhenNotNeeded
        renderTrackHorizontal={({ style, ...props }) => (
          <div {...props} style={{ ...style, ...scrollTrackHorizontal }} />
        )}
        renderThumbHorizontal={({ style, ...props }) => (
          <div {...props} style={{ ...style, ...scrollThumbHorizontal }} />
        )}
        css={[swipeZoneStyle(!!topComponents?.length, ignoreTopComponentArea)]}
      >
        <div css={cardHolder} onMouseUp={onEndPress} onTouchEnd={onEndPress} onClick={closeCatalog}>
          {cards}
        </div>
      </Scrollbars>
      {!!topComponents?.length && topComponents}
      {hunts.length === 1 && !hideActions && (
        <HuntCardHelp
          hunt={hunts[0]}
          ignoreTopComponentArea={!!ignoreTopComponentArea}
          hasTopComponents={!!topComponents?.length}
        />
      )}
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(catalog, letterBox);
  }

  return null;
};

const button = (oneCard: boolean, hasTopComponent?: boolean, ignoreTopComponentArea: boolean = false) => css`
  font-weight: bold;
  color: black;
  background: rgba(255, 255, 255);
  border: 0.2em solid black;
  border-radius: 1.5em;
  padding: 0.5em 0.7em;
  position: absolute;
  cursor: pointer;
  white-space: nowrap;
  -moz-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
  font-size: 2.3em;
  left: 65.65%;
  z-index: 100;
  text-transform: capitalize;
  top: ${getButtonTop(ignoreTopComponentArea, !!hasTopComponent, oneCard)}em;
  & svg {
    margin-right: 0.3em;
  }
`;

const getButtonTop = (ignoreTopComponentArea: boolean, hasTopComponent: boolean, oneCard: boolean) => {
  const topComponents = !ignoreTopComponentArea && hasTopComponent;
  if (topComponents) {
    return oneCard ? 13.4 : 5.2;
  }

  return oneCard ? 11.8 : 4.3;
};

const cardHolder = css`
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding-left: 5em;
`;

const scrollTrackHorizontal: CSSProperties = {
  position: 'absolute',
  height: '2em',
  left: 0,
  right: 0,
  bottom: '1%',
  borderRadius: '3em',
};

const scrollThumbHorizontal: CSSProperties = {
  borderRadius: '3em',
  backgroundColor: 'white',
};

const swipeZoneStyle = (hasTopComponent?: boolean, ignoreTopComponentArea: boolean = false) => css`
  position: absolute !important;
  width: ${catalogSwipeAreaWidth}% !important;
  z-index: 99;
  left: 2.5%;
  height: ${catalogSwipeAreaHeight}% !important;
  top: ${catalogTop(ignoreTopComponentArea, hasTopComponent)}% !important;
  font-size: ${catalogSwipeAreaHeight / 100}em !important;
`;

const cardStyle = css`
  position: absolute;
  width: ${(93 * cardRatio) / catalogSwipeAreaRatio}%;
  height: 93%;
  font-size: ${93 / 100}em;
  z-index: 120;
  top: 1.1em;
`;

const fewCards = (cardCount: number) => css`
  left: ${(100 -
    (catalogCardWidth * (cardCount > 3 ? 3 : cardCount) +
      0.1 * catalogCardWidth * (cardCount > 3 ? 2 : cardCount - 1))) /
  2}%;
`;

const cardPosition = (index: number) => css`
  transform: translateX(${index * 110}%);
`;

const selectableCard = css`
  box-shadow: 0 0 0.3em 1em gold, 0 0 0.3em 1em gold;

  &:hover {
    box-shadow: 0 0 0.3em 1em green, 0 0 0.3em 1em green;
  }

  cursor: pointer;
`;

export { HuntCardsCatalog };
