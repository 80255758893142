import Move from '../moves/Move';
import { huntOnTrackMove } from '../moves/HuntOnTrack';
import GameState from '../GameState';
import { EffectType } from './EffectType';
import { GameEffect } from './GameEffect';

export class FreeHuntEffect implements GameEffect {
  type: EffectType.FreeHunt = EffectType.FreeHunt;
  huntColumn?: number;
  optional?: boolean;

  constructor(object?: Partial<FreeHuntEffect>) {
    if (object) {
      this.huntColumn = object.huntColumn;
      this.optional = object.optional;
    }
  }

  legalMoves(state: GameState): Move[] {
    return state.hunt.track
      .flatMap((line, lineIndex) => {
        if (this.huntColumn !== undefined && line[this.huntColumn] && line[this.huntColumn].length) {
          return [huntOnTrackMove(lineIndex, this.huntColumn)];
        }

        return line
          .filter((area) => area.length)
          .flatMap((_, areaIndex) => huntOnTrackMove(lineIndex, areaIndex));
      });
  }


}
