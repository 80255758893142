import { Player } from './player';
import { Hunt } from './hunt';
import { PositionedBonus } from './bonus/PositionedBonus';
import { NewRoundPhase } from './round/NewRoundPhase';
import Vampire from './player/Vampire';
import GameView from './GameView';
import { GameMode } from './GameMode';

/**
 * In here, you describe what a GameState will look like at any time during a game.
 */
type GameState = {
  players: Player[];
  hunt: Hunt;
  missions: number[][];
  roses: number[];
  tavern: number[];
  bonusTokens: PositionedBonus[];
  castleTokens: number[];
  turnOrder: Vampire[];
  activePlayer?: Vampire;
  round: number;
  newRoundPhase?: NewRoundPhase;
  mode: GameMode;
  tutorial?: boolean;
  oldTokensCount?: boolean;
};

export default GameState;

export const isGameState = (state: GameState | GameView): state is GameState => typeof state.tavern !== 'number';
