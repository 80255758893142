/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { Missions } from '@gamepark/the-hunger/mission';
import { VictoryPointSymbol } from '../../../../symbols';
import { missionRatio } from '../../../../utils/Style';
import { MissionTile } from '../../../mission/MissionTile';
import { PlayerMission } from '@gamepark/the-hunger/player/PlayerMission';

type PersonalMissionScoreProps = {
  mission: PlayerMission;
};

const PersonalMissionScore: FC<PersonalMissionScoreProps> = (props) => {
  const { mission } = props;

  return (
    <>
      <div css={[container, publicMissionContainer]}>
        <MissionTile css={missionStyle} mission={Missions[mission.mission]} />
        <span css={equals}>=</span>
      </div>
      <div css={[container, victoryPointContainer]}>
        <VictoryPointSymbol value={(mission.score || 0).toString()} hd css={victoryPointStyle} width={3} bold />
      </div>
    </>
  );
};

const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const firstLineHeight = 14;
const missionHeight = firstLineHeight;
const missionStyle = css`
  height: ${missionHeight}em;
  width: ${missionHeight / missionRatio}em;
  //margin-right: 5em;

  &:first-of-type {
    margin-right: 3em;
  }

  > div {
    font-size: ${missionHeight / 100}em;
  }
`;

const publicMissionContainer = css`
  display: flex;
  flex-direction: row;
  flex: 2;
  align-items: center;
  justify-content: flex-end;
  grid-column: span 8;
`;

const equals = css`
  font-size: 5em;
`;

const victoryPointContainer = css`
  grid-column: span 4;
  justify-content: flex-start;
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 3em;
  margin: 0 0.2em;
`;

export { PersonalMissionScore };
