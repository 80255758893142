import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { isMyPlayerView, isOtherPlayerView } from '../player';
import { isReadyEffect } from '../utils/EffectUtils';
import Vampire from '../player/Vampire';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';

export type PlaceOnDeck = {
  type: MoveType.PlaceOnDeck,
  cards: number[]
};

export type PlaceOnDeckView = Omit<PlaceOnDeck, 'cards'> & { cards: number };

export const placeOnDeckMove = (cards: number[]): PlaceOnDeck => ({
  type: MoveType.PlaceOnDeck,
  cards
});

export const isPlaceOnDeckView = (move: PlaceOnDeck | PlaceOnDeckView): move is PlaceOnDeckView => typeof move.cards === 'number';

export const placeOnDeck = (state: GameState, move: PlaceOnDeck) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  player.deck.unshift(...move.cards);

  const pendingEffect = getNextPendingEffect(player);
  if (isReadyEffect(pendingEffect)) {
    removeNextPendingEffect(player);
  }
};

export const placeOnDeckView = (state: GameView, move: PlaceOnDeck | PlaceOnDeckView) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  if (isMyPlayerView(player) && !isPlaceOnDeckView(move)) {
    player.deck += move.cards.length;
  }

  if (isOtherPlayerView(player) && isPlaceOnDeckView(move)) {
    player.deck += move.cards;
  }

  const pendingEffect = getNextPendingEffect(player);
  if (isReadyEffect(pendingEffect)) {
    removeNextPendingEffect(player);
  }
};

export const getPlaceOnDeckMoveView = (state: GameState, move: PlaceOnDeck, playerId?: Vampire): PlaceOnDeck | PlaceOnDeckView => {
  if (state.activePlayer && playerId === state.activePlayer) {
    return move;
  }

  return {
    ...move,
    cards: move.cards.length
  };
};
