/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { useDrop } from 'react-dnd';
import { DraggableTypes } from '../../draggable/DraggableTypes';
import { moveVampireMove } from '@gamepark/the-hunger/moves/MoveVampire';
import { PlayerTokenFromBoard } from '../../draggable/PlayerTokenFromBoard';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { usePlay } from '@gamepark/react-client';
import { selectVampireMove } from '../../../moves/SelectVampire';
import { BoardBoxEffect, BoardBoxes, Region } from '@gamepark/the-hunger/board';
import { highlightBoxMove } from '../../../moves/HighlightBox';
import { GameMode } from '@gamepark/the-hunger/GameMode';

type BoxProps = {
  position: number;
  isTargetable: boolean;
  selectedVampire?: Vampire;
  highlightedBox?: number;
  mode: GameMode;
} & HTMLAttributes<HTMLDivElement>;

const Box: FC<BoxProps> = ({ isTargetable, mode, selectedVampire, highlightedBox, position, ...props }) => {
  const play = usePlay();
  const boardBox = BoardBoxes[position] || {};

  const lostVictoryPoint =
    boardBox.effect === BoardBoxEffect.Cemetery
      ? boardBox.lostVictoryPoints || 0
      : mode === GameMode.Rookie && boardBox.region === Region.Mountain
      ? boardBox.lostVictoryPoints || 0
      : 0;

  const hasHelp = boardBox.effect || lostVictoryPoint;

  const [{ isOver }, ref] = useDrop({
    accept: DraggableTypes.PlayerTokenFromBoard,
    canDrop: (_: PlayerTokenFromBoard) => isTargetable,
    drop: (item: PlayerTokenFromBoard) => moveVampireMove(position, item.vampire),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const onBoxClick = () => {
    if (selectedVampire && isTargetable) {
      play(selectVampireMove(), { local: true });
      play(moveVampireMove(position, selectedVampire));
    }

    if (!selectedVampire) {
      if (highlightedBox && highlightedBox === position) {
        play(highlightBoxMove(), { local: true });
      } else if (!highlightedBox || highlightedBox !== position) {
        play(highlightBoxMove(position), { local: true });
      }
    }
  };
  return (
    <div
      ref={ref}
      onClick={onBoxClick}
      css={[
        box,
        hasHelp && withHelp,
        isTargetable && boxHover,
        isTargetable && isOver && hoverBox,
        isTargetable && !isOver && highlightBox,
      ]}
      {...props}
    >
      {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
        !lostVictoryPoint &&
        position &&
        position > 0}
      {lostVictoryPoint !== 0 && -lostVictoryPoint}
    </div>
  );
};

const box = css`
  border-radius: 50%;
  //box-shadow: 0 0 0.2em 0 black, 0 0 0.3em 0.3em gold, 0 0 0.3em 0.3em gold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
`;

const withHelp = css`
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const boxHover = css`
  &:hover {
    box-shadow: 0 0 0.2em 0.5em green, 0 0 0.2em 0.5em green;
  }
`;

const highlightBox = css`
  cursor: pointer;
  box-shadow: 0 0 0.2em 0.5em gold, 0 0 0.2em 0.5em gold;
`;

const hoverBox = css`
  cursor: pointer;
  box-shadow: 0 0 0.2em 0.5em green, 0 0 0.2em 0.5em green;
`;

export { Box };
