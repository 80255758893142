/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player, PlayerType } from '@gamepark/the-hunger/player';
import { FC, useState } from 'react';
import { CardScore } from './CardScore';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { isEndOfGameEffect } from '@gamepark/the-hunger/utils/CardUtils';
import { VictoryPointSymbol } from '../../../../symbols';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { computeEndOfGameEffects } from '@gamepark/the-hunger/utils/EndOfGameUtils';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { useTranslation } from 'react-i18next';

type CardsScoreProps = {
  player: PlayerType;
  oldTokensCount?: boolean;
};

const CardsScore: FC<CardsScoreProps> = (props) => {
  const player = props.player as Player;
  const { oldTokensCount } = props;
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const cardWithEndOfGameEffect = [...player.discard, ...player.digest].filter((c) =>
    GameDeck[c].passiveEffects?.some((e) => isEndOfGameEffect(e))
  );

  const effects = cardWithEndOfGameEffect
    .flatMap((c) => GameDeck[c].passiveEffects?.filter((e) => isEndOfGameEffect(e)))
    .filter((e) => !!e)
    .map((e) => e!);
  const totalScore = computeEndOfGameEffects(effects, player, GameDeck, Bonuses(oldTokensCount));

  return (
    <>
      <div css={subtitle} onClick={() => setExpanded((expanded) => !expanded)}>
        <span>{t('scoring.playercards')}</span>
        <div>
          <span> + </span>
          <VictoryPointSymbol css={victoryPointStyle} value={totalScore?.toString()} width={3} hd bold />
        </div>
        <div css={search}>
          <FontAwesomeIcon icon={expanded ? faSearchMinus : faSearchPlus} />
        </div>
      </div>
      {expanded &&
        cardWithEndOfGameEffect.map((c) => (
          <CardScore key={c} hunt={c} player={player} oldTokensCount={oldTokensCount} />
        ))}
    </>
  );
};

const subtitle = css`
  grid-column: span 24;
  font-size: 4em;
  padding: 0.2em 0.2em 0.2em 0.5em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.06em solid lightgoldenrodyellow;
  cursor: pointer;

  > span {
    text-align: left;
    flex: 2;
  }

  > div:first-of-type {
    flex: 3;
    display: flex;
    align-items: center;
    justify-self: center;

    > span {
      font-family: monospace;
    }
  }
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 0.7em;
  margin: 0 0.2em;
`;

const search = css`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  right: 0.5em;

  > svg {
    cursor: pointer;
  }
`;

export { CardsScore };
