export enum GameMode {
  Elder = 1,
  Rookie
}

let values = Object.values(GameMode);
export const gameModes = values.filter(isGameMode)

function isGameMode(arg: string | GameMode): arg is GameMode {
  return typeof arg === 'number';
}
