/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { LastTurn } from '@gamepark/the-hunger/utils/GameConstants';
import { Images } from '../../images/Images';
import { turnCounterHeight, turnCounterLeft, turnCounterTop, turnCounterWidth } from '../../utils/Style';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export type TurnCounterProps = {
  round: number
  playerCount: number;
  playerHasParasol: boolean;
} & HTMLAttributes<HTMLDivElement>

export const TurnCounter: FC<TurnCounterProps> = ({ round, playerCount, playerHasParasol, ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <div css={ [turn(playerCount), turnStyle(round >= LastTurn)] } { ...props } data-tip data-for="turn-counter">
        <span css={ round >= LastTurn ? lastTurnStyle : otherTurnStyle }>{ round }</span>
      </div>
      <ReactTooltip className="the-hunger-tooltip" place="bottom" type="info" effect="solid" id="turn-counter">
        <span css={ remainingTurn }>{t('remaining.turn', { current: round, total: playerHasParasol? LastTurn + 1: LastTurn})}</span>
      </ReactTooltip>
    </>
  );
};

const turn = (playerCount: number) => css`
  position: absolute;
  width: ${ turnCounterWidth }%;
  height: ${ turnCounterHeight }%;
  left: ${ turnCounterLeft }%;
  top: ${ turnCounterTop(playerCount) }%;
  cursor: pointer;
`;

const turnStyle = (lastTurn?: boolean) => css`
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
  background-image: url(${ lastTurn ? Images.LastTurn : Images.Turn });
`;

const otherTurnStyle = css`
  position: absolute;
  color: gold;
  font-weight: bold;
  font-size: 3.4em;
  height: 50%;
  width: 50%;
  top: 16.6%;
  right: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const lastTurnStyle = css`
  position: absolute;
  color: #e7957b;
  font-weight: bold;
  font-size: 3.2em;
  height: 50%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.82em;
  left: 0.2em;
  letter-spacing: -2px;
`;

const remainingTurn = css`
  white-space: nowrap;
  font-size: 1.4em;
`
