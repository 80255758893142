import { GameEffect, GameEffects } from './GameEffect';
import { EffectType } from './EffectType';
import { isPlayer, PlayerType } from '../player';
import Move from '../moves/Move';
import GameState from '../GameState';
import { EffectTrigger } from './EffectTrigger';
import { getNextPendingEffect } from '../utils/PendingUtils';
import GameView from '../GameView';
import { hasPermanentTrait } from '../utils/CardUtils';
import Vampire from '../player/Vampire';
import {
  discardCardMove,
  DiscardCards,
  discardCardsMove,
} from '../moves/DiscardCards';
import { canDiscardCard, getPlayerHandSize } from '../utils/GameUtils';
import { isDiscard, isPushEffect } from '../utils/EffectUtils';
import { BoardBox } from '../board';
import { Card } from '../card/Card';
import { CardType } from '../card/CardType';

export class DiscardEffect implements GameEffect {
  type: EffectType.Discard = EffectType.Discard;
  itself?: boolean;
  card?: number;
  optional?: boolean;
  trigger?: EffectTrigger;
  allHand?: boolean;
  auto?: boolean;
  permanent?: boolean;
  otherPlayer?: boolean;
  vampire?: Vampire;
  huntTypes?: Array<CardType>;

  constructor(options?: Partial<DiscardEffect>) {
    if (options) {
      this.itself = options.itself;
      this.optional = options.optional;
      this.trigger = options.trigger;
      this.allHand = options.allHand;
      this.auto = options.auto;
      this.permanent = options.permanent;
      this.otherPlayer = options.otherPlayer;
      this.vampire = options.vampire;
      this.huntTypes = options.huntTypes;
    }
  }

  computePendingEffects(
    state: GameState | GameView,
    player: PlayerType,
    _board: BoardBox[],
    _contextCards: Card[],
    deck: Card[]
  ): Array<GameEffects> {
    // In case the effect is triggered by a push effect, the pending effect must be added to the pushed vampire
    const pendingEffect = getNextPendingEffect(player);
    if (this.otherPlayer && this.permanent && isPushEffect(pendingEffect)) {
      const permanentCards: number = state.players
        .find((p) => p.vampire === pendingEffect.vampire)!
        .playingArea.filter((c) => hasPermanentTrait(deck[c])).length;
      if (
        permanentCards -
        player.pendingEffects.filter((e) => isDiscard(e) && e.permanent)
          .length <=
        0
      ) {
        return [];
      }

      return [
        new DiscardEffect({permanent: true, vampire: pendingEffect.vampire}),
      ];
    }

    return [this];
  }

  legalMoves(_: GameState, player: PlayerType, deck: Card[]): Move[] {
    if (this.itself && this.card) {
      return this.auto ? [] : [discardCardMove(this.card, player.vampire)];
    }

    if (this.permanent) {
      return player.playingArea
        .filter((c) => hasPermanentTrait(deck[c]))
        .map((c) => discardCardMove(c, player.vampire));
    }

    return player.playingArea
      .filter((c) => canDiscardCard(player, c))
      .map((c) => discardCardMove(c, player.vampire));
  }

  static automaticMove(
    player: PlayerType,
    effect: DiscardEffect
  ): DiscardCards | undefined {
    if (isPlayer(player) && !effect.itself && effect.allHand && effect.auto) {
      return discardCardsMove([...player.hand], player.vampire, true);
    }

    if (effect.itself && effect.card && effect.auto) {
      return discardCardMove(effect.card, player.vampire);
    }

    return;
  }

  isPlayable(player: PlayerType): boolean {
    if (this.trigger === EffectTrigger.BeforePlaying) {
      return !!getPlayerHandSize(player);
    }

    return true;
  }
}
