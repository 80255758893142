import shuffle from 'lodash.shuffle';
import { Mission, Missions, MissionType } from './mission';
import { Player, TokenSide } from './player';
import { isStarting } from './card/vampire/Starters';
import GameState from './GameState';
import {
  MissionPerStack,
  NonVisibleTokenPosition,
  StartHandSize,
  StartupBeigeMissionCount,
  StartupMissionPerPlayer,
  TavernSize,
  VisibleTokenPosition,
} from './utils/GameConstants';
import { Hunt } from './hunt';
import { computeSpeedFromCards } from './utils/GameUtils';
import Vampire from './player/Vampire';
import { TheHungerPlayerOptions } from './TheHungerOptions';
import { GameDeck, isHunt, RookieStartingCards } from './card/hunt/GameDeck';
import { PositionedBonus } from './bonus/PositionedBonus';
import { Bonuses } from './bonus/Bonuses';
import { isRose } from './card/hunt/Rose';
import { GameMode } from './GameMode';
import { Card } from './card/Card';

/**
 * Initialize for game
 */
class GameInitializer {
  private playersOptions: { id: Vampire }[];
  private readonly mode: GameMode;
  protected readonly huntDeck: number[];
  protected allMissions: [number, Mission][];
  protected otherMissions: number[];
  protected beigeMissions: number[];
  protected oldTokensCount?: boolean;

  constructor(
    playersOptions: Array<TheHungerPlayerOptions>,
    mode: GameMode = GameMode.Rookie,
    oldTokensCount?: boolean
  ) {
    this.playersOptions = playersOptions;
    this.oldTokensCount = oldTokensCount;
    this.mode = mode;
    this.huntDeck = this.initializeHuntDeck();
    this.allMissions = this.getAllMissions().filter(
      (entry) => !entry[1].minPlayers || this.playersOptions.length >= entry[1].minPlayers
    );
    this.beigeMissions = shuffle(
      this.allMissions
        .filter((entry) => MissionType.Beige === entry[1].type)
        .filter((entry) => this.mode === GameMode.Elder || entry[1].gameMode === this.mode)
    ).map((entry) => entry[0]);
    this.otherMissions = shuffle([
      ...this.beigeMissions.slice(StartupBeigeMissionCount),
      ...this.allMissions
        .filter(
          (entry) =>
            MissionType.Gold === entry[1].type || (this.mode === GameMode.Rookie && entry[1].gameMode !== this.mode)
        )
        .map((entry) => entry[0]),
    ]);
  }

  protected getAllMissions = (): Array<[number, Mission]> => {
    return Array.from(Missions.entries());
  };

  protected initializeHuntDeck(): number[] {
    const deck = Array.from(GameDeck.entries()).filter((entry) => isHunt(entry[1]));
    switch (this.mode) {
      case GameMode.Elder:
        return shuffle(deck.map((entry) => entry[0]));
      case GameMode.Rookie:
        const starterCard = this.getStarterCards(deck);
        return starterCard
          .splice(0, this.playersOptions.length * 2 + 2)
          .concat(shuffle(starterCard.concat(deck.map((e) => e[0]))));
    }
  }

  protected getStarterCards(deck: [number, Card][]) {
    const cards = Array.from(RookieStartingCards.entries())
      .flatMap((startingCard) =>
        deck.splice(
          deck.findIndex((card) => card[1] === startingCard[1]),
          1
        )
      )
      .map((e) => e[0]);

    return shuffle(cards);
  }

  /**
   * Get the base game state
   */
  public getGameState(): GameState {
    const players = this.initializePlayers();
    let turnOrder = players.map((p) => p.vampire);
    return {
      players: players,
      bonusTokens: this.initializeBoardTokens(),
      roses: this.initializeRoses(),
      tavern: this.initializeTavern(),
      missions: this.initializeBoardMissions(),
      castleTokens: this.initializeCastleTokens(),
      hunt: this.initializeHuntTrack(),
      round: 1,
      turnOrder: turnOrder,
      mode: this.mode,
    };
  }

  private speedCompare() {
    return (playerA: Player, playerB: Player) =>
      computeSpeedFromCards(playerA, playerA.hand, GameDeck) - computeSpeedFromCards(playerB, playerB.hand, GameDeck);
  }

  /**
   * Initiialize hunt track depending on the player count : 1 + the number of player rows
   */
  protected initializeHuntTrack(): Hunt {
    const huntTrackSize = this.playersOptions.length + 1;
    const track: number[][][] = [];

    Array(huntTrackSize)
      .fill(0)
      .forEach((_) => track.push([[], [], []]));

    this.huntDeck.splice(0, huntTrackSize).forEach((card, index) => track[index][0].push(card));

    return {
      deck: this.huntDeck,
      track,
    };
  }

  protected initializeRoses(): Array<number> {
    return Array.from(GameDeck.entries())
      .filter((entry) => isRose(entry[1]))
      .map((entry) => entry[0]);
  }

  /**
   * Initialize bonus token on board
   */
  protected initializeBoardTokens(): Array<PositionedBonus> {
    const bonusTokens = shuffle(Array.from(Bonuses().entries())).slice(
      0,
      VisibleTokenPosition.length + NonVisibleTokenPosition.length
    );

    return [...VisibleTokenPosition, ...NonVisibleTokenPosition].map((p, index) => ({
      bonus: bonusTokens[index][0],
      position: p,
    }));
  }

  /**
   * Generate an array with one castle token per player. Each token is 2 points less from the previous
   */
  protected initializeCastleTokens(): number[] {
    const playerCount = this.playersOptions.length;
    if (playerCount > 3) {
      // Base table for 4, 5 or 6 players
      return [10, 8, 6, 4, 2].slice(0, playerCount);
    }

    // Base table for 2 or 3 players
    return [10, 6, 4].slice(0, playerCount);
  }

  /**
   * Initialize missions on board
   */
  protected initializeBoardMissions(): number[][] {
    const missions: number[][] = [];

    MissionPerStack.forEach((count, index) => {
      if (index < StartupBeigeMissionCount) {
        missions.push(this.beigeMissions.splice(0, count));
      } else {
        missions.push(this.otherMissions.splice(0, count));
      }
    });

    return missions;
  }

  protected initializePlayers() {
    return (
      this.playersOptions
        .map((player) => {
          const missionChoice = this.otherMissions.slice(0, StartupMissionPerPlayer);
          this.otherMissions = this.otherMissions.slice(StartupMissionPerPlayer);
          return this.initializePlayer(player, missionChoice);
        })
        // Sort them per
        .sort(this.speedCompare())
        .map((p, index) => {
          p.position.z = index + 1;
          return p;
        })
    );
  }

  /**
   * Initialize player deck and board
   * @param player The player to initialize
   * @param missionChoice The mission to choose at the start of the game
   */
  protected initializePlayer(player: { id: Vampire }, missionChoice: number[]): Player {
    const playerDeck = shuffle(
      Array.from(GameDeck.entries()).filter((entry) => isStarting(entry[1]) && entry[1].vampire === player.id)
    ).map((entry) => entry[0]);

    const startingHand = playerDeck.slice(0, StartHandSize);
    return {
      vampire: player.id,
      tokenSide: TokenSide.Active,
      position: { box: 0, z: 0 },
      hand: startingHand,
      deck: playerDeck.slice(StartHandSize),
      missionChoice: {
        missions: missionChoice,
      },
      digest: [],
      drawCount: 0,
      discard: [],
      playingArea: [],
      playedCards: [],
      hunts: [],
      missions: [],
      playedMissions: [],
      bonusTokens: [],
      usedBonuses: [],
      pendingEffects: [],
      uniques: [],
      score: 0,
    };
  }

  protected initializeTavern() {
    return this.mode === GameMode.Rookie ? this.huntDeck.splice(-TavernSize) : this.huntDeck.splice(0, TavernSize);
  }
}

export { GameInitializer };
