/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { MoveHuntTrackCardDirection, moveHuntTrackCardMove } from '@gamepark/the-hunger/moves/MoveHuntTrackCard';
import { usePlay } from '@gamepark/react-client';
import { Images } from '../../images/Images';
import { cardRatio } from '../../utils/Style';

type HuntTrackMoveOverlayProps = {
  card: number,
  left?: boolean,
  right?: boolean,
  top?: boolean,
  bottom?: boolean,
  onMove?: (event: any) => void
}


const HunTrackMoveOverlay: FC<HuntTrackMoveOverlayProps> = ({ card, left, top, right, bottom, onMove }) => {
  const play = usePlay();
  const moveTo = (event: any, direction: MoveHuntTrackCardDirection) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    if (onMove) {
      onMove(event);
    }
    play(moveHuntTrackCardMove(direction, card), { delayed: true });
  };

  return (
    <>
      { !!left && <span css={[ arrow, leftStyle ]} onClick={ (event) => moveTo(event, MoveHuntTrackCardDirection.LEFT) }/> }
      { !!top && <span css={[ arrow, topStyle ]} onClick={ (event) => moveTo(event, MoveHuntTrackCardDirection.TOP) }/> }
      { !!right && <span css={[ arrow, rightStyle ]} onClick={ (event) => moveTo(event, MoveHuntTrackCardDirection.RIGHT) }/> }
      { !!bottom && <span css={[ arrow, bottomStyle ]} onClick={ (event) => moveTo(event, MoveHuntTrackCardDirection.BOTTOM) }/> }
    </>
  );
};

const arrow = css`
  position: absolute;
  background-image: url(${Images.BasicArrow});
  background-size: cover;
  width: 15%;
  height: ${13 * cardRatio }%;
  cursor: pointer;
  
  &:hover {
    opacity: 0.5;
  }
`;

const leftStyle = css`
  left: 10%;
  top: 50%;
  transform: translateY(-50%) rotateZ(180deg);
`;

const topStyle = css`
  top: 10%;
  left: 50%;
  transform: translateX(-50%) rotateZ(-90deg);
`;

const rightStyle = css`
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
`;

const bottomStyle = css`
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) rotateZ(90deg);
`;

export {
  HunTrackMoveOverlay
};
