/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { victoryPointSymbolHeight, victoryPointSymbolWidth } from '../utils/Style';
import { Images } from '../images/Images';

type VictoryPointSymbolProps = {
  value: string;
  width?: number;
  bold?: boolean;
  hd?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const VictoryPointSymbol: FC<VictoryPointSymbolProps> = (props) => {
  const { value, bold, width, hd, ...rest } = props;
  return (
    <div css={mini(width, hd)} {...rest}>
      <div css={valueStyle(bold)}>{value}</div>
    </div>
  );
};

const mini = (width?: number, hd?: boolean) => css`
  height: ${victoryPointSymbolHeight(width, hd)}em;
  width: ${width || victoryPointSymbolWidth}em;
  background-image: url(${hd ? Images.Blood : Images.VictoryPointSymbol});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  top: 0.8em;
  position: relative;
  margin-bottom: -0.5em;
  margin-top: -0.5em;
  transform: translateY(-50%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
`;

const valueStyle = (bold?: boolean) => css`
  position: absolute;
  top: 60%;
  left: 50%;
  font-size: 0.9em;
  font-weight: ${bold ? 'bold' : 'normal'};
  color: white;
  transform: translate(-50%, -50%);
`;

export { VictoryPointSymbol };
