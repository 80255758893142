/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player, PlayerType } from '@gamepark/the-hunger/player';
import { FC } from 'react';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { getMissionScore } from '../score.utils';
import { computeEndOfGameCards } from '@gamepark/the-hunger/utils/EndOfGameUtils';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { VictoryPointSymbol } from '../../../../symbols';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { useTranslation } from 'react-i18next';

type BrutPointScoreProps = {
  player: PlayerType;
  players: PlayerType[];
  publicMissions: number[];
  mode: GameMode;
  oldTokensCount?: boolean;
};

const BrutPointScore: FC<BrutPointScoreProps> = (props) => {
  const { player, players, publicMissions, mode, oldTokensCount } = props;
  const { t } = useTranslation();

  const scoringWithPersonalMissions = (player as Player).missions.reduce(
    (accumulator, mission) => accumulator + (getMissionScore(player, players, mission.mission, oldTokensCount) || 0),
    0
  );

  const scoreWithPublicMissions = publicMissions.reduce(
    (accumulator, mission) => accumulator + (getMissionScore(player, players, mission, oldTokensCount) || 0),
    0
  );

  const brutPoint =
    player.score +
    (mode === GameMode.Rookie ? BoardBoxes[player.position.box].lostVictoryPoints || 0 : 0) -
    scoreWithPublicMissions -
    computeEndOfGameCards(player as Player, GameDeck, Bonuses(oldTokensCount)) -
    scoringWithPersonalMissions;

  return (
    <div css={subtitle}>
      <span>{t('scoring.brutpoint')}</span>
      <div>
        <span>&nbsp;</span>
        <VictoryPointSymbol css={victoryPointStyle} value={brutPoint?.toString()} width={3} hd bold />
      </div>
    </div>
  );
};

const subtitle = css`
  grid-column: span 24;
  font-size: 4em;
  padding: 0.2em 0.2em 0.2em 0.5em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.06em solid lightgoldenrodyellow;

  > span {
    text-align: left;
    flex: 2;
  }

  > div:first-of-type {
    flex: 4;
    display: flex;
    align-items: center;
    justify-self: center;

    > span {
      font-family: monospace;
    }
  }
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 0.7em;
  margin: 0 0.2em;
  padding-right: 0.5em;
`;

export { BrutPointScore };
