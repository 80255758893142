import { GameEffect, GameEffects } from './GameEffect';
import { EffectType } from './EffectType';
import GameState from '../GameState';
import Move from '../moves/Move';
import GameView from '../GameView';
import { acquireBonusTokenMove } from '../moves/AcquireBonus';
import { BoardBox } from '../board';
import { PositionedBonus } from '../bonus/PositionedBonus';
import { PlayerType } from '../player';
import { Card } from '../card/Card';

export class WinBonusTokenEffect implements GameEffect {
  type: EffectType.WinBonusToken = EffectType.WinBonusToken;

  constructor() {
  }

  computePendingEffects(state: GameState | GameView): GameEffects[] {
    if (state.bonusTokens.length) {
      return [this];
    }

    return [];
  }


  legalMoves(state: GameState): Move[] {
    if (!state.activePlayer) {
      return [];
    }

    return state.bonusTokens.flatMap(b => acquireBonusTokenMove(state.activePlayer!, b.position))
  }

  isPlayable(_player: PlayerType, _deck: Card[], _board?: BoardBox[], _track?: number[][][], bonuses?: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>): boolean {
    return !!bonuses && !!bonuses.length;
  }




}
