import GameView from '@gamepark/the-hunger/GameView';
import Vampire from '@gamepark/the-hunger/player/Vampire';

export type DisplayVampire = {
  type: 'DisplayVampire'
  vampire?: Vampire
}

export const displayVampireMove = (vampire?: Vampire): DisplayVampire => ({
  type: 'DisplayVampire', vampire
});

export function displayVampire(state: GameView, move: DisplayVampire) {
  state.displayedPlayer = move.vampire;
}
