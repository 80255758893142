import { DraggableTypes } from './DraggableTypes';

export type PlayableCardFromPlayingArea = {
  type: DraggableTypes.PlayableCard,
  card: number
}

export const playableCard = (card: number): PlayableCardFromPlayingArea => ({
  type: DraggableTypes.PlayableCard,
  card
});
