import { GameEffect } from './GameEffect';
import { EffectType } from './EffectType';
import Move from '../moves/Move';
import { placeInDiscardMove } from '../moves/PlaceInDiscard';
import { placeOnDeckMove } from '../moves/PlaceOnDeck';

export class ReadyEffect implements GameEffect {
  type: EffectType.Ready = EffectType.Ready;
  card: number;

  constructor(object: Partial<ReadyEffect>) {
    this.card = object.card!;
  }

  legalMoves(): Move[] {

    return [
      placeInDiscardMove([this.card]),
      placeOnDeckMove([this.card])
    ]
  }
};
