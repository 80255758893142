import GameState from '../GameState';
import { PlayerType } from '../player';
import GameView from '../GameView';
import { Card } from '../card/Card';
import { EffectType } from './EffectType';
import { BoardBox, BoardBoxEffect } from '../board';
import { ReadyEffect } from './ReadyEffect';
import { PushEffect } from './PushEffect';
import Move from '../moves/Move';
import Vampire from '../player/Vampire';
import { AdditionalHuntEffect } from './AdditionalHuntEffect';
import { ConfuseEffect } from './ConfuseEffect';
import { DiscardEffect } from './DiscardEffect';
import { DigestEffect } from './DigestEffect';
import { DrawEffect } from './DrawEffect';
import { FastEffect } from './FastEffect';
import { VictoryPointsPerTraitEffect } from './VictoryPointsPerTraitEffect';
import { VictoryPointEffect } from './VictoryPointEffect';
import { FreeHuntEffect } from './FreeHuntEffect';
import { SpeedBonusEffect } from './SpeedBonusEffect';
import { TeleportEffect } from './TeleportEffect';
import { SpicyEffect } from './SpicyEffect';
import { RoseEffect } from './RoseEffect';
import { RegionEffect } from './RegionEffect';
import { InspiringEffect } from './InspiringEffect';
import { IgnoreEffect } from './IgnoreEffect';
import { HuntTrackMoveEffect } from './HuntTrackMoveEffect';
import { HolyWaterEffect } from './HolyWaterEffect';
import { GregariousEffect } from './GregariousEffect';
import { EffectTrigger } from './EffectTrigger';
import { WinHuntTrackCardEffect } from './WinHuntTrackCardEffect';
import { WinBonusTokenEffect } from './WinBonusTokenEffect';
import { SkipTurnEffect } from './SkipTurnEffect';
import { Bonus } from '../bonus/Bonus';
import { PositionedBonus } from '../bonus/PositionedBonus';

/**
 * Base type for card effect
 */
export type GameEffects = GameEffect &
  (
    | AdditionalHuntEffect
    | ConfuseEffect
    | DiscardEffect
    | DigestEffect
    | DrawEffect
    | FastEffect
    | GregariousEffect
    | HolyWaterEffect
    | HuntTrackMoveEffect
    | IgnoreEffect
    | InspiringEffect
    | RegionEffect
    | RoseEffect
    | SpicyEffect
    | TeleportEffect
    | SpeedBonusEffect
    | FreeHuntEffect
    | VictoryPointEffect
    | VictoryPointsPerTraitEffect
    | ReadyEffect
    | PushEffect
    | WinHuntTrackCardEffect
    | WinBonusTokenEffect
    | SkipTurnEffect
  );

export type GameEffect = {
  type: EffectType;
  trigger?: EffectTrigger;
  once?: boolean;
  card?: number;
  mission?: number;
  bonusToken?: number;
  victoryPoint?: number;
  legalMoves?: (state: GameState, player: PlayerType, deck: Card[]) => Move[];
  computePendingEffects?: (
    state: GameState | GameView,
    player: PlayerType,
    board: BoardBox[],
    contextCards: Card[],
    deck: Card[]
  ) => GameEffects[];
  isPlayable?: (
    player: PlayerType,
    deck: Card[],
    board: BoardBox[],
    track?: number[][][],
    bonuses?: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>
  ) => boolean;
  isApplicable?: (
    player: PlayerType,
    deck: Card[],
    board?: BoardBox[],
    track?: number[][][],
    bonuses?: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>
  ) => boolean;
  computeScore?: (player: PlayerType, deck: Card[], bonuses: Bonus[]) => number;
  // Indicate that the effect can be played or not
  optional?: boolean;
  // Indicate that the effect must be played before doing anything else (its an obligation to play the effect)
  mandatory?: boolean;
  vampire?: Vampire;
};

export const isGameEffect = (effect: GameEffects | BoardBoxEffect): effect is GameEffects =>
  (effect as GameEffects).type !== undefined;
