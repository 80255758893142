/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PlayerType } from '@gamepark/the-hunger/player';
import { FC } from 'react';
import { VictoryPointSymbol } from '../../../../symbols';
import { isBurned } from '@gamepark/the-hunger/utils/GameUtils';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { useTranslation } from 'react-i18next';

type TotalScoreProps = {
  player: PlayerType;
  round: number;
  mode: GameMode;
  rank: number;
  oldTokensCount?: boolean;
};

const TotalScore: FC<TotalScoreProps> = (props) => {
  const { player, round, mode, rank, oldTokensCount } = props;
  const { t } = useTranslation();

  const burned = isBurned(player, round, mode, BoardBoxes, Bonuses(oldTokensCount));

  return (
    <div css={subtitle}>
      <span>{t('scoring.total')}</span>
      <div>
        <span> = </span>
        <VictoryPointSymbol css={victoryPointStyle} value={player.score?.toString()} width={3} hd bold />
      </div>
      <div css={totalText}>
        {burned && t('scoring.burned')}
        {!burned && rank > 0 && t('scoring.rank', { position: rank + 1 })}
        {!burned && rank === 0 && t('scoring.winner')}
      </div>
    </div>
  );
};

const subtitle = css`
  grid-column: span 24;
  font-size: 4em;
  padding: 0.2em 0.2em 0.2em 0.5em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  background-color: rgba(250, 250, 210);
  border: 0.06em solid lightgoldenrodyellow;

  > span {
    text-align: left;
    flex: 2;
  }

  > div:first-of-type {
    flex: 1;
    display: flex;
    align-items: center;
    justify-self: center;

    > span {
      font-family: monospace;
    }
  }
`;

const totalText = css`
  flex: 3;
  text-align: right;
  position: relative;
  right: 0.5em;
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 0.7em;
  margin: 0 0.2em;
`;

export { TotalScore };
