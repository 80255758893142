/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GameEffects } from '@gamepark/the-hunger/effect/GameEffect';
import { FC } from 'react';
import { EffectType } from '@gamepark/the-hunger/effect/EffectType';
import { Images } from '../../../images/Images';
import { playerEffectSummaryHeight, playerEffectSummaryLeft, playerEffectSummaryTop, playerEffectSummaryWidth } from '../../../utils/Style';
import { BoardBoxEffect } from '@gamepark/the-hunger/board';

type PlayerEffectProps = {
  effect: GameEffects | BoardBoxEffect
  index: number,
  opened: boolean,
  context: any,
  onIconClick: (e: any) => void
}

const isGameEffect = (effect: GameEffects | BoardBoxEffect): effect is GameEffects => (effect as GameEffects).type !== undefined
const getImage = (effect: GameEffects | BoardBoxEffect) => {
  if (isGameEffect(effect)) {
    return !IconPerEffect.has(effect.type) ? Images.VictoryPointSymbolHD : IconPerEffect.get(effect.type)
  }

  return !IconPerBoardEffect.has(effect) ? Images.VictoryPointSymbolHD : IconPerBoardEffect.get(effect)
}

const PlayerEffect: FC<PlayerEffectProps> = ({ effect, onIconClick, opened, context, index }) =>
  <>
    <div
      onClick={ onIconClick }
      css={ playerEffect(index, getImage(effect)) }>{ !!context.speed && <span css={playerEffectSpeed}>{context.speed}</span> }</div>
    { opened && <div css={effectPrevent(index)} /> }
  </>;

const playerEffect = (index: number, image: any) => css`
  background-image: url(${image});
  background-size: 100% 100%;
  position: absolute;
  height: ${ playerEffectSummaryHeight }%;
  width: ${ playerEffectSummaryWidth }%;
  top: ${ playerEffectSummaryTop(index) }%;
  left: ${ playerEffectSummaryLeft(index) }%;
`;

const playerEffectSpeed = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 11em;
  color: black;
  font-weight: bold;
`

const effectPrevent = (index: number) => css`
  position: absolute;
  height: ${ playerEffectSummaryHeight }%;
  width: ${ playerEffectSummaryWidth }%;
  top: ${ playerEffectSummaryTop(index) }%;
  left: ${ playerEffectSummaryLeft(index) }%;
  z-index: 2;
  
`

const IconPerBoardEffect = new Map<BoardBoxEffect, any>();
IconPerBoardEffect.set(BoardBoxEffect.Castle, Images.CantHuntSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Cemetery, Images.CantHuntSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Ship, Images.CantHuntSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Crypt, Images.InspiringSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Church, Images.HumanReligiousSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Barracks, Images.HumanMilitarySymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Mansion, Images.HumanNobleSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Market, Images.HumanVillagerSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Tavern, Images.HuntSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Well, Images.HuntSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Labyrinth, Images.HuntSymbolHD);
IconPerBoardEffect.set(BoardBoxEffect.Chest, Images.WinBonusSymbolHD);



const IconPerEffect = new Map<EffectType, any>();
IconPerEffect.set(EffectType.VictoryPoint, Images.VictoryPointSymbolHD);
IconPerEffect.set(EffectType.Digest, Images.DigestSymbolHD);
IconPerEffect.set(EffectType.Inspiring, Images.InspiringSymbolHD);
IconPerEffect.set(EffectType.HolyWater, Images.HolyWaterSymbolHD);
IconPerEffect.set(EffectType.Confuse, Images.ConfuseSymbolHD);
IconPerEffect.set(EffectType.Ignore, Images.PowerSymbolHD);
IconPerEffect.set(EffectType.Teleport, Images.PowerSymbolHD);
IconPerEffect.set(EffectType.Spicy, Images.SpicySymbolHD);
IconPerEffect.set(EffectType.AdditionalHunt, Images.AdditionalHuntSymbolHD);
IconPerEffect.set(EffectType.FreeHunt, Images.AdditionalHuntSymbolHD);
IconPerEffect.set(EffectType.Discard, Images.DiscardDrawSymbolHD);
IconPerEffect.set(EffectType.Draw, Images.DrawSymbolHD);
IconPerEffect.set(EffectType.WinHuntTrackCard, Images.AdditionalHuntSymbolHD);
IconPerEffect.set(EffectType.WinBonusToken, Images.WinBonusSymbolHD);
IconPerEffect.set(EffectType.SpeedBonus, Images.SpeedBonusSymbolHD);

export {
  PlayerEffect
};
