import MoveType from './MoveType';
import GameState from '../GameState';
import { NewRoundPhase } from '../round/NewRoundPhase';
import GameView from '../GameView';
import Move from './Move';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { isHuntTrackMove } from '../utils/EffectUtils';
import { isLastTurn, isOverLastTurn } from '../utils/TurnUtils';
import { canRefillTavern } from '../utils/TavernUtils';

export enum MoveHuntTrackCardDirection {
  LEFT = 1,
  RIGHT,
  TOP,
  BOTTOM
}

const isMoveHuntTrackDirection = (arg: string | MoveHuntTrackCardDirection): arg is MoveHuntTrackCardDirection => typeof arg === 'number';
export const moveHuntTrackDirections = Object.values(MoveHuntTrackCardDirection).filter(isMoveHuntTrackDirection);

export const isMoveHuntTrack = (move: Move): move is MoveHuntTrackCard => move.type === MoveType.MoveHuntTrackCard;

export type MoveHuntTrackCard = {
  type: MoveType.MoveHuntTrackCard,
  card?: number,
  direction: MoveHuntTrackCardDirection
}

export const moveHuntTrackCardMove = (direction: MoveHuntTrackCardDirection, card?: number): MoveHuntTrackCard => ({
  type: MoveType.MoveHuntTrackCard,
  direction,
  card
});

const moveTrackToRight = (state: GameState | GameView) => {
  state.hunt.track.forEach(line => {
    line[2].unshift(...line[1]);
    line[1] = line[0];
    line[0] = [];
  });
};

const moveCardOnTrack = (state: GameState | GameView, move: MoveHuntTrackCard) => {
  const card = move.card!;
  let found = false;
  state.hunt.track.forEach((line, lineIndex) => line.forEach((area, areaIndex) => {
    if (!found && area.includes(card)) {
      found = true;
      switch (move.direction) {
        case MoveHuntTrackCardDirection.LEFT:
          if (areaIndex === 0) {
            throw new Error('Card can\'t be moved to left since its already at the maximum left');
          }

          line[areaIndex - 1].push(card);
          break;
        case MoveHuntTrackCardDirection.RIGHT:
          if (areaIndex === 2) {
            throw new Error('Card can\'t be moved to right since its already at the maximum right');
          }

          line[areaIndex + 1].unshift(card);
          break;
        case MoveHuntTrackCardDirection.TOP:
          if (lineIndex === 0) {
            throw new Error('Card can\'t be moved to top since its already at the maximum top');
          }
          state.hunt.track[lineIndex - 1][areaIndex].push(card);
          break;

        case MoveHuntTrackCardDirection.BOTTOM:
          if (lineIndex === (state.hunt.track.length - 1)) {
            throw new Error('Card can\'t be moved to bottom since its already at the maximum bottom');
          }
          state.hunt.track[lineIndex + 1][areaIndex].push(card);
          break;
      }

      area.splice(area.findIndex(c => c === card), 1);
    }
  }));

};

export const moveHuntTrackCard = (state: GameState | GameView, move: MoveHuntTrackCard) => {
  if (!move.card) {
    moveTrackToRight(state);
  } else {
    moveCardOnTrack(state, move);
  }

  if (state.newRoundPhase === NewRoundPhase.MoveHuntTrackCards) {
    if (isLastTurn(state.round) || isOverLastTurn(state.round)) {
      if (canRefillTavern(state)) {
        state.newRoundPhase = NewRoundPhase.FillTavern;
      } else {
        state.players.forEach(p => delete p.end);
        delete state.newRoundPhase;
      }
    } else {
      state.newRoundPhase = NewRoundPhase.FillHuntTrack;
    }
  }

  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  const pendingEffect = getNextPendingEffect(player);
  if (isHuntTrackMove(pendingEffect)) {
    removeNextPendingEffect(player);
  }
};
