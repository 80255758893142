export enum BoardBoxEffect {
  Castle = 1,
  Cemetery,
  Chest,
  Crypt,
  Labyrinth,
  Market,
  Church,
  Mansion,
  Barracks,
  Ship,
  Tavern,
  Well
}