/**
 * Hunt card category
 */
export enum CardType {
  HumanVillager = 1,
  HumanReligious,
  HumanMilitary,
  HumanNoble,
  StartingCard,
  Power,
  Familiar,
  Item,
  Rose
}

export const HumanTypes = [CardType.HumanMilitary, CardType.HumanNoble, CardType.HumanVillager, CardType.HumanReligious];