import { Player, PlayerType } from '@gamepark/the-hunger/player';
import { Missions } from '@gamepark/the-hunger/mission';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';

export const getMissionScore = (
  player: PlayerType,
  players: PlayerType[],
  mission: number,
  oldTokensCount?: boolean
): number | undefined => {
  let m = Missions[mission];
  if (m.computeScore && player) {
    return m.computeScore(
      player as Player,
      players.filter((p) => player!.vampire !== p.vampire) as Array<Player>,
      GameDeck,
      BoardBoxes,
      Bonuses(oldTokensCount)
    );
  }

  return undefined;
};
