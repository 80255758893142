/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes, useRef, useState } from 'react';
import { Images } from '../../images/Images';
import { playerCountersHeight, playerCountersWidth } from '../../utils/Style';
import { SerializedStyles } from '@emotion/serialize';
import { useTranslation } from 'react-i18next';

export type PlayerCountersProps = {
  speed?: number;
  additionalHuntSpeed?: number;
  victoryPoints: number;
  width?: number;
  victoryPointCss?: SerializedStyles;
} & HTMLAttributes<HTMLDivElement>;

const PlayerCounters: FC<PlayerCountersProps> = ({
  speed,
  additionalHuntSpeed,
  victoryPoints,
  victoryPointCss,
  width,
  ...props
}) => {
  const { t } = useTranslation();
  const [displaySpeedPrecision, setDisplaySpeedPrecision] = useState(false);
  const speedPrecisionTimer = useRef<any>();

  const displayPopup = (event: any) => {
    if (!additionalHuntSpeed) {
      return null;
    }

    event.stopPropagation?.();

    if (speedPrecisionTimer.current) {
      clearTimeout(speedPrecisionTimer.current);
      setDisplaySpeedPrecision(false);
      speedPrecisionTimer.current = undefined;
      return;
    }

    setDisplaySpeedPrecision(true);

    speedPrecisionTimer.current = setTimeout(() => {
      setDisplaySpeedPrecision(false);
      speedPrecisionTimer.current = undefined;
    }, 2000);

    return;
  };

  const speedPrecisionPopup = (
    <div css={speedPrecision}>{t('player.additionalHuntSpeed', { speed: `+${additionalHuntSpeed}` })}</div>
  );

  return (
    <div css={counters} {...props}>
      <div css={victoryPointsStyle(victoryPointCss)}>{victoryPoints}</div>
      <div css={speedStyle} onClick={displayPopup}>
        {displaySpeedPrecision && speedPrecisionPopup}
        {speed === undefined ? '-' : `${speed}${additionalHuntSpeed ? `+${additionalHuntSpeed}` : ''}`}
      </div>
    </div>
  );
};

const victoryPointsStyle = (victoryPointCss?: any) => css`
  position: absolute;
  height: 50%;
  width: 100%;
  color: white;
  background-image: url(${Images.Blood});
  font-weight: bold;
  font-size: 15em;
  background-size: 100% 100%;
  border-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 25%;
  padding-right: 1.5%;
  transition: 0.3s transform, 0.3s z-index;
  ${victoryPointCss}
`;

const speedStyle = css`
  position: absolute;
  top: 50%;
  height: 50%;
  width: 100%;
  color: black;
  background-image: url(${Images.Speed2});
  font-weight: bold;
  font-size: 18em;
  background-size: 100% 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 6%;
  cursor: pointer;
`;

const counters = css`
  position: fixed;
  z-index: 4;
  height: ${playerCountersHeight}%;
  width: ${playerCountersWidth}%;
  font-size: ${playerCountersHeight / 100}em;
  font-weight: bold;
  bottom: 1.2%;
  left: 1%;
`;

const speedPrecision = css`
  position: absolute;
  left: 0;
  background-color: white;
  padding: 0.5em;
  border-radius: 0.6em;
  transform: translateX(-100%);
  white-space: nowrap;
`;

export { PlayerCounters };
