/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes, useState } from 'react';
import { Hand } from '@gamepark/react-components';
import {
  cardRatio,
  getDeckCardFromDeckTranslateX,
  getDeckCardFromDeckTranslateY,
  getDiscardCardFromDiscardTranslateX,
  getDiscardCardFromDiscardTranslateY,
  getHandCardToPlayingAreaCardTranslateX,
  handCardToPlayingAreaCardTranslateY,
  playerHandCardHeight,
  playerHandCardWidth,
  playerHandLeft,
  playerHandTop,
} from '../../utils/Style';
import { HuntCard } from '../card/HuntCard';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { DraggableTypes } from '../draggable/DraggableTypes';
import { huntCardFromHand } from '../draggable/HuntCardFromHand';
import { useAnimation, usePlayerId } from '@gamepark/react-client';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { isMyPlayerHand } from '@gamepark/the-hunger/player';
import { HuntCardsCatalog } from '../card/HuntCardsCatalog';
import { isPlaceInPlayingAreaMove } from '@gamepark/the-hunger/moves/PlaceInPlayingArea';
import { DiscardCardDelay, DiscardCardDuration, DrawCardDelay, DrawCardDuration, PlaceInPlayingAreaCardDelay, PlaceInPlayingAreaCardDuration } from '@gamepark/the-hunger/utils/AnimationsConstants';
import { isDrawCardMove, isDrawCardMoveView } from '@gamepark/the-hunger/moves/DrawCards';
import { DeckLimit, DiscardLimit } from '@gamepark/the-hunger/utils/GameConstants';
import { ItemProps } from '@gamepark/react-components/dist/Hand/Hand';
import { isDiscardCardMove } from '@gamepark/the-hunger/moves/DiscardCards';

export type PlayerHandProps = {
  activePlayer?: Vampire
  displayedPlayer: Vampire,
  playingAreaLength: number,
  deckLength: number,
  discardLength: number,
  hand: number[] | number;
  visible: boolean;
} & HTMLAttributes<HTMLDivElement>

const PlayerHand: FC<PlayerHandProps> = ({ hand, activePlayer, displayedPlayer, playingAreaLength, deckLength, discardLength, visible, ...props }) => {
  const playerId = usePlayerId();

  const [cardToDisplay, setCardToDisplay] = useState<number>();
  const animation = useAnimation(animation => (isPlaceInPlayingAreaMove(animation.move) || isDrawCardMoveView(animation.move) || isDrawCardMove(animation.move) || isDiscardCardMove(animation.move)) && activePlayer === displayedPlayer);

  const placeInPlayingArea = animation && isPlaceInPlayingAreaMove(animation.move) ? animation.move : undefined;
  const drawCardMove = animation && isDrawCardMove(animation.move) ? animation.move : undefined;
  const drawCardMoveView = animation && isDrawCardMoveView(animation.move) ? animation.move : undefined;
  const discardCardMove = animation && isDiscardCardMove(animation.move) && animation.move.hand ? animation.move : undefined;

  const discardCardDuration: number = animation && discardCardMove ? animation.duration * DiscardCardDuration / (DiscardCardDuration + (discardCardMove.cards.length - 1) * DiscardCardDelay) : 0;
  const discardCardDelay = animation && discardCardMove && discardCardDuration && discardCardMove.cards.length > 1 ? ((animation.duration * 100 - discardCardDuration * 100) / 100 / (discardCardMove.cards.length - 1)) : 0;

  const placeInPlayingAreaCardDuration: number = animation && placeInPlayingArea ? animation.duration * PlaceInPlayingAreaCardDuration / (PlaceInPlayingAreaCardDuration + (placeInPlayingArea.cards.length - 1) * PlaceInPlayingAreaCardDelay) : 0;
  const placeInPlayingAreaCardDelay = animation && placeInPlayingArea && placeInPlayingAreaCardDuration && placeInPlayingArea.cards.length > 1 ? ((animation.duration * 100 - placeInPlayingAreaCardDuration * 100) / 100 / (placeInPlayingArea.cards.length - 1)) : 0;

  const isDrawCardMoveOrView = drawCardMove || drawCardMoveView;
  const drawnCardCount = isDrawCardMoveOrView ? (drawCardMove ? Math.min(drawCardMove.count, deckLength) : drawCardMoveView!.cards.length) : 0;
  const drawCardDuration: number = animation && isDrawCardMoveOrView ? animation.duration * DrawCardDuration / (DrawCardDuration + (drawnCardCount - 1) * DrawCardDelay) : 0;
  const drawCardDelay: number = animation && isDrawCardMoveOrView && drawCardDuration && drawnCardCount > 1 ? ((animation.duration * 100 - drawCardDuration * 100) / 100 / (drawnCardCount - 1)) : 0;

  const deckStackSize = isDrawCardMoveOrView ? Math.min(deckLength - drawnCardCount, DeckLimit) : deckLength;
  const discardStackSize = Math.min(deckLength, DeckLimit);


  const hideOverlay = (event: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    setCardToDisplay(undefined);
  };

  const getCard = (card: number) => {
    return GameDeck[card];
  };

  const isDisabled = (displayedPlayer && displayedPlayer !== playerId) || activePlayer !== playerId;

  function displayCard(event: any, cardIndex: number) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    if (!isMyPlayerHand(hand)) {
      return;
    }

    setCardToDisplay(hand[cardIndex]);
  }

  let playerHand = isMyPlayerHand(hand) ? [...hand] : hand;
  let handSize = isMyPlayerHand(hand) ? hand.length : hand;

  if (isDrawCardMoveOrView) {
    if (isMyPlayerHand(playerHand)) {
      playerHand.push(...(drawCardMoveView ? drawCardMoveView.cards : []));
      handSize = playerHand.length
    } else if (drawCardMove) {
      playerHand += Math.min(drawCardMove.count, deckLength);
      handSize = playerHand;
    }
  }

  const getItemProps = (index: number): ItemProps<any, any> => {
    return ({
      hoverStyle: isMyPlayerHand(hand) ? css`transform: translateY(-25%) scale(1.5);` : undefined,
      drag: {
        type: DraggableTypes.HuntCardFromHand,
        item: huntCardFromHand(hand[index]),
        canDrag: !isDisabled && !placeInPlayingArea && !drawCardMove && !drawCardMoveView && !discardCardMove
      },
      css: drawCardMoveView ? reverseZIndex(handSize, index, drawCardDuration + (drawCardDelay * index)) : (!visible ? invisibleCard : selectable),
      animation: animation ? {
        seconds: animation.duration * 0.6,
        delay: isDrawCardMoveOrView ? animation.duration * 0.4 : 0,
        fromNeutralPosition: !!isDrawCardMoveOrView,
        toNeutralPosition: !!placeInPlayingArea || !!discardCardMove
      } : undefined,
      onClick: (event: any) => displayCard(event, index),
    });
  };

  return (
    <>
      { cardToDisplay !== undefined && <HuntCardsCatalog hunts={ [cardToDisplay] } onClose={ hideOverlay }/> }
      <Hand css={ [handStyle] } rotationOrigin={ 50 } gapMaxAngle={ 0.72 } maxAngle={ 10 } sizeRatio={ cardRatio } getItemProps={ getItemProps } { ...props }>
        {
          isMyPlayerHand(playerHand) && playerHand.map((card, index) => {
            return <HuntCard
              key={ `player-hand-${ card }` }
              hunt={ getCard(card) }
              css={ [
                handCardStyle,
                (cardToDisplay === card || !visible) && invisibleCard,
                isDrawCardMoveOrView && drawCardMoveView && drawCardMoveView.cards.includes(card) && animateDrawCard(drawnCardCount - 1 - index, drawnCardCount, deckStackSize, drawCardDuration, drawCardDelay),
                discardCardMove && discardCardMove.cards.includes(card) && animateDiscardCard(index, discardStackSize, discardCardDuration, discardCardDelay),
                placeInPlayingArea && animatePlaceInPlayingArea(index, playingAreaLength, playingAreaLength + placeInPlayingArea.cards.length, placeInPlayingAreaCardDuration, placeInPlayingAreaCardDelay)
              ] }
            />;
          })
        }
        {
          !isMyPlayerHand(playerHand) && [...Array(playerHand)].map((_, index) => {
            return <HuntCard
              key={ `player-hand-${ index }` }
              hunt={ (placeInPlayingArea || discardCardMove) ? GameDeck[(placeInPlayingArea || discardCardMove)!.cards[index]] : undefined }
              css={ [
                handCardStyle,
                placeInPlayingArea && hiddenCard,
                isDrawCardMoveOrView && drawCardMove && index >= hand && animateDrawCard(index, drawnCardCount, deckStackSize, drawCardDuration, drawCardDelay),
                discardCardMove && animateRevealDiscardCard(index, discardStackSize, discardCardDuration, discardCardDelay),
                placeInPlayingArea && animateRevealPlaceInPlayingArea(index, playingAreaLength, playingAreaLength + placeInPlayingArea.cards.length, placeInPlayingAreaCardDuration, placeInPlayingAreaCardDelay),
                !visible && invisibleCard
              ] }
            />;
          })
        }
      </Hand>
    </>
  );
};

const handStyle = css`
  height: ${ playerHandCardHeight }%;
  width: ${ playerHandCardWidth }%;
  top: ${ playerHandTop }%;
  left: ${ playerHandLeft }%;
  font-size: ${ playerHandCardHeight / 100 }em;
`;

const handCardStyle = css`
  height: 100%;
  width: 100%;
`;

const selectable = css`
  cursor: pointer;
`;

const invisibleCard = css`
  opacity: 0;
  pointer-events: none;
  cursor: default;

  &:before, > * {
    pointer-events: none !important;
  }
`;

const animateDrawCard = (index: number, drawnCardCount: number, deckStackSize: number, duration: number, delay: number) => css`
  animation: ${ duration }s ${ drawCardAnimation(index, drawnCardCount, deckStackSize) } ${ delay * (drawnCardCount - index - 1) }s ease-in-out both; 
`;

const drawCardAnimation = (index: number, _drawnCardCount: number, deckStackSize: number) => {
  return keyframes`
    from {
      transform: translate(${ getDeckCardFromDeckTranslateX(index, deckStackSize, DeckLimit) }%, ${ getDeckCardFromDeckTranslateY(index, deckStackSize, DeckLimit) }%) rotateY(180deg);
    }
  `;
};

const animateDiscardCard = (index: number, discardStackSize: number, duration: number, delay: number) => css`
  animation: ${ duration }s ${ discardCardAnimation(index, discardStackSize) } ${ delay * index }s ease-in-out both;
`;

const discardCardAnimation = (index: number, discardStackSize: number) => keyframes`
  to {
    transform: translate(${ getDiscardCardFromDiscardTranslateX(index, discardStackSize, DiscardLimit) }%, ${ getDiscardCardFromDiscardTranslateY(index, discardStackSize, DiscardLimit) }%);
  }
`;

const animateRevealDiscardCard = (index: number, discardStackSize: number, duration: number, delay: number) => css`
  animation: ${ duration }s ${ revealDiscardCardAnimation(index, discardStackSize) } ${ delay * index }s ease-in-out both;
`;

const revealDiscardCardAnimation = (index: number, discardStackSize: number) => keyframes`
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: translate(${ getDiscardCardFromDiscardTranslateX(index, discardStackSize, DiscardLimit) }%, ${ getDiscardCardFromDiscardTranslateY(index, discardStackSize, DiscardLimit) }%);
  }
`;

/* Place in playing area for current player */
const animatePlaceInPlayingArea = (index: number, playingAreaSize: number, newPlayingAreaSize: number, duration: number, delay: number) => css`
  animation: ${ duration }s ${ placeInPlayingAreaAnimation(index, playingAreaSize, newPlayingAreaSize) } ${ delay * index }s ease-in-out both;
`;

const placeInPlayingAreaAnimation = (index: number, playingAreaSize: number, newPlayingAreaSize: number) => keyframes`
  to {
    transform: translate(${ getHandCardToPlayingAreaCardTranslateX(index, playingAreaSize, newPlayingAreaSize) }%, ${ handCardToPlayingAreaCardTranslateY }%);
  }
`;

/* Place in playing area for other player (hidden cards) */
const animateRevealPlaceInPlayingArea = (index: number, playingAreaSize: number, newPlayingAreaSize: number, duration: number, delay: number) => css`
  animation: ${ duration }s ${ revealPlaceInPlayingAreaAnimation(index, playingAreaSize, newPlayingAreaSize) } ${ delay * index }s ease-in-out both;
`;

const revealPlaceInPlayingAreaAnimation = (index: number, playingAreaSize: number, newPlayingAreaSize: number) => keyframes`
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: translate(${ getHandCardToPlayingAreaCardTranslateX(index, playingAreaSize, newPlayingAreaSize) }%, ${ handCardToPlayingAreaCardTranslateY }%);
  }
`;

const hiddenCard = css`
  transform: rotateY(180deg);
`;

const animateReverseZIndex = (cardCount: number, index: number) => keyframes`
  from {
    z-index: ${ (cardCount - index) * 10 }
  }
  to {
    z-index: ${ index * 10 }
  }
`;
const reverseZIndex = (cardCount: number, index: number, duration: number) => css` 
  animation: ${ animateReverseZIndex(cardCount, index) } ${ duration }s ease-in-out both;
`;

export {
  PlayerHand
};
