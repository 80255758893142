/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes, useState } from 'react';
import { HuntCard, HuntCardProps } from '../card/HuntCard';
import { canActivateCard } from '@gamepark/the-hunger/utils/CardUtils';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { PlayerType } from '@gamepark/the-hunger/player';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { cardRatio } from '../../utils/Style';
import { canDigestCards, canDiscardCard } from '@gamepark/the-hunger/utils/GameUtils';
import { HuntCardsCatalog } from '../card/HuntCardsCatalog';
import { TheHungerButton } from '../../utils/TheHungerButton';
import { DraggableTypes } from '../draggable/DraggableTypes';
import { playableCard } from '../draggable/PlayableCardFromPlayingArea';
import { activateCardMove } from '@gamepark/the-hunger/moves/ActivateCard';
import { discardCardMove, DiscardCards } from '@gamepark/the-hunger/moves/DiscardCards';
import { digestCardMove, DigestCards } from '@gamepark/the-hunger/moves/DigestCards';
import { BoardBoxes } from '@gamepark/the-hunger/board';

type PlayableCardProps = {
  activePlayer?: Vampire;
  player: PlayerType;
  card: number;
  preTransform?: string;
  isGameOver?: boolean;
} & HTMLAttributes<HTMLDivElement> &
  HuntCardProps;

const PlayableCard: FC<PlayableCardProps> = ({
  activePlayer,
  player,
  card,
  onFocus,
  isGameOver,
  preTransform,
  ...props
}) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const [isFocused, setFocused] = useState<boolean>(false);
  const animation = useAnimation();

  const hideOverlay = (event: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    setFocused(false);
  };

  const canBeActivated =
    !isGameOver &&
    !animation &&
    activePlayer === player.vampire &&
    player.vampire === playerId &&
    canActivateCard(player, card, GameDeck, BoardBoxes);
  const canBeDiscarded = !isGameOver && !animation && player.vampire === playerId && canDiscardCard(player, card);
  const canBeDigested =
    !isGameOver && !animation && player.vampire === playerId && canDigestCards(player, [card], GameDeck);

  let canBeDigestedOrDiscarded = canBeDiscarded || canBeDigested;
  const isActivable = canBeActivated || canBeDigestedOrDiscarded;

  const playEffect = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (canBeActivated) {
      if (isFocused) {
        hideOverlay(event);
      }

      return play(activateCardMove(card));
    }

    if (isFocused) {
      hideOverlay(event);
    }

    return;
  };

  const playCard = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (!(canBeDiscarded || canBeDigested)) {
      if (!isFocused) {
        setFocused(true);
      }

      return;
    }

    if (isFocused) {
      hideOverlay(event);
    }

    if (canBeDigested) {
      return play(digestCardMove(card));
    }

    if (canBeDiscarded) {
      return play(discardCardMove(card, player.vampire));
    }
  };

  const buttonKey = () => {
    if (canBeActivated) {
      return 'button.activate.effect';
    }

    if (canBeDigested) {
      return 'button.to.digest';
    }

    if (canBeDiscarded) {
      return 'button.to.discard';
    }

    return;
  };

  const onDropCard = (move: DigestCards | DiscardCards) => play(move);
  return (
    <>
      {isFocused && (
        <HuntCardsCatalog
          hunts={[card]}
          onClose={hideOverlay}
          topComponents={
            isActivable
              ? [
                  <TheHungerButton
                    key={`context-button`}
                    onClick={canBeActivated ? playEffect : playCard}
                    css={activationButton}
                    labelKey={buttonKey()!}
                  />,
                ]
              : []
          }
        />
      )}
      <HuntCard
        type={DraggableTypes.PlayableCard}
        item={() => playableCard(card)}
        drop={onDropCard}
        isDraggable={true}
        preTransform={preTransform}
        canDrag={canBeDigestedOrDiscarded}
        hunt={GameDeck[card]}
        onClick={() => setFocused(true)}
        onEffectClick={playEffect}
        isEffectActivable={canBeActivated}
        css={[
          !canBeDigestedOrDiscarded && pointable,
          canBeDigestedOrDiscarded && activableCard,
          isFocused && hiddenCard,
        ]}
        {...props}
      />
    </>
  );
};

const activableCard = css`
  box-shadow: 0 0 0.3em 1.5em gold, 0 0 0.3em 1.5em gold;
  border-radius: 6% / ${cardRatio * 6}%;
`;

const pointable = css`
  cursor: pointer;
  transition-duration: 0.5s;
`;

const hiddenCard = css`
  opacity: 0;
`;

const activationButton = css`
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 5em;
`;

export { PlayableCard };
