/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { MissionTile } from '../../../mission/MissionTile';
import { Missions } from '@gamepark/the-hunger/mission';
import { missionRatio } from '../../../../utils/Style';
import { PlayerType } from '@gamepark/the-hunger/player';
import { VictoryPointSymbol } from '../../../../symbols';
import { useTranslation } from 'react-i18next';
import { getMissionScore } from '../score.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';

type PublicMissionScoreProps = {
  player: PlayerType;
  players: PlayerType[];
  missions: number[];
  oldTokensCount?: boolean;
};

const PublicMissionScore: FC<PublicMissionScoreProps> = (props) => {
  const { missions, players, player, oldTokensCount } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const score = missions.reduce(
    (accumulator, mission) => accumulator + (getMissionScore(player, players, mission, oldTokensCount) || 0),
    0
  );

  return (
    <>
      <div css={subtitle} onClick={() => setExpanded((expanded) => !expanded)}>
        <span>{t('scoring.commonmissions')}</span>
        <div>
          <span> + </span>
          <VictoryPointSymbol css={victoryPointStyle} value={score?.toString()} width={3} hd bold />
        </div>
        <div css={search}>
          <FontAwesomeIcon icon={expanded ? faSearchMinus : faSearchPlus} />
        </div>
      </div>
      {expanded && (
        <>
          <div css={[container, publicMissionContainer]}>
            <MissionTile css={missionStyle} mission={Missions[missions[0]]} />
            <span css={equals}>=</span>
          </div>
          <div css={[container, victoryPointContainer]}>
            <VictoryPointSymbol
              value={(getMissionScore(player, players, missions[0], oldTokensCount) || 0).toString()}
              hd
              css={missionVictoryPointStyle}
              width={3}
              bold
            />
          </div>
          <div css={[container, publicMissionContainer]}>
            <MissionTile css={missionStyle} mission={Missions[missions[1]]} />
            <span css={equals}>=</span>
          </div>
          <div css={[container, victoryPointContainer]}>
            <VictoryPointSymbol
              value={(getMissionScore(player, players, missions[1], oldTokensCount) || 0).toString()}
              hd
              css={missionVictoryPointStyle}
              width={3}
              bold
            />
          </div>
        </>
      )}
    </>
  );
};

const subtitle = css`
  grid-column: span 24;
  font-size: 4em;
  padding: 0.2em 0.2em 0.2em 0.5em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.06em solid lightgoldenrodyellow;
  cursor: pointer;

  > span {
    text-align: left;
    flex: 2;
  }

  > div:first-of-type {
    flex: 3;
    display: flex;
    align-items: center;
    justify-self: center;

    > span {
      font-family: monospace;
    }
  }
`;

const search = css`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  right: 0.5em;

  > svg {
    cursor: pointer;
  }
`;

const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const firstLineHeight = 14;
const missionHeight = firstLineHeight;
const missionStyle = css`
  height: ${missionHeight}em;
  width: ${missionHeight / missionRatio}em;
  margin: 0 3em;

  > div {
    font-size: ${missionHeight / 100}em;
  }
`;

const publicMissionContainer = css`
  display: flex;
  flex-direction: row;
  flex: 2;
  align-items: center;
  justify-content: flex-end;
  grid-column: span 8;
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 0.7em;
  margin: 0 0.2em;
`;

const missionVictoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 3em;
  margin: 0 0.2em;
`;

const victoryPointContainer = css`
  grid-column: span 4;
  justify-content: flex-start;
`;

const equals = css`
  font-size: 5em;
`;

export { PublicMissionScore };
