import { SVGProps } from 'react';

const MedalBronze = (props: SVGProps<any>) => (
  <svg width="38px" height="49px" viewBox="0 0 38 49" {...props}>
    <g transform="translate(10.133333, 10.051282)" fill="#C04D55">
      <polygon transform="translate(15.374006, 2.381707) rotate(-82.007686) translate(-15.374006, -2.381707) "
               points="13.1339224 1.68890753 17.639673 1.61839427 17.6140901 3.07450669 13.1083396 3.14501996"/>
      <polygon transform="translate(1.106125, 2.517676) rotate(-7.992314) translate(-1.106125, -2.517676) "
               points="0.415829824 0.117552627 1.87194225 0.0919697396 1.79641986 4.91780034 0.340307434 4.94338323"/>
    </g>
    <path fill="#FB963F"
          d="M36.7333333,30.7815773 C36.7333333,35.8068587 34.672217,40.3628864 31.3436765,43.6618827 C28.0180014,46.958983 23.4287225,49 18.3666667,49 C18.014233,49 17.6646646,48.9905201 17.3170064,48.9706124 C12.6723314,48.7099158 8.48419657,46.7295701 5.38965679,43.6618827 C2.06111631,40.3628864 0,35.8068587 0,30.7815773 C0,25.7591398 2.06016121,21.2050081 5.38583637,17.9060118 C8.48037615,14.8364285 12.6694661,12.8541867 17.3170064,12.5934902 C17.6646646,12.5735824 18.014233,12.5641026 18.3666667,12.5641026 C23.4306327,12.5641026 28.0218218,14.6070155 31.347497,17.9060118 C34.6731721,21.2050081 36.7333333,25.7591398 36.7333333,30.7815773 Z"/>
    <path fill="#F66C4C"
          d="M31.6666667,18.0877724 L30.5540112,19.2023088 L18.3715596,31.4063354 L18.3666667,31.4102564 L5.06666667,18.0877724 C8.23729429,14.9137453 12.5293846,12.864057 17.2911976,12.5944901 C17.6474039,12.573905 18.0055674,12.5641026 18.3666667,12.5641026 C23.5551443,12.5641026 28.2592206,14.6765264 31.6666667,18.0877724 Z"/>
    <path fill="#F66C4C"
          d="M31.6666667,43.4779534 C28.2582174,46.8886581 23.5547141,49 18.3666667,49 C18.0054611,49 17.6471922,48.9901935 17.290881,48.9695998 C12.5306028,48.6999207 8.23822772,46.6513405 5.06666667,43.4779534 L18.3627512,30.1587494 L18.3666667,30.1538462 L31.6666667,43.4779534 Z"/>
    <path fill="#F66C4C"
          d="M38,30.7815773 C38,35.8068587 35.8486169,40.3628864 32.3743027,43.6618827 C28.9029793,46.958983 24.1127125,49 18.8289635,49 C18.461095,49 18.0962172,48.9905201 17.7333333,48.9706124 C27.8103399,48.4302595 35.8067457,40.4937087 35.8067457,30.7815773 C35.8067457,21.0703939 27.8103399,13.133843 17.7333333,12.5934902 C18.0962172,12.5735824 18.461095,12.5641026 18.8289635,12.5641026 C24.1147064,12.5641026 28.9069671,14.6070155 32.3782905,17.9060118 C35.8496139,21.2050081 38,25.7591398 38,30.7815773 Z"/>
    <path fill="#DE4F42"
          d="M30.5879386,42.7179487 L31.6666667,43.7733111 C28.3631213,47.0015874 23.8043806,49 18.7760089,49 C18.4259204,49 18.0786781,48.990718 17.7333333,48.9712258 C22.8774524,48.6871969 27.4513732,46.3193611 30.5879386,42.7179487 Z"/>
    <path fill="#DE4F42"
          d="M17.7333333,12.5928597 C18.0785841,12.5733791 18.4257318,12.5641026 18.775725,12.5641026 C23.8046245,12.5641026 28.3640209,14.5631901 31.6666667,17.7914149 L30.5882324,18.8461538 C27.452521,15.2440858 22.8779487,12.8767209 17.7333333,12.5928597 Z"/>
    <path
      d="M37.891414,0.782509506 L30.3636229,10.0512821 L15.2,10.0512821 L23.2215565,0.175801891 C23.3115905,0.0640148191 23.4461677,0 23.5892744,0 L37.5246438,0 C37.9255321,0 38.1454047,0.47007897 37.891414,0.782509506 Z"/>
    <path
      d="M37.8951247,0.782509506 L30.6245842,10.0512821 L27.8666667,10.0512821 L35.7358392,0 L37.5408884,0 C37.9280769,0 38.1404357,0.47007897 37.8951247,0.782509506 Z"/>
    <path fill="#DE4F42"
          d="M32.9333333,30.1538462 C32.9333333,37.7872361 26.695207,43.974359 19,43.974359 C18.6812636,43.974359 18.3644245,43.9640016 18.0513798,43.9423453 C10.7982298,43.4593147 5.06666667,37.4708652 5.06666667,30.1538462 C5.06666667,22.8377687 10.7982298,16.8483777 18.0513798,16.3653471 C18.3644245,16.3436907 18.6812636,16.3333333 19,16.3333333 C26.695207,16.3333333 32.9333333,22.5213978 32.9333333,30.1538462 L32.9333333,30.1538462 Z"/>
    <path fill="#C04D55"
          d="M32.9333333,30.1538462 C32.9333333,37.7872361 26.5618902,43.974359 18.7022268,43.974359 C18.3766786,43.974359 18.0530682,43.9640016 17.7333333,43.9423453 C25.1414924,43.4593147 30.9955465,37.4708652 30.9955465,30.1538462 C30.9955465,22.8377687 25.1414924,16.8483777 17.7333333,16.3653471 C18.0530682,16.3436907 18.3766786,16.3333333 18.7022268,16.3333333 C26.5618902,16.3333333 32.9333333,22.5213978 32.9333333,30.1538462 L32.9333333,30.1538462 Z"/>
    <g transform="translate(11.400000, 23.871795)" fill="#FB963F">
      <path
        d="M1.57812293,0.0120435897 C0.759514894,0.0120435897 0.0958534279,0.674638462 0.0958534279,1.49182564 L0.0958534279,12.2938397 C0.0958534279,13.1111256 0.759514894,13.7736218 1.57812293,13.7736218 C2.39673097,13.7736218 3.06039243,13.1110269 3.06039243,12.2938397 L3.06039243,1.49182564 C3.06039243,0.674638462 2.39673097,0.0120435897 1.57812293,0.0120435897 Z"/>
      <path
        d="M7.01311111,0.0120435897 C6.19450307,0.0120435897 5.53084161,0.674638462 5.53084161,1.49182564 L5.53084161,12.2938397 C5.53084161,13.1111256 6.19450307,13.7736218 7.01311111,13.7736218 C7.83171915,13.7736218 8.49538061,13.1110269 8.49538061,12.2938397 L8.49538061,1.49182564 C8.49538061,0.674638462 7.83171915,0.0120435897 7.01311111,0.0120435897 L7.01311111,0.0120435897 Z"/>
      <path
        d="M12.4480993,0.0120435897 C11.6294913,0.0120435897 10.9658298,0.674638462 10.9658298,1.49182564 L10.9658298,12.2938397 C10.9658298,13.1111256 11.6294913,13.7736218 12.4480993,13.7736218 C13.2667073,13.7736218 13.9303688,13.1110269 13.9303688,12.2938397 L13.9303688,1.49182564 C13.9303688,0.674638462 13.2667073,0.0120435897 12.4480993,0.0120435897 Z"/>
    </g>
    <path
      d="M22.8,10.0512821 L7.63258322,10.0512821 L5.48449781,7.40756556 L0.109544492,0.792063956 C-0.14735363,0.474856196 0.0763657674,0 0.482094166,0 L14.3887194,0 C14.5441855,0 14.6911198,0.0697474895 14.789708,0.191089012 L22.8,10.0512821 Z"/>
    <path
      d="M22.8,10.0512821 L7.63258322,10.0512821 L5.48449781,7.40756556 L0.109544492,0.792063956 C-0.14735363,0.474856196 0.0763657674,0 0.482094166,0 L14.3887194,0 C14.5441855,0 14.6911198,0.0697474895 14.789708,0.191089012 L22.8,10.0512821 Z"/>
    <path
      d="M22.8,10.0512821 L7.26658637,10.0512821 L5.06666667,7.40756556 L13.6508194,7.40756556 C15.3031862,7.40756556 16.2118909,5.51769523 15.1653272,4.25937409 L11.6207964,0 L14.1857513,0 C14.3449688,0 14.4954487,0.0697474895 14.5964159,0.191089012 L22.8,10.0512821 Z"/>
    <g transform="translate(7.600000, 18.846154)" fill="#F66C4C">
      <path
        d="M19.1657837,2.32496245 C18.849885,2.09385716 18.4050307,2.16030345 18.1720388,2.47355027 C17.939047,2.78679709 18.0061304,3.22814335 18.3218396,3.45924864 C19.198757,4.10115556 19.968606,4.86984615 20.6102575,5.74417414 C20.7493514,5.93373874 20.9658567,6.03430103 21.185394,6.03430103 C21.3303625,6.03430103 21.4767522,5.99041076 21.6029601,5.89924682 C21.9202801,5.67011518 21.9903008,5.22923885 21.7592987,4.91448829 C21.0321822,3.92371482 20.1596234,3.05248829 19.1657837,2.32496245 L19.1657837,2.32496245 Z"/>
      <path
        d="M5.03476562,2.22411821 C3.98322283,2.97100464 3.06366772,3.87625318 2.30158819,4.91458227 C2.07058609,5.22933283 2.14060682,5.67020917 2.45792677,5.8993408 C2.58413465,5.99050474 2.73042966,6.03439501 2.87549291,6.03439501 C3.09503018,6.03439501 3.31153543,5.93383273 3.4506294,5.74426812 C4.1230748,4.82792954 4.93442467,4.02925823 5.8622231,3.37024632 C6.18134331,3.14365223 6.25477507,2.70333979 6.02623648,2.38680355 C5.7978874,2.07026731 5.35398058,1.99733616 5.03476562,2.22411821 L5.03476562,2.22411821 Z"/>
      <path
        d="M21.6029601,17.93862 C21.2855454,17.7094884 20.8411648,17.7790361 20.6101627,18.0936927 C19.968606,18.9680207 19.1986622,19.7367113 18.3217449,20.3786182 C18.0059409,20.6097235 17.9388575,21.0510698 18.1719441,21.3643166 C18.3113223,21.5516256 18.5263115,21.6506841 18.7443328,21.6506841 C18.8909121,21.6506841 19.0387231,21.605854 19.1657837,21.5129044 C20.1596234,20.7854725 21.0321822,19.914152 21.7593934,18.9232846 C21.9903008,18.608628 21.9202801,18.1677517 21.6029601,17.93862 L21.6029601,17.93862 Z"/>
      <path
        d="M5.86231785,20.4677145 C4.93451942,19.8087026 4.12316955,19.0100313 3.45072415,18.0936927 C3.2198168,17.7791301 2.77543622,17.7093944 2.45802152,17.938714 C2.14070157,18.1678456 2.07068084,18.608628 2.30168294,18.9233786 C3.06366772,19.9618016 3.98331759,20.8670502 5.03486037,21.6139366 C5.16021549,21.7029389 5.30480499,21.7457954 5.44797323,21.7457954 C5.66940551,21.7457954 5.88761627,21.6434474 6.02633123,21.4512513 C6.25477507,21.134621 6.18134331,20.6943086 5.86231785,20.4677145 L5.86231785,20.4677145 Z"/>
      <circle cx="12.0304908" cy="0.704593882" r="1"/>
      <circle cx="8.16087402" cy="1.38089875" r="1"/>
      <circle cx="15.9001076" cy="1.38080476" r="1"/>
      <circle cx="12.0304908" cy="23.1333669" r="1"/>
      <circle cx="8.16087402" cy="22.4570621" r="1"/>
      <circle cx="15.9001076" cy="22.4571561" r="1"/>
      <circle cx="23.3437192" cy="11.9192624" r="1"/>
      <circle cx="22.6618934" cy="8.0809785" r="1"/>
      <circle cx="22.6619882" cy="15.7575462" r="1"/>
      <circle cx="0.731854068" cy="11.9192624" r="1"/>
      <circle cx="1.41367979" cy="8.0809785" r="1"/>
      <circle cx="1.41358504" cy="15.7575462" r="1"/>
    </g>
    <path fill="#FB963F"
          d="M32.2,10.0512821 L5.8,10.0512821 C5.39500444,10.0512821 5.06666667,10.3325504 5.06666667,10.6794872 C5.06666667,11.0264239 5.39500444,11.3076923 5.8,11.3076923 L32.2,11.3076923 C32.6049956,11.3076923 32.9333333,11.0264239 32.9333333,10.6794872 C32.9333333,10.3325504 32.6049956,10.0512821 32.2,10.0512821 Z"/>
  </svg>
)

export default MedalBronze
