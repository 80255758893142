import MoveType from './MoveType';
import Move from './Move';
import GameState from '../GameState';
import GameView from '../GameView';
import { applyCardActivation } from '../utils/GameUtils';
import { GameDeck } from '../card/hunt/GameDeck';
import { BoardBoxes } from '../board';

export type ActivateCard = {
  type: MoveType.ActivateCard;
  card: number;
};

export const activateCardMove = (card: number): ActivateCard => ({
  type: MoveType.ActivateCard,
  card,
});

export const isActivateCard = (move: Move): move is ActivateCard => move.type === MoveType.ActivateCard;

export const activateCard = (state: GameState | GameView, move: ActivateCard) => {
  const player = state.players.find((p) => p.vampire === state.activePlayer)!;

  applyCardActivation(state, player, GameDeck, BoardBoxes, move.card);
};
