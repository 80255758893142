/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PlayerType } from '@gamepark/the-hunger/player';
import { FC, HTMLAttributes, useState } from 'react';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import { BonusToken } from './BonusToken';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { BonusTokenType } from '@gamepark/the-hunger/bonus/BonusTokenType';
import { popupBackgroundStyle } from '../../utils/Style';
import { acquireBonusTokenMove } from '@gamepark/the-hunger/moves/AcquireBonus';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

type MultiFactionChooserProps = {
  player: PlayerType;
  oldTokensCount?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const MultiFactionChooser: FC<MultiFactionChooserProps> = ({ player, oldTokensCount, ...props }) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { t } = useTranslation();
  const [reduced, setReduced] = useState(false);

  if (playerId !== player.vampire) {
    return null;
  }

  const chooseType = (bonus: number) => {
    play(acquireBonusTokenMove(playerId, undefined, bonus));
  };

  const bonusList = Bonuses(oldTokensCount);
  return (
    <>
      <button key="mission-button" css={button} onClick={() => setReduced((r) => !r)}>
        <FontAwesomeIcon icon={reduced ? faExternalLinkAlt : faTimes} />
        {reduced ? t('button.open') : t('button.close')}
      </button>
      {!reduced && (
        <>
          <div key="multifaction-chooser-overlay" css={popupBackgroundStyle} />
          <div key="multifaction-chooser" css={tokenContainer} {...props}>
            <BonusToken
              key="multifaction-villager"
              css={token}
              type={bonusList.find((b) => b.type === BonusTokenType.Villager)}
              onClick={() => chooseType(bonusList.findIndex((b) => b.type === BonusTokenType.Villager))}
            />
            <BonusToken
              key="multifaction-noble"
              css={token}
              type={bonusList.find((b) => b.type === BonusTokenType.Noble)}
              onClick={() => chooseType(bonusList.findIndex((b) => b.type === BonusTokenType.Noble))}
            />
            <BonusToken
              key="multifaction-military"
              css={token}
              type={bonusList.find((b) => b.type === BonusTokenType.Military)}
              onClick={() => chooseType(bonusList.findIndex((b) => b.type === BonusTokenType.Military))}
            />
            <BonusToken
              key="multifaction-church"
              css={token}
              type={bonusList.find((b) => b.type === BonusTokenType.Church)}
              onClick={() => chooseType(bonusList.findIndex((b) => b.type === BonusTokenType.Church))}
            />
          </div>
          <div key="multifaction-chooser-text" css={multifactionText}>
            <Trans defaults="multifaction.chooser.text" components={[<strong />]} />
          </div>
        </>
      )}
    </>
  );
};

const button = css`
  position: absolute;
  z-index: 100;
  right: 21.6%;
  font-size: 3.2em;
  color: black;
  background: rgba(255, 255, 255);
  border-radius: 1em;
  padding: 0.3em 0.6em;
  top: 10%;
  cursor: pointer;

  & svg {
    margin-right: 0.3em;
  }
`;

const tokenContainer = css`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const token = css`
  height: 15em;
  width: 15em;
  flex: 1;
  margin: 3em;
  cursor: pointer;
  border-radius: 8em;
  box-shadow: 0 0 0 0.8em gold;

  &:hover {
    box-shadow: 0 0 0 0.8em green;
  }
`;

const multifactionText = css`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3em;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.6em;
  border-radius: 0.3em;
`;

export { MultiFactionChooser };
