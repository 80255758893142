import { Bolat, ChantreSami, Dee, Faith, Nemes, PereEli, Rufus, Wright, Zephania } from '@gamepark/the-hunger/card/hunt';
import { EffectDescription } from './EffectDescription';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { HumanTypeSymbol, SpeedSymbol, VictoryPointSymbol, RegionSymbol } from '../../../symbols';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { Region } from '@gamepark/the-hunger/board';
import { Card } from '@gamepark/the-hunger/card/Card';

const HumanReligiousEffects = new Map<Card, Array<EffectDescription>>();

HumanReligiousEffects.set(Dee, [new EffectDescription((t: TFunction) => t('card.effect.draw'), 5.7)]);

HumanReligiousEffects.set(Rufus, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.spicy"
  components={[<strong/>]}
  values={{
    card: t('Rufus')
  }}
/>, 7.3, undefined, 2.1)]);

HumanReligiousEffects.set(Faith, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.region"
  components={[
    <VictoryPointSymbol width={1.305} value="1"/>,
    <RegionSymbol region={Region.Plain}/>
  ]}
  values={{
    card: t('Faith'),
    region: t('the.plains')
  }}
/>, 5.5, undefined, 2.5, 'white')]);

HumanReligiousEffects.set(Zephania, [new EffectDescription((t: TFunction) => t('card.effect.digest', { card: t('Zephania') }), 7.8, undefined, 2.4, 'white')]);

HumanReligiousEffects.set(Bolat, [new EffectDescription(() => <Trans
  defaults="card.effect.holywater"
  components={[<strong/>]}
/>, 5.5)]);

HumanReligiousEffects.set(PereEli, [new EffectDescription(() => <Trans
  defaults="card.effect.slow"
  components={[
    <strong/>,
    <SpeedSymbol value="2" width={1.45}/>
  ]}
/>, 5.5, 8, undefined, 'white')]);

HumanReligiousEffects.set(Wright, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.inspiring"
  components={[<strong/>]}
  values={{
    card: t('Wright')
  }}
/>, 5.7, undefined, 2.5, 'white')]);

HumanReligiousEffects.set(ChantreSami, [new EffectDescription(() => <Trans
  defaults="card.effect.holywater"
  components={[<strong/>]}
/>, 5.5)]);

HumanReligiousEffects.set(Nemes, [new EffectDescription(() => <Trans
  defaults="card.effect.human.hunt" components={[
  <VictoryPointSymbol value="1"/>,
  <HumanTypeSymbol category={CardType.HumanReligious}/>
]}/>, 7.5)  ]);

export {
  HumanReligiousEffects
};
