/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import {
  digestCardFromPlayingAreaTranslateX,
  digestCardFromPlayingAreaTranslateY,
  getDiscardCardFromPlayingAreaTranslateX,
  getDiscardCardFromPlayingAreaTranslateY,
  playingAreaCardHeight,
  playingAreaCardTranslateX,
  playingAreaCardTranslateY,
  playingAreaCardWidth,
  playingAreaHeight,
  playingAreaLeft,
  playingAreaWidth,
} from '../../utils/Style';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { PlayerType } from '@gamepark/the-hunger/player';
import { PlayableCard } from './PlayableCard';
import { useAnimation } from '@gamepark/react-client';
import { isPlaceInPlayingAreaMove } from '@gamepark/the-hunger/moves/PlaceInPlayingArea';
import { isDiscardCardMove } from '@gamepark/the-hunger/moves/DiscardCards';
import { DiscardLimit } from '@gamepark/the-hunger/utils/GameConstants';
import {
  DigestCardDelay,
  DigestCardDuration,
  DrawCardDelay,
  DrawCardDuration,
} from '@gamepark/the-hunger/utils/AnimationsConstants';
import { isEndOfGame } from '@gamepark/the-hunger/moves/EndOfGame';
import { isDigestCardMove } from '@gamepark/the-hunger/moves/DigestCards';

export type PlayingAreaProps = {
  activePlayer?: Vampire;
  discardStackSize: number;
  player: PlayerType;
  isGameOver?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlayingArea: FC<PlayingAreaProps> = ({ player, activePlayer, isGameOver, discardStackSize, ...props }) => {
  const animation = useAnimation(
    (animation) =>
      ((isPlaceInPlayingAreaMove(animation.move) ||
        isEndOfGame(animation.move) ||
        isDiscardCardMove(animation.move) ||
        isDigestCardMove(animation.move)) &&
        activePlayer === player.vampire) ||
      (isDiscardCardMove(animation.move) && animation.move.vampire && animation.move.vampire === player.vampire)
  );
  const placeInPlayingArea = animation && isPlaceInPlayingAreaMove(animation.move) ? animation.move : undefined;
  const discard = animation && isDiscardCardMove(animation.move) && !animation.move.hand ? animation.move : undefined;
  const discardCardDuration: number =
    animation && discard
      ? (animation.duration * DrawCardDuration) / (DrawCardDuration + (discard.cards.length - 1) * DrawCardDelay)
      : 0;
  const discardCardDelay: number =
    animation && discard && discard.cards.length > 1
      ? (animation.duration * 100 - discardCardDuration * 100) / 100 / (discard.cards.length - 1)
      : 0;
  const endOfGame = animation && isEndOfGame(animation.move) ? animation.move : undefined;

  const digest = animation && isDigestCardMove(animation.move) ? animation.move : undefined;
  const digestCardDuration: number =
    animation && digest
      ? (animation.duration * DigestCardDuration) / (DigestCardDuration + (digest.cards.length - 1) * DigestCardDelay)
      : 0;
  const digestCardDelay: number =
    animation && digest && digest.cards.length > 1
      ? (animation.duration * 100 - digestCardDuration * 100) / 100 / (digest.cards.length - 1)
      : 0;

  let cards = player.playingArea;
  let playingAreaLength = cards.length;

  if (placeInPlayingArea) {
    playingAreaLength = cards.length + placeInPlayingArea.cards.length;
  }

  if (discard) {
    playingAreaLength = cards.length - discard.cards.length;
  }

  if (digest) {
    playingAreaLength = cards.length - digest.cards.length;
  }

  return (
    <>
      <div css={[playingAreaStyle, discard && onTop]} {...props}>
        {cards.map((card, index) => {
          const realIndex =
            discard || digest
              ? cards
                  .filter((c) => (!discard || !discard.cards.includes(c)) && (!digest || !digest.cards.includes(c)))
                  .findIndex((c) => c === card)
              : index;
          return (
            <PlayableCard
              key={`playing-area-card-${card}`}
              activePlayer={activePlayer}
              player={player}
              card={card}
              preTransform={`translate(${playingAreaCardTranslateX(
                playingAreaLength,
                realIndex
              )}%, ${playingAreaCardTranslateY}%)`}
              isGameOver={isGameOver}
              css={[
                playingCard,
                (placeInPlayingArea || (discard && !discard.cards.includes(card))) &&
                  animationTransition(animation!.duration),
                discard &&
                  discard.cards.includes(card) &&
                  discardAnimation(
                    discard.cards.findIndex((c) => c === card),
                    discardStackSize,
                    discardCardDuration,
                    discardCardDelay
                  ),
                digest &&
                  digest.cards.includes(card) &&
                  digestAnimation(
                    digest.cards.findIndex((c) => c === card),
                    digestCardDuration,
                    digestCardDelay
                  ),
                endOfGame && animation && discardAnimation(index, discardStackSize, animation.duration, 0),
              ]}
            />
          );
        })}
      </div>
    </>
  );
};

const playingAreaStyle = css`
  position: absolute;
  transition-property: transform, z-index;
  transition-duration: 0.5s;
  left: ${playingAreaLeft}%;
  height: ${playingAreaHeight}%;
  width: ${playingAreaWidth}%;
  font-size: ${playingAreaHeight / 100}em;
  border-radius: 5em;
  background-color: rgba(1, 94, 6, 0.5);
  box-shadow: green 0 0 2em, green 0 0 2em;
`;

const playingCard = css`
  position: absolute;
  height: ${playingAreaCardHeight}%;
  width: ${playingAreaCardWidth}%;
  font-size: ${playingAreaCardHeight / 100}em;
`;

const discardAnimation = (index: number, discardStackSize: number, duration: number, delay: number) => css`
  z-index: 99;
  transition-delay: ${delay * index}s;
  transition-duration: ${duration}s;
  transition-timing-function: ease-in-out;
  transform: translate(
    ${getDiscardCardFromPlayingAreaTranslateX(index, discardStackSize, DiscardLimit)}%,
    ${getDiscardCardFromPlayingAreaTranslateY(index, discardStackSize, DiscardLimit)}%
  );
`;

const digestAnimation = (index: number, duration: number, delay: number) => css`
  z-index: 99;
  transition-delay: ${delay * index}s;
  transition-duration: ${duration}s;
  transition-timing-function: ease-in-out;
  transform: translate(${digestCardFromPlayingAreaTranslateX}%, ${digestCardFromPlayingAreaTranslateY}%) rotateZ(90deg);
`;

const animationTransition = (duration: number) => css`
  transition-duration: ${duration * 0.9}s;
  transition-delay: ${duration * 0.1}s;
`;

const onTop = css`
  z-index: 99;
`;

export { PlayingArea };
