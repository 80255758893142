/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes, useMemo } from 'react';
import { isStackCount, Missions } from '@gamepark/the-hunger/mission';
import { MissionTile } from './MissionTile';
import { isInvisibleMissions } from '@gamepark/the-hunger/GameView';
import { useAnimation, usePlayerId } from '@gamepark/react-client';
import { DrawMissionsView, isDrawMission } from '@gamepark/the-hunger/moves/DrawMissions';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { PlayerType } from '@gamepark/the-hunger/player';
import { getActivePlayerIndex, getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import { boardMissionStackTranslateToPlayerX, boardMissionStackTranslateToPlayerY, missionRatio } from '../../utils/Style';
import { ChooseMission, ChooseMissionView, isChooseMissionMove, isChooseMissionMoveView } from '@gamepark/the-hunger/moves/ChooseMission';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

type Place = 'top' | 'right' | 'bottom' | 'left';
type MissionStackProps = {
  missions: number[] | number,
  position: number,
  activePlayer?: Vampire,
  players: Array<PlayerType>,
  preTransform: string,
  tooltipPosition?: Place,
  selectable: boolean,
  missionScore?: (mission: number) => number | undefined
} & HTMLAttributes<HTMLDivElement>;

const MissionTileStack: FC<MissionStackProps> = ({
                                                   missions,
                                                   activePlayer,
                                                   players,
                                                   position,
                                                   tooltipPosition,
                                                   missionScore,
                                                   preTransform,
                                                   selectable,
                                                   ...props
                                                 }) => {
  const { t } = useTranslation();
  const playerId = usePlayerId();
  const drawMissionAnimation = useAnimation<DrawMissionsView>(animation => isDrawMission(animation.move) && animation.move.stack === position);
  const chooseMissionAnimation = useAnimation<ChooseMissionView | ChooseMission>(animation => !!activePlayer && (isChooseMissionMove(animation.move) || isChooseMissionMoveView(animation.move)));

  // eslint-disable-next-line
  const realPlayers = useMemo(() => getOrderedPlayersForDisplay(players, playerId), [players]);
  const playerIndex = (drawMissionAnimation || chooseMissionAnimation) && getActivePlayerIndex(realPlayers, activePlayer);
  const player = (drawMissionAnimation || chooseMissionAnimation) && realPlayers.find(p => p.vampire === activePlayer);
  const realMissions = chooseMissionAnimation && player?.missionChoice.stack === position ? (isInvisibleMissions(chooseMissionAnimation.move.missions) ? chooseMissionAnimation.move.missions : chooseMissionAnimation.move.missions.length) : missions;
  const showCounter: boolean = selectable && isStackCount(realMissions);

  if ((isInvisibleMissions(realMissions) && !realMissions) || (!isInvisibleMissions(realMissions) && !realMissions.length)) {
    return null;
  }

  return (
    <>
      <div { ...props } css={ [
        missionStackStyle(preTransform),
        selectable && missionSelectable,
        drawMissionAnimation && activePlayer === playerId && hideMissions,
        drawMissionAnimation && activePlayer !== playerId && playerIndex !== undefined && playerIndex !== -1 && animateDrawMission(playerIndex, players.length, drawMissionAnimation.duration),
        chooseMissionAnimation && playerIndex !== undefined && playerIndex !== -1 && player?.missionChoice.stack === position && animateChooseMission(playerIndex, players.length, chooseMissionAnimation.duration, preTransform)
      ] } data-tip data-for={ `missions-${ position }` }>
        { isStackCount(realMissions) &&
        [...Array(realMissions)].map((_, index) => {
          return (
            <MissionTile
              key={ index }
              css={ missionTile }
              preTransform={ `translate(${ (realMissions - index) * 0.07 }%, ${ (realMissions - index) * 1.5 }%)` }
            />
          );
        })
        }

        { !isStackCount(realMissions) && realMissions.map((mission, index) => {
          return (
            <MissionTile
              key={ index }
              css={ missionTile }
              mission={ Missions[mission] }
              score={ missionScore ? missionScore(mission) : undefined }
              preTransform={ `translate(${ (realMissions.length - index) * 0.07 }%, ${ (realMissions.length - index) * 1.5 }%)` }
            />
          );
        }) }
        { showCounter && <div css={ missionCounter }>{ isStackCount(realMissions) ? realMissions : realMissions.length }</div> }
      </div>
      {
        isStackCount(realMissions) && <ReactTooltip className="the-hunger-tooltip"
                                                    offset={ {
                                                      top: 1,
                                                      bottom: tooltipPosition === 'bottom' ? 3 : 0,
                                                      left: tooltipPosition === 'left' ? 1 : 0,
                                                      right: tooltipPosition === 'right' ? 1 : 0
                                                    } }
                                                    id={ `missions-${ position }` }
                                                    type="info" effect="solid" place={ tooltipPosition }>
            <span css={ missionCount }>{ t('mission.count', { missionCount: realMissions }) }  </span>
        </ReactTooltip>
      }
    </>
  );
};

const missionStackStyle = (preTransform: string) => css`
  transition: 0.8s opacity;
  transform: ${ preTransform };

  &:hover {
    z-index: 999;
  }
`;

const missionTile = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const missionCounter = css`
  position: absolute;
  right: -10%;
  bottom: -18%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gold;
  border-radius: 50%;
  height: 50%;
  width: ${ 50 * missionRatio }%;
  z-index: 10;
  font-size: 33em;
  color: black;
`;

const animateDrawMission = (playerIndex: number, playerCount: number, duration: number) => css`
  z-index: 99;
  animation: ${ drawAnimation(playerIndex, playerCount) } ${ duration }s ease-in-out both;
`;

const drawAnimation = (playerIndex: number, playerCount: number) => keyframes`
  80% {
    transform: translate(${ boardMissionStackTranslateToPlayerX }%, ${ boardMissionStackTranslateToPlayerY(playerIndex, playerCount) }%) scale(0.8);
  }
  100% {
    transform: translate(${ boardMissionStackTranslateToPlayerX }%, ${ boardMissionStackTranslateToPlayerY(playerIndex, playerCount) }%) scale(0);
  }
`;

const animateChooseMission = (playerIndex: number, playerCount: number, duration: number, preTransform: string) => css`
  z-index: 99;
  animation: ${ chooseAnimation(playerIndex, playerCount, preTransform) } ${ duration * 0.9 }s ease-in-out both;
`;

const chooseAnimation = (playerIndex: number, playerCount: number, preTransform: string) => keyframes`
  0% {
    transform: translate(${ boardMissionStackTranslateToPlayerX }%, ${ boardMissionStackTranslateToPlayerY(playerIndex, playerCount) }%) scale(0);
  }
  20% {
    transform: translate(${ boardMissionStackTranslateToPlayerX }%, ${ boardMissionStackTranslateToPlayerY(playerIndex, playerCount) }%) scale(0.8);
  }
  100% {
    transform: ${ preTransform };
  }
`;

const missionSelectable = css`

  filter: drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold);

  &:hover {
    filter: drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green) drop-shadow(0 0 1em green);
  }

  cursor: pointer;
`;

const hideMissions = css`
  opacity: 0;
`;

const missionCount = css`
  white-space: nowrap;
  font-size: 1.4em;
`;

export {
  MissionTileStack
};
