enum Vampire {
  LadyBeatrice = 1,
  BorisPouchkine,
  DonGervasi,
  YokoChiyako,
  JosephineLafayette,
  RajeshAmara

}

export default Vampire;

let values = Object.values(Vampire);
export const vampires = values.filter(isVampire)

function isVampire(arg: string | Vampire): arg is Vampire {
  return typeof arg === 'number';
}
