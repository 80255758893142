import { PlayerType } from "../player";
import Vampire from "../player/Vampire";

export const getOrderedPlayersForDisplay = (players: Array<PlayerType>, playerId?: Vampire) => {
  const me = players.find((p) => p.vampire === playerId);
  // eslint-disable-next-line
  return me ? [me, ...players.filter((p) => p.vampire !== playerId)] : players;
};

export const getActivePlayerIndex = (players: Array<PlayerType>, playerId?: Vampire) => {
  return players.findIndex((p) => p.vampire === playerId);
};

export const hasHunted = (player: PlayerType) => !!player.hunts.filter((h) => !h.free).length;
