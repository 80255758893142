export const BonusTokenCount = 11;
export const StartHandSize = 3;
export const TavernSize = 3;
export const VisibleTokenPosition = [33, 34, 35, 36, 37, 38];
export const NonVisibleTokenPosition = [20, 28, 47, 51, 60];
export const MissionPerStack: number[] = [1, 1, 6, 4, 4, 5, 5, 5];
export const StartupBeigeMissionCount = 2;
export const StartupMissionPerPlayer = 2;
export const LastTurn = 15;
export const EndOfTurnCardDrawCount = 3;
export const TavernHuntCost = 2;
export const BonusTokenVictoryPoints = 2;
export const BonusTokenVelvetVictoryPoints = 2;
export const HuntInForestBonus = 2;
export const HuntInPlainsBonus = 1;

export const DeckLimit = 5;
export const DiscardLimit = 5;
export const DigestLimit = 3;

export const GameDisplaySwitchTransition = 0.2;
export const SecondViewEnabled = false;
