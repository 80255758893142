/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { cardRatio } from '../../utils/Style';
import {
  Agnes,
  Angus,
  Anton,
  Archibald,
  Arthur,
  Bagoly,
  BaronChristien,
  Belle,
  Bernard,
  Billy,
  Bippo,
  Bo,
  Bolat,
  Boo,
  Boris,
  Bradford,
  Bridget,
  Bruce,
  Caine,
  Calvin,
  Campbell,
  Capra,
  Carlyle,
  Catarina,
  ChantreSami,
  Chop,
  Cotton,
  Cyrana,
  DeadRose,
  Dee,
  Diego,
  Echo,
  Eglantine,
  Eleanor,
  Eli,
  Elois,
  Eponime,
  EternalRose,
  Eunice,
  Faith,
  Faviana,
  FormOfBat,
  FormOfMist,
  FrereStewart,
  FrereTunk,
  Grant,
  Gray,
  Harper,
  Henrietta,
  Hypnosis,
  Isabel,
  Ivan,
  Ivo,
  Jack,
  Jahda,
  Juri,
  Kaa,
  Khasar,
  Kutya,
  LaPretresse,
  Lockjaw,
  Malac,
  Marcel,
  Marie,
  Marilyn,
  Mindy,
  Momo,
  Murdoch,
  Mycroft,
  Nanny,
  Nanoosh,
  Nemes,
  OlNel,
  Ophelia,
  Ozmo,
  Patcani,
  Patricia,
  PereEli,
  PerfectRose,
  Peter,
  Porumbel,
  PrinceGodfrey,
  Reyda,
  Roxane,
  Rufus,
  Ruth,
  Simone,
  Sova,
  Szalai,
  Tania,
  Teddy,
  Theresa,
  Titus,
  Tyre,
  Tyson,
  Ursa,
  Uwe,
  VampireSpeedThree,
  VampireSpeedTwo,
  VampireStrength,
  VampireStrengthAlt,
  VampireWill,
  VampireWillAlt,
  VampiricStealth,
  Veres,
  Victoria,
  Wadsworth,
  WeeVlad,
  Wentworth,
  Wiggles,
  Wilma,
  Wright,
  Yaga,
  Zara,
  Zephania
} from '@gamepark/the-hunger/card/hunt/';
import { Images } from '../../images/Images';
import {
  BorisPouchkineHunger,
  BorisPouchkineVampiricSpeed2,
  BorisPouchkineVampiricSpeed3,
  BorisPouchkineVampiricSpeed4,
  BorisPouchkineVampiricStrength,
  BorisPouchkineVampiricThirst,
  DonGervasiHunger,
  DonGervasiVampiricSpeed2,
  DonGervasiVampiricSpeed3,
  DonGervasiVampiricSpeed4,
  DonGervasiVampiricStrength,
  DonGervasiVampiricThirst,
  JosephineLafayetteHunger,
  JosephineLafayetteVampiricSpeed2,
  JosephineLafayetteVampiricSpeed3,
  JosephineLafayetteVampiricSpeed4,
  JosephineLafayetteVampiricStrength,
  JosephineLafayetteVampiricThirst,
  LadyBeatriceHunger,
  LadyBeatriceVampiricSpeed2,
  LadyBeatriceVampiricSpeed3,
  LadyBeatriceVampiricSpeed4,
  LadyBeatriceVampiricStrength,
  LadyBeatriceVampiricThirst,
  RajeshAmaraHunger,
  RajeshAmaraVampiricSpeed2,
  RajeshAmaraVampiricSpeed3,
  RajeshAmaraVampiricSpeed4,
  RajeshAmaraVampiricStrength,
  RajeshAmaraVampiricThirst,
  YokoChiyakoHunger,
  YokoChiyakoVampiricSpeed2,
  YokoChiyakoVampiricSpeed3,
  YokoChiyakoVampiricSpeed4,
  YokoChiyakoVampiricStrength,
  YokoChiyakoVampiricThirst
} from '@gamepark/the-hunger/card/vampire/Starters';
import { DraggableProps } from '@gamepark/react-components/dist/Draggable/Draggable';
import { Draggable } from '@gamepark/react-components';
import { DraggableTypes } from '../draggable/DraggableTypes';
import { HuntCardTitles } from './HuntCardTitles';
import { useTranslation } from 'react-i18next';
import { isSpeedBonus } from '@gamepark/the-hunger/utils/EffectUtils';
import { EffectDescriptions } from './descriptions';
import { EffectDescription } from './descriptions/EffectDescription';
import { LongPressProps } from '../../utils/useLongPress';
import { Card } from '@gamepark/the-hunger/card/Card';

export type HuntCardProps = {
  preTransform?: string
  hunt?: Card
  isDraggable?: boolean
  type?: DraggableTypes
  onEffectClick?: (event: any) => void,
  isEffectActivable?: boolean,
  cardOverlay?: any
} & Omit<DraggableProps, 'type'> & LongPressProps<HTMLDivElement>;

const HuntCard: FC<HuntCardProps> = ({
                                       hunt,
                                       preTransform,
                                       isDraggable,
                                       type,
                                       onEffectClick,
                                       isEffectActivable,
                                       cardOverlay,
                                       ...props
                                     }) => {
  const { t } = useTranslation();

  const card = <div css={ [frontFace, getBackgroundImage(hunt)] }>
    { hunt &&
    <div css={ speed(hunt.speed < 0) }>{ hunt.speed }{ hunt.passiveEffects?.length && !hunt.effects?.length && isSpeedBonus(hunt.passiveEffects[0]) && !hunt.passiveEffects[0].perHunt && '*' }</div> }
    { hunt && hunt.victoryPoints !== undefined && <div css={ victoryPoints }>{ hunt.victoryPoints }</div> }
    { hunt && hunt.victoryPoints !== undefined && <div css={ miniVictoryPoints }>{ hunt.victoryPoints }</div> }
    { hunt && HuntCardTitles.has(hunt) && <div css={ title(hunt) }>{ HuntCardTitles.get(hunt)!.translate(t) }</div> }
    { hunt && EffectDescriptions.has(hunt) && EffectDescriptions.get(hunt)?.length && EffectDescriptions.get(hunt)?.map((e, index) => <div key={ `effect-${ index }` } css={ description(e) }>{ e.translate(t) }</div>) }
    { hunt && isEffectActivable && <div css={ effectSelectable(hunt) } onClick={ onEffectClick } /> }
    { hunt && !!cardOverlay && cardOverlay }
  </div>;

  if (!isDraggable || !type || !props.item) {
    return (
      <div css={ [style(preTransform), !hunt && hidden(preTransform)] } { ...props }>
        { !!hunt && card }
      </div>
    );
  }

  return (
    <Draggable type={ type } css={ [style(), !hunt && hidden()] } preTransform={ preTransform } { ...props }>
      { !!hunt && card }
    </Draggable>
  );
};

const speed = (negative?: boolean) => css`
  position: absolute;
  top: 11.6%;
  left: 11.4%;
  transform: translate(-50%, -50%);
  font-family: Arial, serif;
  font-weight: bold;
  font-size: 10em;
  color: ${ negative ? '#db0000' : 'black' };
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const victoryPoints = css`
  position: absolute;
  top: 11%;
  right: 6.8%;
  transform: translate(-50%, -50%);
  font-family: Arial, serif;
  font-weight: bold;
  font-size: 8em;
  color: white;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const miniVictoryPoints = css`
  position: absolute;
  bottom: 15.2%;
  left: 9.6%;
  transform: translate(-50%, -50%);
  font-family: Arial, serif;
  font-weight: bold;
  font-size: 6em;
  color: white;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const title = (hunt: Card) => css`
  position: absolute;
  bottom: ${ HuntCardTitles.get(hunt)!.bottom }%;
  width: 52%;
  left: 50.2%;
  transform: translate(-50%, 50%);
  font-family: Arial, serif;
  font-size: 3.6em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  //text-shadow: 0 0 0.1em #fff, 0 0 0.1em #fff;
  -webkit-font-smoothing: subpixel-antialiased;
  white-space: nowrap;
`;

const description = (effect: EffectDescription) => css`
  position: absolute;
  bottom: ${ effect.bottom || 7.7 }%;
  left: 50.4%;
  transform: translate(-50%, 50%);
  width: ${effect.width || 52 }%;
  font-family: Arial, serif;
  white-space: pre-wrap;
  font-size: ${ effect.fontSize || 2.5 }em;
  color: ${ effect.color || 'black' };
  text-align: center;
  line-height: ${ effect.lineHeight || 1.4 };
  -webkit-font-smoothing: subpixel-antialiased;
`;

const effectSelectable = (hunt: Card) => css`
  position: absolute;
  border-radius: 3.3em;
  bottom: 0;
  height: ${ HuntCardTitles.get(hunt)!.bottom! + 5 }%;
  width: 100%;

  box-shadow: 0 0 0.3em 1.5em gold, 0 0 0.3em 1.5em gold;

  &:hover {
    box-shadow: 0 0 0.3em 1.5em green, 0 0 0.3em 1.5em green;
    background-color: rgba(255, 255, 255, 0.3);
  }

  cursor: pointer;

`;

const style = (preTransform?: string) => css`
  transform-style: preserve-3d;
  ${ preTransform? `transform: ${ preTransform } translateZ(0);`: ''}
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 6% / ${ cardRatio * 6 }%;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0.4em black;
    border-radius: 6% / ${ cardRatio * 6 }%;
    background-image: url(${ Images.CardBack });
    image-rendering: -webkit-optimize-contrast;
    border: 0.3em solid white;
    background-color: green;
    background-size: cover;
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }
`;

const hidden = (preTransform?: string) => css`
  transform: ${preTransform? preTransform: ''} rotateY(180deg);
`;

const frontFace = css`
  position: absolute;
  width: 100%;
  border-radius: 6% / ${ cardRatio * 6 }%;
  height: 100%;
  backface-visibility: hidden;
  background-size: cover;
  box-shadow: 0 0 0.4em black;
  border: 0.3em solid white;
`;

const getBackgroundImage = (hunt?: Card) => css`
  image-rendering: -webkit-optimize-contrast;
  background-image: url(${ images.get(hunt!) });
`;

const images = new Map<Card, any>();
images.set(Tyre, Images.Tyre);
images.set(Murdoch, Images.Murdoch);
images.set(Peter, Images.Peter);
images.set(Harper, Images.Harper);
images.set(Isabel, Images.Isabel);
images.set(Arthur, Images.Arthur);
images.set(Khasar, Images.Khasar);
images.set(Uwe, Images.Uwe);
images.set(Archibald, Images.Archibald);
images.set(Grant, Images.Grant);
images.set(Eglantine, Images.Eglantine);
images.set(Ivan, Images.Ivan);
images.set(Diego, Images.Diego);
images.set(Titus, Images.Titus);
images.set(Victoria, Images.Victoria);
images.set(Calvin, Images.Calvin);
images.set(Marcel, Images.Marcel);
images.set(Cyrana, Images.Cyrana);
images.set(Campbell, Images.Campbell);
images.set(Eli, Images.Eli);

images.set(Agnes, Images.Agnes);
images.set(Mindy, Images.Mindy);
images.set(Bridget, Images.Bridget);
images.set(Marilyn, Images.Marilyn);
images.set(Zara, Images.Zara);
images.set(Jack, Images.Jack);
images.set(Belle, Images.Belle);
images.set(Elois, Images.Elois);
images.set(Ophelia, Images.Ophelia);
images.set(Carlyle, Images.Carlyle);
images.set(Wentworth, Images.Wentworth);
images.set(Henrietta, Images.Henrietta);
images.set(Theresa, Images.Theresa);
images.set(Wadsworth, Images.Wadsworth);
images.set(Wilma, Images.Wilma);
images.set(Catarina, Images.Catarina);
images.set(Tania, Images.Tania);
images.set(BaronChristien, Images.BaronChristien);
images.set(PrinceGodfrey, Images.PrinceGodfrey);
images.set(Veres, Images.Veres);

images.set(OlNel, Images.OlNel);
images.set(Billy, Images.Billy);
images.set(Bruce, Images.Bruce);
images.set(Momo, Images.Momo);
images.set(Boo, Images.Boo);
images.set(Anton, Images.Anton);
images.set(Boris, Images.Boris);
images.set(Ivo, Images.Ivo);
images.set(Yaga, Images.Yaga);
images.set(Roxane, Images.Roxane);
images.set(Eunice, Images.Eunice);
images.set(Angus, Images.Angus);
images.set(Bippo, Images.Bippo);
images.set(Juri, Images.Juri);
images.set(Reyda, Images.Reyda);
images.set(Patricia, Images.Patricia);
images.set(Faviana, Images.Faviana);
images.set(Eponime, Images.Eponime);
images.set(Bernard, Images.Bernard);
images.set(Szalai, Images.Szalai);

images.set(Dee, Images.Dee);
images.set(Ruth, Images.Ruth);
images.set(Rufus, Images.Rufus);
images.set(Faith, Images.Faith);
images.set(Mycroft, Images.Mycroft);
images.set(Ozmo, Images.Ozmo);
images.set(Cotton, Images.Cotton);
images.set(Marie, Images.Marie);
images.set(Eleanor, Images.Eleanor);
images.set(Bradford, Images.Bradford);
images.set(Simone, Images.Simone);
images.set(Zephania, Images.Zephania);
images.set(Bolat, Images.Bolat);
images.set(FrereTunk, Images.FrereTunk);
images.set(PereEli, Images.PereEli);
images.set(Wright, Images.Wright);
images.set(FrereStewart, Images.FrereStewart);
images.set(LaPretresse, Images.LaPretresse);
images.set(ChantreSami, Images.ChantreSami);
images.set(Nemes, Images.Nemes);

images.set(VampireWill, Images.VampireWill);
images.set(VampireWillAlt, Images.VampireWillAlt);
images.set(VampireStrength, Images.VampireForce);
images.set(VampireStrengthAlt, Images.VampireForceAlt);
images.set(VampireSpeedTwo, Images.VampireSpeedTwo);
images.set(VampireSpeedThree, Images.VampireSpeedThree);
images.set(FormOfMist, Images.MistyForm);
images.set(FormOfBat, Images.AnimalForm);
images.set(VampiricStealth, Images.Stealth);
images.set(Hypnosis, Images.Hypnosis);

images.set(Kutya, Images.Kutya);
images.set(Caine, Images.Caine);
images.set(Ursa, Images.Ursa);
images.set(Teddy, Images.Teddy);
images.set(Malac, Images.Malac);
images.set(Chop, Images.Chop);
images.set(Nanoosh, Images.Nanoosh);
images.set(Lockjaw, Images.Lockjaw);
images.set(Echo, Images.Echo);
images.set(Jahda, Images.Jahda);
images.set(Bo, Images.Bo);
images.set(Gray, Images.Gray);
images.set(Patcani, Images.Patcani);
images.set(WeeVlad, Images.WeeVlad);
images.set(Porumbel, Images.Porumbel);
images.set(Tyson, Images.Tyson);
images.set(Bagoly, Images.Bagoly);
images.set(Sova, Images.Sova);
images.set(Capra, Images.Capra);
images.set(Nanny, Images.Nanny);
images.set(Kaa, Images.Kaa);
images.set(Wiggles, Images.Wiggles);

images.set(EternalRose, Images.EternalRose);
images.set(PerfectRose, Images.IdealRose);
images.set(DeadRose, Images.FadedRose);

images.set(LadyBeatriceHunger, Images.LadyBeatriceHunger);
images.set(LadyBeatriceVampiricThirst, Images.LadyBeatriceVampiricThirst);
images.set(LadyBeatriceVampiricStrength, Images.LadyBeatriceVampiricForce);
images.set(LadyBeatriceVampiricSpeed2, Images.LadyBeatriceVampiricSpeed2);
images.set(LadyBeatriceVampiricSpeed3, Images.LadyBeatriceVampiricSpeed3);
images.set(LadyBeatriceVampiricSpeed4, Images.LadyBeatriceVampiricSpeed4);

images.set(BorisPouchkineHunger, Images.BorisPouchkineHunger);
images.set(BorisPouchkineVampiricThirst, Images.BorisPouchkineVampiricThirst);
images.set(BorisPouchkineVampiricStrength, Images.BorisPouchkineVampiricForce);
images.set(BorisPouchkineVampiricSpeed2, Images.BorisPouchkineVampiricSpeed2);
images.set(BorisPouchkineVampiricSpeed3, Images.BorisPouchkineVampiricSpeed3);
images.set(BorisPouchkineVampiricSpeed4, Images.BorisPouchkineVampiricSpeed4);

images.set(RajeshAmaraHunger, Images.RajeshAmaraHunger);
images.set(RajeshAmaraVampiricThirst, Images.RajeshAmaraVampiricThirst);
images.set(RajeshAmaraVampiricStrength, Images.RajeshAmaraVampiricForce);
images.set(RajeshAmaraVampiricSpeed2, Images.RajeshAmaraVampiricSpeed2);
images.set(RajeshAmaraVampiricSpeed3, Images.RajeshAmaraVampiricSpeed3);
images.set(RajeshAmaraVampiricSpeed4, Images.RajeshAmaraVampiricSpeed4);

images.set(YokoChiyakoHunger, Images.YokoChiyakoHunger);
images.set(YokoChiyakoVampiricThirst, Images.YokoChiyakoVampiricThirst);
images.set(YokoChiyakoVampiricStrength, Images.YokoChiyakoVampiricForce);
images.set(YokoChiyakoVampiricSpeed2, Images.YokoChiyakoVampiricSpeed2);
images.set(YokoChiyakoVampiricSpeed3, Images.YokoChiyakoVampiricSpeed3);
images.set(YokoChiyakoVampiricSpeed4, Images.YokoChiyakoVampiricSpeed4);

images.set(JosephineLafayetteHunger, Images.JosephineLafayetteHunger);
images.set(JosephineLafayetteVampiricThirst, Images.JosephineLafayetteVampiricThirst);
images.set(JosephineLafayetteVampiricStrength, Images.JosephineLafayetteVampiricForce);
images.set(JosephineLafayetteVampiricSpeed2, Images.JosephineLafayetteVampiricSpeed2);
images.set(JosephineLafayetteVampiricSpeed3, Images.JosephineLafayetteVampiricSpeed3);
images.set(JosephineLafayetteVampiricSpeed4, Images.JosephineLafayetteVampiricSpeed4);

images.set(DonGervasiHunger, Images.DonGervasiHunger);
images.set(DonGervasiVampiricThirst, Images.DonGervasiVampiricThirst);
images.set(DonGervasiVampiricStrength, Images.DonGervasiVampiricForce);
images.set(DonGervasiVampiricSpeed2, Images.DonGervasiVampiricSpeed2);
images.set(DonGervasiVampiricSpeed3, Images.DonGervasiVampiricSpeed3);
images.set(DonGervasiVampiricSpeed4, Images.DonGervasiVampiricSpeed4);

export {
  HuntCard
};
