import { CardType } from '../CardType';
import { Region } from '../../board';
import { Human } from './Human';
import { CardTrait } from '../CardTrait';
import { RegionEffect } from '../../effect/RegionEffect';
import { DigestEffect } from '../../effect/DigestEffect';
import { EffectTrigger } from '../../effect/EffectTrigger';
import { VictoryPointEffect } from '../../effect/VictoryPointEffect';
import { ConfuseEffect } from '../../effect/ConfuseEffect';
import { SpicyEffect } from '../../effect/SpicyEffect';
import { InspiringEffect } from '../../effect/InspiringEffect';
import { HolyWaterEffect } from '../../effect/HolyWaterEffect';
import { FastEffect } from '../../effect/FastEffect';
import { GregariousEffect } from '../../effect/GregariousEffect';
import { DrawEffect } from '../../effect/DrawEffect';


export const Roxane: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanVillager
};

export const Tyre: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanMilitary
};

export const Murdoch: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new RegionEffect(2, Region.Forest)
  ]
};

export const Peter: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new DigestEffect({ digestCount: 1, trigger: EffectTrigger.Hunted, optional: true })
  ]
};

export const Harper: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanMilitary,
};

export const Isabel: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new VictoryPointEffect(2, { trigger: EffectTrigger.EndOfGame, roseCount: 1 })
  ]
};

export const Arthur: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanMilitary,
};

export const Khasar: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanMilitary,
};

export const Uwe: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanMilitary
};

export const Archibald: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanMilitary
};

export const Grant: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanMilitary
};

export const Eglantine: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new ConfuseEffect({  distance: 4 })
  ]
};

export const Ivan: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanMilitary
};

export const Diego: Human = {
  speed: -1,
  victoryPoints: 3,
  category: CardType.HumanMilitary
};

export const Titus: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanMilitary,
  traits: [CardTrait.Permanent],
  passiveEffects: [
    new SpicyEffect()
  ]
};

export const Victoria: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new InspiringEffect()
  ]
};

export const Calvin: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new FastEffect(1)
  ]
};

export const Marcel: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new HolyWaterEffect()
  ]
};

export const Cyrana: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new VictoryPointEffect(4, { trigger: EffectTrigger.EndOfGame, hunt: Roxane })
  ]
};

export const Campbell: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanMilitary,
  passiveEffects: [
    new ConfuseEffect({ distance: 4 })
  ]
};

export const Eli: Human = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.HumanMilitary,
  passiveEffects: [
    new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfGame, huntTypes: [CardType.HumanMilitary], perHunt: true })
  ]
};

export const Agnes: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanNoble
};

export const Mindy: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanNoble
};

export const Bridget: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanNoble,
  passiveEffects: [
    new RegionEffect(1, Region.Plain)
  ]
};

export const Marilyn: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanNoble
};

export const Zara: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanNoble
};

export const Jack: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanNoble
};

export const Belle: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanNoble,
  passiveEffects: [
    new RegionEffect(2, Region.Forest)
  ]
};

export const Elois: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanNoble,
  passiveEffects: [
    new HolyWaterEffect()
  ]
};

export const Ophelia: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanNoble
};

export const Carlyle: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanNoble
};

export const Wentworth: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanNoble
};

export const Henrietta: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanNoble
};

export const Theresa: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanNoble,
  passiveEffects: [
    new ConfuseEffect({  distance: 4 })
  ]
};

export const Wadsworth: Human = {
  speed: -1,
  victoryPoints: 3,
  category: CardType.HumanNoble
};

export const Wilma: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanNoble,
  passiveEffects: [
    new GregariousEffect()
  ]
};

export const Catarina: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanNoble,
  passiveEffects: [
    new GregariousEffect()
  ]
};

export const Tania: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanNoble
};

export const BaronChristien: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanNoble,
  passiveEffects: [
    new VictoryPointEffect(2, { trigger: EffectTrigger.EndOfGame, hunt: Roxane })
  ]
};

export const PrinceGodfrey: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanNoble
};

export const Veres: Human = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.HumanNoble,
  passiveEffects: [
    new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfGame, huntTypes: [CardType.HumanNoble], perHunt: true })
  ]
};

export const OlNel: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanVillager,
};

export const Billy: Human = {
  speed: 1,
  victoryPoints: 1,
  category: CardType.HumanVillager,
};

export const Bruce: Human = {
  speed: 1,
  victoryPoints: 1,
  category: CardType.HumanVillager
};

export const Momo: Human = {
  speed: 1,
  victoryPoints: 1,
  category: CardType.HumanVillager
};

export const Boo: Human = {
  speed: 1,
  victoryPoints: 1,
  category: CardType.HumanVillager
};

export const Anton: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanVillager,
  traits: [CardTrait.Permanent],
  passiveEffects: [
    new SpicyEffect()
  ]
};

export const Boris: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanVillager
};

export const Ivo: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanVillager
};

export const Yaga: Human = {
  speed: -1,
  victoryPoints: 2,
  category: CardType.HumanVillager,
  passiveEffects: [
    new GregariousEffect()
  ]
};

export const Eunice: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanVillager,
  traits: [CardTrait.Permanent],
  passiveEffects: [
    new SpicyEffect()
  ]
};

export const Angus: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanVillager,
  passiveEffects: [
    new DigestEffect({ digestCount: 1, trigger: EffectTrigger.Hunted, optional: true })
  ]
};

export const Bippo: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanVillager,
  passiveEffects: [
    new ConfuseEffect({  distance: 4 })
  ]
};

export const Juri: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanVillager,
  passiveEffects: [
    new ConfuseEffect({  distance: 4 })
  ]
};

export const Reyda: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanVillager,
  passiveEffects: [
     new FastEffect(1)
  ]
};

export const Patricia: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanVillager,
  traits: [CardTrait.Slow]
};

export const Faviana: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanVillager
};

export const Eponime: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanVillager
};

export const Bernard: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanVillager,
  traits: [CardTrait.Permanent],
  passiveEffects: [
    new SpicyEffect()
  ]
};

export const Szalai: Human = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.HumanVillager,
  passiveEffects: [
    new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfGame, huntTypes: [CardType.HumanVillager], perHunt: true })
  ]
};

export const Dee: Human = {
  speed: 0,
  victoryPoints: 0,
  category: CardType.HumanReligious,
  effects: [
    new DrawEffect(1, { mandatory: true })
  ]
};

export const Ruth: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanReligious,
};

export const Rufus: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanReligious,
  traits: [CardTrait.Permanent],
  passiveEffects: [
    new SpicyEffect()
  ]
};

export const Faith: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanReligious,
  passiveEffects: [
    new RegionEffect(1, Region.Plain)
  ]
};

export const Mycroft: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanReligious
};

export const Ozmo: Human = {
  speed: 0,
  victoryPoints: 2,
  category: CardType.HumanReligious
};

export const Cotton: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanReligious
};

export const Marie: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanReligious
};

export const Eleanor: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanReligious
};

export const Bradford: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanReligious
};

export const Simone: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanReligious
};

export const Zephania: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanReligious,
  passiveEffects: [
    new DigestEffect({ digestCount: 1, trigger: EffectTrigger.Hunted, optional: true })
  ]
};

export const Bolat: Human = {
  speed: 0,
  victoryPoints: 1,
  category: CardType.HumanReligious,
  passiveEffects: [
    new HolyWaterEffect()
  ]
};

export const FrereTunk: Human = {
  speed: -1,
  victoryPoints: 2,
  category: CardType.HumanReligious
};

export const PereEli: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanReligious,
  traits: [CardTrait.Slow]
};

export const Wright: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanReligious,
  passiveEffects: [
    new InspiringEffect()
  ]
};

export const FrereStewart: Human = {
  speed: 0,
  victoryPoints: 3,
  category: CardType.HumanReligious
};

export const LaPretresse: Human = {
  speed: 0,
  victoryPoints: 5,
  category: CardType.HumanReligious
};

export const ChantreSami: Human = {
  speed: 0,
  victoryPoints: 4,
  category: CardType.HumanReligious,
  passiveEffects: [
    new HolyWaterEffect()
  ]
};

export const Nemes: Human = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.HumanReligious,
  passiveEffects: [
    new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfGame, huntTypes: [CardType.HumanReligious], perHunt: true })
  ]
}

export const Humans: Human[] = [
  Tyre, Murdoch, Peter, Harper, Isabel, Arthur, Khasar, Uwe, Archibald, Grant, Eglantine, Ivan, Diego, Titus, Victoria, Calvin, Marcel, Cyrana, Campbell, Eli,
  Agnes, Mindy, Bridget, Marilyn, Zara, Jack, Belle, Elois, Ophelia, Carlyle, Wentworth, Henrietta, Theresa, Wadsworth, Wilma, Catarina, Tania, BaronChristien, PrinceGodfrey, Veres,
  OlNel, Billy, Bruce, Momo, Boo, Anton, Boris, Ivo, Yaga, Roxane, Eunice, Angus, Bippo, Juri, Reyda, Patricia, Faviana, Eponime, Bernard, Szalai,
  Dee, Ruth, Rufus, Faith, Mycroft, Ozmo, Cotton, Marie, Eleanor, Bradford, Simone, Zephania, Bolat, FrereTunk, PereEli, Wright, FrereStewart, LaPretresse, ChantreSami, Nemes,
];
