/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes, useMemo } from 'react';
import { HuntCardStack } from '../card/HuntCardStack';
import {
  cardRatio,
  deckToPlayerTranslateX,
  deckToPlayerTranslateY,
  deckToTavernTranslateX,
  deckToTavernTranslateY,
  getFillHuntTrackCardTargetScale,
  getFillHuntTrackCardTargetTranslateX,
  getFillHuntTrackCardTargetTranslateY,
  huntDeckHeight,
  huntDeckLeft,
  huntDeckTop,
  huntDeckWidth
} from '../../utils/Style';
import { HuntCard } from '../card/HuntCard';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { isFillHuntTrackMoveView } from '@gamepark/the-hunger/moves/FillHuntTrack';
import { useAnimation, usePlayerId } from '@gamepark/react-client';
import { FillHuntCardDelay, FillHuntCardDuration } from '@gamepark/the-hunger/utils/AnimationsConstants';
import { isFillTavern } from '@gamepark/the-hunger/moves/FillTavern';
import { isDrawHuntCardView } from '@gamepark/the-hunger/moves/DrawHuntCard';
import { getActivePlayerIndex, getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { PlayerType } from '@gamepark/the-hunger/player';

export type HuntDeckProps = {
  players: Array<PlayerType>;
  activePlayer?: Vampire
  hunts: number,
  tavernSize: number,
  cardLimit: number,
  invisible: any
} & HTMLAttributes<HTMLDivElement>;

export const HuntDeck: FC<HuntDeckProps> = ({ hunts, players, activePlayer, tavernSize, cardLimit, invisible, ...props }) => {
  const playerId = usePlayerId();
  const animation = useAnimation(animation =>
    isFillHuntTrackMoveView(animation.move) ||
    isFillTavern(animation.move) ||
    isDrawHuntCardView(animation.move)
  );

  const drawHuntCardView = animation && isDrawHuntCardView(animation.move) ? animation.move : undefined;
  const fillHuntTrack = animation && isFillHuntTrackMoveView(animation.move) ? animation.move : undefined;
  const fillTavern = animation && isFillTavern(animation.move) ? animation.move : undefined;
  const remainingCardInDeck = fillHuntTrack ? (hunts - fillHuntTrack.hunts.length) : (drawHuntCardView ? hunts - 1 : hunts);
  const stackSize = Math.min(remainingCardInDeck, cardLimit);

  // eslint-disable-next-line
  const realPlayers = useMemo(() => getOrderedPlayersForDisplay(players, playerId), [players]);
  const playerIndex = activePlayer && getActivePlayerIndex(realPlayers, activePlayer);

  const fillHuntTrackDuration = animation && fillHuntTrack ? animation.duration * FillHuntCardDuration / (FillHuntCardDuration + (fillHuntTrack.hunts.length - 1) * FillHuntCardDelay) : 0;
  const fillHuntTrackDelay = fillHuntTrackDuration ? (animation!.duration - fillHuntTrackDuration) / (fillHuntTrack!.hunts.length - 1) : 0;

  const cards = [];
  if (fillHuntTrack) {
    cards.push(...fillHuntTrack.hunts.map((c, index) => {
        return <HuntCard
          key={ c }
          hunt={ GameDeck[c] }
          css={ [
            huntCard(players.length),
            animateHuntCard(
              players.length,
              Math.min(stackSize + fillHuntTrack.hunts.length, cardLimit),
              stackSize + fillHuntTrack.hunts.length,
              index + stackSize + 1,
              index,
              fillHuntTrackDuration,
              fillHuntTrackDelay
            ),
            invisible
          ] }
        />;
      }
    ));
  }

  if (animation && (fillTavern || drawHuntCardView)) {
  cards.push(<HuntCard
      hunt={ drawHuntCardView ? GameDeck[drawHuntCardView.card] : undefined }
      key="animated-card"
      css={ [
        huntCard(players.length),
        deckCard(stackSize, Math.min(stackSize + 1, cardLimit)),
        fillTavern && animateTavernCard(tavernSize, players.length, animation.duration),
        drawHuntCardView && playerIndex !== undefined && playerIndex > -1 && animateDrawCard(playerIndex, players.length, true, animation.duration),
        invisible
      ] }
    />
  );
  }

  return <>
    { cards }
    <HuntCardStack hunts={ hunts } position={ 2 } tooltipPosition={ 'bottom' } cardLimit={ stackSize } { ...props } css={ [huntDeck(players.length), onBackground, invisible] }/>
  </>;
};

const animateTavernCard = (index: number, playerCount: number, duration: number) => css`
  z-index: 99;
  animation: ${ tavernCardAnimation(index, playerCount) } ${ duration }s ease-in-out both;
`;

const tavernCardAnimation = (index: number, playerCount: number) => keyframes`
  to {
    transform: translate(${ deckToTavernTranslateX(index) }%, ${ deckToTavernTranslateY(index, playerCount) }%) rotateY(180deg) rotateZ(90deg);
  }
`;

const animateDrawCard = (playerIndex: number, playerCount: number, willBeShown: boolean, duration: number) => css`
  z-index: 99;
  animation: ${ duration }s ${ drawCardAnimation(playerIndex, playerCount, willBeShown) } ease-in-out both;
`;

const drawCardAnimation = (playerIndex: number,  playerCount: number, willBeShown: boolean) => keyframes`
  60%, 70% {
    transform: translate(${ deckToPlayerTranslateX - 120 }%, ${ deckToPlayerTranslateY(playerIndex, playerCount) }%) ${ willBeShown ? `` : 'rotateY(180deg)' };
  }
  
  to {
    transform: translate(${ deckToPlayerTranslateX }%, ${ deckToPlayerTranslateY(playerIndex, playerCount) }%) ${ willBeShown ? `` : 'rotateY(180deg)' } scale(0);
  }
`;

const animateHuntCard = (playerCount: number, limit: number, cardCount: number, indexOnStack: number, index: number, duration: number, delay: number) => css`
  z-index: ${ cardCount - index };
  animation: ${ duration }s ${ huntCardAnimation(playerCount, limit, indexOnStack, index) } ${ delay * index }s ease-in-out both;
`;

const huntCardAnimation = (playerCount: number, limit: number, indexOnStack: number, index: number) => keyframes`
  0% {
    transform: translate(${ getFillHuntTrackCardTranslateX(indexOnStack, limit) }%, ${ getFillHuntTrackCardTranslateY(indexOnStack, limit) }%) rotateY(-180deg);
  }
  50% {
    z-index: ${ 10 + index };
  }
  80%, 100% {
    transform-origin: top left;
    border-width: 0.01em;
    z-index: ${ 10 + index };
    transform: translate(${ getFillHuntTrackCardTargetTranslateX(0) }%, ${ getFillHuntTrackCardTargetTranslateY(playerCount, index) }%) scale(${ getFillHuntTrackCardTargetScale(playerCount) });
  }
`;

const getFillHuntTrackCardTranslateX = (index: number, limit: number) => index >= limit ? (1 * (limit - 1)) : (1 * (index - 1));
const getFillHuntTrackCardTranslateY = (index: number, limit: number) => cardRatio * (index >= limit ? (limit - 1) : (index - 1));

const huntDeck = (playerCount: number) => css`
  position: absolute;
  top: ${ huntDeckTop(playerCount) }%;
  left: ${ huntDeckLeft }%;
  height: ${ huntDeckHeight }%;
  width: ${ huntDeckWidth }%;
  font-size: ${ huntDeckHeight / 100 }em;
  cursor: pointer;
`;

const deckCard = (indexOnStack: number, limit: number) => css`
  transform: translate(${ getFillHuntTrackCardTranslateX(indexOnStack, limit) }%, ${ getFillHuntTrackCardTranslateY(indexOnStack, limit) }%) rotateY(180deg);
`;

const huntCard = (playerCount: number) => css`
  position: absolute;
  top: ${ huntDeckTop(playerCount) }%;
  left: ${ huntDeckLeft }%;
  height: ${ huntDeckHeight }%;
  width: ${ huntDeckWidth }%;
  font-size: ${ huntDeckHeight / 100 }em;
`;

export const onBackground = css`
  z-index: -1;
`;
