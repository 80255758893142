import { Card } from "../card/Card";
import { EffectType } from "../effect/EffectType";
import { EffectTrigger } from "../effect/EffectTrigger";
import { CardTrait } from "../card/CardTrait";
import { GameEffects } from "../effect/GameEffect";
import { VictoryPointEffect } from "../effect/VictoryPointEffect";
import { DiscardEffect } from "../effect/DiscardEffect";
import { DigestEffect } from "../effect/DigestEffect";
import { InspiringEffect } from "../effect/InspiringEffect";
import { FastEffect } from "../effect/FastEffect";
import { ConfuseEffect } from "../effect/ConfuseEffect";
import { GregariousEffect } from "../effect/GregariousEffect";
import { ReadyEffect } from "../effect/ReadyEffect";
import { DrawEffect } from "../effect/DrawEffect";
import { PushEffect } from "../effect/PushEffect";
import { SpeedBonusEffect } from "../effect/SpeedBonusEffect";
import { FreeHuntEffect } from "../effect/FreeHuntEffect";
import { HuntTrackMoveEffect } from "../effect/HuntTrackMoveEffect";
import { SpicyEffect } from "../effect/SpicyEffect";
import { TeleportEffect } from "../effect/TeleportEffect";
import { WinHuntTrackCardEffect } from "../effect/WinHuntTrackCardEffect";
import { AdditionalHuntEffect } from "../effect/AdditionalHuntEffect";
import { WinBonusTokenEffect } from "../effect/WinBonusTokenEffect";
import { VictoryPointsPerTraitEffect } from "../effect/VictoryPointsPerTraitEffect";

/** Passive Effect **/
export const hasPassiveEffect = (card: Card, effect: EffectType, trigger?: EffectTrigger, once?: boolean) =>
  card.passiveEffects && card.passiveEffects.some((e) => e.type === effect && (!trigger || trigger === e.trigger) && (!once || e.once === once));
export const hasAnIgnore = (cards?: Card[]): boolean => !!cards?.length && cards.some((c) => hasIgnoreEffect(c));
export const hasIgnoreEffect = (card: Card) => hasPassiveEffect(card, EffectType.Ignore);
export const hasConfuseEffect = (card: Card) => hasPassiveEffect(card, EffectType.Confuse);
export const hasVictoryPointEffect = (card: Card, trigger?: EffectTrigger, once?: boolean) => hasPassiveEffect(card, EffectType.VictoryPoint, trigger, once);
export const hasSpicyEffect = (card: Card) => hasPassiveEffect(card, EffectType.Spicy);
export const hasHolyWaterEffect = (card: Card) => hasPassiveEffect(card, EffectType.HolyWater);
export const hasGregariousEffect = (card: Card) => hasPassiveEffect(card, EffectType.Gregarious);

/** Effect **/
export const isReady = (card: Card) => card.traits?.some((trait) => trait === CardTrait.Ready);
export const hasEffect = (card: Card, effect: EffectType) => card.effects && card.effects.some((e) => e.type === effect);
export const hasMandatoryEffect = (card: Card) => card.effects?.some((e) => e.mandatory);

export const isEffect = (type: EffectType, effect?: GameEffects) => !!effect && effect.type === type;
export const isVictoryPoint = (effect?: GameEffects): effect is VictoryPointEffect => isEffect(EffectType.VictoryPoint, effect);
export const isVictoryPointPerTrait = (effect?: GameEffects): effect is VictoryPointsPerTraitEffect => isEffect(EffectType.PointPerTrait, effect);
export const isDiscard = (effect?: GameEffects): effect is DiscardEffect => isEffect(EffectType.Discard, effect);
export const isDigest = (effect?: GameEffects): effect is DigestEffect => isEffect(EffectType.Digest, effect);
export const isInspiring = (effect?: GameEffects): effect is InspiringEffect => isEffect(EffectType.Inspiring, effect);
export const isFast = (effect?: GameEffects): effect is FastEffect => isEffect(EffectType.Fast, effect);
export const isConfuseEffect = (effect?: GameEffects): effect is ConfuseEffect => isEffect(EffectType.Confuse, effect);
export const isGregariousEffect = (effect?: GameEffects): effect is GregariousEffect => isEffect(EffectType.Gregarious, effect);
export const isReadyEffect = (effect?: GameEffects): effect is ReadyEffect => isEffect(EffectType.Ready, effect);
export const isDraw = (effect?: GameEffects): effect is DrawEffect => isEffect(EffectType.Draw, effect);
export const isPushEffect = (effect?: GameEffects): effect is PushEffect => isEffect(EffectType.PushVampire, effect);
export const isSpeedBonus = (effect?: GameEffects): effect is SpeedBonusEffect => isEffect(EffectType.SpeedBonus, effect);
export const isWinBonusToken = (effect?: GameEffects): effect is WinBonusTokenEffect => isEffect(EffectType.WinBonusToken, effect);
export const isFreeHunt = (effect?: GameEffects): effect is FreeHuntEffect => isEffect(EffectType.FreeHunt, effect);
export const isAdditionalHunt = (effect?: GameEffects): effect is AdditionalHuntEffect => isEffect(EffectType.AdditionalHunt, effect);
export const isWinHuntTrackCard = (effect?: GameEffects): effect is WinHuntTrackCardEffect => isEffect(EffectType.WinHuntTrackCard, effect);
export const isHuntTrackMove = (effect?: GameEffects): effect is HuntTrackMoveEffect => isEffect(EffectType.HuntTrack, effect);
export const isSpicy = (effect?: GameEffects): effect is SpicyEffect => isEffect(EffectType.Spicy, effect);
export const isTeleport = (effect?: GameEffects): effect is TeleportEffect => isEffect(EffectType.Teleport, effect);

export const hasSpeedBonus = (card: Card) => card.passiveEffects && card.passiveEffects.some((e) => e.type === EffectType.SpeedBonus);
