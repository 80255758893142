/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { powerSymbolHeight, powerSymbolWidth } from '../utils/Style';
import { Images } from '../images/Images';

type PowerSymbolProps = {
  width?: number
} & HTMLAttributes<HTMLDivElement>;

const PowerSymbol: FC<PowerSymbolProps> = ({ width, ...props }) => {
  return (
    <span css={mini(width)} {...props} />
  );
};

const mini = (width?: number) => css`
  height: ${powerSymbolHeight(width)}em;
  width: ${width || powerSymbolWidth}em;
  background-image: url(${Images.PowerSymbol});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  top: 0.65em;
  position: relative;
  margin-bottom: -0.5em;
  transform: translateY(-50%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
`;

export {
  PowerSymbol
};
