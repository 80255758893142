/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { HuntCard } from '../../card/HuntCard';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { VictoryPointSymbol } from '../../../symbols';
import { FC } from 'react';
import { cardRatio, screenRatio } from '../../../utils/Style';
import { useLongPress } from '../../../utils/useLongPress';
import { VictoryPointEffect } from '@gamepark/the-hunger/effect/VictoryPointEffect';
import { VictoryPointsPerTraitEffect } from '@gamepark/the-hunger/effect/VictoryPointsPerTraitEffect';
import { isVictoryPoint, isVictoryPointPerTrait } from '@gamepark/the-hunger/utils/EffectUtils';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { PlayerType } from '@gamepark/the-hunger/player';

type CardsViewerCardProps = {
  hunt: number;
  player: PlayerType;
  onCardLongPress: () => void;
  oldTokensCount?: boolean;
}

const CardsViewerCard: FC<CardsViewerCardProps> = (props) => {
  const { hunt, onCardLongPress, oldTokensCount, player } = props;

  const longPress = useLongPress({
    onLongPress: onCardLongPress,
    moveTolerance: 5000
  });

  const card = GameDeck[hunt];
  const effect = card.passiveEffects?.length ? card.passiveEffects[0] : undefined;
  const victoryPointEffect: VictoryPointEffect | VictoryPointsPerTraitEffect | undefined = effect && (isVictoryPoint(effect) || isVictoryPointPerTrait(effect)) ? effect : undefined;
  const score = victoryPointEffect && victoryPointEffect.computeScore(player, GameDeck, Bonuses(oldTokensCount));
  return (
    <div css={ cardHolderStyle }>
      <HuntCard hunt={ card } css={ cardStyle } onClick={ (e) => e.stopPropagation() }  { ...longPress } />
      { !!score && (
        <div css={ cardVictoryPointHolder } >
          <VictoryPointSymbol value={ `${ score }` } css={ cardVictoryPoint } />
        </div>
      ) }
    </div>
  );
};


const viewerCardHeight = 31;
const cardHolderStyle = css`
  float: left;
  height: ${ viewerCardHeight }%;
  margin: 3em;
  width: ${ (viewerCardHeight * (90 / 100) * cardRatio) / screenRatio }%;
  font-size: ${ viewerCardHeight / 100 }em;
  z-index: 100;
`;

const cardStyle = css`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  
`;

const cardVictoryPointHolder = css`
  position: relative;
  height: 100%;
  width: 100%;
  top: -100%;
  pointer-events: none;
`;

const cardVictoryPoint = css`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 9em;
  filter: drop-shadow(0.1em 0.1em 0.1em black);
`;

export {
  CardsViewerCard
};
