import {
  AHankering,
  AnimalLover,
  ASoldiersLife,
  ASoldiersLife5P,
  BeastMaster,
  CatchUp,
  CommonTaste,
  CommonTaste5P,
  DangerousDiet,
  Digestion,
  EarlyNight,
  EatThemAll,
  EatThemAll5P,
  FamilyBusiness,
  Gluttony,
  Gluttony5P, Gourmet,
  HauteCuisine,
  HauteCuisine5P,
  HolierThanThou,
  HolierThanThou5p,
  Hungry,
  Landlord,
  Meh,
  Meh5P,
  Mission,
  Missionary,
  MyBodyIsATemple,
  OnADiet,
  Picky,
  Picky5P,
  Revolutionary,
  RichGetRicher,
  Romantic,
  Royal,
  Royal5P,
  Selective,
  Strategist,
  TheCollector,
  TheDevout,
  TheHost,
  TheOpportunist,
  ThreeStarDinner, ThreeStarDinner5P, Tipsy, TreasureChest, VampireOfTheCoast, VampireOnTop, VariedDiet, WildVampire, Zoologist
} from '@gamepark/the-hunger/mission';
import { TFunction } from 'i18next';

const MissionTitles = new Map<Mission, (t: TFunction) => string>();

MissionTitles.set(AHankering, (t: TFunction) => t('mission.title.hankering'));
MissionTitles.set(ASoldiersLife, (t: TFunction) => t('mission.title.soldierlife'));
MissionTitles.set(ASoldiersLife5P, (t: TFunction) => t('mission.title.soldierlife'));
MissionTitles.set(AnimalLover, (t: TFunction) => t('mission.title.animallover'));
MissionTitles.set(BeastMaster, (t: TFunction) => t('mission.title.beastmaster'));
MissionTitles.set(CatchUp, (t: TFunction) => t('mission.title.catchup'));
MissionTitles.set(CommonTaste, (t: TFunction) => t('mission.title.commontaste'));
MissionTitles.set(CommonTaste5P, (t: TFunction) => t('mission.title.commontaste'));
MissionTitles.set(DangerousDiet, (t: TFunction) => t('mission.title.dangerousdiet'));
MissionTitles.set(Digestion, (t: TFunction) => t('mission.title.digestion'));
MissionTitles.set(EarlyNight, (t: TFunction) => t('mission.title.earlynight'));
MissionTitles.set(EatThemAll, (t: TFunction) => t('mission.title.eatthemall'));
MissionTitles.set(EatThemAll5P, (t: TFunction) => t('mission.title.eatthemall'));
MissionTitles.set(FamilyBusiness, (t: TFunction) => t('mission.title.familybusiness'));
MissionTitles.set(Gluttony, (t: TFunction) => t('mission.title.gluttony'));
MissionTitles.set(Gluttony5P, (t: TFunction) => t('mission.title.gluttony'));
MissionTitles.set(Gourmet, (t: TFunction) => t('mission.title.gourmet'));
MissionTitles.set(HauteCuisine, (t: TFunction) => t('mission.title.hautecuisine'));
MissionTitles.set(HauteCuisine5P, (t: TFunction) => t('mission.title.hautecuisine'));
MissionTitles.set(HolierThanThou, (t: TFunction) => t('mission.title.holierthanthou'));
MissionTitles.set(HolierThanThou5p, (t: TFunction) => t('mission.title.holierthanthou'));
MissionTitles.set(Hungry, (t: TFunction) => t('mission.title.hungry'));
MissionTitles.set(Landlord, (t: TFunction) => t('mission.title.landlord'));
MissionTitles.set(Meh, (t: TFunction) => t('mission.title.meh'));
MissionTitles.set(Meh5P, (t: TFunction) => t('mission.title.meh'));
MissionTitles.set(Missionary, (t: TFunction) => t('mission.title.missionary'));
MissionTitles.set(MyBodyIsATemple, (t: TFunction) => t('mission.title.mybodyisatemple'));
MissionTitles.set(OnADiet, (t: TFunction) => t('mission.title.onadiet'));
MissionTitles.set(Picky, (t: TFunction) => t('mission.title.picky'));
MissionTitles.set(Picky5P, (t: TFunction) => t('mission.title.picky'));
MissionTitles.set(Revolutionary, (t: TFunction) => t('mission.title.revolutionary'));
MissionTitles.set(RichGetRicher, (t: TFunction) => t('mission.title.richgetricher'));
MissionTitles.set(Romantic, (t: TFunction) => t('mission.title.romantic'));
MissionTitles.set(Royal, (t: TFunction) => t('mission.title.royal'));
MissionTitles.set(Royal5P, (t: TFunction) => t('mission.title.royal'));
MissionTitles.set(Selective, (t: TFunction) => t('mission.title.selective'));
MissionTitles.set(Strategist, (t: TFunction) => t('mission.title.strategist'));
MissionTitles.set(TheCollector, (t: TFunction) => t('mission.title.thecollector'));
MissionTitles.set(TheDevout, (t: TFunction) => t('mission.title.thedevout'));
MissionTitles.set(TheHost, (t: TFunction) => t('mission.title.thehost'));
MissionTitles.set(TheOpportunist, (t: TFunction) => t('mission.title.theopportunist'));
MissionTitles.set(ThreeStarDinner, (t: TFunction) => t('mission.title.threestardinner'));
MissionTitles.set(ThreeStarDinner5P, (t: TFunction) => t('mission.title.threestardinner'));
MissionTitles.set(Tipsy, (t: TFunction) => t('mission.title.tipsy'));
MissionTitles.set(TreasureChest, (t: TFunction) => t('mission.title.treasurechest'));
MissionTitles.set(VampireOfTheCoast, (t: TFunction) => t('mission.title.vampireofthecoast'));
MissionTitles.set(VampireOnTop, (t: TFunction) => t('mission.title.vampireontop'));
MissionTitles.set(VariedDiet, (t: TFunction) => t('mission.title.varieddiet'));
MissionTitles.set(WildVampire, (t: TFunction) => t('mission.title.wildvampire'));
MissionTitles.set(Zoologist, (t: TFunction) => t('mission.title.zoologist'));

export {
  MissionTitles
};
