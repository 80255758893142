import { GameEffect } from "./GameEffect";
import { EffectType } from "./EffectType";
import Move from "../moves/Move";
import { moveVampireMove } from "../moves/MoveVampire";
import { dismissEffectMove } from "../moves/DismissEffect";
import GameState from "../GameState";
import Vampire from "../player/Vampire";
import { BoardBoxes } from "../board";

export class PushEffect implements GameEffect {
  type: EffectType.PushVampire = EffectType.PushVampire;
  optional?: boolean = true;
  vampire: Vampire;

  constructor(object: Partial<PushEffect>) {
    this.vampire = object.vampire!;
    this.optional = true;
  }

  legalMoves(state: GameState): Move[] {
    const vampireBox = state.players.find((p) => this.vampire === p.vampire)!.position.box;
    return [...BoardBoxes[vampireBox].branches.map((b) => moveVampireMove(b, this.vampire)), dismissEffectMove];
  }
}
