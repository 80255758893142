import { DraggableTypes } from './DraggableTypes';
import Vampire from '@gamepark/the-hunger/player/Vampire';

export type PlayerTokenFromBoard = {
  type: DraggableTypes.PlayerTokenFromBoard
  vampire: Vampire
}

export function playerTokenFromBoard(vampire: Vampire): PlayerTokenFromBoard {
    return {
      type: DraggableTypes.PlayerTokenFromBoard,
      vampire
    }
}