/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { forwardRef, HTMLAttributes } from 'react';
import { Token } from '../Token';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import Move from '@gamepark/the-hunger/moves/Move';
import { playerTokenFromBoard } from '../draggable/PlayerTokenFromBoard';
import { selectVampireMove } from '../../moves/SelectVampire';
import { PlayerType, TokenSide } from '@gamepark/the-hunger/player';
import { canMove } from '@gamepark/the-hunger/utils/MoveUtils';
import { Images } from '../../images/Images';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { DraggableTypes } from '../draggable/DraggableTypes';
import { BoardBoxes } from '@gamepark/the-hunger/board';

export type PlayerTokenProps = {
  player?: PlayerType;
  vampire: Vampire;
  activePlayer?: Vampire;
  selectedVampire?: Vampire;
  canDrag?: boolean;
  isDraggable?: boolean;
  preTransform?: string;
  postTransform?: string;
  semiTransparent?: boolean;
  flippable?: boolean;
  overlayColor?: string;
  zoom?: number;
} & HTMLAttributes<HTMLDivElement>;

const PlayerToken = forwardRef<HTMLDivElement, PlayerTokenProps>(
  (
    {
      selectedVampire,
      semiTransparent,
      isDraggable,
      canDrag,
      activePlayer,
      player,
      vampire,
      preTransform,
      postTransform,
      flippable = true,
      zoom = 1,
      overlayColor,
      ...props
    },
    ref
  ) => {
    const play = usePlay();
    const playerId = usePlayerId();
    const onDropToken = (move: Move) => {
      if (move) {
        play(move);
      }
    };

    const selectVampire = () => {
      if (selectedVampire) {
        play(selectVampireMove(), { local: true });
      } else {
        play(selectVampireMove(vampire), { local: true });
      }
    };

    const onDrag = () => {
      play(selectVampireMove(vampire), { local: true });
      return playerTokenFromBoard(vampire);
    };

    const onEndDrag = () => {
      if (selectedVampire) {
        play(selectVampireMove(), { local: true });
      }
    };

    const playerCanDrag =
      isDraggable &&
      canDrag &&
      playerId &&
      player &&
      (playerId !== vampire || canMove(player, GameDeck, BoardBoxes, activePlayer));
    const onVampireClick = (event: any) => {
      if (props.onClick) {
        props.onClick(event);
        return;
      }

      if (!canDrag) {
        return;
      }

      selectVampire();
    };

    return (
      <Token
        {...props}
        ref={ref}
        onClick={onVampireClick}
        backColor={playerVampireColor.get(vampire)}
        front={playerVampireImages.get(vampire)}
        canDrag={playerCanDrag}
        preTransform={preTransform}
        postTransform={
          postTransform || (flippable && player?.tokenSide === TokenSide.Resting)
            ? `${postTransform || ''} ${player?.tokenSide === TokenSide.Resting ? `rotateY(180deg)` : ''}`
            : undefined
        }
        type={isDraggable ? DraggableTypes.PlayerTokenFromBoard : undefined}
        item={onDrag}
        drop={onDropToken}
        end={onEndDrag}
        overlayColor={overlayColor}
        semiTransparent={semiTransparent}
        zoom={zoom}
        css={[
          stopHighlight,
          playerCanDrag && !selectedVampire && draggable,
          playerCanDrag && selectedVampire && selectedVampire !== vampire && pointerEvent,
        ]}
      />
    );
  }
);

const draggable = css`
  cursor: grab;

  box-shadow: 0 0 0.2em 0.5em gold, 0 0 0.2em 0.5em gold;
`;

const stopHighlight = css`
  -webkit-tap-highlight-color: transparent;
`;

const pointerEvent = css`
  pointer-events: none;
`;

const playerVampireColor = new Map<Vampire, string>();
playerVampireColor.set(Vampire.YokoChiyako, 'red');
playerVampireColor.set(Vampire.BorisPouchkine, 'blue');
playerVampireColor.set(Vampire.LadyBeatrice, 'purple');
playerVampireColor.set(Vampire.RajeshAmara, 'green');
playerVampireColor.set(Vampire.JosephineLafayette, 'saddlebrown');
playerVampireColor.set(Vampire.DonGervasi, 'orange');

const playerVampireImages = new Map<Vampire, any>();
playerVampireImages.set(Vampire.YokoChiyako, Images.YokoChiyakoPlayerToken);
playerVampireImages.set(Vampire.BorisPouchkine, Images.BorisPouchkinePlayerToken);
playerVampireImages.set(Vampire.LadyBeatrice, Images.LadyBeatricePlayerToken);
playerVampireImages.set(Vampire.RajeshAmara, Images.RajeshAmaraPlayerToken);
playerVampireImages.set(Vampire.JosephineLafayette, Images.JosephineLafayettePlayerToken);
playerVampireImages.set(Vampire.DonGervasi, Images.DonGervasiPlayerToken);

export { PlayerToken };
