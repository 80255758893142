/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, useMemo } from 'react';
import { castleTokenHeight, castleTokenLeft, castleTokenTop, castleTokenWidth, translateXCastleTokenToPlayer, translateYCastleTokenToPlayer } from '../../utils/Style';
import { Images } from '../../images/Images';
import { PlayerType } from '@gamepark/the-hunger/player';
import { useAnimation, usePlayerId } from '@gamepark/react-client';
import { isMoveVampire, MoveVampire } from '@gamepark/the-hunger/moves/MoveVampire';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { comeBackToCastle } from '@gamepark/the-hunger/utils/MoveUtils';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';

export type CastleTokenProps = {
  castleTokens: number[],
  activePlayer?: Vampire,
  players: Array<PlayerType>
};

const CastleToken: FC<CastleTokenProps> = ({ castleTokens, activePlayer, players }) => {
  const playerId = usePlayerId();
  const moveAnimation = useAnimation<MoveVampire>(animation => isMoveVampire(animation.move) && animation.move.vampire === activePlayer);

  // eslint-disable-next-line
  const realPlayers = useMemo(() => getOrderedPlayersForDisplay(players, playerId), [players]);
  const targetPlayer = moveAnimation && realPlayers.findIndex(p => p.vampire === moveAnimation.move.vampire && comeBackToCastle(p, moveAnimation.move.box, BoardBoxes));

  if (!castleTokens.length) {
    return null;
  }

  return (
    <>

      { !!moveAnimation && !!(castleTokens.length - 1) &&
        <div style={{ backgroundImage: `url(${ Images.CastleToken })`}}  css={ castleToken }>
            <span css={ tokenValue }>{ castleTokens[1] }</span>
        </div>
      }
      <div style={{ backgroundImage: `url(${ Images.CastleToken })`}} css={ [castleToken, moveAnimation && targetPlayer !== undefined && targetPlayer !== -1 && animateToPlayer(targetPlayer, players.length, moveAnimation.duration)] }>
        <span css={ tokenValue }>{ castleTokens[0] }</span>
      </div>
    </>

  );
};

const castleToken = css`
  position: absolute;
  left: ${ castleTokenLeft }%;
  top: ${ castleTokenTop }%;
  height: ${ castleTokenHeight }%;
  width: ${ castleTokenWidth }%;
  background-size: 100% 100%;
  font-size: ${ castleTokenHeight }em;
  filter: drop-shadow(0.03em 0.03em 0 black);
`;

const animateToPlayer = (index: number, playerCount: number, duration: number) => css`
  z-index: 99;
  animation: ${ animation(index, playerCount) } ${ duration }s ease-in-out;
`;

const animation = (index: number, playerCount: number) => keyframes`
  50% {
    transform: translate(${ translateXCastleTokenToPlayer() / 2 }%, ${ translateYCastleTokenToPlayer(index, playerCount) / 2 }%) scale(2);
  }
  100% {
    transform: translate(${ translateXCastleTokenToPlayer() }%, ${ translateYCastleTokenToPlayer(index, playerCount) }%) scale(0);
  }
`;

const tokenValue = css`
  color: white;
  position: absolute;
  font-weight: bold;
  top: 56%;
  font-size: 0.25em;
  left: 48.8%;
  transform: translate(-50%, -50%);
`;

export {
  CastleToken
};
