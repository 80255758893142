import { Mission } from './Mission';
import { MissionType } from './MissionType';
import { GameMode } from '../GameMode';
import { CardType, HumanTypes } from '../card/CardType';
import { WinHuntTrackCardEffect } from '../effect/WinHuntTrackCardEffect';
import { BoardBox, BoardBoxEffect, Region } from '../board';
import { WinBonusTokenEffect } from '../effect/WinBonusTokenEffect';
import { SpeedBonusEffect } from '../effect/SpeedBonusEffect';
import { Player } from '../player';
import { isFamiliar } from '../utils/CardUtils';
import { Card, isBrutVictoryPoint } from '../card/Card';
import { isHuman } from '../card/hunt/Human';
import { FamiliarTypes } from '../card/hunt';
import { isRose } from '../card/hunt/Rose';
import { isPower } from '../card/hunt/Power';
import { hasConfuseEffect, hasHolyWaterEffect, hasSpicyEffect } from '../utils/EffectUtils';
import { EffectTrigger } from '../effect/EffectTrigger';
import { DigestEffect } from '../effect/DigestEffect';
import { DiscardEffect } from '../effect/DiscardEffect';
import { SkipTurnEffect } from '../effect/SkipTurnEffect';
import { Bonus } from '../bonus/Bonus';
import { computeHumans } from '../utils/EndOfGameUtils';

export const WildVampire: Mission = {
  type: MissionType.Gold,
  effects: [
    new WinHuntTrackCardEffect({ huntedTypes: HumanTypes, region: Region.Forest })
  ]
};

export const VampireOnTop: Mission = {
  type: MissionType.Gold,
  effects: [
    new WinHuntTrackCardEffect({ huntedTypes: HumanTypes, region: Region.Mountain })
  ]
};

export const BeastMaster: Mission = {
  type: MissionType.Gold,
  effects: [
    new WinHuntTrackCardEffect({ huntTypes: [CardType.Familiar], toPlayingArea: true })
  ]
};

export const Hungry: Mission = {
  type: MissionType.Gold,
  effects: [
    new WinHuntTrackCardEffect({ huntedArea: 0 })
  ]
};

export const HolierThanThou: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanReligious);
  }

};

export const HolierThanThou5p: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanReligious);
  }
};

export const CatchUp: Mission = {
  type: MissionType.Beige,
  scoreTrigger: EffectTrigger.BeforeMissionScore,
  computeScore(player: Player, otherPlayers: Array<Player>): number {
    return otherPlayers.every(p => p.score > player.score) ? 10 : 0;
  }
};

export const Picky: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return ![...player.digest, ...player.discard].some(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && vp >= 5;
    }) ? 6 : 0;
  }
};

export const Picky5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return ![...player.digest, ...player.discard].some(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && vp >= 5;
    }) ? 6 : 0;
  }
};

export const AnimalLover: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return new Set(
      [...player.digest, ...player.discard]
        .filter(c => isFamiliar(deck[c]))
        .flatMap(c => deck[c].traits?.filter(t => FamiliarTypes.includes(t)))
    ).size;
  }
};

export const Romantic: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].some(c => isRose(deck[c])) ? 5 : 0;
  }

};

export const MyBodyIsATemple: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => isPower(deck[c])).length;
  }

};

export const DangerousDiet: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => hasSpicyEffect(deck[c]) || hasHolyWaterEffect(deck[c]) || hasConfuseEffect(deck[c])).length * 2;
  }
};

export const EarlyNight: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, _deck: Card[], board: BoardBox[]): number {
    if (board[player.position.box].effect !== BoardBoxEffect.Castle) {
      return 0;
    }

    return player.position.z === 1 ? 6 : 0;
  }
};

export const Landlord: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const humanCount = computeHumans(player, deck, bonuses, CardType.HumanVillager);
    if (!otherPlayers.some(p => computeHumans(p, deck, bonuses, CardType.HumanVillager) >= humanCount)) {
      return 6;
    }
    return 0;
  }

};

export const RichGetRicher: Mission = {
  type: MissionType.Beige,
  scoreTrigger: EffectTrigger.BeforeMissionScore,
  computeScore(player: Player, otherPlayers: Array<Player>): number {
    return otherPlayers.every(p => p.score < player.score) ? 4 : 0;
  }
};

export const Meh: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && ([1, 2].includes(vp));
    }).length;
  }
};

export const Meh5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && ([1, 2].includes(vp));
    }).length;
  }
};

export const HauteCuisine: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && vp >= 4;
    }).length;
  }
};

export const HauteCuisine5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && vp >= 4;
    }).length;
  }
};

export const EatThemAll: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses) >= 13 ? 5 : 0;
  }
};

export const EatThemAll5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses) >= 13 ? 5 : 0;
  }
};

export const TheHost: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, otherPlayers: Array<Player>, _deck: Card[], board: BoardBox[]): number {
    if (board[player.position.box].effect !== BoardBoxEffect.Castle) {
      return 0;
    }

    return 2 + (otherPlayers.filter(p => board[p.position.box].effect !== BoardBoxEffect.Castle || p.position.z > player.position.z).length * 2);
  }
};

export const OnADiet: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const humanCount = computeHumans(player, deck, bonuses);
    return !otherPlayers.some(p => computeHumans(p, deck, bonuses) <= humanCount) ? 5 : 0;
  }

};

export const Revolutionary: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const nobleCount = computeHumans(player, deck, bonuses, CardType.HumanNoble);
    return !otherPlayers.some(p => computeHumans(p, deck, bonuses, CardType.HumanNoble) >= nobleCount) ? 6 : 0;
  }
};

export const ThreeStarDinner: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && vp === 3;
    }).length;
  }
};

export const ThreeStarDinner5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => {
      const card = deck[c];
      const vp: number = isBrutVictoryPoint(card.victoryPoints) ? card.victoryPoints : 0;
      return isHuman(deck[c]) && vp === 3;
    }).length;
  }
};

export const Royal: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanNoble);
  }
};

export const Royal5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanNoble);
  }
};

export const Gluttony: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const humanCount = computeHumans(player, deck, bonuses);
    return !otherPlayers.some(p => computeHumans(p, deck, bonuses) >= humanCount) ? 5 : 0;
  }
};

export const Gluttony5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const humanCount = computeHumans(player, deck, bonuses);
    return !otherPlayers.some(p => computeHumans(p, deck, bonuses) >= humanCount) ? 5 : 0;
  }
};

export const ASoldiersLife: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanMilitary);
  }
};

export const ASoldiersLife5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanMilitary);
  }
};

export const CommonTaste: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanVillager);
  }
};

export const CommonTaste5P: Mission = {
  type: MissionType.Beige,
  minPlayers: 5,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    return computeHumans(player, deck, bonuses, CardType.HumanVillager);
  }
};

export const TreasureChest: Mission = {
  type: MissionType.Gold,
  effects: [
    new WinBonusTokenEffect()
  ]
};

export const VampireOfTheCoast: Mission = {
  type: MissionType.Gold,
  effects: [
    new WinHuntTrackCardEffect({ huntedTypes: HumanTypes, region: Region.Plain })
  ]
};

export const Digestion: Mission = {
  type: MissionType.Gold,
  effects: [
    new DigestEffect({ hand: true, auto: true, huntTypes: HumanTypes, trigger: EffectTrigger.BeforePlaying }),
    new DiscardEffect({ allHand: true, auto: true }),
    new SkipTurnEffect()
  ]
};

export const TheOpportunist: Mission = {
  type: MissionType.Gold,
  effects: [
    new SpeedBonusEffect(1, { perPlayerCloserToCastle: true })
  ]
};

export const VariedDiet: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const nobles = computeHumans(player, deck, bonuses, CardType.HumanNoble);
    const militaries = computeHumans(player, deck, bonuses, CardType.HumanMilitary);
    const villagers = computeHumans(player, deck, bonuses, CardType.HumanVillager);
    const religious = computeHumans(player, deck, bonuses, CardType.HumanReligious);

    return Math.min(nobles, militaries, villagers, religious) * 3;
  }

};

export const AHankering: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const nobles = computeHumans(player, deck, bonuses, CardType.HumanNoble);
    const militaries = computeHumans(player, deck, bonuses, CardType.HumanMilitary);
    const villagers = computeHumans(player, deck, bonuses, CardType.HumanVillager);
    const religious = computeHumans(player, deck, bonuses, CardType.HumanReligious);

    return Math.max(nobles, militaries, villagers, religious) >= 5 ? 6: 0;
  }
};

export const Zoologist: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[]): number {
    const familiarCount = [...player.digest, ...player.discard].filter(c => isFamiliar(deck[c])).length;

    return !otherPlayers.some(p => [...p.digest, ...p.discard].filter(c => isFamiliar(deck[c])).length >= familiarCount)? 4: 0;
  }
};

export const FamilyBusiness: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const nobles = computeHumans(player, deck, bonuses, CardType.HumanNoble);
    const militaries = computeHumans(player, deck, bonuses, CardType.HumanMilitary);
    const villagers = computeHumans(player, deck, bonuses, CardType.HumanVillager);
    const religious = computeHumans(player, deck, bonuses, CardType.HumanReligious);

    return Math.max(nobles, militaries, villagers, religious);
  }
};

export const TheCollector: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player): number {
    return player.bonusTokens.length;
  }
};

export const Missionary: Mission = {
  type: MissionType.Beige,
  scoreTrigger: EffectTrigger.AfterMissionScore,
  computeScore(player: Player): number {
    return player.missions.filter(m => m.score).length + 1;
  }

};

export const TheDevout: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const nobleCount = computeHumans(player, deck, bonuses, CardType.HumanReligious);
    return !otherPlayers.some(p => computeHumans(p, deck, bonuses, CardType.HumanReligious) >= nobleCount) ? 6 : 0;
  }
};

export const Strategist: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Elder,
  computeScore(player: Player, otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const militaryCount = computeHumans(player, deck, bonuses, CardType.HumanMilitary);
    return !otherPlayers.some(p => computeHumans(p, deck, bonuses, CardType.HumanMilitary) >= militaryCount) ? 6 : 0;
  }
};

export const Selective: Mission = {
  type: MissionType.Beige,
  gameMode: GameMode.Rookie,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[], _board: BoardBox[], bonuses: Bonus[]): number {
    const nobles = computeHumans(player, deck, bonuses, CardType.HumanNoble);
    const militaries = computeHumans(player, deck, bonuses, CardType.HumanMilitary);
    const villagers = computeHumans(player, deck, bonuses, CardType.HumanVillager);
    const religious = computeHumans(player, deck, bonuses, CardType.HumanReligious);

    return Math.min(nobles, militaries, villagers, religious);
  }
};

export const Tipsy: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return [...player.digest, ...player.discard].filter(c => hasConfuseEffect(deck[c])).length * 4;
  }
};

export const Gourmet: Mission = {
  type: MissionType.Beige,
  computeScore(player: Player, _otherPlayers: Array<Player>, deck: Card[]): number {
    return player.digest.filter(c => isHuman(deck[c])).length * 2;
  }
};

export const Missions: Mission[] = [
  AHankering,
  ASoldiersLife,
  ASoldiersLife5P,
  AnimalLover,
  BeastMaster,
  CatchUp,
  CommonTaste,
  CommonTaste5P,
  DangerousDiet,
  Digestion,
  EarlyNight,
  EatThemAll,
  EatThemAll5P,
  FamilyBusiness,
  Gluttony,
  Gluttony5P,
  Gourmet,
  HauteCuisine,
  HauteCuisine5P,
  HolierThanThou,
  HolierThanThou5p,
  Hungry,
  Landlord,
  Meh,
  Meh5P,
  Missionary,
  MyBodyIsATemple,
  OnADiet,
  Picky,
  Picky5P,
  Revolutionary,
  RichGetRicher,
  Romantic,
  Royal,
  Royal5P,
  Selective,
  Strategist,
  TheCollector,
  TheDevout,
  TheHost,
  TheOpportunist,
  ThreeStarDinner,
  ThreeStarDinner5P,
  Tipsy,
  TreasureChest,
  VampireOfTheCoast,
  VampireOnTop,
  VariedDiet,
  WildVampire,
  Zoologist
];



