import { DraggableTypes } from './DraggableTypes';

export type HuntTrackArea = {
  type: DraggableTypes.HuntTrackArea;
  row: number;
  col: number;
  card?: number;
};

export const huntTrackArea = (row: number, col: number, card?: number): HuntTrackArea => ({
  type: DraggableTypes.HuntTrackArea,
  row: row,
  col: col,
  card,
});
