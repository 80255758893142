import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { TavernHuntCost } from '../utils/GameConstants';
import { applyHunting } from '../utils/HuntUtils';
import { isReady } from '../utils/EffectUtils';
import { GameDeck } from '../card/hunt/GameDeck';
import Move from './Move';
import MoveView from './MoveView';
import { BoardBox, BoardBoxEffect } from '../board';

export type HuntInTavern = {
  type: MoveType.HuntInTavern
  count: number,
}

export const huntInTavernMove = (count: number): HuntInTavern => ({
  type: MoveType.HuntInTavern,
  count
});

export type HuntInTavernView = HuntInTavern & { hunts: number[] }


export const isHuntInTavernMoveView = (move: Move): move is HuntInTavernView => move.type === MoveType.HuntInTavern && (move as HuntInTavernView).hunts !== undefined;
export const isHuntInTavernMove = (move: Move): move is HuntInTavern => move.type === MoveType.HuntInTavern && !isHuntInTavernMoveView(move);
export const isHuntInTavern = (move: Move | MoveView): move is HuntInTavern => move.type === MoveType.HuntInTavern;

export const huntInTavern = (state: GameState, board: BoardBox[]) => {
  let cards = state.tavern;
  state.tavern = [];

  applyHuntInTavern(state, cards, board);
};

export const huntInTavernView = (state: GameView, move: HuntInTavernView, board: BoardBox[]) => {
  state.tavern = 0;

  applyHuntInTavern(state, move.hunts, board);
};

const applyHuntInTavern = (state: GameState | GameView, cards: number[], board: BoardBox[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  player.remainingSpeed! -= TavernHuntCost;
  player.hunts.push({
    hunts: cards,
    region: board[player.position.box].region
  });

  applyHunting(state, cards, GameDeck);
  player.discard.push(...cards.filter(c => !isReady(GameDeck[c])));

  if (player.boardEffect === BoardBoxEffect.Tavern) {
    delete player.boardEffect;
  }

};

export const getHuntInTavernMoveView = (state: GameState, move: HuntInTavern): HuntInTavernView => {
  return {
    ...move,
    hunts: state.tavern
  };
};
