import GameView from '@gamepark/the-hunger/GameView';
import Vampire from '@gamepark/the-hunger/player/Vampire';

export type DisplayScore = {
  type: 'DisplayScore';
  player?: Vampire;
};

export const displayScoreMove = (player?: Vampire): DisplayScore => ({
  type: 'DisplayScore',
  player,
});

export function displayScore(state: GameView, move: DisplayScore) {
  state.displayedScore = move.player;
}
