import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { applyHunting } from '../utils/HuntUtils';
import { isGregariousEffect, isReady } from '../utils/EffectUtils';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { Card } from '../card/Card';
import MoveView from './MoveView';
import Move from './Move';

export type DrawHuntCard = {
  type: MoveType.DrawHuntCard
}

export type DrawHuntCardView = DrawHuntCard & {
  card: number
};


export const drawHuntCardMove: DrawHuntCard = ({
  type: MoveType.DrawHuntCard
});

export const isDrawHuntCard = (move: Move | MoveView): move is DrawHuntCard => move.type === MoveType.DrawHuntCard
  export const isDrawHuntCardView = (move: Move | MoveView): move is DrawHuntCardView => move.type === MoveType.DrawHuntCard && (move as DrawHuntCardView).card !== undefined;

/**
 * Draw a card from the hunt deck
 * @param state The game state
 * @param deck
 */
export const drawHuntCard = (state: GameState, deck: Card[]) => {
  let card = state.hunt.deck.shift()!;
  applyDrawHuntCard(state, card, deck);
};

export const drawHuntCardView = (state: GameView, move: DrawHuntCardView, deck: Card[]) => {
  state.hunt.deck = Math.max(0, state.hunt.deck - 1);
  applyDrawHuntCard(state, move.card, deck);
};

const applyDrawHuntCard = (state: GameState | GameView, card: number, deck: Card[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  if (card && !isReady(deck[card])) {
    player.discard.push(card);
  }

  const pendingEffect = getNextPendingEffect(player);
  if (isGregariousEffect(pendingEffect)) {
    removeNextPendingEffect(player)
  }

  applyHunting(state, [card], deck);

}

export const getDrawHuntCardMoveView = (state: GameState, move: DrawHuntCard): DrawHuntCardView => {
  return {
    ...move,
    card: state.hunt.deck[0]
  }
}
