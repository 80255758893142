/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes, useState } from 'react';
import { HuntCardStack } from '../card/HuntCardStack';
import { gameBoardCardHeight, gameBoardCardWidth } from '../../utils/Style';
import { PlayerType } from '@gamepark/the-hunger/player';
import { usePlay } from '@gamepark/react-client';
import { huntRoseMove } from '@gamepark/the-hunger/moves/HuntRose';
import { canHuntRose } from '@gamepark/the-hunger/utils/HuntUtils';
import { HuntCardsCatalog } from '../card/HuntCardsCatalog';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';

type RosePileProps = {
  roses: number[];
  player?: PlayerType;
} & HTMLAttributes<HTMLDivElement>;

const RosePile: FC<RosePileProps> = ({ player, roses, ...props }) => {
  const [isRoseFocused, setRoseFocused] = useState(false);
  const play = usePlay();
  const canDrawRose = player && canHuntRose(player!, GameDeck);
  const drawRoses = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setRoseFocused(true);
  };

  const chooseRose = (rose: number) => {
    if (canDrawRose) {
      play(huntRoseMove(rose));
      setRoseFocused(false);
    }
  };

  let hideOverlay = (event?: any) => {
    setRoseFocused(false);
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
  };
  return (
    <>
      {isRoseFocused && (
        <HuntCardsCatalog
          hunts={roses}
          onClose={hideOverlay}
          onHuntClick={canDrawRose ? (_, hunt) => chooseRose(hunt) : undefined}
        />
      )}
      {!isRoseFocused && !!roses.length && (
        <HuntCardStack
          css={[roseStack, canDrawRose && highlightPile]}
          hunts={roses}
          position={0}
          tooltipPosition="right"
          preTransform={`translate(174%, 20%) rotateZ(-90deg)`}
          onClick={drawRoses}
          {...props}
        />
      )}
    </>
  );
};

const roseStack = css`
  position: absolute;
  height: ${gameBoardCardHeight}%;
  width: ${gameBoardCardWidth}%;
  font-size: ${gameBoardCardHeight / 100}em;
  z-index: 4;
`;

const highlightPile = css`
  filter: drop-shadow(0 0 0.5em gold) drop-shadow(0 0 0.5em gold) drop-shadow(0 0 0.5em gold)
    drop-shadow(0 0 0.5em gold) drop-shadow(0 0 0.5em gold) drop-shadow(0 0 0.5em gold);

  &:hover {
    filter: drop-shadow(0 0 0.5em green) drop-shadow(0 0 0.5em green) drop-shadow(0 0 0.5em green)
      drop-shadow(0 0 0.5em green) drop-shadow(0 0 0.5em green) drop-shadow(0 0 0.5em green);
  }

  cursor: pointer;
`;

export { RosePile };
