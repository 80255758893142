import { FormOfBat, FormOfMist, Hypnosis, VampireStrength, VampireStrengthAlt, VampireWill, VampireWillAlt, VampiricStealth } from '@gamepark/the-hunger/card/hunt';
import { TFunction } from 'i18next';
import { EffectDescription } from './EffectDescription';
import { Card } from '@gamepark/the-hunger/card/Card';

const PowerEffects = new Map<Card, Array<EffectDescription>>();

PowerEffects.set(VampireWill, [new EffectDescription((t: TFunction) => t('card.effect.vampirewill'), 5.6, undefined, 2.2, undefined, undefined, 54)]);
PowerEffects.set(VampireWillAlt, [new EffectDescription((t: TFunction) => t('card.effect.vampirewill.alt'), 5.6, undefined, 2.2, undefined, undefined, 54)]);
PowerEffects.set(VampireStrength, [new EffectDescription((t: TFunction) => t('card.effect.vampirestrength'), 7.8)]);
PowerEffects.set(VampireStrengthAlt, [new EffectDescription((t: TFunction) => t('card.effect.vampirestrength.alt'), 7.8)]);
PowerEffects.set(FormOfMist, [new EffectDescription((t: TFunction) => t('card.effect.formofmist'), 7.8)]);
PowerEffects.set(FormOfBat, [new EffectDescription((t: TFunction) => t('card.effect.formofbat'), 7.8)]);
PowerEffects.set(VampiricStealth, [new EffectDescription((t: TFunction) => t('card.effect.vampiricstealth'), 5.7)]);
PowerEffects.set(Hypnosis, [new EffectDescription((t: TFunction) => t('card.effect.hypnosis'), 7.8)]);

export {
  PowerEffects
}


