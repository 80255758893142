export enum EffectTrigger {
  Hunting = 1,
  HuntingAndOnce,
  Hunted,
  NotMoving,
  EndOfGame,
  EndOfTurn,
  GainMission,
  PushVampire,
  PlaceInPlayingArea,
  EndOfTurnWithoutHunt,
  EndOfTurnWithHunt,
  BeforeMoveOrHunt,
  BeforePlaying,
  WinBonus,
  AfterMissionScore,
  BeforeMissionScore
}
