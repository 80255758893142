import {OptionsSpec} from '@gamepark/rules-api';
import {TFunction} from 'i18next';
import {GameMode, gameModes} from './GameMode';
import GameState from './GameState';
import Vampire, {vampires} from './player/Vampire';

export type TheHungerPlayerOptions = {
  id: Vampire
};

/**
 * This is the options for each players in the game.
 */
export type TheHungerOptions = {
  players: Array<TheHungerPlayerOptions>
  mode: GameMode
  //promo: boolean
}

/**
 * Typeguard to help Typescript distinguish between a GameState and new game's options, for you main class constructor.
 * @param arg GameState or Game options
 * @return true if arg is a Game options
 */
export function isGameOptions(arg: GameState | TheHungerOptions): arg is TheHungerOptions {
  return typeof (arg as GameState).hunt === 'undefined';
}

/**
 * This object describes all the options a game can have, and will be used by GamePark website to create automatically forms for you game
 * (forms for friendly games, or forms for matchmaking preferences, for instance).
 */
export const TheHungerOptionsSpec: OptionsSpec<TheHungerOptions> = {
  players: {
    id: {
      label: (t: TFunction) => t('Vampire'),
      values: vampires,
      valueSpec: vampire => ({ label: t => getPlayerName(vampire, t) })
    }
  },
  mode: {
    label: t => t('Mode'),
    values: gameModes,
    valueSpec: mode => ({
      label: t => getModeLabel(mode, t),
      subscriberRequired: mode === GameMode.Elder,
      help: t => getModeHelp(mode, t)
    })
  }
  /*promo: {
    label: t => t('Promo'),
    help: t => t('With promo pack')
  }*/
};

export function getModeLabel(mode: GameMode, t: TFunction) {
  switch (mode) {
    case GameMode.Elder:
      return t('elder.mode');
    case GameMode.Rookie:
      return t('rookie.mode');
  }
}

export function getModeHelp(mode: GameMode, t: TFunction) {
  switch (mode) {
    case GameMode.Elder:
      return t('elder.help');
    case GameMode.Rookie:
      return t('rookie.help');
  }
}

export function getPlayerName(playerId: Vampire, t: TFunction) {
  switch (playerId) {
    case Vampire.LadyBeatrice:
      return t('Lady Beatrice');
    case Vampire.BorisPouchkine:
      return t('Boris Pouchkine');
    case Vampire.DonGervasi:
      return t('Don Gervasi');
    case Vampire.JosephineLafayette:
      return t('Josephine Lafayette');
    case Vampire.RajeshAmara:
      return t('Rajesh Amara');
    case Vampire.YokoChiyako:
      return t('Yoko Chiyako');
  }
}
