import GameState from '../GameState';
import GameView from '../GameView';
import { isMyPlayerView, isOtherPlayerView, isPlayer, PlayerType, TokenSide } from '../player';
import { HuntInForestBonus, HuntInPlainsBonus } from './GameConstants';
import { BonusTokenType } from '../bonus/BonusTokenType';
import { BoardBox, BoardBoxEffect, BoardBoxes, Region } from '../board';
import {
  getActivatableCards,
  getActiveConfuseEffect,
  getCardToDiscard,
  hasPlayerSpicyEffect,
  isActivableCard,
  isCastle,
  isWell,
} from './CardUtils';
import { MoveHuntTrackCardDirection } from '../moves/MoveHuntTrackCard';
import { GameEffects } from '../effect/GameEffect';
import { EffectTrigger } from '../effect/EffectTrigger';
import { Card, isBrutVictoryPoint } from '../card/Card';
import { getNextPendingEffect } from './PendingUtils';
import {
  hasMandatoryEffect,
  isAdditionalHunt,
  isConfuseEffect,
  isDigest,
  isDiscard,
  isFreeHunt,
  isSpeedBonus,
  isVictoryPoint,
} from './EffectUtils';
import { isHuman, isMilitary, isNoble, isReligious, isVillager } from '../card/hunt/Human';
import { SpeedBonusEffect } from '../effect/SpeedBonusEffect';
import { Mission } from '../mission';
import { GameMode } from '../GameMode';
import { Bonus } from '../bonus/Bonus';
import { PositionedBonus } from '../bonus/PositionedBonus';
import { isLastTurn, isOverLastTurn } from './TurnUtils';
import { hasHunted } from './PlayerUtils';

export const hasParasol = (player: PlayerType, bonuses: Bonus[]) =>
  player.bonusTokens.some((b) => bonuses[b].type === BonusTokenType.Parasol);

export const isPeacefulEffect = (boardEffect?: BoardBoxEffect) =>
  boardEffect && [BoardBoxEffect.Castle, BoardBoxEffect.Ship].includes(boardEffect);
export const isOnPeacefulBox = (player: PlayerType) => isPeacefulEffect(BoardBoxes[player.position.box].effect);

export const everyoneHasPlayed = (state: GameState | GameView, deck: Card[], activePlayer?: PlayerType) => {
  const players = state.players.filter((p) => state.turnOrder.includes(p.vampire));
  return (
    activePlayer &&
    players.every(
      (p) => p.tokenSide === TokenSide.Resting && !getCardToDiscard(p.playingArea, p.position, deck).length && p.end
    ) &&
    players.every((p) => isPlayerHandFull(p)) &&
    state.activePlayer === state.turnOrder[state.turnOrder.length - 1]
  );
};

export const isPlayerHandFull = (player: PlayerType) => {
  // Player has 3 cards
  return (
    getPlayerHandSize(player) === 3 ||
    // Or there is no card in the deck or disard meaning that the player can't have 3 cards
    !(isPlayer(player) ? player.deck.length + player.discard.length : player.deck + player.discard.length)
  );
};

export const computeSpeed = (player: PlayerType, deck: Card[]) => {
  return computeSpeedFromCards(player, player.playingArea, deck);
};

export const computeAdditionalHuntSpeed = (player: PlayerType, deck: Card[]) => {
  return player.playingArea
    .flatMap((card) => deck[card].passiveEffects)
    .filter((effect) => isSpeedBonus(effect) && effect.huntOnly)
    .map((effect) => SpeedBonusEffect.computeSpeedBonus(player, effect as SpeedBonusEffect, deck))
    .reduce((a, b) => a + b, 0);
};

export const computeSpeedForHunt = (player: PlayerType, deck: Card[]) => {
  if (player.end) {
    return 0;
  }

  if (player.additionalHuntSpeed !== undefined && player.remainingSpeed !== undefined) {
    return player.additionalHuntSpeed + player.remainingSpeed;
  }

  return computeSpeed(player, deck) + computeAdditionalHuntSpeed(player, deck);
};

export const computeSpeedFromCards = (player: PlayerType, cards: number[], deck: Card[]) => {
  if (player.end) {
    return 0;
  }

  if (player.remainingSpeed !== undefined) {
    return player.remainingSpeed;
  }

  const passiveSpeedBonus: number = cards
    .flatMap((card) => deck[card].passiveEffects)
    .filter((effect) => isSpeedBonus(effect) && !effect.huntOnly)
    .map((effect) => SpeedBonusEffect.computeSpeedBonus(player, effect as SpeedBonusEffect, deck))
    .reduce((a, b) => a + b, 0);

  const baseSpeed: number = cards.map((c) => deck[c].speed).reduce((a, b) => a + b, 0);

  let activeSpeedBonus = 0;
  for (let pendingEffect of player.pendingEffects) {
    if (isSpeedBonus(pendingEffect)) {
      activeSpeedBonus += SpeedBonusEffect.computeSpeedBonus(player, pendingEffect, deck);
    } else {
      break;
    }
  }

  return baseSpeed + passiveSpeedBonus + activeSpeedBonus;
};

export const getCombinations = (array: any[], size: number): any[] => {
  let i, j, combs, head, tailcombs;
  if (size > array.length || size <= 0) {
    return [];
  }
  if (size == array.length) {
    return [array];
  }
  if (size == 1) {
    combs = [];
    for (i = 0; i < array.length; i++) {
      combs.push([array[i]]);
    }
    return combs;
  }
  combs = [];
  for (i = 0; i < array.length - size + 1; i++) {
    head = array.slice(i, i + 1);
    tailcombs = getCombinations(array.slice(i + 1), size - 1);
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
};

export type EffectContext = { card?: number; bonus?: number; mission?: number };

export const constructEffect = (effect: GameEffects, context?: EffectContext): GameEffects => {
  let baseEffect: GameEffects = {
    ...JSON.parse(JSON.stringify(effect)),
  };

  if (!context) {
    return baseEffect;
  }

  if (context.card) {
    baseEffect.card = context.card;
  } else if (context.bonus) {
    baseEffect.bonusToken = context.bonus;
  } else if (context.mission) {
    baseEffect.mission = context.mission;
  }

  return baseEffect;
};

export const computeEffectsAndAddToPending = (
  effects: GameEffects[],
  context: EffectContext,
  state: GameState | GameView,
  player: PlayerType,
  contextCards: Card[],
  deck: Card[]
) => {
  const computedEffects = effects.flatMap((effect) => {
    if (!effect.computePendingEffects) {
      return constructEffect(effect, context);
    }

    return effect
      .computePendingEffects(state, player, BoardBoxes, contextCards, deck)
      .map((i: GameEffects) => constructEffect(i, context));
  });

  if (computedEffects.every((e) => isVictoryPoint(e))) {
    player.pendingEffects.unshift(...computedEffects.filter((e) => !e.vampire || e.vampire === player.vampire));
  } else if (computedEffects.every((e) => isSpeedBonus(e)) && player.remainingSpeed !== undefined) {
    computedEffects.forEach((e) => {
      player.remainingSpeed! += SpeedBonusEffect.computeSpeedBonus(player, e as SpeedBonusEffect, deck);
    });
  } else {
    const passiveEffects = player.pendingEffects.findIndex(
      (e) => !isSpeedBonus(e) && !isAdditionalHunt(e) && !isFreeHunt(e)
    );

    if (passiveEffects > -1) {
      player.pendingEffects = [
        ...player.pendingEffects.slice(0, passiveEffects),
        ...computedEffects.filter((e) => !e.vampire || e.vampire === player.vampire),
        ...player.pendingEffects.slice(passiveEffects),
      ];
    } else {
      player.pendingEffects.push(...computedEffects.filter((e) => !e.vampire || e.vampire === player.vampire));
    }
  }

  // In case a computed effect targer another player, it must be added to its pendings
  const otherPlayerPending = computedEffects.filter((e) => e.vampire && e.vampire !== player.vampire);
  if (otherPlayerPending.length) {
    otherPlayerPending.forEach((e) =>
      state.players.filter((p) => p.vampire === e.vampire).forEach((p) => p.pendingEffects.push(e))
    );
  }
};

/**
 * Compute passive effect to pending effect
 * @param state The game state & view
 * @param player The active player
 * @param cards The cards that had bonus on
 * @param triggers The game triggers
 * @param contextCards The cards used for passive effect computing
 * @param deck The game deck
 */
export const computePassiveEffects = (
  state: GameState | GameView,
  player: PlayerType,
  cards: number[],
  contextCards: Card[],
  triggers: Array<EffectTrigger>,
  deck: Card[]
) => {
  for (let card of cards.filter((c) => !player.playedCards.includes(c))) {
    const effects = deck[card].passiveEffects || [];
    computeEffectsAndAddToPending(
      effects.filter((e) => e.trigger && triggers.includes(e.trigger)),
      {card: card},
      state,
      player,
      contextCards,
      deck
    );
  }
};

export const computeCardsScore = (player: PlayerType, board: BoardBox[], cards: Card[]): number => {
  const realCards = cards;
  let score: number = realCards
    .map((c) => (isBrutVictoryPoint(c.victoryPoints) ? c.victoryPoints || 0 : 0))
    .reduce((v1, v2) => v1 + v2, 0)!;
  const region = board[player.position.box].region;

  switch (region) {
    case Region.Plain:
      score += HuntInPlainsBonus * realCards.filter((c) => isHuman(c)).length;
      break;
    case Region.Forest:
      score += HuntInForestBonus * realCards.filter((c) => isHuman(c)).length;
  }

  return score;
};

export const getDigestableCard = (player: PlayerType) => [...player.playingArea, ...player.discard];

export const applyCardActivation = (
  state: GameState | GameView,
  player: PlayerType,
  deck: Card[],
  board: BoardBox[],
  cardIndex: number,
  applyOptional = true
) => {
  const card = deck[cardIndex];

  if (card.effects && !isActivableCard(cardIndex, player, deck, board)) {
    return;
  }

  if (card.effects?.length) {
    const effects = applyOptional || !card.effects[0].optional ? card.effects : [];

    if (effects.length) {
      player.playedCards.push(cardIndex);
      computeEffectsAndAddToPending(effects, {card: cardIndex}, state, player, [card], deck);
    }
  }

  // If placed card has a confuse effect, enable it only if there is no confuse effect
  if (
    card.passiveEffects?.length &&
    isConfuseEffect(card.passiveEffects[0]) &&
    !player.pendingEffects.some((e) => isConfuseEffect(e))
  ) {
    player.pendingEffects.push(card.passiveEffects[0]);
  }
};

export const canEndTurn = (deck: Card[], board: BoardBox[], player?: PlayerType, otherPlayers?: Array<PlayerType>) => {
  const nextPendingEffect = player && getNextPendingEffect(player);
  const hasMandatoryCard = player && getActivatableCards(player, deck, board).some((c) => hasMandatoryEffect(deck[c]));

  if (
    !player ||
    hasMandatoryCard ||
    !otherPlayers?.length ||
    otherPlayers.some((p) => p.pendingEffects.length) ||
    !!getPlayerHandSize(player) ||
    hasMissionChoice(player) ||
    (!player.hasMoved &&
      !hasHunted(player) &&
      hasPlayerSpicyEffect(player, deck) &&
      !isWell(BoardBoxes[player.position.box].effect) &&
      computeSpeed(player, deck) > 0) ||
    nextPendingEffect ||
    !!getActiveConfuseEffect(player, deck, board)
  ) {
    return false;
  }

  return true;
};

export const recomputePlayerZPosition = (
  movedPlayer: PlayerType,
  playerOnTargetBox: Array<PlayerType>,
  targetBox?: number
) => {
  // Castle
  if (targetBox === 0) {
    movedPlayer.position.z = playerOnTargetBox.length + 1;
    return;
  }

  const restingPlayers = playerOnTargetBox.filter((p) => p.tokenSide === TokenSide.Resting);
  playerOnTargetBox
    .filter((p) => p.tokenSide === TokenSide.Active)
    .forEach((p, index) => (p.position.z = restingPlayers.length + 2 + index));

  movedPlayer.position.z = restingPlayers.length + 1;
};

export const canDiscardCard = (player: PlayerType, card: number) => {
  const currentPendingEffect = getNextPendingEffect(player);
  return isDiscard(currentPendingEffect)
    && !player.playedCards.includes(card)
    && !currentPendingEffect.auto
    && currentPendingEffect.card !== card;
};

export const canDigestCards = (player: PlayerType, cards: number[], deck: Card[], discard?: boolean) => {
  return canDigestAnyCard(player, cards, deck, discard) || canDigestOneOfTheseCards(player, cards, deck);
};

export const canDigestAnyCard = (player: PlayerType, cards: number[], deck: Card[], discard?: boolean) => {
  const currentPendingEffect = getNextPendingEffect(player);
  return (
    isDigest(currentPendingEffect) &&
    !!cards.filter((c) => isHuman(deck[c]) && c !== currentPendingEffect.card).length &&
    (!discard || !currentPendingEffect.playingAreaOnly)
  );
};

export const canDigestOneOfTheseCards = (player: PlayerType, cards: number[], deck: Card[]) => {
  if (player.boardEffect) {
    if (!cards?.length) {
      return false;
    }

    switch (player.boardEffect) {
      case BoardBoxEffect.Market:
        return cards.some((c) => isVillager(deck[c]));
      case BoardBoxEffect.Mansion:
        return cards.some((c) => isNoble(deck[c]));
      case BoardBoxEffect.Barracks:
        return cards.some((c) => isMilitary(deck[c]));
      case BoardBoxEffect.Church:
        return cards.some((c) => isReligious(deck[c]));
    }
  }

  return false;
};

export const getPlayerHandSize = (player: PlayerType) => {
  return isOtherPlayerView(player) ? player.hand : player.hand.length;
};

export const hasMissionChoice = (player: PlayerType) => {
  return !!(isMyPlayerView(player) ? player.missionChoice.missions.length : player.missionChoice.missions);
};

export const canMoveCardTo = (
  lineIndex: number,
  areaIndex: number,
  playerCount: number,
  direction: MoveHuntTrackCardDirection
) => {
  switch (direction) {
    case MoveHuntTrackCardDirection.LEFT:
      return areaIndex !== 0;
    case MoveHuntTrackCardDirection.RIGHT:
      return areaIndex !== 2;
    case MoveHuntTrackCardDirection.TOP:
      return lineIndex > 0;
    case MoveHuntTrackCardDirection.BOTTOM:
      return lineIndex < playerCount;
  }
};

export const isPlayableMission = (
  mission: Mission,
  missionIndex: number,
  player: PlayerType,
  deck: Card[],
  board: BoardBox[],
  track?: number[][][],
  bonuses?: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>
) => {
  return (
    mission.effects?.length &&
    !player.playedMissions.includes(missionIndex) &&
    ((mission.effects[0].isPlayable && mission.effects[0].isPlayable(player, deck, board, track, bonuses)) ||
      isSpeedBonus(mission.effects[0]))
  );
};

export const isBurned = (player: PlayerType, round: number, mode: GameMode, board: BoardBox[], bonuses: Bonus[]) => {
  const box = board[player.position.box];
  switch (mode) {
    case GameMode.Elder:
      if (!hasParasol(player, bonuses)) {
        return (isLastTurn(round) || isOverLastTurn(round)) && ![Region.Castle, Region.Cemetery].includes(box.region);
      }

      return isOverLastTurn(round) && ![Region.Castle, Region.Cemetery].includes(box.region);

    case GameMode.Rookie:
      if (!hasParasol(player, bonuses)) {
        return (
          (isLastTurn(round) || isOverLastTurn(round)) &&
          ![Region.Castle, Region.Cemetery, Region.Mountain].includes(box.region)
        );
      }

      return isOverLastTurn(round) && ![Region.Castle, Region.Cemetery, Region.Mountain].includes(box.region);
  }
};

export const getRanking = (
  players: Array<PlayerType>,
  round: number,
  mode: GameMode,
  board: BoardBox[],
  bonuses: Bonus[]
): Array<PlayerType> => {
  return [...players].sort((p1, p2) => {
    const burnedSort = playerBurnedCompare(p1, p2, round, mode, board, bonuses);
    if (burnedSort) {
      return burnedSort;
    }

    const positionSort = playerPositionCompare(p1, p2, board);
    if (positionSort) {
      return positionSort;
    }

    return p2.score - p1.score;
  });
};

const playerPositionCompare = (p1: PlayerType, p2: PlayerType, board: BoardBox[]) => {
  if (p1.score === p2.score) {
    const p1OnCastle = isCastle(board[p1.position.box].effect);
    const p2OnCastle = isCastle(board[p2.position.box].effect);
    if (p1OnCastle && !p2OnCastle) {
      return -1;
    }

    if (!p1OnCastle && p2OnCastle) {
      return 1;
    }

    if (p1.position.box === p2.position.box) {
      return p1.position.z - p2.position.z;
    }
  }

  return 0;
};

const playerBurnedCompare = (
  p1: PlayerType,
  p2: PlayerType,
  round: number,
  mode: GameMode,
  board: BoardBox[],
  bonuses: Bonus[]
) => {
  const p1Burned = isBurned(p1, round, mode, board, bonuses);
  const p2Burned = isBurned(p2, round, mode, board, bonuses);
  if (!p1Burned && p2Burned) {
    return -1;
  } else if (p1Burned && !p2Burned) {
    return 1;
  }

  return 0;
};
