/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes, memo } from 'react';
import { huntTrackHeight, huntTrackLeft, huntTrackLineTop, huntTrackLineWidth, huntTrackWidth, realHuntTrackHeight, realHuntTrackLineHeight } from '../../utils/Style';
import { HuntView } from '@gamepark/the-hunger/hunt';
import { Images } from '../../images/Images';
import { PlayerType } from '@gamepark/the-hunger/player';
import { HuntTrackArea } from './HuntTrackArea';
import { SpeedSymbol } from '../../symbols';
import Vampire from '@gamepark/the-hunger/player/Vampire';

type HuntTrackProps = {
  player: PlayerType,
  playerCount: number,
  activePlayer?: Vampire,
  activePlayerIndex?: number,
  hunt: HuntView,
  round: number,
  onDragArea?: () => void,
  onDropArea?: () => void
  isGameOver?: boolean,
} & HTMLAttributes<HTMLDivElement>

const HuntTrack: FC<HuntTrackProps> = memo(({ playerCount, player, activePlayer, activePlayerIndex, hunt, round, onDragArea, onDropArea, isGameOver, ...props }) => {

    return (
      <div css={ [huntTrack(playerCount)] } { ...props }>
        { Array(playerCount + 1).fill(0).map((_, index) =>
          <div key={ `track-line-${ index }` } css={ huntTrackLine(index, playerCount) }/>
        ) }
        { hunt.track.flatMap((line, lineIndex) =>
          line.flatMap((area, areaIndex) =>
            <HuntTrackArea
              key={ `area-${ lineIndex }-${ areaIndex }` }
              area={ area }
              lineIndex={ lineIndex }
              areaIndex={ areaIndex }
              hunt={ hunt }
              round={ round }
              player={ player }
              playerCount={ playerCount }
              onDrag={ onDragArea }
              activePlayer={ activePlayer }
              activePlayerIndex={ activePlayerIndex }
              onDrop={ onDropArea }
              isGameOver={ isGameOver }
            />
          )) }
        <SpeedSymbol value="3" width={ 1.7 } light={ false } css={ [huntTrackCost(playerCount), firstColumn] }/>
        <SpeedSymbol value="2" width={ 1.7 } light={ false } css={ [huntTrackCost(playerCount), secondColumn] }/>
        <SpeedSymbol value="1" width={ 1.7 } light={ false } css={ [huntTrackCost(playerCount), thirdColumn] }/>
      </div>
    );
  }
);

const huntTrack = (playerCount: number) => css`
  position: absolute;
  left: ${ huntTrackLeft }%;
  height: ${ realHuntTrackHeight(playerCount) }%;
  width: ${ huntTrackWidth }%;
  background-size: 100%;
  border-radius: 0.5em;
  box-shadow: 0.2em 0.2em 0.5em black;
  font-size: ${ realHuntTrackHeight(playerCount) / 100 }em;
  pointer-events: all;
`;

const huntTrackLine = (index: number, playerCount: number) => css`
  position: absolute;
  height: ${ realHuntTrackLineHeight(playerCount) }%;
  width: ${ huntTrackLineWidth }%;
  top: ${ huntTrackLineTop(index, playerCount) }%;
  background: url(${ Images.HuntTrackLine });
  background-size: cover;
`;

const huntTrackCost = (playerCount: number) => css`
  position: absolute;
  z-index: 10;
  font-size: ${ 3 / realHuntTrackHeight(playerCount) * huntTrackHeight }em;
  top: 0.2%;
  filter: drop-shadow(0 0.04em 0 black);
  pointer-events: none;

`;
const firstColumn = css`
  left: 7.7%;
`;
const secondColumn = css`
  left: 34%;
`;
const thirdColumn = css`
  left: 70%;
`;

export
{
  HuntTrack
}
  ;
