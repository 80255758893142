/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { PlayerToken } from './PlayerToken';
import { PlayerCounters } from './PlayerCounters';
import {
  computeAdditionalHuntSpeed,
  computeSpeed,
  computeSpeedFromCards,
  isBurned,
} from '@gamepark/the-hunger/utils/GameUtils';
import { PlayerInfos } from './PlayerInfos';
import {
  playerPanelCountersHeight,
  playerPanelCountersTop,
  playerPanelCountersWidth,
  playerPanelDrawHuntCardHeight,
  playerPanelDrawHuntCardTop,
  playerPanelDrawHuntCardTranslatedX,
  playerPanelDrawHuntCardTranslateX,
  playerPanelDrawHuntCardTranslateY,
  playerPanelDrawHuntCardWidth,
  playerPanelHeight,
  playerPanelInfoTop,
  playerPanelLeft,
  playerPanelMissionHeight,
  playerPanelMissionTop,
  playerPanelMissionTranslatedX,
  playerPanelMissionTranslateX,
  playerPanelMissionTranslateY,
  playerPanelMissionWidth,
  playerPanelsRatio,
  playerPanelTokenHeight,
  playerPanelTokenTop,
  playerPanelTokenTranslatedX,
  playerPanelTokenTranslateX,
  playerPanelTokenTranslateY,
  playerPanelTokenWidth,
  playerPanelTop,
  playerPanelWidth,
} from '../../utils/Style';
import { GamePoints, PlayerTimer, useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { isMyPlayerView, PlayerType, TokenSide } from '@gamepark/the-hunger/player';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { useDrop } from 'react-dnd';
import { DraggableTypes } from '../draggable/DraggableTypes';
import { huntOnTrackMove } from '@gamepark/the-hunger/moves/HuntOnTrack';
import { HuntTrackArea } from '../draggable/HuntOnTrack';
import { Images } from '../../images/Images';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { isVictoryPointMove } from '@gamepark/the-hunger/moves/VictoryPoint';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { isActivateMission } from '@gamepark/the-hunger/moves/ActivateMission';
import { MissionTile } from '../mission/MissionTile';
import { Missions } from '@gamepark/the-hunger/mission';
import { isAcquireBonusView } from '@gamepark/the-hunger/moves/AcquireBonus';
import { BonusToken } from '../bonus/BonusToken';
import { ViewType } from '../../ViewType';
import { isDrawHuntCardView } from '@gamepark/the-hunger/moves/DrawHuntCard';
import { HuntCard } from '../card/HuntCard';
import MedalGold from './ranking/MedalGold';
import MedalSilver from './ranking/MedalSilver';
import MedalBronze from './ranking/MedalBronze';
import { useSelector } from 'react-redux';
import { displayScoreMove } from '../../moves/DisplayScore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export type PlayerPanelType = {
  index: number;
  player: PlayerType;
  turnOrder?: number;
  round: number;
  selected: boolean;
  scoreSelected: boolean;
  activePlayer?: Vampire;
  isActive: boolean;
  ranking?: number;
  overlayText?: string;
  onVampireClick: (e: any) => void;
  gameMode: GameMode;
  view: ViewType;
  oldTokensCount?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlayerPanel: FC<PlayerPanelType> = ({
  index,
  turnOrder,
  player,
  activePlayer,
  selected,
  scoreSelected,
  isActive,
  ranking,
  gameMode,
  round,
  onVampireClick,
  view,
  oldTokensCount,
  ...props
}) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const playerInfo = useSelector((state: any) => state.players.find((p: any) => p.id === player.vampire));
  const bonusList = Bonuses(oldTokensCount);

  const animation = useAnimation(
    (a) =>
      (isVictoryPointMove(a.move) ||
        isActivateMission(a.move) ||
        isDrawHuntCardView(a.move) ||
        (isAcquireBonusView(a.move) && a.move.position !== undefined)) &&
      player.vampire === activePlayer
  );
  const victoryPointAnimation = animation && isVictoryPointMove(animation.move) ? animation.move : undefined;
  const activateMissionAnimation = animation && isActivateMission(animation.move) ? animation.move : undefined;
  const acquireTokenAnimation = animation && isAcquireBonusView(animation.move) ? animation.move : undefined;
  const drawHuntCardAnimation = animation && isDrawHuntCardView(animation.move) ? animation.move : undefined;
  const burned: boolean = !!ranking && isBurned(player, round, gameMode, BoardBoxes, bonusList);
  const score: number = victoryPointAnimation ? player.score + victoryPointAnimation.victoryPoint : player.score;

  const canDrop = player.vampire === playerId && activePlayer === playerId;

  const dropCards = (item: HuntTrackArea) => {
    const { row, col, card } = item;
    return huntOnTrackMove(row, col, card);
  };

  const [{ isOver }, ref] = useDrop({
    accept: DraggableTypes.HuntTrackArea,
    canDrop: () => canDrop,
    drop: dropCards,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [displayedScore, setDisplayedScore] = useState(score);
  const [scoreInterval, setScoreInterval] = useState<NodeJS.Timeout>();
  const [timingForEachTick, setTimingForEachTick] = useState(0);

  useEffect(() => {
    if (score - displayedScore !== 0) {
      setTimingForEachTick(Math.min(2000, 150 * Math.abs(score - displayedScore)) / Math.abs(score - displayedScore));
    }

    // eslint-disable-next-line
  }, [score]);

  useEffect(() => {
    if (scoreInterval || displayedScore === score) {
      return;
    }

    const interval = setInterval(() => {
      setDisplayedScore((currentScore) => {
        if (currentScore === score) {
          clearInterval(interval);
          setScoreInterval(undefined);
          setTimingForEachTick(0);
          return currentScore;
        }
        return score - displayedScore > 0 ? currentScore + 1 : currentScore - 1;
      });
    }, timingForEachTick);

    setScoreInterval(interval);

    // eslint-disable-next-line
  }, [timingForEachTick]);

  const playerSpeed = isMyPlayerView(player)
    ? computeSpeedFromCards(player, [...player.hand, ...player.playingArea], GameDeck)
    : computeSpeed(player, GameDeck);

  const additionalHuntSpeed = computeAdditionalHuntSpeed(player, GameDeck);
  const itsMe = activePlayer === player.vampire || playerId === player.vampire;
  const showScore = () => (ranking ? play(displayScoreMove(player.vampire), { local: true }) : undefined);

  return (
    <>
      <PlayerToken
        ref={ref}
        css={[panel(index), selected && displayedVampireStyle, isOver && canDrop && canDropOnPlayer]}
        semiTransparent={player.tokenSide === TokenSide.Resting}
        player={player}
        vampire={player.vampire}
        overlayColor={burned ? 'red' : undefined}
        flippable={false}
        onClick={onVampireClick}
        {...props}
      />
      {!ranking && turnOrder && (
        <div css={turnOrderStyle(index)} onClick={onVampireClick}>
          {turnOrder}
        </div>
      )}
      {!burned && ranking === 1 && <MedalGold css={medal(index)} />}
      {!burned && ranking === 2 && <MedalSilver css={medal(index)} />}
      {!burned && ranking === 3 && <MedalBronze css={medal(index)} />}
      {!ranking && isActive && playerInfo?.time?.playing && (
        <span css={playerTimerHolder(index)}>
          <PlayerTimer
            css={css`
              color: black;
            `}
            playerId={player.vampire}
          />
        </span>
      )}
      <PlayerCounters
        css={playerCounter(index)}
        speed={itsMe ? playerSpeed : undefined}
        additionalHuntSpeed={itsMe ? additionalHuntSpeed : undefined}
        victoryPoints={displayedScore}
        victoryPointCss={displayedScore !== score ? scaleBlood : undefined}
        onClick={onVampireClick}
      />
      <PlayerInfos css={playerInfos(index)} player={player} onClick={onVampireClick} />
      <GamePoints playerId={player.vampire} css={gamePointsStyle(index)} />
      {activateMissionAnimation && (
        <MissionTile
          css={[playerPanelMissionTile(index, !selected, animation!.duration)]}
          mission={Missions[activateMissionAnimation.mission]}
        />
      )}
      {acquireTokenAnimation && (
        <BonusToken
          css={[playerPanelBonusToken(index, view === ViewType.Player, animation!.duration)]}
          type={bonusList[acquireTokenAnimation.bonus]}
        />
      )}
      {drawHuntCardAnimation && (
        <HuntCard
          css={[
            playerPanelDrawHunCard(index, view === ViewType.Player, animation!.duration, player.vampire === playerId),
          ]}
          hunt={player.vampire === playerId ? GameDeck[drawHuntCardAnimation.card] : undefined}
        />
      )}
      {!ranking && isActive && <div css={currentPlayer(index)} />}
      {ranking && (
        <div css={showScoreButton(index, scoreSelected)} onClick={showScore}>
          <FontAwesomeIcon icon={faStar} />
        </div>
      )}
    </>
  );
};

const showScoreButton = (index: number, scoreSelected: boolean) => css`
  height: 7em;
  width: 7em;
  position: relative;
  left: -4em;
  top: ${index * 10 + 1.6}em;
  border-radius: 50%;
  background-color: ${scoreSelected ? 'gold' : 'white'};
  border: 0.5em solid black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    font-size: 4.5em;
    color: ${scoreSelected ? 'black' : 'gold'};
  }
`;

const panel = (index: number) => css`
  position: absolute;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  top: ${playerPanelTop(index)}%;
  left: ${playerPanelLeft}%;
  border-radius: 50%;
  height: ${playerPanelHeight}%;
  width: ${playerPanelWidth}%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const medal = (index: number) => css`
  position: absolute;
  height: 5%;
  width: ${5 / playerPanelsRatio}%;
  top: ${playerPanelTop(index) - 0.5}%;
  left: 50.9%;
  border-radius: 50%;
  filter: drop-shadow(0 0 0.2em black);
  color: #28b8ce;
  fill: currentColor;
`;

const turnOrderStyle = (index: number) => css`
  position: absolute;
  height: 4%;
  width: ${4 / playerPanelsRatio}%;
  background-color: #e4e2e2;
  top: ${playerPanelTop(index) - 0.3}%;
  left: 11.9%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.05em solid black;
  border-radius: 50%;
  font-size: 3em;
  pointer-events: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const playerCounter = (index: number) => css`
  position: absolute;
  top: ${playerPanelCountersTop(index)}%;
  right: 0;
  left: unset;
  height: ${playerPanelCountersHeight}%;
  width: ${playerPanelCountersWidth}%;
  font-size: ${playerPanelCountersHeight / 100}em;
  transition: 0.5s transform;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const scaleBlood = css`
  z-index: 99;
  transform: scale(2);
`;

const playerInfos = (index: number) => css`
  position: absolute;
  top: ${playerPanelInfoTop(index)}%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const canDropOnPlayer = css`
  box-shadow: 0 0 0 0.5em green, 0 0 0 0.5em green;
`;

const displayedVampireStyle = css`
  box-shadow: 0 0 0 0.5em gold, 0 0 0 0.5em gold;
`;

const currentPlayer = (index: number) =>
  css`
    position: absolute;
    height: 10%;
    width: 50%;
    background-image: url(${Images.Arrow});
    background-size: 100% 100%;
    transition: transform 0.5s;
    left: -18%;
    top: ${playerPanelTop(index) - 1}%;
    pointer-events: none;
  `;

const playerTimerHolder = (index: number) =>
  css`
    position: absolute;
    border-top-left-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    left: -3.6%;
    top: ${playerPanelTop(index) + 1.4}%;
    padding: 0 0.3em 0 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    transform: translateX(-100%);
    font-size: 4em;
    height: 5.1%;
    box-sizing: border-box;
    color: black;
    z-index: 50;
  `;

const gamePointsStyle = (index: number) => css`
  position: absolute;
  top: ${playerPanelTop(index) - 1}%;
  font-size: 2.5em;
  right: 6.6em;
  white-space: nowrap;
`;

const playerPanelMissionTile = (index: number, visible: boolean, duration: number) => css`
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: ${playerPanelMissionTop(index)}%;
  height: ${playerPanelMissionHeight}%;
  width: ${playerPanelMissionWidth}%;
  font-size: ${playerPanelMissionHeight / 100}em;
  z-index: 99;
  animation: ${duration}s ${playerPanelMissionTileAnimation} ease-in-out both;
`;

const playerPanelMissionTileAnimation = keyframes`
  from {
    transform: translate(${playerPanelMissionTranslateX}%, ${playerPanelMissionTranslateY}%) scale(0);
  }
  35%, 65% {
    transform: translate(${playerPanelMissionTranslatedX}%, ${playerPanelMissionTranslateY}%) scale(1);
  }
  to {
    transform: translate(${playerPanelMissionTranslateX}%, ${playerPanelMissionTranslateY}%) scale(0);
  }
`;

const playerPanelBonusToken = (index: number, visible: boolean, duration: number) => css`
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: ${playerPanelTokenTop(index)}%;
  height: ${playerPanelTokenHeight}%;
  width: ${playerPanelTokenWidth}%;
  font-size: ${playerPanelTokenHeight / 100}em;
  z-index: 99;
  animation: ${duration}s ${playerPanelTokenAnimation} ease-in-out both;
`;

const playerPanelTokenAnimation = keyframes`
  from {
    transform: translate(${playerPanelTokenTranslateX}%, ${playerPanelTokenTranslateY}%) scale(0);
  } 
  35%, 65% {
    transform: translate(${playerPanelTokenTranslatedX}%, ${playerPanelTokenTranslateY}%) scale(1);
  }
  to {
    transform: translate(${playerPanelTokenTranslateX}%, ${playerPanelTokenTranslateY}%) scale(0);
  }
`;

const playerPanelDrawHunCard = (index: number, visible: boolean, duration: number, front: boolean) => css`
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: ${playerPanelDrawHuntCardTop(index)}%;
  height: ${playerPanelDrawHuntCardHeight}%;
  width: ${playerPanelDrawHuntCardWidth}%;
  font-size: ${playerPanelDrawHuntCardHeight / 100}em;
  z-index: 99;
  animation: ${duration}s ${playerPanelDrawHuntCardAnimation(front)} 3s ease-in-out both;
`;

const playerPanelDrawHuntCardAnimation = (front: boolean) => keyframes`
  from {
    transform: translate(${playerPanelDrawHuntCardTranslateX}%, ${playerPanelDrawHuntCardTranslateY}%) ${
  front ? '' : 'rotateY(180deg)'
} scale(0);
  }
  40%, 60% {
    transform: translate(${playerPanelDrawHuntCardTranslatedX}%, ${playerPanelDrawHuntCardTranslateY}%) ${
  front ? '' : 'rotateY(180deg)'
} scale(1);
  }
  to {
    transform: translate(${playerPanelDrawHuntCardTranslateX}%, ${playerPanelDrawHuntCardTranslateY}%) ${
  front ? '' : 'rotateY(180deg)'
} scale(0);
  }
`;

export { PlayerPanel };
