import { DraggableTypes } from './DraggableTypes';

export type HuntCardFromHand = {
  type: DraggableTypes.HuntCardFromHand
  card: number
}

export function huntCardFromHand(card: number): HuntCardFromHand {
    return {
      type: DraggableTypes.HuntCardFromHand,
      card
    }
}