import { EffectTrigger } from './EffectTrigger';
import { GameEffect } from './GameEffect';
import { BoardBoxEffect, BoardBoxes } from '../board';
import { CardType } from '../card/CardType';
import { EffectType } from './EffectType';
import { PlayerType } from '../player';
import { Card, isBrutVictoryPoint } from '../card/Card';

export class SpeedBonusEffect implements GameEffect {
  type: EffectType.SpeedBonus = EffectType.SpeedBonus;
  speedBonus: number;
  huntTypes?: Array<CardType>;
  huntCount?: number;
  boxEffect?: BoardBoxEffect;
  perHunt?: boolean;
  huntLevels?: Array<number>;
  trigger?: EffectTrigger;
  perPlayerCloserToCastle?: boolean;
  huntOnly?: boolean;

  // trigger?: EffectTrigger, huntTypes?: Array<CardType>, perHunt?: boolean, huntCount?: number, huntLevels?: Array<number>, boxType?: BoardBoxEffect
  constructor(speedBonus: number, options?: Partial<Omit<SpeedBonusEffect, 'speedBonus'>>) {
    this.speedBonus = speedBonus;

    if (options) {
      this.huntTypes = options.huntTypes;
      this.huntCount = options.huntCount;
      this.perHunt = options.perHunt;
      this.boxEffect = options.boxEffect;
      this.huntLevels = options.huntLevels;
      this.trigger = options.trigger;
      this.perPlayerCloserToCastle = options.perPlayerCloserToCastle;
      this.huntOnly = options.huntOnly;
    }
  }

  static computeSpeedBonus = (player: PlayerType, effect: SpeedBonusEffect, deck: Card[]): number => {
    // Bonus there player is on a box with effect
    if (effect.boxEffect) {
      return BoardBoxes[player.position.box].effect === effect.boxEffect ? effect.speedBonus : 0;
    }

    // Brut speed bonus if the number of hunts of a type is in the playing area
    if (!effect.perHunt && effect.huntTypes) {
      return player.playingArea.filter((c) => effect.huntTypes?.includes(deck[c].category)).length >=
        (effect.huntCount || 1)
        ? effect.speedBonus
        : 0;
    }

    if (effect.perHunt && effect.huntTypes) {
      const huntsOfType = player.playingArea.filter((c) => effect.huntTypes?.includes(deck[c].category));

      // Point for each hunt of a type and a specific VP
      if (effect.huntLevels?.length) {
        return (
          huntsOfType.filter(
            (c) =>
              isBrutVictoryPoint(deck[c].victoryPoints) && effect.huntLevels?.includes(deck[c].victoryPoints as number)
          ).length * effect.speedBonus
        );
      }

      // Point per hunt of a given types
      return huntsOfType.length * effect.speedBonus;
    }

    return effect.speedBonus;
  };
}
