/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes, MouseEvent, useState } from 'react';
import { MissionTileStack } from '../mission/MissionTileStack';
import { isInvisibleMissions } from '@gamepark/the-hunger/GameView';
import {
  boardMissionStackTranslateX,
  boardMissionStackTranslateY,
  gameBoardMissionHeight,
  gameBoardMissionWidth,
} from '../../utils/Style';
import { MissionPositions } from '../mission/MissionPositions';
import { drawMissionMove, isDrawMission } from '@gamepark/the-hunger/moves/DrawMissions';
import { isInspiring } from '@gamepark/the-hunger/utils/EffectUtils';
import { getNextPendingEffect } from '@gamepark/the-hunger/utils/PendingUtils';
import { isPlayer, Player, PlayerType } from '@gamepark/the-hunger/player';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { MissionCatalog } from '../mission/MissionCatalog';
import { Missions } from '@gamepark/the-hunger/mission';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { isChooseMissionMove } from '@gamepark/the-hunger/moves/ChooseMission';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';

export type BoardMissionsProps = {
  players: PlayerType[];
  activePlayer?: Vampire;
  missions: (number | number[])[];
  isGameOver?: boolean;
  oldTokensCount?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const BoardMissions: FC<BoardMissionsProps> = ({
  players,
  activePlayer,
  isGameOver,
  missions,
  oldTokensCount,
  ...props
}) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const [missionToDisplay, setMissionToDisplay] = useState<number[]>([]);
  const animation = useAnimation((animation) => isChooseMissionMove(animation.move) || isDrawMission(animation.move));

  const player = activePlayer === playerId ? players.find((p) => p.vampire === activePlayer) : undefined;
  const pendingEffect = player && getNextPendingEffect(player);
  const mustDrawMissions = player && isInspiring(pendingEffect) && !player.missionChoice.stack;
  const drawMissions = (event: MouseEvent, missionStack: number) => {
    event.stopPropagation();
    if (mustDrawMissions) {
      play(drawMissionMove(missionStack), { delayed: true });
    }
  };

  function showMissionCatalog(event: MouseEvent, missionStack: number[]) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    setMissionToDisplay(missionStack);
  }

  const getMissionScore = (mission: number): number | undefined => {
    let m = Missions[mission];
    if (isGameOver && m.computeScore) {
      let player = players.find((p) => playerId === p.vampire);
      if (!player || !isPlayer(player)) {
        return 0;
      }

      return m.computeScore(
        player,
        players.filter((p) => player!.vampire !== p.vampire) as Array<Player>,
        GameDeck,
        BoardBoxes,
        Bonuses(oldTokensCount)
      );
    }

    return undefined;
  };

  return (
    <>
      {!!missionToDisplay?.length && (
        <MissionCatalog
          missions={missionToDisplay}
          onClose={() => setMissionToDisplay([])}
          missionScore={isGameOver ? getMissionScore : undefined}
        />
      )}
      {missions.map((missionStack: number | number[], index: number) => (
        <MissionTileStack
          key={`mission-stack-${index}`}
          css={[
            missionStackStyle,
            !animation && !isInvisibleMissions(missionStack) && pointable,
            !animation &&
              !isInvisibleMissions(missionStack) &&
              missionToDisplay &&
              missionToDisplay.includes(missionStack[0]) &&
              hideMission,
          ]}
          missions={missionStack}
          missionScore={isGameOver && !isInvisibleMissions(missionStack) ? getMissionScore : undefined}
          position={index}
          players={players}
          activePlayer={activePlayer}
          selectable={!animation && !!isInvisibleMissions(missionStack) && !!mustDrawMissions}
          tooltipPosition={index === 7 ? 'right' : 'left'}
          onClick={(event) =>
            isInvisibleMissions(missionStack) ? drawMissions(event, index) : showMissionCatalog(event, missionStack)
          }
          preTransform={`translate(${boardMissionStackTranslateX(
            index,
            MissionPositions
          )}%, ${boardMissionStackTranslateY(index, MissionPositions)}%)`}
          {...props}
        />
      ))}
    </>
  );
};

const missionStackStyle = css`
  position: absolute;
  height: ${gameBoardMissionHeight}%;
  width: ${gameBoardMissionWidth}%;
  font-size: ${gameBoardMissionHeight / 100}em;
`;

const hideMission = css`
  opacity: 0;
`;

const pointable = css`
  cursor: pointer;
`;

export { BoardMissions };
