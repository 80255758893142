/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { regionSymbolHDHeight, regionSymbolHDWidth, regionSymbolHeight, regionSymbolWidth } from '../utils/Style';
import { Images } from '../images/Images';
import { Region } from '@gamepark/the-hunger/board';

type RegionSymbolProps = {
  region: Region;
  hd?: boolean;
  width?: number;
} & HTMLAttributes<HTMLDivElement>;

const RegionSymbol: FC<RegionSymbolProps> = ({ region, hd, width, ...props }) => {
  if (hd) {
    return <span css={miniHD(region, width)} {...props} />;
  }

  return <span css={mini(region, width)} {...props} />;
};

const mini = (region: Region, width?: number) => css`
  height: ${regionSymbolHeight(width)}em;
  width: ${width || regionSymbolWidth}em;
  background-image: url(${regionSymbols.get(region)!});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  top: 0.65em;
  position: relative;
  margin-bottom: -0.5em;
  transform: translateY(-50%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
`;

const miniHD = (region: Region, width?: number) => css`
  height: ${regionSymbolHDHeight(width)}em;
  width: ${width || regionSymbolHDWidth}em;
  background-image: url(${regionSymbolsHD.get(region)!});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  transform: translateY(20%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
  filter: drop-shadow(0.1em 0.2em 0.4em black);
`;

const regionSymbols = new Map<Region, any>();
regionSymbols.set(Region.Forest, Images.RegionForestSymbol);
regionSymbols.set(Region.Plain, Images.RegionPlainSymbol);

const regionSymbolsHD = new Map<Region, any>();
regionSymbolsHD.set(Region.Forest, Images.ForestHDSymbol);
regionSymbolsHD.set(Region.Plain, Images.PlainHDSymbol);
regionSymbolsHD.set(Region.Mountain, Images.MountainHDSymbol);
regionSymbolsHD.set(Region.Cemetery, Images.CemeteryHDSymbol);

export { RegionSymbol };
