  import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';

export type NextPlayer = {
  type: MoveType.NextPlayer
}

export const nextPlayerMove: NextPlayer = {
  type: MoveType.NextPlayer
};

export const nextPlayer = (state: GameState | GameView) => {
  state.activePlayer = state.turnOrder[state.turnOrder.findIndex(vampire => vampire === state.activePlayer) + 1];
};
