/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { CardTrait } from '@gamepark/the-hunger/card/CardTrait';
import { Trans } from 'react-i18next';
import { Images } from '../../images/Images';

type TraitHelpProps = {
  hunt: number;
} & HTMLAttributes<HTMLDivElement>;

const TRAIT_HELPS = [CardTrait.Ready, CardTrait.Permanent, CardTrait.Unique];

const TraitHelp: FC<TraitHelpProps> = (props) => {
  const { hunt, ...rest } = props;
  const traitsHelp = TRAIT_HELPS.filter((t) => GameDeck[hunt].traits?.includes(t)) || [];

  if (!traitsHelp.length) {
    return null;
  }

  return (
    <div css={traitStyle} {...rest}>
      {traitsHelp.map((trait) => (
        <p key={trait}>
          <Trans defaults={CardTraitDescription.get(trait)} components={[<strong css={underline} />]} />
        </p>
      ))}
    </div>
  );
};

const CardTraitDescription = new Map<CardTrait, string>();
CardTraitDescription.set(CardTrait.Ready, 'help.trait.ready');
CardTraitDescription.set(CardTrait.Permanent, 'help.trait.permanent');
CardTraitDescription.set(CardTrait.Unique, 'help.trait.unique');

const underline = css`
  text-decoration: underline;
`;

const traitStyle = css`
  font-size: 2.2em;
  color: lightgoldenrodyellow;
  border: 0.2em gold solid;
  box-shadow: 0 0 1em 0.3em black inset, 0 0 1em 0.3em black;
  background: url(${Images.PopupBackground});
  background-size: cover;
  border-radius: 1em;
  letter-spacing: 0.05em;
`;

export { TraitHelp };
