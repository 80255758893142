import GameView from '@gamepark/the-hunger/GameView';

export type HighlightBox = {
  type: 'HighlightBox'
  box?: number
}

export const highlightBoxMove = (box?: number): HighlightBox => ({
  type: 'HighlightBox', box
});

export function highlightBox(state: GameView, move: HighlightBox) {
  state.hightlightedBox = move.box;
}
