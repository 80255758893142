import {Action, Competitive, RandomMove, Rules, SecretInformation, TimeLimit, Undo} from '@gamepark/rules-api'
import GameState from './GameState'
import GameView from './GameView'
import Move from './moves/Move'
import MoveView from './moves/MoveView'
import {isGameOptions, TheHungerOptions} from './TheHungerOptions'
import {canHuntRose, canHuntTavern, getHuntableTrackAreas} from './utils/HuntUtils'
import {hasHunted} from './utils/PlayerUtils'
import {getActualMovablePaths, getMovablePaths, getPlayerOnSamePosition, mayPushVampiresOnBox} from './utils/MoveUtils'
import {BoardBoxEffect, BoardBoxes} from './board'
import MoveType from './moves/MoveType'
import Vampire from './player/Vampire'
import {GameInitializer} from './GameInitializer'
import {getBoardEffectAutomaticMove, getPendingEffectAutomaticMove, getPredictableAutomaticMoves} from './rules/AutomaticMoves'
import {NewRoundPhase} from './round/NewRoundPhase'
import {
  getActivatableCards,
  getBeforePlayingCard,
  getCardToDiscard,
  getMissionToPlayFirst,
  mustBePlayedFirst,
  mustMissionBePlayedFirst
} from './utils/CardUtils'
import {StartupBeigeMissionCount, VisibleTokenPosition} from './utils/GameConstants'
import {getPendingEffectLegalMoves} from './rules/LegalMoves'
import {
  canEndTurn,
  computeSpeed,
  computeSpeedForHunt,
  everyoneHasPlayed,
  getCombinations,
  getDigestableCard,
  getPlayerHandSize,
  getRanking,
  isPlayableMission
} from './utils/GameUtils'
import {canUndo} from './utils/CanUndo'
import {TokenSide} from './player'
import shuffle from 'lodash.shuffle'
import {drawHuntCard, getDrawHuntCardMoveView} from './moves/DrawHuntCard'
import {dismissEffect, dismissEffectMove} from './moves/DismissEffect'
import {placeInDiscard} from './moves/PlaceInDiscard'
import {activateBonus, activateBonusMove} from './moves/ActivateBonus'
import {chooseMissions, chooseMissionsMove, getChooseMissionMoveView} from './moves/ChooseMission'
import {activateCard, activateCardMove} from './moves/ActivateCard'
import {placeInPlayingArea, placeInPlayingAreaMove} from './moves/PlaceInPlayingArea'
import {moveVampire, moveVampireMove} from './moves/MoveVampire'
import {endTurn, endTurnMove} from './moves/EndTurn'
import {digestCard, digestCardMove} from './moves/DigestCards'
import {huntRose, huntRoseMove} from './moves/HuntRose'
import {getHuntInTavernMoveView, huntInTavern, huntInTavernMove} from './moves/HuntInTavern'
import {huntOnTrack, huntOnTrackMove} from './moves/HuntOnTrack'
import {victoryPoint} from './moves/VictoryPoint'
import {drawMissions, getDrawMissionsMoveView} from './moves/DrawMissions'
import {drawCards, drawCardsMove, getDrawCardMoveView} from './moves/DrawCards'
import {discardCards} from './moves/DiscardCards'
import {nextPlayer} from './moves/NextPlayer'
import {flipToken} from './moves/FlipToken'
import {newRound} from './moves/NewRound'
import {getShuffleDiscardToDeckMoveView, shuffleDiscardToDeck, shuffleDiscardToDeckMove} from './moves/ShuffleDiscardToDeck'
import {fillHuntTrack, fillHuntTrackMove, getFillHuntTrackMoveView} from './moves/FillHuntTrack'
import {fillTavern, fillTavernMove} from './moves/FillTavern'
import {moveHuntTrackCard} from './moves/MoveHuntTrackCard'
import {acquireBonusToken, acquireBonusTokenMove, getAcquireBonusTokenView} from './moves/AcquireBonus'
import {getPlaceOnDeckMoveView, placeOnDeck} from './moves/PlaceOnDeck'
import {getNextPendingEffect} from './utils/PendingUtils'
import {GameDeck} from './card/hunt/GameDeck'
import {isMilitary, isNoble, isReligious, isVillager} from './card/hunt/Human'
import {canBonusBePlayed, hasMultifactionToken} from './utils/BonusUtils'
import {isOver} from './utils/IsOver'
import {endOfGame, endOfGameMove, getEndOfGameView} from './moves/EndOfGame'
import {activateMission, activateMissionMove} from './moves/ActivateMission'
import {Missions} from './mission'
import {isActive} from './utils/IsActive'
import {canRefillTavern} from './utils/TavernUtils'
import {isLastTurn, isOverLastTurn} from './utils/TurnUtils'
import {Bonuses} from './bonus/Bonuses'
import {hasMandatoryEffect, isPushEffect} from './utils/EffectUtils'
import {BonusTokenType} from './bonus/BonusTokenType'
import {GameMode} from './GameMode'
import MoveRandomized from './moves/MoveRandomized'

export default class TheHunger
  extends Rules<GameState, Move, Vampire>
  implements
    SecretInformation<GameView, Move, MoveView, Vampire>,
    TimeLimit<GameState, Move, Vampire>,
    Undo<GameState, Move, Vampire>,
    Competitive<GameState, Move, Vampire>,
    RandomMove<Move, MoveRandomized>
{

  constructor(state: GameState);
  constructor(options: TheHungerOptions);
  constructor(arg: GameState | TheHungerOptions) {
    if (isGameOptions(arg)) {
      super(new GameInitializer(arg.players, arg.mode).getGameState());
    } else {
      super(arg);
    }
  }

  canUndo(action: Action<Move, Vampire>, consecutiveActions: Action<Move, Vampire>[]): boolean {
    return canUndo(this.state, action, consecutiveActions, Bonuses(this.state.oldTokensCount));
  }

  getScore(playerId: Vampire): number {
    return this.state.players.find((p) => p.vampire === playerId)!.score;
  }

  rankPlayers(playerA: Vampire, playerB: Vampire): number {
    const ranking = getRanking(
      this.state.players,
      this.state.round,
      this.state.mode,
      BoardBoxes,
      Bonuses(this.state.oldTokensCount)
    );

    return ranking.findIndex((p) => p.vampire === playerA) - ranking.findIndex((p) => p.vampire === playerB);
  }

  isTurnToPlay(playerId: Vampire): boolean {
    const player = this.state.players.find((p) => p.vampire === playerId)!;
    return isActive(
      player,
      this.state.players.filter((p) => p.vampire !== player.vampire),
      playerId,
      this.state.round,
      this.state.turnOrder,
      this.state.hunt.track,
      this.state.tavern.length,
      GameDeck,
      BoardBoxes,
      Bonuses(this.state.oldTokensCount),
      this.state.activePlayer
    );
  }

  giveTime(): number {
    return 60;
  }

  isOver(): boolean {
    const bonusList = Bonuses(this.state.oldTokensCount);
    return (
      isOver(this.state.players, this.state.round, this.state.turnOrder, bonusList) &&
      !this.state.players.some((p) => hasMultifactionToken(p, bonusList))
    );
  }

  getLegalMoves(playerId: Vampire): Move[] {
    const board = BoardBoxes;
    const bonusList = Bonuses(this.state.oldTokensCount);

    const player = this.state.players.find((p) => p.vampire === playerId)!;
    const first = getNextPendingEffect(player);
    if (player.missionChoice.missions?.length && !isPushEffect(first)) {
      // In rookie mode, player must choose one mission
      if (this.state.mode === GameMode.Rookie) {
        return player.missionChoice.missions.map((m) => chooseMissionsMove(player.vampire, [m]));
      } else {
        const playerRemainingMissions = player.missions.filter((m) => !player.playedMissions.includes(m.mission));
        let sortedMissions = [...playerRemainingMissions.map((m) => m.mission), ...player.missionChoice.missions].sort(
          (a, b) => a - b
        );

        if (sortedMissions.length <= playerRemainingMissions.length + 1) {
          return [chooseMissionsMove(player.vampire, sortedMissions)];
        }

        return getCombinations(sortedMissions, playerRemainingMissions.length + 1).map((missions) =>
          chooseMissionsMove(player.vampire, missions)
        );
      }
    }

    const moves: Move[] = [];
    if (first) {
      const effectMoves = getPendingEffectLegalMoves(player, this.state);
      if (effectMoves.length) {
        moves.push(...effectMoves);
        if (first.optional) {
          moves.push(dismissEffectMove);
        }
        return moves;
      }
    }

    if (this.state.activePlayer === playerId) {
      const speed = computeSpeed(player, GameDeck);
      const huntSpeed = computeSpeedForHunt(player, GameDeck);

      let missionToPlayFirst = getMissionToPlayFirst(player, Missions);
      let firstBeforePlayingCard = getBeforePlayingCard(player, GameDeck, BoardBoxes);
      if (!!getPlayerHandSize(player) && (firstBeforePlayingCard || missionToPlayFirst) && !player.playedCards.length) {
        if (firstBeforePlayingCard) {
          moves.push(activateCardMove(firstBeforePlayingCard));
        }

        if (missionToPlayFirst) {
          moves.push(activateMissionMove(missionToPlayFirst.mission));
        }

        moves.push(placeInPlayingAreaMove(player.hand));

        return moves;
      }

      // When player hasn't moves and hunted, it is possible to play "not played" card
      if (!player.hasMoved && !hasHunted(player)) {
        let activatableCards = getActivatableCards(player, GameDeck, BoardBoxes);
        activatableCards.forEach((c) => moves.push(activateCardMove(c)));

        if (activatableCards.some((c) => hasMandatoryEffect(GameDeck[c]))) {
          return moves;
        }

        new Set<number>(
          getActualMovablePaths(
            speed,
            player,
            board,
            GameDeck,
            true,
            this.state.players.map((p) => p.position),
            this.state.activePlayer
          ).flat()
        ).forEach((position) => moves.push(moveVampireMove(position, player.vampire)));
      }

      if (
        canEndTurn(
          GameDeck,
          BoardBoxes,
          player,
          this.state.players.filter((p) => p.vampire !== player.vampire)
        )
      ) {
        moves.push(endTurnMove);
      }

      const box = board[player.position.box];
      if (player.hasMoved && !hasHunted(player) && mayPushVampiresOnBox(box)) {
        const playerOnSamePosition = getPlayerOnSamePosition(player, this.state.players);

        // If the player just moved and there are vampires on the target position
        // he must move vampires one by one starting from the top
        if (playerOnSamePosition.length) {
          const otherPlayer = playerOnSamePosition[0];
          new Set<number>(getMovablePaths(1, otherPlayer, board, GameDeck).flat()).forEach((box) =>
            moves.push(moveVampireMove(box, otherPlayer.vampire))
          );
        }
      }

      if (player.hasMoved && player.boardEffect) {
        switch (player.boardEffect) {
          case BoardBoxEffect.Market:
            getDigestableCard(player)
              .filter((c) => isVillager(GameDeck[c]))
              .forEach((c) => moves.push(digestCardMove(c)));
            break;
          case BoardBoxEffect.Church:
            getDigestableCard(player)
              .filter((c) => isReligious(GameDeck[c]))
              .forEach((c) => moves.push(digestCardMove(c)));
            break;
          case BoardBoxEffect.Mansion:
            getDigestableCard(player)
              .filter((c) => isNoble(GameDeck[c]))
              .forEach((c) => moves.push(digestCardMove(c)));
            break;
          case BoardBoxEffect.Barracks:
            getDigestableCard(player)
              .filter((c) => isMilitary(GameDeck[c]))
              .forEach((c) => moves.push(digestCardMove(c)));
            break;
          case BoardBoxEffect.Labyrinth:
            if (canHuntRose(player, GameDeck)) {
              this.state.roses.forEach((rose) => moves.push(huntRoseMove(rose)));
            }
            break;
          case BoardBoxEffect.Tavern:
            if (canHuntTavern(player, huntSpeed, this.state.tavern.length, GameDeck)) {
              moves.push(huntInTavernMove(this.state.tavern.length));
            }
        }
      }

      // Player can hunt after using all its card in playing area
      if (!isOverLastTurn(this.state.round)) {
        getHuntableTrackAreas(player, this.state.hunt.track, GameDeck, BoardBoxes, huntSpeed).forEach((item) =>
          moves.push(huntOnTrackMove(item.row, item.col))
        );
      }

      // Bonus tokens
      player.bonusTokens
        .filter((b) => canBonusBePlayed(player, b, bonusList))
        .forEach((b) => moves.push(activateBonusMove(b)));

      // Missions
      player.missions
        .filter((m) =>
          isPlayableMission(
            Missions[m.mission],
            m.mission,
            player,
            GameDeck,
            BoardBoxes,
            this.state.hunt.track,
            this.state.bonusTokens
          )
        )
        .forEach((m) => moves.push(activateMissionMove(m.mission)));
    }

    if (
      isOver(this.state.players, this.state.round, this.state.turnOrder, bonusList) &&
      hasMultifactionToken(player, bonusList)
    ) {
      moves.push(
        acquireBonusTokenMove(
          player.vampire,
          undefined,
          bonusList.findIndex((b) => b.type === BonusTokenType.Noble)
        ),
        acquireBonusTokenMove(
          player.vampire,
          undefined,
          bonusList.findIndex((b) => b.type === BonusTokenType.Church)
        ),
        acquireBonusTokenMove(
          player.vampire,
          undefined,
          bonusList.findIndex((b) => b.type === BonusTokenType.Military)
        ),
        acquireBonusTokenMove(
          player.vampire,
          undefined,
          bonusList.findIndex((b) => b.type === BonusTokenType.Villager)
        )
      );
    }

    return moves;
  }

  randomize(move: Move): Move & MoveRandomized {
    if (move.type === MoveType.ShuffleDiscardToDeck) {
      const player = this.state.players.find(p => p.vampire === this.state.activePlayer)!;
      // TODO: allow tutorial API to override randomize function and remove tutorial property from the game state
      if (this.state.tutorial && this.state.round === 2 && player.vampire === Vampire.BorisPouchkine) {
        return {...move, deck: [...player.discard.slice(0, 3), ...player.discard.slice(3)]};
      } else {
        return {...move, deck: shuffle(player.discard)};
      }
    }
    return move
  }

  play(move: MoveRandomized): Move[] {
    const bonusList = Bonuses(this.state.oldTokensCount);
    switch (move.type) {
      case MoveType.VictoryPoint:
        victoryPoint(this.state, move, GameDeck);
        break;
      case MoveType.DrawMissions:
        drawMissions(this.state, move, GameDeck);
        break;
      case MoveType.EndTurn:
        endTurn(this.state);
        break;
      case MoveType.DrawCards:
        drawCards(this.state, move);
        break;
      case MoveType.DiscardCards:
        discardCards(this.state, move);
        break;
      case MoveType.ActivateCard:
        activateCard(this.state, move);
        break;
      case MoveType.MoveVampire:
        moveVampire(this.state, move, GameDeck, BoardBoxes);
        break;
      case MoveType.ChooseMission:
        chooseMissions(this.state, move);
        break;
      case MoveType.HuntOnTrack:
        huntOnTrack(this.state, move, GameDeck, BoardBoxes);
        break;
      case MoveType.HuntInTavern:
        huntInTavern(this.state, BoardBoxes);
        break;
      case MoveType.HuntRose:
        huntRose(this.state, move, BoardBoxes);
        break;
      case MoveType.NextPlayer:
        nextPlayer(this.state);
        break;
      case MoveType.FlipToken:
        flipToken(this.state, move);
        break;
      case MoveType.NewRound:
        newRound(this.state, bonusList);
        break;
      case MoveType.ShuffleDiscardToDeck:
        shuffleDiscardToDeck(this.state, move);
        break;
      case MoveType.FillHuntTrack:
        fillHuntTrack(this.state);
        break;
      case MoveType.FillTavern:
        fillTavern(this.state);
        break;
      case MoveType.MoveHuntTrackCard:
        moveHuntTrackCard(this.state, move);
        break;
      case MoveType.AcquireBonusToken:
        acquireBonusToken(this.state, move, bonusList);
        break;
      case MoveType.DigestCards:
        digestCard(this.state, move, GameDeck);
        break;
      case MoveType.PlaceInPlayingArea:
        placeInPlayingArea(this.state, move);
        break;
      case MoveType.PlaceOnDeck:
        placeOnDeck(this.state, move);
        break;
      case MoveType.DrawHuntCard:
        drawHuntCard(this.state, GameDeck);
        break;
      case MoveType.DismissEffect:
        dismissEffect(this.state);
        break;
      case MoveType.PlaceInDiscard:
        placeInDiscard(this.state, move);
        break;
      case MoveType.ActivateBonus:
        activateBonus(this.state, move, GameDeck, bonusList);
        break;
      case MoveType.ActivateMission:
        activateMission(this.state, move, GameDeck, Missions);
        break;
      case MoveType.EndOfGame:
        endOfGame(this.state, GameDeck, BoardBoxes, bonusList, Missions);
        break;
    }
    return []
  }

  getAutomaticMoves(): Move[] {
    const bonusList = Bonuses(this.state.oldTokensCount);
    const player = this.state.players.find((p) => p.vampire === this.state.activePlayer);
    const predictedMoves = getPredictableAutomaticMoves(this.state, bonusList, player);

    if (predictedMoves.length > 0) {
      return predictedMoves;
    }

    if (this.state.newRoundPhase) {
      switch (this.state.newRoundPhase) {
        case NewRoundPhase.FillHuntTrack:
          if (!isLastTurn(this.state.round) && !isOverLastTurn(this.state.round)) {
            return [fillHuntTrackMove];
          }
          break;
        case NewRoundPhase.FillTavern:
          if (canRefillTavern(this.state)) {
            return [fillTavernMove];
          }
          break;
      }
    }

    if (
      isOver(this.state.players, this.state.round, this.state.turnOrder, bonusList) &&
      this.state.players.some((p) => p.missions.some((m) => m.score === undefined)) &&
      !this.state.players.some((p) => hasMultifactionToken(p, bonusList))
    ) {
      return [endOfGameMove];
    }

    if (!isOver(this.state.players, this.state.round, this.state.turnOrder, bonusList) && player) {
      if (player.drawCount && player.deck.length) {
        return [drawCardsMove(player.drawCount)];
      }

      if (player.drawCount && !player.deck.length && player.discard.length) {
        return [shuffleDiscardToDeckMove];
      }

      if (player.pendingEffects && player.pendingEffects.length) {
        let automaticMove = getPendingEffectAutomaticMove(this.state, player);
        if (automaticMove) {
          return [automaticMove];
        }
      }

      if (!everyoneHasPlayed(this.state, GameDeck, player) && player.end && !this.state.newRoundPhase) {
        const nonPermanentCards = getCardToDiscard(player.playingArea, player.position, GameDeck);
        // To trigger draw effect, player must have at least 3 card in hand + discard + deck
        if (player.tokenSide === TokenSide.Resting && !nonPermanentCards.length) {
          if (getPlayerHandSize(player) !== 3) {
            if (!player.deck.length && player.discard.length) {
              return [shuffleDiscardToDeckMove];
            } else {
              return [drawCardsMove(3)];
            }
          }
        }
      }

      if (
        player.hand.length &&
        !player.playingArea.some((c) => mustBePlayedFirst(GameDeck[c]) && !player.playedCards.includes(c)) &&
        (!player.missions
          .filter((m) => !player.playedMissions.includes(m.mission))
          .some((m) => mustMissionBePlayedFirst(Missions[m.mission])) ||
          player.playedCards.length)
      ) {
        return[ placeInPlayingAreaMove(player.hand)];
      }

      if (player.hasMoved && !hasHunted(player)) {
        const boardEffectMove = getBoardEffectAutomaticMove(this.state, player);
        if (boardEffectMove) {
          return [boardEffectMove];
        }
      }
    }
    return []
  }

  /**
   * If you game has incomplete information, you must hide some of the game's state to the players and spectators.
   * @return What a person can see from the game state
   */
  getView(playerId?: Vampire | undefined): GameView {
    const bonusList = Bonuses(this.state.oldTokensCount);
    return {
      ...this.state,
      bonusTokens: this.state.bonusTokens.map((bonus) =>
        VisibleTokenPosition.includes(bonus.position) ? { ...bonus } : { position: bonus.position }
      ),
      missions: this.state.missions.map((m, index) => (index < StartupBeigeMissionCount ? m : m.length)),
      tavern: this.state.tavern.length,
      hunt: {
        ...this.state.hunt,
        deck: this.state.hunt.deck.length,
      },
      players: this.state.players.map((player) => {
        if (
          isOver(this.state.players, this.state.round, this.state.turnOrder, bonusList) &&
          !this.state.players.some((p) => hasMultifactionToken(p, bonusList))
        ) {
          return player;
        }

        if (player.vampire === playerId) {
          return {
            ...player,
            deck: player.deck.length,
          };
        }

        return {
          ...player,
          deck: player.deck.length,
          hand: player.hand.length,
          missions: player.missions.length,
          missionChoice: {
            ...player.missionChoice,
            missions: player.missionChoice.missions.length,
          },
        };
      }),
    };
  }

  getPlayerView(playerId: Vampire): GameView {
    return this.getView(playerId);
  }

  /**
   * If you game has incomplete information, sometime you need to alter a Move before it is sent to the players and spectator.
   * For example, if a card is revealed, the id of the revealed card should be ADDED to the Move in the MoveView
   * Sometime, you will hide information: for example if a player secretly choose a card, you will hide the card to the other players or spectators.
   *
   * @param move The move that has been played
   * @param playerId Player identifier
   * @return What a person should know about the move that was played
   */
  getMoveView(move: MoveRandomized, playerId?: Vampire): MoveView {
    switch (move.type) {
      case MoveType.DrawCards:
        return getDrawCardMoveView(this.state, move, playerId);
      case MoveType.FillHuntTrack:
        return getFillHuntTrackMoveView(this.state, move);
      case MoveType.ChooseMission:
        return getChooseMissionMoveView(move, playerId);
      case MoveType.DrawMissions:
        return getDrawMissionsMoveView(this.state, move, playerId);
      case MoveType.HuntInTavern:
        return getHuntInTavernMoveView(this.state, move);
      case MoveType.PlaceOnDeck:
        return getPlaceOnDeckMoveView(this.state, move, playerId);
      case MoveType.ShuffleDiscardToDeck:
        return getShuffleDiscardToDeckMoveView(move);
      case MoveType.DrawHuntCard:
        return getDrawHuntCardMoveView(this.state, move);
      case MoveType.EndOfGame:
        return getEndOfGameView(this.state, move);
      case MoveType.AcquireBonusToken:
        return getAcquireBonusTokenView(this.state, move);
    }

    return move;
  }

  /**
   * If you game has secret information, sometime you need to alter a Move depending on which player it is.
   * For example, if a card is drawn, the id of the revealed card should be ADDED to the Move in the MoveView, but only for the played that draws!
   * Sometime, you will hide information: for example if a player secretly choose a card, you will hide the card to the other players or spectators.
   *
   * @param move The move that has been played
   * @param playerId Player identifier
   * @return What a person should know about the move that was played
   */
  getPlayerMoveView(move: MoveRandomized, playerId: Vampire): MoveView {
    return this.getMoveView(move, playerId);
  }
}
