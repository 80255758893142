import { Card } from '../Card';
import { isStarting, Starters } from '../vampire/Starters';
import { isRose } from './Rose';
import { Roses } from './Roses';
import { Dee, Humans } from './Humans';
import { FormOfBat, FormOfMist, Hypnosis, Powers, VampireStrength, VampireWill, VampiricStealth } from './Powers';
import { Bagoly, Familiars, Malac, Nanoosh, Patcani, Ursa } from './Familiars';

export const GameDeck: Card[] = [
  ...Starters,
  ...Roses,
  ...Humans,
  ...Powers,
  ...Familiars
];

export const getGameDeck = () => GameDeck;

export const isHunt = (card: Card) => !isStarting(card) && !isRose(card);

export const RookieStartingCards: Card[] = [
  Dee,
  VampireWill,
  VampireWill,
  VampireWill,
  VampireStrength,
  VampireStrength,
  VampireStrength,
  FormOfMist,
  FormOfBat,
  VampiricStealth,
  Hypnosis,
  Ursa,
  Malac,
  Nanoosh,
  Patcani,
  Bagoly
]
