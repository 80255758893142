import { GameEffect, GameEffects } from "./GameEffect";
import { EffectType } from "./EffectType";
import { CardType } from "../card/CardType";
import { BoardBox, Region } from "../board";
import GameState from "../GameState";
import { PlayerType } from "../player";
import { Card } from "../card/Card";
import Move from "../moves/Move";
import GameView from "../GameView";
import { huntOnTrackMove } from "../moves/HuntOnTrack";

export class WinHuntTrackCardEffect implements GameEffect {
  type: EffectType.WinHuntTrackCard = EffectType.WinHuntTrackCard;
  huntTypes?: Array<CardType>;
  region?: Region;
  huntedTypes?: Array<CardType>;
  huntedArea?: number;
  toPlayingArea?: boolean;

  constructor(object: Partial<WinHuntTrackCardEffect>) {
    this.huntTypes = object.huntTypes;
    this.region = object.region;
    this.huntedArea = object.huntedArea;
    this.huntedTypes = object.huntedTypes;
    this.toPlayingArea = object.toPlayingArea;
  }

  computePendingEffects(state: GameState | GameView, player: PlayerType, _board: BoardBox[], _contextCards: Card[], deck: Card[]): GameEffects[] {
    if (this.huntedTypes?.length && this.region) {
      if (
        player.hunts.some(
          (h) => h.col !== undefined && h.region === this.region && h.hunts.some((c) => this.huntedTypes?.includes(deck[c].category)) && state.hunt.track.some((line) => line[h.col!].length)
        )
      ) {
        return [this];
      }
    }

    if (this.huntTypes?.length) {
      if (state.hunt.track.some((line) => line.some((area) => area.some((c) => this.huntTypes!.includes(deck[c].category))))) {
        return [this];
      }
    }

    if (this.huntedArea !== undefined && player.hunts.some((e) => e.col === this.huntedArea) && state.hunt.track.some((line) => line.some((area) => area.length))) {
      return [this];
    }
    return [];
  }

  legalMoves(state: GameState, player: PlayerType, deck: Card[]): Move[] {
    if (this.huntedTypes?.length && this.region) {
      const areas = player.hunts.filter((h) => h.region === this.region).map((h) => h.col);

      if (areas.length) {
        return state.hunt.track.flatMap((line, lineIndex) => line.flatMap((area, areaIndex) => (areas.includes(areaIndex) ? area.map((c) => huntOnTrackMove(lineIndex, areaIndex, c)) : [])));
      }
    }

    if (this.huntTypes?.length) {
      return state.hunt.track.flatMap((line, lineIndex) =>
        line.flatMap((area, areaIndex) => area.filter((c) => this.huntTypes?.includes(deck[c].category)).map((c) => huntOnTrackMove(lineIndex, areaIndex, c)))
      );
    }

    if (this.huntedArea !== undefined && player.hunts.some((e) => e.col === this.huntedArea)) {
      return state.hunt.track.flatMap((line, lineIndex) => line.flatMap((area, areaIndex) => area.map((c) => huntOnTrackMove(lineIndex, areaIndex, c))));
    }

    return [];
  }

  isPlayable(player: PlayerType, deck: Card[], _board?: BoardBox[], track?: number[][][]): boolean {
    if (!track) {
      throw Error("The hunt track must be passed to the isPlayable function");
    }

    if (this.huntedTypes?.length && this.region) {
      return player.hunts.some(
        (h) => h.col !== undefined && h.region === this.region && h.hunts.some((c) => this.huntedTypes?.includes(deck[c].category)) && track.some((line) => line[h.col!].length)
      );
    }

    if (this.huntTypes?.length) {
      return track.some((line) => line.some((area) => area.some((c) => this.huntTypes!.includes(deck[c].category))));
    }

    if (this.huntedArea !== undefined) {
      return player.hunts.some((e) => e.col === this.huntedArea) && track.some((line) => line.some((area) => area.length));
    }

    return false;
  }

  isHuntTrackAreaPlayable(area: number[], areaIndex: number, player: PlayerType, deck: Card[]): boolean {
    if (this.huntedTypes?.length && this.region) {
      return player.hunts.some((h) => h.col !== undefined && h.region === this.region && h.col === areaIndex) && !!area.length;
    }

    if (this.huntTypes?.length) {
      return area.some((c) => this.huntTypes?.includes(deck[c].category));
    }

    if (this.huntedArea !== undefined) {
      return player.hunts.some((e) => e.col === this.huntedArea) && !!area.length;
    }

    return !!area.length;
  }

  isCardHuntable(card: number, deck: Card[]): boolean {
    if (this.huntTypes?.length) {
      return this.huntTypes?.includes(deck[card].category);
    }

    return true;
  }
}
