/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { playerEffectSummaryBubbleLeft, playerEffectSummaryMissionBubbleHeight, playerEffectSummaryMissionBubbleTop, playerEffectSummaryMissionBubbleWidth } from '../../../../utils/Style';
import { Missions } from '@gamepark/the-hunger/mission';
import { MissionTile } from '../../../mission/MissionTile';

type MissionBubbleProps = {
  mission?: number,
  index?: number,
  playerCount: number,
}

const MissionBubble = forwardRef<HTMLDivElement, MissionBubbleProps>(({
                                                                        mission,
                                                                          index,
                                                                          playerCount
                                                                        }, ref) => {
  if (mission === undefined || index === undefined) {
    return null;
  }

  return <div ref={ ref } key="tooltip" css={ [effectBubbleMissionCss(index, playerCount)] }>
    <MissionTile css={ missionStyle } mission={ Missions[mission] }/>
  </div>;
});

const missionStyle = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const effectBubbleMissionCss = (index: number, playerCount: number) => css`
  position: absolute;
  width: ${ playerEffectSummaryMissionBubbleWidth }%;
  height: ${ playerEffectSummaryMissionBubbleHeight }%;
  font-size: ${ playerEffectSummaryMissionBubbleHeight / 100 }em;
  z-index: 500;
  left: ${ playerEffectSummaryBubbleLeft(index) }%;
  top: ${ playerEffectSummaryMissionBubbleTop(index, playerCount) }%;
  transition: 0.5s left;
  transform: translateX(-100%);
  filter: drop-shadow(0 0 0.7em white);

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: 39%;
    transition: 0.5s top;
    width: 0;
    height: 0;
    border: 12em solid transparent;
    border-left-color: white;
    border-right: 0;
    margin-top: 1em;
    margin-right: -12.3em;
  }
`;

export {
  MissionBubble
};

