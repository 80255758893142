/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createRef, CSSProperties, FC, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import {
  boxHeight,
  boxWidth,
  gameBoardHeight,
  gameBoardLeft,
  gameBoardWidth,
  getTokenTranslate,
} from '../../utils/Style';
import { Images } from '../../images/Images';
import { RookieBoxPosition } from './box/BoxPositions';
import { Box } from './box/Box';
import { PlayerType } from '@gamepark/the-hunger/player';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { getActualMovablePaths } from '@gamepark/the-hunger/utils/MoveUtils';
import { computeSpeed } from '@gamepark/the-hunger/utils/GameUtils';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { BoardBoxEffect, BoardBoxes } from '@gamepark/the-hunger/board';
import { PlayerToken } from '../player/PlayerToken';
import { isDrawMission } from '@gamepark/the-hunger/moves/DrawMissions';
import { HumanTypeSymbol, SpeedSymbol } from '../../symbols';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { GameDisplaySwitchTransition, TavernHuntCost } from '@gamepark/the-hunger/utils/GameConstants';
import { RosePile } from './RosePile';
import { TavernPile } from './TavernPile';
import { getNextPendingEffect } from '@gamepark/the-hunger/utils/PendingUtils';
import { selectVampireMove } from '../../moves/SelectVampire';
import { PositionedBonus } from '@gamepark/the-hunger/bonus/PositionedBonus';
import { isPushEffect, isTeleport } from '@gamepark/the-hunger/utils/EffectUtils';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CastleToken } from '../castle/CastleToken';
import { isMoveVampire, MoveVampire } from '@gamepark/the-hunger/moves/MoveVampire';
import { BoardMissions } from './BoardMissions';
import { isChooseMissionMove, isChooseMissionMoveView } from '@gamepark/the-hunger/moves/ChooseMission';
import { isFillTavern } from '@gamepark/the-hunger/moves/FillTavern';
import { isAcquireBonus } from '@gamepark/the-hunger/moves/AcquireBonus';
import { isHuntInTavern } from '@gamepark/the-hunger/moves/HuntInTavern';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { highlightBoxMove } from '../../moves/HighlightBox';
import { isHuntRose } from '@gamepark/the-hunger/moves/HuntRose';
import { BoardTokens } from './BoardTokens';
import { hasHunted } from '@gamepark/the-hunger/utils/PlayerUtils';
import { BoardHelpModal } from '../../modal/BoardHelpModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

type GameBoardProps = {
  activePlayer?: Vampire;
  players: PlayerType[];
  tavern: number;
  roses: number[];
  castleTokens: number[];
  bonusTokens: (PositionedBonus | Omit<PositionedBonus, 'bonus'>)[];
  missions: (number | number[])[];
  selectedVampire?: Vampire;
  visible: boolean;
  gameMode: GameMode;
  isGameOver: boolean;
  oldTokensCount?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const initialState = 1;
const INITIAL_BOARD_SCALE = initialState;
const GameBoard: FC<GameBoardProps> = ({
  visible,
  gameMode,
  isGameOver,
  selectedVampire,
  activePlayer,
  players,
  roses,
  tavern,
  missions,
  bonusTokens,
  castleTokens,
  oldTokensCount,
  ...props
}) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const [isTurnOrderHelpOpened, setTurnOrderHelpOpened] = useState(false);
  const player = players.find((p) => activePlayer === playerId && p.vampire === playerId);
  const pendingEffect = player && getNextPendingEffect(player);
  const selectedPlayer =
    !!selectedVampire && selectedVampire !== playerId && players.find((p) => p.vampire === selectedVampire)!;
  const moveAnimation = useAnimation<MoveVampire>(
    (animation) => isMoveVampire(animation.move) && animation.move.vampire === activePlayer
  );
  const animation = useAnimation();
  const isBlockingAnimation =
    animation &&
    (isMoveVampire(animation.move) ||
      isDrawMission(animation.move) ||
      isChooseMissionMoveView(animation.move) ||
      isChooseMissionMove(animation.move) ||
      isFillTavern(animation.move) ||
      isHuntInTavern(animation.move) ||
      isHuntRose(animation.move) ||
      isAcquireBonus(animation.move));
  const ref = createRef<ReactZoomPanPinchRef>();
  const [isOverflowVisible, setOverflowVisible] = useState(false);
  const [boardZoom, setBoardZoom] = useState(INITIAL_BOARD_SCALE);

  useEffect(() => {
    if (isBlockingAnimation && ref.current) {
      ref.current.resetTransform(0, 'easeOut');
      setBoardZoom(INITIAL_BOARD_SCALE);
      setOverflowVisible(true);
    } else {
      setOverflowVisible(false);
    }
  }, [isBlockingAnimation, ref]);

  useEffect(() => {
    if (isPushEffect(pendingEffect) || isTeleport(pendingEffect)) {
      play(selectVampireMove(isTeleport(pendingEffect) ? playerId : pendingEffect.vampire), { local: true });
    } else if (selectedVampire && selectedVampire !== player?.vampire) {
      play(selectVampireMove(), { local: true });
    }

    // eslint-disable-next-line
  }, [pendingEffect, selectedVampire]);

  const targetPosition = useMemo(
    () => {
      if (!playerId) {
        return [];
      }

      const me = players.find((p) => p.vampire === playerId)!;

      return getActualMovablePaths(
        computeSpeed(me, GameDeck),
        me,
        BoardBoxes,
        GameDeck,
        true,
        players.map((player) => player.position),
        activePlayer
      ).flat();
    },
    // eslint-disable-next-line
    [players]
  );

  const onDigestAreaClick = (type: CardType) => {
    switch (type) {
      case CardType.HumanMilitary:
        return play(highlightBoxMove(BoardBoxes.findIndex((b) => b.effect === BoardBoxEffect.Barracks)), {
          local: true,
        });
      case CardType.HumanNoble:
        return play(highlightBoxMove(BoardBoxes.findIndex((b) => b.effect === BoardBoxEffect.Mansion)), {
          local: true,
        });
      case CardType.HumanReligious:
        return play(highlightBoxMove(BoardBoxes.findIndex((b) => b.effect === BoardBoxEffect.Church)), { local: true });
      case CardType.HumanVillager:
        return play(highlightBoxMove(BoardBoxes.findIndex((b) => b.effect === BoardBoxEffect.Market)), { local: true });
    }
  };

  const closeModal = () => {
    setTurnOrderHelpOpened(false);
  };

  const openHelp = () => {
    ref?.current?.resetTransform?.(0, 'easeOut');
    setTurnOrderHelpOpened((value) => !value);
  };

  const isPlayerTokenDraggable = (p: PlayerType) =>
    (player?.vampire === p.vampire &&
      !hasHunted(p) &&
      !p.hasMoved &&
      !animation &&
      (!pendingEffect || pendingEffect?.optional || isTeleport(pendingEffect))) ||
    (isPushEffect(pendingEffect) && p.vampire === pendingEffect.vampire);

  return (
    <>
      <TransformWrapper
        disabled={isOverflowVisible}
        initialScale={INITIAL_BOARD_SCALE}
        maxScale={3}
        minScale={INITIAL_BOARD_SCALE}
        centerZoomedOut={true}
        doubleClick={{ disabled: true }}
        panning={{ velocityDisabled: true }}
        onZoomStop={(ref) => setBoardZoom(Math.max(initialState, ref.state.scale))}
        ref={ref}
      >
        <TransformComponent
          wrapperStyle={{
            ...boardWrapperStyle,
            ...visibleStyle,
            ...(!visible ? invisibleStyle : {}),
            ...(isOverflowVisible ? { overflow: 'visible' } : {}),
          }}
          contentStyle={boardWContentStyle}
          {...props}
        >
          <TavernPile tavern={tavern} player={player} players={players} visible={visible} />
          <RosePile roses={roses} player={player} css={[!visible && notTargetable]} />
          {RookieBoxPosition.map((_, index) => (
            <Box
              isTargetable={
                !!(
                  selectedVampire &&
                  selectedVampire === playerId &&
                  targetPosition &&
                  targetPosition.includes(index)
                ) ||
                (selectedPlayer && BoardBoxes[selectedPlayer.position.box].branches.includes(index))
              }
              selectedVampire={selectedVampire}
              css={[boxStyle(index), !visible && notTargetable]}
              key={index}
              mode={gameMode}
              position={index}
            />
          ))}
          <CastleToken castleTokens={castleTokens} players={players} activePlayer={activePlayer} />
          {players.map((p) => (
            <PlayerToken
              activePlayer={activePlayer}
              ref={(r) => r}
              selectedVampire={selectedVampire}
              key={`player-${p.vampire}`}
              player={p}
              vampire={p.vampire}
              css={[
                playerTokenPosition(p.position.z),
                !isPlayerTokenDraggable(p) && notTargetable,
                isPlayerTokenDraggable(p) && onTop,
                !visible && notTargetable,
              ]}
              zoom={boardZoom}
              canDrag={isPlayerTokenDraggable(p)}
              isDraggable={true}
              preTransform={getTokenTranslate(
                moveAnimation && moveAnimation.move.vampire === p.vampire ? moveAnimation.move.box : p.position.box
              )}
            />
          ))}
          <BoardTokens
            players={players}
            activePlayer={activePlayer}
            bonuses={bonusTokens}
            selectedVampire={selectedVampire}
            pendingEffect={pendingEffect}
            oldTokensCount={oldTokensCount}
          />
          <BoardMissions
            players={players}
            missions={missions}
            activePlayer={activePlayer}
            isGameOver={isGameOver}
            css={[!visible && notTargetable]}
            oldTokensCount={oldTokensCount}
          />
          <SpeedSymbol value={TavernHuntCost.toString()} width={1.4} css={[tavernCost, !visible && notTargetable]} />
          <SpeedSymbol value="0" width={1.4} css={[labyrinthCost, !visible && notTargetable]} />
          <HumanTypeSymbol
            category={CardType.HumanNoble}
            hd
            width={1.6}
            onClick={() => onDigestAreaClick(CardType.HumanNoble)}
            css={[digestHuman, digestHumanNoble, !visible && notTargetable]}
          />
          <HumanTypeSymbol
            category={CardType.HumanReligious}
            hd
            width={1.6}
            onClick={() => onDigestAreaClick(CardType.HumanReligious)}
            css={[digestHuman, digestHumanReligious, !visible && notTargetable]}
          />
          <HumanTypeSymbol
            category={CardType.HumanMilitary}
            hd
            width={1.6}
            onClick={() => onDigestAreaClick(CardType.HumanMilitary)}
            css={[digestHuman, digestHumanMilitary, !visible && notTargetable]}
          />
          <HumanTypeSymbol
            category={CardType.HumanVillager}
            hd
            width={1.6}
            onClick={() => onDigestAreaClick(CardType.HumanVillager)}
            css={[digestHuman, digestHumanVillager, !visible && notTargetable]}
          />
        </TransformComponent>
      </TransformWrapper>
      {visible && <FontAwesomeIcon css={helpStyle} icon={faQuestionCircle} onClick={openHelp} />}
      {isTurnOrderHelpOpened && <BoardHelpModal onClose={closeModal} />}
      {isTurnOrderHelpOpened && <div css={regionsHelp} />}
    </>
  );
};

const regionsHelp = css`
  position: absolute;
  z-index: 2;
  background-image: url(${Images.RegionHelp});
  background-size: cover;
  height: ${gameBoardHeight}%;
  width: ${gameBoardWidth}%;
  left: ${gameBoardLeft - 0.1}%;
  top: 0.1%;
  filter: drop-shadow(0.1em 0.2em 0.3em black);
  pointer-events: none;
`;

const helpStyle = css`
  position: absolute;
  font-size: 5em;
  top: 0.1em;
  right: 15.5em;
  z-index: 3;
  cursor: pointer;
  filter: drop-shadow(0.05em 0.1em 0.15em black);
`;

const invisibleStyle: CSSProperties = {
  opacity: 0,
  pointerEvents: 'none',
};

const visibleStyle: CSSProperties = {
  transition: `${GameDisplaySwitchTransition}s opacity`,
  transitionTimingFunction: 'ease-in-out',
  opacity: 1,
};

const boardWrapperStyle: CSSProperties = {
  position: 'absolute',
  left: `${gameBoardLeft}%`,
  height: `${gameBoardHeight}%`,
  width: `${gameBoardWidth}%`,
  boxShadow: '0.2em 0.2em 0.5em black',
  fontSize: `${gameBoardHeight / 100}em`,
};

const boardWContentStyle: CSSProperties = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  background: `url(${Images.GameBoard})`,
  backgroundSize: '100% 100%',
  borderRadius: '0.2em',
  imageRendering: '-webkit-optimize-contrast',
};

const playerTokenPosition = (zIndex: number) => css`
  position: absolute;
  width: ${boxWidth}%;
  height: ${boxHeight}%;
  z-index: ${zIndex};
`;

const onTop = css`
  z-index: 99;
`;

const boxStyle = (position: number) => css`
  position: absolute;
  width: ${boxWidth}%;
  height: ${boxHeight}%;
  left: ${RookieBoxPosition[position].left}%;
  top: ${RookieBoxPosition[position].top}%;
`;

const tavernCost = css`
  position: absolute;
  font-size: 2.6em;
  left: 3.2%;
  top: 40.25%;
  filter: drop-shadow(0 0.1em 0 black);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const labyrinthCost = css`
  position: absolute;
  font-size: 2.6em;
  left: 32.1%;
  top: 9.5%;
  filter: drop-shadow(0 0.1em 0 black);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const digestHuman = css`
  position: absolute;
  font-size: 2.3em;
  filter: drop-shadow(0 0.1em 0 black);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const digestHumanNoble = css`
  left: 91%;
  top: 18.5%;
`;

const digestHumanReligious = css`
  left: 52.7%;
  top: 70.3%;
`;

const digestHumanMilitary = css`
  left: 75%;
  top: 27%;
`;

const digestHumanVillager = css`
  left: 41.8%;
  top: 80.3%;
`;

const notTargetable = css`
  cursor: default;
  pointer-events: none;
  transition-duration: 0.5s;
`;

export { GameBoard };
