/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { HuntCard } from '../../../card/HuntCard';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { computeEffectTooltipArrow, playerEffectSummaryBubbleHeight, playerEffectSummaryBubbleLeft, playerEffectSummaryBubbleWidth } from '../../../../utils/Style';

type HuntCardBubbleProps = {
  card?: number,
  index?: number,
  playerCount: number,
}

const HuntCardBubble = forwardRef<HTMLDivElement, HuntCardBubbleProps>(({
                                                                                card,
                                                                                index,
                                                                                playerCount
                                                                              }, ref) => {
  if (card === undefined || index === undefined) {
    return null;
  }

  return <div ref={ ref } key="tooltip" css={ [effectBubbleCardCss(index, playerCount)] }>
    <HuntCard css={ hunt } hunt={ GameDeck[card] }/>
  </div>;
});

const hunt = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const effectBubbleCardCss = (index: number, playerCount: number) => css`
  position: absolute;
  width: ${ playerEffectSummaryBubbleWidth }%;
  height: ${ playerEffectSummaryBubbleHeight }%;
  font-size: ${ playerEffectSummaryBubbleHeight / 100 }em;
  z-index: 500;
  left: ${ playerEffectSummaryBubbleLeft(index) }%;
  bottom: 14em;
  transition: 0.5s left;
  transform: translateX(-100%);
  filter: drop-shadow(0 0 0.1em black);

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: ${ computeEffectTooltipArrow(index, playerCount) }%;
    transition: 0.5s top;
    width: 0;
    height: 0;
    border: 2.9em solid transparent;
    border-left-color: white;
    border-right: 0;
    margin-top: 1em;
    margin-right: -2.7em;
  }
`;

export {
  HuntCardBubble
};

