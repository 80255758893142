import { BoardBox, BoardBoxEffect, BoardBoxes } from '../board';
import { computeCardsScore, computePassiveEffects, computeSpeed, constructEffect, isOnPeacefulBox } from './GameUtils';
import { isMyPlayerView, PlayerType } from '../player';
import {
  getActivatableCards,
  getActiveConfuseEffect,
  hasHolyWater,
  hasPlayerSpicyEffect,
  hasUniqueTrait,
  isPlayerOnLabyrinth,
  isWell,
} from './CardUtils';
import GameState from '../GameState';
import GameView from '../GameView';
import { CardTrait } from '../card/CardTrait';
import { ReadyEffect } from '../effect/ReadyEffect';
import { CardType } from '../card/CardType';
import { isTavern } from './MoveUtils';
import { TavernHuntCost } from './GameConstants';
import { getNextPendingEffect } from './PendingUtils';
import { isHuman } from '../card/hunt/Human';
import { EffectTrigger } from '../effect/EffectTrigger';
import { hasMandatoryEffect, isAdditionalHunt, isFast, isFreeHunt } from './EffectUtils';
import { Card } from '../card/Card';
import { isOverLastTurn } from './TurnUtils';
import { VictoryPointEffect } from '../effect/VictoryPointEffect';

export const FirstSpaceAdditionalCost = 3;
export const SecondSpaceAdditionalCost = 2;
export const LastSpaceAdditionalCost = 1;

export const getAvailableHunts = (player: PlayerType, deck: Card[], trackArea?: number) => {
  const hunts = player.hunts.filter((h) => !h.free);
  const additionalHuntsByCard = player.playingArea.filter((c) =>
    deck[c].passiveEffects?.some(
      (e) => isAdditionalHunt(e) && (!player.hasMoved || e.trigger !== EffectTrigger.NotMoving)
    )
  ).length;
  const effect = BoardBoxes[player.position.box].effect;
  let baseHuntCount = 1 + additionalHuntsByCard - hunts.length;

  const pendingEffect = getNextPendingEffect(player, true);
  if (isFreeHunt(pendingEffect) && trackArea === 2) {
    baseHuntCount++;
  }

  if (isAdditionalHunt(pendingEffect)) {
    baseHuntCount++;
  }

  if (player.hasMoved && isWell(effect) && (trackArea === 2 || hunts.some((h) => h.col === 2))) {
    baseHuntCount++;
  }

  return baseHuntCount;
};

/**
 * Indicate if the player has enought possible hunt
 */
export const hasEnoughHunts = (player: PlayerType, deck: Card[], trackArea?: number): boolean => {
  return getAvailableHunts(player, deck, trackArea) > 0;
};

export const canHuntOnCemetery = (cards: (number | undefined)[], deck: Card[], effect?: BoardBoxEffect) =>
  BoardBoxEffect.Cemetery === effect && !cards.filter((c) => !!c).some((c) => isHuman(deck[c!]));

export const canHuntRose = (player: PlayerType, deck: Card[]): boolean => {
  return (
    !!player.hasMoved &&
    !player.uniques.includes(CardType.Rose) &&
    isPlayerOnLabyrinth(player, BoardBoxes) &&
    !hasHolyWater(player, deck) &&
    hasEnoughHunts(player, deck)
  );
};

export const canHuntTavern = (player: PlayerType, speed: number, tavernSize: number, deck: Card[]): boolean => {
  return (
    !!player.hasMoved &&
    !!tavernSize &&
    player.boardEffect === BoardBoxEffect.Tavern &&
    isTavern(BoardBoxes[player.position.box]) &&
    speed >= TavernHuntCost &&
    !hasHolyWater(player, deck) &&
    hasEnoughHunts(player, deck)
  );
};

/**
 * Indicate if the group of card can be hunted with a potential board effect
 * @param player The player
 * @param cards Verified hunted card
 * @param deck The game deck
 * @param board
 * @param trackArea The potential track area
 */
export const canBeHuntedByPlayer = (
  player: PlayerType,
  cards: (number | undefined)[],
  deck: Card[],
  board: BoardBox[],
  trackArea?: number
) => {
  let boardBox = BoardBoxes[player.position.box];
  const mandatoryPendingEffect = getNextPendingEffect(player);

  return (
    !hasHolyWater(player, deck) &&
    !mandatoryPendingEffect &&
    !getActiveConfuseEffect(player, deck, board) &&
    hasEnoughHunts(player, deck, trackArea) &&
    !isOnPeacefulBox(player) &&
    (boardBox.effect !== BoardBoxEffect.Cemetery || canHuntOnCemetery(cards, deck, boardBox.effect))
  );
};

export const canHunt = (
  player: PlayerType,
  speed: number,
  round: number,
  huntTrack: number[][][],
  tavernSize: number,
  deck: Card[],
  board: BoardBox[]
): boolean => {
  return (
    !isOverLastTurn(round) &&
    hasEnoughHunts(player, deck) &&
    (canHuntRose(player, deck) ||
      canHuntTavern(player, speed, tavernSize, deck) ||
      !!getHuntableTrackAreas(player, huntTrack, deck, board, speed).length)
  );
};

/**
 * Get the hunt track available for hunting
 * @param player The actual player
 * @param hunts The hunt track content
 * @param deck The game deck
 * @param board The game board
 * @param playerSpeed The player speed
 */
export const getHuntableTrackAreas = (
  player: PlayerType,
  hunts: number[][][],
  deck: Card[],
  board: BoardBox[],
  playerSpeed?: number
): { row: number; col: number }[] => {
  const speed = playerSpeed !== undefined ? playerSpeed : computeSpeed(player, deck);
  const hasMandatoryCard = player && getActivatableCards(player, deck, board).some((c) => hasMandatoryEffect(deck[c]));
  const hasMissionChoice = isMyPlayerView(player) && !!player.missionChoice.missions.length;
  const pendingEffect = getNextPendingEffect(player);

  if (
    (!speed && !isFreeHunt(pendingEffect)) ||
    isOnPeacefulBox(player) ||
    hasHolyWater(player, deck) ||
    hasMandatoryCard ||
    hasMissionChoice
  ) {
    return [];
  }

  return hunts.flatMap((huntTrackLine, index) => {
    const huntable: { row: number; col: number }[] = [];

    huntTrackLine.forEach((area, areaIndex) => {
      if (area && area.length && isHuntableTrackArea(hunts[index][areaIndex], areaIndex, player, deck, board, playerSpeed)) {
        huntable.push({ row: index, col: areaIndex });
      }
    });

    return huntable;
  });
};

/**
 * Indicate if a specific area on the hunt track can be hunted by a player.
 * @param area The area content
 * @param areaIndex The hunt track area
 * @param player The actual player
 * @param deck The Game Deck
 * @param board
 * @param playerSpeed the player speed if already computed
 */
export const isHuntableTrackArea = (
  area: number[],
  areaIndex: number,
  player: PlayerType,
  deck: Card[],
  board: BoardBox[],
  playerSpeed?: number
): boolean => {
  const speed = playerSpeed !== undefined ? playerSpeed : computeSpeed(player, deck);
  const pendingEffect = getNextPendingEffect(player);

  if (isFreeHunt(pendingEffect) && pendingEffect.huntColumn && areaIndex === pendingEffect.huntColumn) {
    return true;
  }

  return (
    !!speed &&
    !!area.length &&
    !getActiveConfuseEffect(player, deck, board) &&
    (!hasPlayerSpicyEffect(player, deck) || !!player.hasMoved || !!isWell(board[player.position.box].effect)) &&
    canBeHuntedByPlayer(player, area, deck, board, areaIndex) &&
    getAreaCost(area, areaIndex, deck) <= speed
  );
};

/**
 * Compute the cost of an area. Include the Fast effect
 * @param area The area content
 * @param areaIndex The area index
 * @param deck The game deck
 */
export const getAreaCost = (area: number[], areaIndex: number, deck: Card[]): number => {
  const baseCost = 3 - areaIndex;

  return (
    baseCost +
    area
      .flatMap((c) => deck[c].passiveEffects)
      .map((e) => (isFast(e) ? e.additionalCost : 0))
      .reduce((a, b) => a + b, 0)
  );
};

export const applyHunting = (
  state: GameState | GameView,
  cards: number[],
  deck: Card[],
  ignoreReadyTrait: boolean = false
) => {
  const player = state.players.find((p) => p.vampire === state.activePlayer)!;
  const huntedCards = cards.map((c) => deck[c]);
  computePassiveEffects(
    state,
    player,
    player.playingArea.filter((c) => !player.playedCards.includes(c)),
    huntedCards,
    [EffectTrigger.Hunting, EffectTrigger.HuntingAndOnce],
    deck
  );
  computePassiveEffects(state, player, cards, huntedCards, [EffectTrigger.Hunted], deck);

  const cardsWithUniqueTraits = huntedCards.filter((c) => hasUniqueTrait(c));

  player.pendingEffects.unshift(
    constructEffect(new VictoryPointEffect(computeCardsScore(player, BoardBoxes, huntedCards)))
  );

  // Pushing pending effect for trait cards
  if (!ignoreReadyTrait) {
    player.pendingEffects.unshift(
      ...cards
        .filter((c) => deck[c].traits?.includes(CardTrait.Ready))
        .map((card) => JSON.parse(JSON.stringify(new ReadyEffect({ card }))))
    );
  }

  if (cardsWithUniqueTraits.length) {
    cardsWithUniqueTraits.forEach((c) => {
      let category = c.category;
      if (!player.uniques.includes(category)) {
        player.uniques.push(category);
      }
    });
  }
};
