/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player, PlayerType } from '@gamepark/the-hunger/player';
import { FC } from 'react';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { VictoryPointSymbol } from '../../../../symbols';
import { cardRatio } from '../../../../utils/Style';
import { isEndOfGameEffect } from '@gamepark/the-hunger/utils/CardUtils';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { computeEndOfGameEffects } from '@gamepark/the-hunger/utils/EndOfGameUtils';
import { HuntCard } from '../../../card/HuntCard';

type CardScoringProps = {
  player: PlayerType;
  hunt: number;
  oldTokensCount?: boolean;
};

const CardScore: FC<CardScoringProps> = (props) => {
  const { player, hunt, oldTokensCount } = props;
  const card = GameDeck[hunt];
  const endOfGameEffects = (card.passiveEffects || []).filter((e) => isEndOfGameEffect(e));
  const score = computeEndOfGameEffects(endOfGameEffects, player as Player, GameDeck, Bonuses(oldTokensCount));

  return (
    <>
      <div css={[container, cardContainer]}>
        <HuntCard css={cardStyle} hunt={card} />
        <span css={equals}>=</span>
      </div>
      <div css={[container, victoryPointContainer]}>
        <VictoryPointSymbol value={score.toString()} hd css={victoryPointStyle} width={3} bold />
      </div>
    </>
  );
};

const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const cardContainer = css`
  display: flex;
  flex-direction: row;
  flex: 2;
  align-items: center;
  justify-content: flex-end;
  grid-column: span 9;
`;

const cardHeight = 60;
const cardStyle = css`
  height: ${cardHeight}em;
  width: ${cardHeight * cardRatio}em;

  &:first-of-type {
    margin-right: 3em;
  }

  > div {
    font-size: ${cardHeight / 100}em;
  }
`;

const equals = css`
  font-size: 5em;
`;

const victoryPointContainer = css`
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 3em;
  margin: 0 0.2em;
`;

export { CardScore };
