/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PlayerType } from '@gamepark/the-hunger/player';
import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import { playerPanelsHeight, playerPanelsRight, playerPanelsTop, playerPanelsWidth } from '../../utils/Style';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { displayVampireMove } from '../../moves/DisplayVampire';
import { selectVampireMove } from '../../moves/SelectVampire';
import useKeyDown from '../../utils/useKeyDown';
import { PlayerPanel } from './PlayerPanel';
import { isActive } from '@gamepark/the-hunger/utils/IsActive';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { EndOfGameView, isEndOfGame } from '@gamepark/the-hunger/moves/EndOfGame';
import { applyEndOfGame } from '@gamepark/the-hunger/utils/EndOfGameUtils';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import { isMoveVampire } from '@gamepark/the-hunger/moves/MoveVampire';
import { isDrawMission } from '@gamepark/the-hunger/moves/DrawMissions';
import { isChooseMissionMove, isChooseMissionMoveView } from '@gamepark/the-hunger/moves/ChooseMission';
import { isHuntOnTrack } from '@gamepark/the-hunger/moves/HuntOnTrack';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { Missions } from '@gamepark/the-hunger/mission';
import { ViewType } from '../../ViewType';
import { isHuntInTavern } from '@gamepark/the-hunger/moves/HuntInTavern';

type PlayersPanelsProps = {
  players: Array<PlayerType>;
  track: number[][][];
  boardMissions: number[];
  tavern: number;
  turnOrder: Vampire[];
  round: number;
  activePlayer?: Vampire;
  ranking?: Array<Vampire>;
  gameMode: GameMode;
  displayedVampire?: Vampire;
  displayedScore?: Vampire;
  onVampireClick?: (vampire: Vampire) => void;
  view: ViewType;
  oldTokensCount?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlayerPanels: FC<PlayersPanelsProps> = ({
  players,
  activePlayer,
  displayedVampire,
  displayedScore,
  track,
  tavern,
  turnOrder,
  round,
  boardMissions,
  ranking,
  gameMode,
  onVampireClick,
  view,
  oldTokensCount,
  ...props
}) => {
  const play = usePlay();
  const bonusList = Bonuses(oldTokensCount);
  const playerId = usePlayerId();
  const endOfGameAnimation = useAnimation<EndOfGameView>((animation) => isEndOfGame(animation.move));
  const animation = useAnimation(
    (animation) =>
      isMoveVampire(animation.move) ||
      isDrawMission(animation.move) ||
      isChooseMissionMove(animation.move) ||
      isChooseMissionMoveView(animation.move) ||
      isHuntOnTrack(animation.move) ||
      isHuntInTavern(animation.move)
  );

  const realPlayers =
    endOfGameAnimation && !endOfGameAnimation.action.cancelled
      ? applyEndOfGame(
          endOfGameAnimation.move.players,
          boardMissions,
          gameMode,
          GameDeck,
          Missions,
          BoardBoxes,
          bonusList
        )
      : players;

  // eslint-disable-next-line
  const sortedPlayers = useMemo(() => getOrderedPlayersForDisplay(realPlayers, playerId), [realPlayers]);

  const displayVampire = useCallback(
    (vampire: Vampire) => {
      if (displayedVampire !== vampire) {
        play(displayVampireMove(vampire), { local: true });
        play(selectVampireMove(), { local: true });
      }

      if (onVampireClick) {
        onVampireClick(vampire);
      }
    },
    // eslint-disable-next-line
    [activePlayer, displayedVampire, onVampireClick]
  );

  const displayNextPlayer = useCallback(() => {
    const displayedPlayerIndex = sortedPlayers.findIndex((player) => player.vampire === displayedVampire);
    displayVampire(sortedPlayers[(displayedPlayerIndex + 1) % sortedPlayers.length].vampire);
    // eslint-disable-next-line
  }, [activePlayer, displayVampire, displayedVampire, sortedPlayers]);
  useKeyDown('ArrowDown', displayNextPlayer);

  const displayPreviousPlayer = useCallback(() => {
    const displayedPlayerIndex = sortedPlayers.findIndex((player) => player.vampire === displayedVampire);
    displayVampire(sortedPlayers[(displayedPlayerIndex + sortedPlayers.length - 1) % sortedPlayers.length].vampire);
    // eslint-disable-next-line
  }, [activePlayer, displayVampire, displayedVampire, sortedPlayers]);
  useKeyDown('ArrowUp', displayPreviousPlayer);

  function getPlayerTurnOrder(player: PlayerType): number | undefined {
    const placement = turnOrder.findIndex((p) => player.vampire === p);
    return placement !== -1 ? placement + 1 : undefined;
  }

  const playerComponents = sortedPlayers.map((p, index) => (
    <PlayerPanel
      key={`player-${p.vampire}`}
      index={index}
      player={p}
      round={round}
      ranking={ranking && ranking.findIndex((player) => player === p.vampire)! + 1}
      turnOrder={getPlayerTurnOrder(p)}
      gameMode={gameMode}
      activePlayer={activePlayer}
      view={view}
      selected={displayedVampire === p.vampire && ViewType.Player === view}
      scoreSelected={displayedScore === p.vampire}
      onVampireClick={() => displayVampire(p.vampire)}
      oldTokensCount={oldTokensCount}
      isActive={isActive(
        p,
        realPlayers.filter((player) => player.vampire !== p.vampire),
        p.vampire,
        round,
        turnOrder,
        track,
        tavern,
        GameDeck,
        BoardBoxes,
        bonusList,
        activePlayer
      )}
    />
  ));

  return (
    <div css={[panels(players.length), animation && inBackground]} {...props}>
      {playerComponents}
    </div>
  );
};

const panels = (playerCount: number) => css`
  position: absolute;
  right: ${playerPanelsRight}%;
  top: ${playerPanelsTop(playerCount)}%;
  height: ${playerPanelsHeight}%;
  width: ${playerPanelsWidth}%;
  font-size: ${playerPanelsHeight / 100}em;
`;

const inBackground = css`
  z-index: 0;
`;

export { PlayerPanels };
