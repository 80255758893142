import { Calvin, Campbell, Cyrana, Eglantine, Eli, Isabel, Marcel, Murdoch, Peter, Titus, Victoria } from "@gamepark/the-hunger/card/hunt";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";
import { HumanTypeSymbol, VictoryPointSymbol, RegionSymbol, SpeedSymbol } from "../../../symbols";
import { CardType } from "@gamepark/the-hunger/card/CardType";
import { EffectDescription } from "./EffectDescription";
import { Region } from "@gamepark/the-hunger/board";
import { Card } from "@gamepark/the-hunger/card/Card";

const HumanMilitaryEffects = new Map<Card, Array<EffectDescription>>();

// Military
HumanMilitaryEffects.set(Murdoch, [
  new EffectDescription(
    (t: TFunction) => (
      <Trans
        defaults="card.effect.region"
        components={[<VictoryPointSymbol width={1.305} value="2" />, <RegionSymbol region={Region.Forest} />]}
        values={{
          card: t("Murdoch"),
          region: t("the.forest"),
        }}
      />
    ),
    5.5,
    undefined,
    2.5,
    "white"
  ),
]);

HumanMilitaryEffects.set(Peter, [new EffectDescription((t: TFunction) => t("card.effect.digest", { card: t("Peter") }), 7.8, undefined, 2.4, "white")]);

HumanMilitaryEffects.set(Isabel, [new EffectDescription(() => <Trans defaults="card.effect.rose" components={[<VictoryPointSymbol value="2" />]} />, 5.5)]);

HumanMilitaryEffects.set(Eglantine, [new EffectDescription(() => <Trans defaults="card.effect.confused" components={[<strong />]} />, 5.5, undefined, undefined)]);

HumanMilitaryEffects.set(Titus, [
  new EffectDescription(
    (t: TFunction) => (
      <Trans
        defaults="card.effect.spicy"
        components={[<strong />]}
        values={{
          card: t("Titus"),
        }}
      />
    ),
    7.3,
    undefined,
    2.1,
    undefined,
    undefined
  ),
]);
HumanMilitaryEffects.set(Victoria, [
  new EffectDescription(
    (t: TFunction) => (
      <Trans
        defaults="card.effect.inspiring"
        components={[<strong />]}
        values={{
          card: t("Victoria"),
        }}
      />
    ),
    5.7,
    undefined,
    undefined,
    "white"
  ),
]);

HumanMilitaryEffects.set(Calvin, [
  new EffectDescription(
    (t: TFunction) => (
      <Trans
        defaults="card.effect.fast"
        components={[<strong />, <SpeedSymbol width={1.595} value="+1" />]}
        values={{
          card: t("Calvin"),
        }}
      />
    ),
    5.5,
    undefined,
    2.5,
    "white"
  ),
]);

HumanMilitaryEffects.set(Marcel, [new EffectDescription(() => <Trans defaults="card.effect.holywater" components={[<strong />]} />, 5.5)]);

HumanMilitaryEffects.set(Cyrana, [
  new EffectDescription(
    (t: TFunction) => (
      <Trans
        defaults="card.effect.card"
        components={[<VictoryPointSymbol width={1.305} value="4" />]}
        values={{
          card: t("Roxane"),
        }}
      />
    ),
    5.7
  ),
]);

HumanMilitaryEffects.set(Campbell, [new EffectDescription(() => <Trans defaults="card.effect.confused" components={[<strong />]} />, 5.5)]);

HumanMilitaryEffects.set(Eli, [
  new EffectDescription(() => <Trans defaults="card.effect.human.hunt" components={[<VictoryPointSymbol value="1" />, <HumanTypeSymbol category={CardType.HumanMilitary} />]} />, 7.5),
]);

export { HumanMilitaryEffects };
