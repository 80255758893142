import { GameEffect } from './GameEffect';
import { EffectType } from './EffectType';
import { drawCardsMove } from '../moves/DrawCards';
import { PlayerType } from '../player';
import { CardType } from '../card/CardType';
import { Card } from '../card/Card';
import { getMaximumDrawableCard } from '../utils/CardUtils';

export type DrawBonus = {
  huntTypes?: Array<CardType>
  drawCount: number
}

export class DrawEffect implements GameEffect {
  type: EffectType.Draw = EffectType.Draw;
  bonus?: DrawBonus;
  huntTypes?: Array<CardType>;
  drawCount: number;
  mandatory?: boolean;


  // huntTypes?: Array<CardType>, bonus?: DrawBonus
  constructor(drawCount: number, options?: Partial<Omit<DrawEffect, 'drawCount'>>) {
    this.drawCount = drawCount;

    if (options) {
      this.bonus = options.bonus;
      this.huntTypes = options.huntTypes;
      this.mandatory = options.mandatory;
    }
  }

  static automaticMove (player: PlayerType, effect: DrawEffect, deck: Card[]) {

    let drawableCount = 0;
    if (!effect.bonus && !effect.huntTypes?.length) {
      drawableCount = getMaximumDrawableCard(player, effect.drawCount);
    }

    if (effect.bonus && !effect.huntTypes?.length && !player.playingArea.some(c => effect.bonus!.huntTypes?.includes(deck[c].category))) {
      drawableCount = getMaximumDrawableCard(player, effect.drawCount);
    }

    if (effect.bonus && !effect.huntTypes?.length && player.playingArea.some(c => effect.bonus!.huntTypes?.includes(deck[c].category))) {
      drawableCount = getMaximumDrawableCard(player, effect.drawCount + effect.bonus.drawCount);
    }

    if (effect.huntTypes?.length && player.playingArea.some(c => effect.huntTypes?.includes(deck[c].category))) {
      drawableCount = getMaximumDrawableCard(player, effect.drawCount);
    }

    if (!drawableCount) {
      return;
    }

    return drawCardsMove(drawableCount);
  }

  isPlayable(player: PlayerType, deck: Card[]): boolean {
    if (!this.huntTypes?.length) {
      return true;
    }

    return !!this.huntTypes?.length && player.playingArea.some(c => this.huntTypes?.includes(deck[c].category));
  }
}
