/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { isMyPlayerView, isPlayer, PlayerType } from '@gamepark/the-hunger/player';
import { FC, HTMLAttributes, useCallback, useState } from 'react';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { Images } from '../../images/Images';
import {
  cardRatio,
  digestCardFromDiscardTranslateX,
  digestCardFromDiscardTranslateY,
  getDeckCardTranslateX,
  getDeckCardTranslateY,
  getDiscardCardTranslateX,
  getDiscardCardTranslateY,
  playerBoardCardHeight,
  playerBoardCardWidth,
  playerBoardDeckStackTranslateX,
  playerBoardDeckStackTranslateY,
  playerBoardDigestStackTranslateX,
  playerBoardDigestStackTranslateY,
  playerBoardDiscardStackTranslateX,
  playerBoardDiscardStackTranslateY,
  playerBoardHeight,
  playerBoardLeft,
  playerBoardRatio,
  playerBoardTokenHeight,
  playerBoardTokenTranslateX,
  playerBoardTokenTranslateY,
  playerBoardTokenWidth,
  playerBoardTop,
  playerBoardWidth,
} from '../../utils/Style';
import { HuntCardStack } from '../card/HuntCardStack';
import { BonusToken } from '../bonus/BonusToken';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { useTranslation } from 'react-i18next';
import { discardCardMove } from '@gamepark/the-hunger/moves/DiscardCards';
import { canDigestCards, canDiscardCard } from '@gamepark/the-hunger/utils/GameUtils';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { DraggableTypes } from '../draggable/DraggableTypes';
import { PlayableCardFromPlayingArea } from '../draggable/PlayableCardFromPlayingArea';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { digestCardMove, isDigestCardMove } from '@gamepark/the-hunger/moves/DigestCards';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { canBonusBePlayed, isBonusUsed } from '@gamepark/the-hunger/utils/BonusUtils';
import { isDrawCardMove, isDrawCardMoveView } from '@gamepark/the-hunger/moves/DrawCards';
import { DeckLimit, DigestLimit, DiscardLimit } from '@gamepark/the-hunger/utils/GameConstants';
import { HuntCard } from '../card/HuntCard';
import {
  DigestCardDelay,
  DigestCardDuration,
  ShuffleDiscardToDeckCardDelay,
  ShuffleDiscardToDeckCardDuration,
} from '@gamepark/the-hunger/utils/AnimationsConstants';
import { isShuffleDiscardToDeck } from '@gamepark/the-hunger/moves/ShuffleDiscardToDeck';
import { FullscreenBonus } from '../bonus/FullscreenBonus';
import { CardsViewer } from './viewer/CardsViewer';
import { HuntCardsCatalog } from '../card/HuntCardsCatalog';
import { EffectTrigger } from '@gamepark/the-hunger/effect/EffectTrigger';
import { isVictoryPoint, isVictoryPointPerTrait } from '@gamepark/the-hunger/utils/EffectUtils';

export type PlayerBoardProps = {
  player: PlayerType;
  activePlayer?: Vampire;
  isGameOver?: boolean;
  oldTokensCount?: boolean;
} & HTMLAttributes<HTMLDivElement>;

enum CardStack {
  DIGEST = 1,
  DISCARD,
  DECK,
}

type DropItem = { card: number };
const PlayerBoard: FC<PlayerBoardProps> = ({ player, activePlayer, isGameOver, oldTokensCount, ...props }) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { t } = useTranslation();
  const hasAnimation = useAnimation();
  const [cardsToDisplay, setCardsToDisplay] = useState<{ type: CardStack; cards: number[] }>();
  const [selectedBonus, setSelectedBonus] = useState<number>();
  const bonusList = Bonuses(oldTokensCount);

  const animation = useAnimation(
    (animation) =>
      (isDrawCardMoveView(animation.move) ||
        isDrawCardMove(animation.move) ||
        isDigestCardMove(animation.move) ||
        isShuffleDiscardToDeck(animation.move)) &&
      activePlayer === player.vampire
  );
  const drawCardMove = animation && isDrawCardMove(animation.move) ? animation.move : undefined;
  const drawCardMoveView = animation && isDrawCardMoveView(animation.move) ? animation.move : undefined;

  const digestMove = animation && isDigestCardMove(animation.move) ? animation.move : undefined;
  const digestCardDuration: number =
    animation && digestMove
      ? (animation.duration * DigestCardDuration) /
        (DigestCardDuration + (digestMove.cards.length - 1) * DigestCardDelay)
      : 0;
  const digestCardDelay: number =
    animation && digestMove && digestMove.cards.length > 1
      ? (animation.duration * 100 - digestCardDuration * 100) / 100 / (digestMove.cards.length - 1)
      : 0;

  const shuffleMove = animation && isShuffleDiscardToDeck(animation.move) ? animation.move : undefined;
  const shuffleCardDuration: number =
    animation && shuffleMove
      ? (animation.duration * ShuffleDiscardToDeckCardDuration) /
        (ShuffleDiscardToDeckCardDuration +
          (Math.min(shuffleMove.deck, DiscardLimit) - 1) * ShuffleDiscardToDeckCardDelay)
      : 0;
  const shuffleCardDelay: number =
    animation && shuffleMove && shuffleMove.deck > 1
      ? (animation.duration * 100 - shuffleCardDuration * 100) / 100 / (shuffleMove.deck - 1)
      : 0;

  let deck = isPlayer(player) ? player.deck.length : player.deck;
  const isDrawCardMoveOrView = drawCardMove || drawCardMoveView;
  const drawnCardCount = isDrawCardMoveOrView
    ? drawCardMove
      ? Math.min(drawCardMove.count, deck)
      : drawCardMoveView!.cards.length
    : 0;
  const deckStackSize = drawCardMove || drawCardMoveView ? Math.min(deck - drawnCardCount, DeckLimit) : deck;

  const canBeDigestedOrDiscarded = useCallback(
    (monitor: DropTargetMonitor<DropItem>, card: number = monitor.getItem().card) =>
      (activePlayer === player.vampire || !!player.pendingEffects.length) &&
      player.vampire === playerId &&
      (canDigestCards(player, [card], GameDeck) || canDiscardCard(player, card)),
    [playerId, activePlayer, player]
  );

  const [{ isOver, isDiscardable, isDigestible }, ref] = useDrop({
    accept: DraggableTypes.PlayableCard,
    canDrop: (item: PlayableCardFromPlayingArea, monitor) => canBeDigestedOrDiscarded(monitor, item.card),
    drop: (item: PlayableCardFromPlayingArea) =>
      canDigestCards(player, [item.card], GameDeck)
        ? digestCardMove(item.card)
        : discardCardMove(item.card, player.vampire),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDiscardable:
        monitor.getItemType() === DraggableTypes.PlayableCard &&
        canDiscardCard(player, (monitor.getItem() as DropItem).card),
      isDigestible:
        monitor.getItemType() === DraggableTypes.PlayableCard &&
        canDigestCards(player, [(monitor.getItem() as DropItem).card], GameDeck),
    }),
  });

  const canBePlayed = (bonus: number) =>
    activePlayer === player.vampire &&
    player.vampire === playerId &&
    isMyPlayerView(player) &&
    !player.missionChoice.missions.length &&
    canBonusBePlayed(player, bonus, bonusList);

  const selectBonus = (bonus: number) => {
    setSelectedBonus(bonus);
  };

  const hideOverlay = (event?: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    setCardsToDisplay(undefined);
  };

  const displayCards = useCallback(
    (event: any, type: CardStack, cards: number[]) => {
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }

      if (!isGameOver && (!cards.length || playerId !== player.vampire)) {
        return;
      }

      setCardsToDisplay({
        type,
        cards,
      });
    },
    [setCardsToDisplay, isGameOver, player, playerId]
  );

  const digestCard = (event: any, card: number) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    if (canDigestCards(player, [card], GameDeck)) {
      play(digestCardMove(card));
      hideOverlay(event);
    }
  };

  let discard = [...player.discard];
  if (digestMove) {
    discard = discard.filter((c) => !digestMove.cards.includes(c));
  }

  const isDisplayDiscardCards = CardStack.DISCARD === cardsToDisplay?.type;

  // eslint-disable-next-line
  const onDiscardClick = useCallback(
    (event: any) =>
      displayCards(
        event,
        CardStack.DISCARD,
        [...player.discard].sort((c1, c2) => GameDeck[c1].category - GameDeck[c2].category)
      ),
    [player.discard, displayCards]
  );
  // eslint-disable-next-line
  const onDigestClick = useCallback(
    (event: any) => displayCards(event, CardStack.DIGEST, player.digest),
    [player.digest, displayCards]
  );
  return (
    <div css={[playerBoard(player.vampire)]} {...props}>
      {!isGameOver && !!cardsToDisplay?.cards.length && (
        <HuntCardsCatalog
          hunts={cardsToDisplay.cards}
          onClose={hideOverlay}
          isClickable={
            isDisplayDiscardCards
              ? (card) => canDigestCards(player, [card], GameDeck, cardsToDisplay.type === CardStack.DISCARD)
              : undefined
          }
          onHuntClick={isDisplayDiscardCards ? digestCard : undefined}
        />
      )}
      {isGameOver && !!cardsToDisplay?.cards.length && (
        <CardsViewer
          cards={getSortedCards(cardsToDisplay.cards)}
          player={player}
          onClose={hideOverlay}
          oldTokensCount={oldTokensCount}
        />
      )}
      <span css={[boardArea, playingAreaTitle]}>{t('playingarea')}</span>
      <span css={[boardArea, deckTitle]}>{t('deck')}</span>
      <span css={[boardArea, discardTitle]}>{t('discard')}</span>
      <span css={[boardArea, digestTitle]}>{t('digest')}</span>
      <HuntCardStack
        css={cardStackStyle}
        hunts={deckStackSize}
        horizontalDirection="left"
        verticalDirection="top"
        tooltipPosition="left"
        cardLimit={5}
        preTransform={`translate(${playerBoardDeckStackTranslateX}%, ${playerBoardDeckStackTranslateY}%)`}
        position={3}
      />
      <HuntCardStack
        css={[cardStackStyle, isMyPlayerView(player) && pointable, cardsToDisplay?.type === CardStack.DIGEST && hidden]}
        hunts={player.digest}
        horizontalDirection="center"
        verticalDirection="center"
        tooltipPosition="left"
        showOnlyLast={!isGameOver && playerId !== player.vampire}
        cardLimit={DigestLimit}
        preTransform={`translate(${playerBoardDigestStackTranslateX}%, ${playerBoardDigestStackTranslateY}%) rotateZ(90deg) `}
        position={4}
        onClick={onDigestClick}
        displayTooltip={!cardsToDisplay?.cards.length}
      />
      {!!digestMove &&
        discard.length !== player.discard.length &&
        digestMove.cards.map((c, index) => (
          <HuntCard
            key={`dicarded-card-${c}`}
            hunt={GameDeck[c]}
            css={[
              cardStackStyle,
              digestAnimation(
                index,
                player.discard[player.discard.length - 1] === c,
                discard.length,
                digestCardDuration,
                digestCardDelay
              ),
            ]}
          />
        ))}
      {!!shuffleMove &&
        discard.slice(-Math.min(shuffleMove.deck, DiscardLimit)).map((c, index) => {
          return (
            <HuntCard
              key={`player-discard-${c}`}
              hunt={
                isGameOver || playerId === player.vampire || c === discard[discard.length - 1] ? GameDeck[c] : undefined
              }
              css={[
                cardStackStyle,
                shuffleCardAnimation(
                  index,
                  Math.min(shuffleMove.deck, DiscardLimit),
                  isGameOver || playerId === player.vampire || c === discard[discard.length - 1],
                  shuffleCardDuration,
                  shuffleCardDelay
                ),
              ]}
            />
          );
        })}
      {!shuffleMove && (
        <HuntCardStack
          css={[
            cardStackStyle,
            isMyPlayerView(player) && pointable,
            isDisplayDiscardCards && hidden,
            activePlayer === player.vampire &&
              player.vampire === playerId &&
              !animation &&
              canDigestCards(player, discard, GameDeck, true) &&
              digestible,
          ]}
          hunts={discard}
          horizontalDirection="right"
          verticalDirection="top"
          tooltipPosition="right"
          showOnlyLast={!isGameOver && playerId !== player.vampire}
          cardLimit={DiscardLimit}
          preTransform={`translate(${playerBoardDiscardStackTranslateX}%, ${playerBoardDiscardStackTranslateY}%)`}
          position={5}
          onClick={onDiscardClick}
          displayTooltip={!cardsToDisplay?.cards.length}
        />
      )}
      {(isDigestible || isDiscardable) && (
        <div ref={ref} css={[digestArea(isDigestible), isOver && highlightDigestArea]}>
          <span>{isDigestible ? t('button.to.digest') : t('button.to.discard')}</span>
        </div>
      )}
      {player.bonusTokens.map((bonus, index) => (
        <BonusToken
          key={`bonus-${index}`}
          preTransform={`translate(${playerBoardTokenTranslateX(index)}%, ${playerBoardTokenTranslateY(
            index
          )}%) rotateY(${isBonusUsed(player, bonus) ? 180 : 0}deg)`}
          css={[bonusToken, !hasAnimation && canBePlayed(bonus) && selectableToken]}
          onClick={() => selectBonus(bonus)}
          type={bonusList[bonus]}
        />
      ))}
      {selectedBonus !== undefined && (
        <FullscreenBonus
          key="player-board-bonus-fs"
          bonus={selectedBonus}
          fromPlayer={playerId === player.vampire}
          used={isBonusUsed(player, selectedBonus)}
          playable={!hasAnimation && canBePlayed(selectedBonus)}
          onClose={() => setSelectedBonus(undefined)}
          oldTokensCount={oldTokensCount}
        />
      )}
    </div>
  );
};

const getSortedCards = (cards: number[]) => {
  return [...cards].sort((c1, c2) => {
    const card1 = GameDeck[c1];
    const card2 = GameDeck[c2];
    const comparison = card1.category - card2.category;

    if (!comparison) {
      const card1Effects = card1.passiveEffects;
      const card2Effects = card2.passiveEffects;
      const first1 = card1Effects && card1Effects[0];
      const first2 = card2Effects && card2Effects[0];
      if (
        first1 &&
        first1.trigger === EffectTrigger.EndOfGame &&
        first2 &&
        first2.trigger === EffectTrigger.EndOfGame
      ) {
        if (isVictoryPointPerTrait(first1) || (isVictoryPoint(first1) && first1.perHunt)) {
          return -1;
        } else if (isVictoryPointPerTrait(first2) || (isVictoryPoint(first2) && first2.perHunt)) {
          return 1;
        }
      } else if (first1 && first1.trigger === EffectTrigger.EndOfGame) {
        return -1;
      } else if (first2 && first2.trigger === EffectTrigger.EndOfGame) {
        return 1;
      }
    }

    return comparison;
  });
};

const playerBoard = (vampire: Vampire) => css`
  position: absolute;
  top: ${playerBoardTop}%;
  left: ${playerBoardLeft}%;
  height: ${playerBoardHeight}%;
  width: ${playerBoardWidth}%;
  background-image: url(${playerBoards.get(vampire)});
  background-size: cover;
  transition-property: transform;
  transition-duration: 0.5s;
  border-radius: 0.5em;
  font-size: ${playerBoardHeight / 100}em;
`;

const bonusToken = css`
  position: absolute;
  transition: 0.5s translate;
  height: ${playerBoardTokenHeight}%;
  width: ${playerBoardTokenWidth}%;
  cursor: pointer;
`;

const cardStackStyle = css`
  position: absolute;
  height: ${playerBoardCardHeight}%;
  width: ${playerBoardCardWidth}%;
  font-size: ${playerBoardCardHeight / 100}em;
`;

const pointable = css`
  cursor: pointer;
`;

const digestible = css`
  filter: drop-shadow(0 0 0.4em gold) drop-shadow(0 0 0.4em gold) drop-shadow(0 0 0.4em gold)
    drop-shadow(0 0 0.4em gold) drop-shadow(0 0 0.4em gold) drop-shadow(0 0 0.4em gold) drop-shadow(0 0 0.4em gold)
    drop-shadow(0 0 0.4em gold);

  &:hover {
    filter: drop-shadow(0 0 0.4em green) drop-shadow(0 0 0.4em green) drop-shadow(0 0 0.4em green)
      drop-shadow(0 0 0.4em green) drop-shadow(0 0 0.4em green) drop-shadow(0 0 0.4em green)
      drop-shadow(0 0 0.4em green) drop-shadow(0 0 0.4em green);
  }

  cursor: grab;
`;

const digestArea = (isDigestible: boolean) => css`
  position: absolute;
  top: ${isDigestible ? 90.5 : 50}%;
  left: ${isDigestible ? 50 : 93.5}%;
  transform: ${isDigestible ? `translateX(-50%)` : `translateY(-51%)`};
  background-color: rgba(255, 255, 255, 0.3);
  border: 1.5em solid white;
  border-radius: 5.1em;
  //z-index: 10;
  width: ${isDigestible ? 75 : (107 * cardRatio) / playerBoardRatio + 3}%;
  height: ${isDigestible ? 75 * cardRatio * playerBoardRatio + 3 : 107}%;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    color: black;
    font-size: 10em;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const highlightDigestArea = css`
  background-color: rgba(255, 255, 255, 0.6);
`;

const boardArea = css`
  position: absolute;
  font-size: 4em;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
`;

const deckTitle = css`
  width: 19%;
  height: 6%;
  left: -3.2%;
  top: 47.4%;
  text-align: center;
  transform: rotateZ(90deg);
  transform-origin: center center;
`;

const playingAreaTitle = css`
  top: 1%;
  left: 50%;
  transform: translateX(-47%);
`;
const digestTitle = css`
  top: 89.3%;
  left: 50%;
  transform: translateX(-47%);
`;

const discardTitle = css`
  width: 19%;
  height: 6%;
  left: 84.7%;
  top: 47.4%;
  text-align: center;
  transform: rotateZ(-90deg);
  transform-origin: center center;
`;

const hidden = css`
  opacity: 0;
`;

const selectableToken = css`
  box-shadow: 0 0 0.3em 1.2em gold, 0 0 0.3em 1.2em gold;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0.3em 1.2em green, 0 0 0.3em 1.2em green;
  }
`;

const digestAnimation = (
  index: number,
  startOnTop: boolean,
  discardSize: number,
  duration: number,
  delay: number
) => css`
  animation: ${duration}s ${animateDigest(index, startOnTop, discardSize)} ${delay * index}s ease-in-out both;
`;

const animateDigest = (index: number, startOnTop: boolean, discardStackSize: number) => keyframes`
  from {
    z-index: ${!startOnTop ? 0 : 99};
    transform: translate(${
      playerBoardDiscardStackTranslateX +
      getDiscardCardTranslateX(!startOnTop ? 0 : discardStackSize + index, DiscardLimit)
    }%, ${
  playerBoardDiscardStackTranslateY + getDiscardCardTranslateY(!startOnTop ? 0 : discardStackSize + index, DiscardLimit)
}%);
  }
${
  !startOnTop
    ? `30% {
    z-index: 0;
    transform: translate(${playerBoardDiscardStackTranslateX + getDiscardCardTranslateX(0, DiscardLimit) + 120}%, ${
        playerBoardDiscardStackTranslateY + getDiscardCardTranslateY(0, DiscardLimit)
      }%);
  }`
    : ''
} to

{
  z-index: 99
;
  transform: translate(${digestCardFromDiscardTranslateX}%, ${digestCardFromDiscardTranslateY}%) rotateZ(90deg)
;
}
`;

const shuffleCardAnimation = (
  index: number,
  cardCount: number,
  visible: boolean,
  duration: number,
  delay: number
) => css`
  animation: ${duration}s ${animateShuffle(index, cardCount, visible)} ${delay * (cardCount - index - 1)}s ease-in-out
    both;
`;

const animateShuffle = (index: number, cardCount: number, visible: boolean) => keyframes`
  from {
    z-index: ${99};
    transform: translate(${playerBoardDiscardStackTranslateX + getDiscardCardTranslateX(index, DiscardLimit)}%, ${
  playerBoardDiscardStackTranslateY + getDiscardCardTranslateY(index, DiscardLimit)
}%) ${!visible ? 'rotateY(180deg)' : ''};
  }
  50% {
    z-index: ${99 + cardCount - index - 1};
  }
  to {
    z-index: ${99 + cardCount - index - 1};
    transform: translate(${
      playerBoardDeckStackTranslateX + getDeckCardTranslateX(cardCount - index - 1, DeckLimit)
    }%, ${playerBoardDeckStackTranslateY + getDeckCardTranslateY(cardCount - index - 1, DeckLimit)}%) rotateY(180deg);
  }
`;

const playerBoards = new Map<Vampire, any>();
playerBoards.set(Vampire.BorisPouchkine, Images.BorisPouchkinePlayerBoard);
playerBoards.set(Vampire.RajeshAmara, Images.RajeshAmaraPlayerBoard);
playerBoards.set(Vampire.JosephineLafayette, Images.JosephineLafayettePlayerBoard);
playerBoards.set(Vampire.YokoChiyako, Images.YokoChiyakoPlayerBoard);
playerBoards.set(Vampire.LadyBeatrice, Images.LadyBeatricePlayerBoard);
playerBoards.set(Vampire.DonGervasi, Images.DonGervasiPlayerBoard);

export { PlayerBoard };
