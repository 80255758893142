/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import {
  boardButtonHeight,
  boardButtonLabelTop,
  boardButtonLeft,
  boardButtonTop,
  boardButtonWidth,
} from '../../utils/Style';
import { Images } from '../../images/Images';
import { useTranslation } from 'react-i18next';
import { Player } from '@gamepark/the-hunger/player';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { canHunt } from '@gamepark/the-hunger/utils/HuntUtils';
import { computeSpeed, computeSpeedForHunt } from '@gamepark/the-hunger/utils/GameUtils';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { canMove } from '@gamepark/the-hunger/utils/MoveUtils';
import { usePlayerId } from '@gamepark/react-client';

export type BoardButtonProps = {
  selected: boolean;
  playerCount: number;
  player: Player;
  track: number[][][];
  round: number;
  tavernSize: number;
  activePlayer?: Vampire;
  onButtonClick: (e: any) => void;
} & HTMLAttributes<HTMLDivElement>;

const BoardButton: FC<BoardButtonProps> = (props) => {
  const { selected, playerCount, player, track, round, activePlayer, tavernSize, onButtonClick, ...rest } = props;

  const playerId = usePlayerId();
  const { t } = useTranslation();
  const speedForMove = computeSpeed(player, GameDeck);
  const speedForHunt = computeSpeedForHunt(player, GameDeck);
  const itsMe = playerId && activePlayer === playerId;
  const canPlayerHuntOrMove =
    itsMe &&
    (speedForHunt || speedForMove) &&
    (canHunt(player, speedForHunt, round, track, tavernSize, GameDeck, BoardBoxes) ||
      canMove(player, GameDeck, BoardBoxes, activePlayer, speedForMove));

  return (
    <>
      <span css={label(playerCount)} onClick={onButtonClick}>
        {t('world')}
      </span>
      <div
        css={[boardButton(playerCount), selected && selectedView, canPlayerHuntOrMove && highlightButton]}
        onClick={onButtonClick}
        {...rest}
      />
    </>
  );
};

const selectedView = css`
  box-shadow: 0 0 0 0.5em gold, 0 0 0 0.5em gold;
`;

const highlightButtonAnimation = keyframes`
  50% {
    box-shadow: 0 0 0.5em 0.8em white;
    filter: brightness(130%);
  }

`;

const highlightButton = css`
  animation: 2s ${highlightButtonAnimation} ease-in-out infinite;
`;

const label = (playerCount: number) => css`
  position: absolute;
  top: ${boardButtonLabelTop(playerCount)}%;
  left: ${boardButtonLeft(playerCount) + (50 / 100) * boardButtonWidth(playerCount)}%;
  transform: translateX(-50%);
  ${playerCount < 6 ? `z-index: 2;` : ''};
  padding: 0.3em;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  background-color: rgb(187, 187, 187);
  color: black;
  border: 0.1em solid black;
  text-transform: uppercase;
  cursor: pointer;
`;

const boardButton = (playerCount: number) => css`
  position: absolute;
  height: ${boardButtonHeight(playerCount)}%;
  width: ${boardButtonWidth(playerCount)}%;
  top: ${boardButtonTop(playerCount)}%;
  left: ${boardButtonLeft(playerCount)}%;
  background-image: url(${Images.BoardButton});
  background-size: 100% 100%;
  border-radius: 50%;
  border: 0.01em solid white;
  cursor: pointer;
  ${playerCount < 6 ? `z-index: 1;` : ''};
`;

export { BoardButton };
