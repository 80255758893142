import MoveType from './MoveType';
import GameState from '../GameState';
import { NewRoundPhase } from '../round/NewRoundPhase';
import GameView from '../GameView';
import { Position } from '../player';
import { isOverLastTurn } from '../utils/TurnUtils';
import { hasParasol } from '../utils/GameUtils';
import { Bonus } from '../bonus/Bonus';
import MoveView from './MoveView';
import Move from './Move';

export type NewRound = {
  type: MoveType.NewRound
}

export const newRoundMove: NewRound = {
  type: MoveType.NewRound
}

export const isNewRound = (move: Move | MoveView): move is NewRound => move.type === MoveType.NewRound

export const newRound = (state: GameState | GameView, bonused: Bonus[]) => {

  state.round++;

  if (isOverLastTurn(state.round)) {
    state.turnOrder = [...state.players.filter(p => hasParasol(p, bonused))]
      .sort((a, b) => turnOrderSort(a.position, b.position))
      .map(p => p.vampire);
  } else {
    state.turnOrder = [...state.players]
      .sort((a, b) => turnOrderSort(a.position, b.position))
      .map(p => p.vampire);
  }

  state.activePlayer = state.turnOrder[0];
  state.players.forEach(p => delete p.end);
  state.newRoundPhase = NewRoundPhase.FlipVampires

}

const zPositionCompare = (a: Position, b: Position) => b.z - a.z;

const turnOrderSort = (a: Position, b: Position) => {
  // In case vampires on same region and same path, the closest to the labyrinth start before
  const difference = b.box - a.box;

  if (difference) {
    return difference;
  }

  // If vampires are exactly on the same position, ordered by zPosition
  return zPositionCompare(a, b);
}
