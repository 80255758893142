import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { computeEffectsAndAddToPending, constructEffect } from '../utils/GameUtils';
import { Mission } from '../mission';
import { Card } from '../card/Card';
import { isSpeedBonus } from '../utils/EffectUtils';
import { SpeedBonusEffect } from '../effect/SpeedBonusEffect';
import Move from './Move';

export type ActivateMission = {
  type: MoveType.ActivateMission;
  mission: number;
};

export const activateMissionMove = (mission: number): ActivateMission => ({
  type: MoveType.ActivateMission,
  mission,
});

export const isActivateMission = (move: Move): move is ActivateMission => move.type === MoveType.ActivateMission;

export const activateMission = (
  state: GameState | GameView,
  move: ActivateMission,
  deck: Card[],
  missions: Mission[]
) => {
  const player = state.players.find((p) => p.vampire === state.activePlayer)!;
  let effects = missions[move.mission].effects;
  if (effects && effects.length) {
    const firstEffect = effects[0];

    // For now, it is not possible to pass the state to compute speed bonus function since its not always set
    // We must calculate it specifically
    if (isSpeedBonus(firstEffect)) {
      if (firstEffect.perPlayerCloserToCastle) {
        const sortedPlayed = state.players
          .filter((p) => p.position.box !== player.position.box && player.vampire !== p.vampire)
          .sort((p1, p2) => p1.position.box - p2.position.box);

        const speedBonus = firstEffect.speedBonus * sortedPlayed.length;
        if (!player.remainingSpeed) {
          player.pendingEffects.unshift(constructEffect(new SpeedBonusEffect(speedBonus), { mission: move.mission }));
        } else {
          player.remainingSpeed += speedBonus;
        }
      }
    } else {
      computeEffectsAndAddToPending(effects!, { mission: move.mission }, state, player, [], deck);
    }

    player.playedMissions.push(move.mission);
  }
};
