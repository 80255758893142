/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { spicySymbolHeight, spicySymbolWidth } from '../utils/Style';
import { Images } from '../images/Images';

type SpicySymbolProps = {
  width?: number
} & HTMLAttributes<HTMLDivElement>;

const SpicySymbol: FC<SpicySymbolProps> = ({ width, ...props }) => {
  return (
    <span css={mini(width)} {...props} />
  );
};

const mini = (width?: number) => css`
  height: ${spicySymbolHeight(width)}em;
  width: ${width || spicySymbolWidth}em;
  background-image: url(${Images.SpicySymbol});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  top: 0.65em;
  position: relative;
  transform: translateY(-50%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
`;

export {
  SpicySymbol
};
