import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { BonusTokenVictoryPoints } from '../utils/GameConstants';
import { BoardBoxEffect } from '../board';
import { constructEffect } from '../utils/GameUtils';
import { VictoryPointEffect } from '../effect/VictoryPointEffect';
import Move from './Move';
import MoveView from './MoveView';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { isVictoryPoint, isWinBonusToken } from '../utils/EffectUtils';
import { PlayerType } from '../player';
import { Bonus } from '../bonus/Bonus';
import { BonusTokenType } from '../bonus/BonusTokenType';
import Vampire from '../player/Vampire';

export type AcquireBonusToken = {
  type: MoveType.AcquireBonusToken,
  vampire?: Vampire
  position?: number,
  bonus?: number
};

export type AcquireBonusTokenView = AcquireBonusToken & { bonus: number };


export const acquireBonusTokenMove = (vampire: Vampire, position?: number, bonus?: number): AcquireBonusToken => {
  if (bonus !== undefined) {
    return ({
      type: MoveType.AcquireBonusToken,
      vampire,
      bonus
    })
  }

  return ({
    type: MoveType.AcquireBonusToken,
    vampire,
    position
  });
};

export const isAcquireBonus = (move: Move | MoveView): move is AcquireBonusToken => move.type === MoveType.AcquireBonusToken
export const isAcquireBonusView = (move: Move | MoveView): move is AcquireBonusTokenView => move.type === MoveType.AcquireBonusToken && (move as AcquireBonusTokenView).bonus !== undefined;

export const acquireBonusToken = (state: GameState, move: AcquireBonusToken, bonuses: Bonus[]) => {
  const player = state.players.find(p => p.vampire === (move.vampire || state.activePlayer))!;

  if (move.position !== undefined) {
    const token = state.bonusTokens.find(b => b.position === move.position)!;
    player.bonusTokens.push(token.bonus);
    applyAcquireBonusToken(state, player, move.position, bonuses[token.bonus]);
  } else if (move.bonus !== undefined) {
    applyTransformMultifactionToken(player, move, bonuses);
  }
};

export const acquireBonusTokenView = (state: GameView, move: AcquireBonusTokenView, bonuses: Bonus[]) => {
  const player = state.players.find(p => p.vampire === (move.vampire || state.activePlayer))!;

  if (move.position !== undefined) {
    player.bonusTokens.push(move.bonus);
    applyAcquireBonusToken(state, player, move.position, bonuses[move.bonus]);
  } else if (move.bonus !== undefined) {
    applyTransformMultifactionToken(player, move, bonuses);
  }
};

const applyTransformMultifactionToken = (player: PlayerType, move: AcquireBonusToken | AcquireBonusTokenView, bonuses: Bonus[]) => {
  const multifaction = player.bonusTokens.findIndex(b => bonuses[b].type === BonusTokenType.MultiFaction);
  if (multifaction < 0) {
    throw Error("Acquiring a bonus token directly can only be done with multi faction");
  } else {
    player.bonusTokens = player.bonusTokens.map((b, index) => index === multifaction? move.bonus!: b);
  }
}

const applyAcquireBonusToken = (state: GameState | GameView, player: PlayerType, position: number, bonus: Bonus) => {
  state.bonusTokens = state.bonusTokens.filter(b => b.position !== position);

  const pendingEffect = getNextPendingEffect(player);
  if (isWinBonusToken(pendingEffect)) {
    removeNextPendingEffect(player);
  }

  // In case bonus is a velvet, there must be 4 VP instead of only 2
  let victoryPointBonus = 0;
  if (bonus && bonus.effects?.length &&  isVictoryPoint(bonus.effects![0])) {
    const effect = bonus.effects![0] as VictoryPointEffect;
    victoryPointBonus = effect.victoryPoint;
  }

  player.pendingEffects.unshift(constructEffect(new VictoryPointEffect(BonusTokenVictoryPoints + victoryPointBonus)));

  if (player.boardEffect && player.boardEffect === BoardBoxEffect.Chest) {
    delete player.boardEffect;
  }
}

export const getAcquireBonusTokenView = (state: GameState, move: AcquireBonusToken): AcquireBonusTokenView => {
  return {
    ...move,
    bonus: (move.bonus ?? state.bonusTokens.find(b => b.position === move.position)!.bonus)
  };

};

