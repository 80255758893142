/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { Mission, MissionType } from '@gamepark/the-hunger/mission';
import { Images } from '../../images/Images';
import { MissionTitles } from './MissionTitles';
import { useTranslation } from 'react-i18next';
import { MissionDescription } from './MissionDescription';
import { missionDescriptionWidth } from '../../utils/Style';
import { VictoryPointSymbol } from '../../symbols';

type MissionTileProps = {
  preTransform?: string,
  mission?: Mission
  missionIndex?: number,
  backCss?: any,
  frontCss?: any,
  score?: number
} & HTMLAttributes<HTMLDivElement>;

const MissionTile: FC<MissionTileProps> = ({ mission, missionIndex, score, preTransform, backCss, frontCss, ...props }) => {
  const { t }  = useTranslation();
  return (
    <div css={[style(preTransform, backCss), !mission && hidden(preTransform)]} {...props} >
      { mission && <div css={[frontFace, backgroundImage(mission?.type), frontCss]}>
        <div css={ missionTitle }>{ MissionTitles.get(mission)!(t) }</div>
        <div css={ missionDescription }>{ MissionDescription.get(mission)!(t) }</div>
        { score !== undefined && mission.type === MissionType.Beige && <VictoryPointSymbol value={`${score}`} css={ [missionScore ] } /> }
      </div> }
    </div>
  );
};

const style = (preTransform?: string, backCss?: any) => css`
  transform-style: preserve-3d;
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 0.5em;
  transform: ${preTransform};
  -webkit-tap-highlight-color: transparent;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${Images.MissionBack});
    image-rendering: -webkit-optimize-contrast;
    background-size: 100%;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    ${backCss}
  }
`;

const missionTitle = css`
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  padding: 4.5% 13% 0 13%;
  white-space: nowrap;
  left: 50%;
  height: 25%;
  text-align: center;
  font-size: 10em;
  font-weight: bold;
  background-image: url(${Images.MissionTitle});
  background-size: 87% 93.7%;
  background-repeat: no-repeat;
  background-position: center 0.2em;
  -webkit-font-smoothing: subpixel-antialiased;
`

const missionDescription = css`
  position: absolute;
  width: ${missionDescriptionWidth}%;
  top: 62.5%;
  transform: translateY(-50%);
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 3%;
  text-align: center;
  font-size: 9.8em;
  font-weight: bold;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: Arial, serif;
  white-space: pre-wrap;
  line-height: 1.3;
`

const hidden = (preTransform?: string) => css`
  transform: ${preTransform} rotateY(180deg);
`;
const frontFace = css`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-size: cover;
  border-radius: 0.5em;
  color: black;
  transform: translateZ(0);
`;

const missionScore = css`
  position: absolute;
  right: -0.3em;
  top: 1em;
  font-size: 14em;
`

const backgroundImage = (missionType?: MissionType) => css`
  image-rendering: -webkit-optimize-contrast;
  background-image: url(${missionFront.get(missionType!)});
`;

const missionFront = new Map<MissionType, any>();
missionFront.set(MissionType.Beige, Images.MissionBeige);
missionFront.set(MissionType.Gold, Images.MissionGold);


export {
  MissionTile
};
