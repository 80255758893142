import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { computeEffectsAndAddToPending } from '../utils/GameUtils';
import { Card } from '../card/Card';
import { Bonus } from '../bonus/Bonus';

export type ActivateBonus = {
  type: MoveType.ActivateBonus;
  bonus: number;
};

export const activateBonusMove = (bonus: number): ActivateBonus => ({
  type: MoveType.ActivateBonus,
  bonus,
});

export const activateBonus = (state: GameState | GameView, move: ActivateBonus, deck: Card[], bonuses: Bonus[]) => {
  const bonus = bonuses[move.bonus];
  const player = state.players.find((p) => p.vampire === state.activePlayer)!;

  if (player && bonus.effects?.length) {
    player.usedBonuses.push(move.bonus);
    computeEffectsAndAddToPending(bonus.effects, { bonus: move.bonus }, state, player, [], deck);
  }
};
