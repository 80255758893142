import { EffectTrigger } from './EffectTrigger';
import { CardTrait } from '../card/CardTrait';
import { EffectType } from './EffectType';
import { GameEffect } from './GameEffect';
import { PlayerType } from '../player';
import { Card } from '../card/Card';
import { hasTrait } from '../utils/CardUtils';

export class VictoryPointsPerTraitEffect implements GameEffect {
  type: EffectType.PointPerTrait = EffectType.PointPerTrait;
  victoryPoint: number;
  trait: CardTrait;
  trigger?: EffectTrigger;


  constructor(victoryPoint: number, trait: CardTrait, trigger?: EffectTrigger) {
    this.victoryPoint = victoryPoint;
    this.trait = trait;
    this.trigger = trigger;
  }

  computeScore(player: PlayerType, deck: Card[]): number {
    if (this.trigger === EffectTrigger.EndOfGame) {
      return this.victoryPoint * [...player.digest, ...player.discard].filter(c => hasTrait(deck[c], this.trait)).length;
    }

    return 0;
  }
}
