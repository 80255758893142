export enum Path {
  Road = 1,
  Railroad,
  Boat
}

export const PathTurnOrderPriority: { [region in Path]: number } = {
  [Path.Road]: 1,
  [Path.Railroad]: 2,
  [Path.Boat]: 3,
}