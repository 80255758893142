/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { isKnownBonus, PositionedBonus } from '@gamepark/the-hunger/bonus/PositionedBonus';
import { FC, useMemo, useState } from 'react';
import { BonusToken } from '../bonus/BonusToken';
import { isWinBonusToken } from '@gamepark/the-hunger/utils/EffectUtils';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import {
  boxHeight,
  boxWidth,
  getAcquireBonusTokenTranslateX,
  getAcquireBonusTokenTranslateY,
  getTokenTranslate,
} from '../../utils/Style';

import Vampire from '@gamepark/the-hunger/player/Vampire';
import { acquireBonusTokenMove, isAcquireBonusView } from '@gamepark/the-hunger/moves/AcquireBonus';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { FullscreenBonus } from '../bonus/FullscreenBonus';
import { GameEffects } from '@gamepark/the-hunger/effect/GameEffect';
import { getActivePlayerIndex, getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import { PlayerType } from '@gamepark/the-hunger/player';

export type BoardTokensProps = {
  players: Array<PlayerType>;
  activePlayer?: Vampire;
  bonuses: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>;
  selectedVampire?: Vampire;
  pendingEffect?: GameEffects;
  oldTokensCount?: boolean;
};

const BoardTokens: FC<BoardTokensProps> = ({
  players,
  activePlayer,
  bonuses,
  selectedVampire,
  pendingEffect,
  oldTokensCount,
}) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const [selectedBonus, setSelectedBonus] = useState<{ bonus: number; position: number }>();
  const animation = useAnimation(
    (animation) => isAcquireBonusView(animation.move) && animation.move.position !== undefined
  );

  // eslint-disable-next-line
  const realPlayers = useMemo(() => getOrderedPlayersForDisplay(players, playerId), [players]);
  const playerIndex = animation && getActivePlayerIndex(realPlayers, activePlayer);

  const acquireBonusToken = (t: PositionedBonus | Omit<PositionedBonus, 'bonus'>) => {
    if (!isKnownBonus(t) && isWinBonusToken(pendingEffect)) {
      play(acquireBonusTokenMove(playerId, t.position), { delayed: true });
    } else if (isKnownBonus(t)) {
      setSelectedBonus(t);
    }
  };

  const bonusList = Bonuses(oldTokensCount);

  return (
    <>
      {bonuses.map((t, index) => {
        const hasAnimation = animation && animation.move.position === t.position;
        const type = hasAnimation ? bonusList[animation!.move.bonus] : isKnownBonus(t) ? bonusList[t.bonus] : undefined;
        return (
          <BonusToken
            key={`bonus-token-${index}`}
            css={[
              bonusTokenPosition,
              isWinBonusToken(pendingEffect) ? selectable : (!isKnownBonus(t) || selectedVampire) && notPointable,
              hasAnimation && animateDrawBonus(playerIndex!, players.length, animation!.duration),
            ]}
            type={type}
            onClick={() => acquireBonusToken(t)}
            preTransform={`${getTokenTranslate(t.position)} ${
              hasAnimation && !isKnownBonus(t) ? `rotateY(180deg)` : ''
            }`}
          />
        );
      })}
      {selectedBonus !== undefined && (
        <FullscreenBonus
          position={selectedBonus.position}
          bonus={selectedBonus.bonus}
          acquire={isWinBonusToken(pendingEffect)}
          onClose={() => setSelectedBonus(undefined)}
          oldTokensCount={oldTokensCount}
        />
      )}
    </>
  );
};

const animateDrawBonus = (playerIndex: number, playerCount: number, duration: number) => css`
  z-index: 99;
  animation: ${duration}s ${drawBonusAnimation(playerIndex, playerCount)} ease-in-out both;
`;

const drawBonusAnimation = (playerIndex: number, playerCount: number) => keyframes`
  60%, 70% {
      transform: translate(${getAcquireBonusTokenTranslateX - 200}%, ${getAcquireBonusTokenTranslateY(
  playerIndex,
  playerCount
)}%) scale(2);
  }
  to {
    transform: translate(${getAcquireBonusTokenTranslateX}%, ${getAcquireBonusTokenTranslateY(
  playerIndex,
  playerCount
)}%) scale(0);
  }
`;

const bonusTokenPosition = css`
  position: absolute;
  width: ${boxWidth}%;
  height: ${boxHeight}%;
  cursor: pointer;
`;

const selectable = css`
  box-shadow: 0 0 0.1em 0.3em gold, 0 0 0.1em 0.3em gold;
  cursor: pointer;
`;

const notPointable = css`
  cursor: default;
  pointer-events: none;
`;

export { BoardTokens };
