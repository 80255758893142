import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { Card } from '../card/Card';
import Move from './Move';
import { isVictoryPoint } from '../utils/EffectUtils';

export type VictoryPoint = {
  type: MoveType.VictoryPoint
  victoryPoint: number,
  card?: number
};

export const isVictoryPointMove = (move: Move): move is VictoryPoint => move.type === MoveType.VictoryPoint;

export const victoryPointMove = (victoryPoint: number, card?: number): VictoryPoint => {
  if (!card) {
    return {
      type: MoveType.VictoryPoint,
      victoryPoint
    }
  }

  return {
    type: MoveType.VictoryPoint,
    victoryPoint,
    card
  }
};

export const victoryPoint = (state: GameState | GameView, move: VictoryPoint, deck: Card[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer);
  if (player) {
    player.score += move.victoryPoint;

    // If player win victory point and the pending effect is a victory point, removing it
    const firstIndex = player.pendingEffects?.findIndex(p => !p.optional);
    if (firstIndex > -1 && isVictoryPoint(player.pendingEffects[firstIndex])) {
      player.pendingEffects = player.pendingEffects.filter((_, index) => index !== firstIndex);
    }

    if (move.card) {
      const victoryPointEffects = deck[move.card].passiveEffects?.filter(e => isVictoryPoint(e));
      // For now there is no case where there is more than one victory point effect on card.
      if (victoryPointEffects?.length && victoryPointEffects[0]?.once) {
        player.playedCards.push(move.card)
      }
    }
  }

}
