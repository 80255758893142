import { GameEffect, GameEffects } from './GameEffect';
import { EffectType } from './EffectType';
import Move from '../moves/Move';
import GameState from '../GameState';
import { moveHuntTrackCardMove, moveHuntTrackDirections } from '../moves/MoveHuntTrackCard';
import GameView from '../GameView';
import { PlayerType } from '../player';
import { canMoveCardTo } from '../utils/GameUtils';

export class HuntTrackMoveEffect implements GameEffect {
  type: EffectType.HuntTrack = EffectType.HuntTrack;
  mandatory?: boolean;


  constructor(options?: Partial<HuntTrackMoveEffect>) {
    if (options) {
      this.mandatory = options.mandatory;
    }
  }

  legalMoves(state: GameState): Move[] {
    return state.hunt.track
      .flatMap((line, lineIndex) =>
        line.flatMap((area, areaIndex) =>
          area.flatMap(c => moveHuntTrackDirections
            .filter(direction => canMoveCardTo(lineIndex, areaIndex, state.players.length, direction))
            .map(d => moveHuntTrackCardMove(d, c))
          )
        )
      );
  }

  computePendingEffects(state: GameView | GameState, _player: PlayerType): Array<GameEffects> {
    if (!state.hunt.track.flat().some(area => area.length)) {
      return [];
    }

    return [this];
  }
}
