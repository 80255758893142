/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { cardRatio, catalogSwipeAreaHeight, catalogTop, screenRatio } from '../../utils/Style';
import { useTranslation } from 'react-i18next';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { HuntCardTitles } from './HuntCardTitles';
import { Card } from '@gamepark/the-hunger/card/Card';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TraitHelp } from './TraitHelp';

export type HuntCardHelpProps = {
  hunt: number;
  ignoreTopComponentArea: boolean;
  hasTopComponents: boolean;
};

const HuntCardHelp: FC<HuntCardHelpProps> = ({ hunt, ignoreTopComponentArea, hasTopComponents }) => {
  const { t } = useTranslation();
  const topStyle = `calc(${catalogTop(ignoreTopComponentArea, hasTopComponents)}% + 1.1em) !important`;

  const [isVisible, setVisible] = useState(false);
  const speed = GameDeck[hunt].speed !== undefined;
  const victoryPoints = GameDeck[hunt].victoryPoints !== undefined;
  const hasEffect: boolean = !!GameDeck[hunt].effects?.length || !!GameDeck[hunt].passiveEffects?.length;
  return (
    <>
      <div onClick={() => setVisible((visible) => !visible)} css={[button, helpButton(topStyle)]}>
        <FontAwesomeIcon icon={faQuestion} />
        {!isVisible ? t('help.open') : t('help.close')}
      </div>
      <div css={[huntCardContainer, cardHelp(topStyle), !isVisible && hidden]}>
        {speed && <div css={[button, speedHelp]}>{t('help.card.speed')}</div>}
        {victoryPoints && <div css={[button, vpHelp]}>{t('help.card.victorypoints')}</div>}
        {victoryPoints && <div css={[button, vpMiniHelp]}>{t('help.card.victorypoints')}</div>}
        <div css={[button, typeHelp]}>{t('help.card.type')}</div>
        <div css={[button, nameHelp(GameDeck[hunt])]}>{t('help.card.name')}</div>
        {hasEffect && <div css={[button, effectHelp]}>{t('help.card.effect')}</div>}
      </div>
      <TraitHelp hunt={hunt} css={traitHelpStyle(hasTopComponents && !ignoreTopComponentArea)} />
    </>
  );
};

const traitHelpStyle = (hasTop?: boolean) => css`
  position: absolute;
  padding: 0 1.3em;
  bottom: ${hasTop ? '7.8' : '11.8'}%;
  right: 65%;
  z-index: 100;
`;

const cardHelpHeight = 93 * (catalogSwipeAreaHeight / 100);
const cardHelp = (top: string) => css`
  left: 50%;
  transform: translateX(-50%);
  height: ${cardHelpHeight}%;
  font-size: ${cardHelpHeight / 100}em;
  width: ${(cardHelpHeight * cardRatio) / screenRatio}%;
  ${topStyle(top)}
`;

const huntCardContainer = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 85%;
  width: ${(85 * cardRatio) / screenRatio}%;
  z-index: 110;
`;

const helpButton = (top: string) => css`
  transform: translateY(-25%);
  font-size: 2.3em;
  left: ${51.5 + (cardHelpHeight * cardRatio) / screenRatio / 2}%;
  z-index: 110;
  text-transform: capitalize;
  ${topStyle(top)}

  & svg {
    margin-right: 0.3em;
  }
`;

const topStyle = (top: string) => css`
  top: ${top};
`;

const button = css`
  font-size: 3.2em;
  font-weight: bold;
  color: black;
  background: rgba(255, 255, 255);
  border: 0.2em solid black;
  border-radius: 1.5em;
  padding: 0.5em 0.7em;
  position: absolute;
  cursor: pointer;
  white-space: nowrap;
`;

const speedHelp = css`
  right: 105%;
  top: -10%;

  &:after {
    content: '';
    height: 0.2em;
    width: 5.2em;
    position: absolute;
    top: 50%;
    left: calc(100% + 0.2em);
    background-color: black;
    transform-origin: left top;
    transform: rotateZ(55deg);
  }
`;

const vpHelp = css`
  left: 105%;
  top: -10%;

  &:before {
    content: '';
    height: 0.2em;
    width: 5.6em;
    position: absolute;
    top: 50%;
    right: calc(100% + 0.2em);
    background-color: black;
    transform-origin: right top;
    transform: rotateZ(-55deg);
  }
`;

const vpMiniHelp = css`
  right: 105%;
  top: 51%;

  &:after {
    content: '';
    height: 0.2em;
    position: absolute;
    top: 50%;
    left: calc(100% + 0.2em);
    background-color: black;
    transform-origin: left top;
    width: 6.5em;
    transform: rotateZ(60deg);
  }
`;

const typeHelp = css`
  left: 105%;
  top: 85.5%;

  &:before {
    content: '';
    height: 0.2em;
    width: 2.3em;
    position: absolute;
    top: 50%;
    right: calc(100% + 0.2em);
    background-color: black;
    transform-origin: right top;
  }
`;

const nameHelp = (hunt: Card) => css`
  left: 50%;
  bottom: ${HuntCardTitles.get(hunt)!.bottom}%;
  transform: translate(-50%, -100%);

  &:after {
    content: '';
    height: 0.2em;
    width: 2.1em;
    position: absolute;
    top: 100.5%;
    left: calc(50% + 0.2em);
    background-color: black;
    transform-origin: left top;
    transform: rotateZ(90deg);
  }
`;

const effectHelp = css`
  top: 101.5%;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: '';
    height: 0.2em;
    width: 1em;
    position: absolute;
    top: -0.5%;
    right: calc(50% - 0.2em);
    background-color: black;
    transform-origin: right top;
    transform: rotateZ(90deg);
  }
`;

const hidden = css`
  display: none;
`;

export { HuntCardHelp };
