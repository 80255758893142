/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { HuntCardsCatalog } from '../card/HuntCardsCatalog';
import { usePlay } from '@gamepark/react-client';
import { placeInDiscardMove } from '@gamepark/the-hunger/moves/PlaceInDiscard';
import { placeOnDeckMove } from '@gamepark/the-hunger/moves/PlaceOnDeck';
import { TheHungerButton } from '../../utils/TheHungerButton';

type ReadyChoiceProps = {
  card: number
}

const ReadyChoice: FC<ReadyChoiceProps> = ({ card }) => {
  const play = usePlay();

  const placeOnDeck = () => {
    play(placeOnDeckMove([card]));
  }

  const placeInDiscard = () => {
    play(placeInDiscardMove([card]));
  }


  return (
    <HuntCardsCatalog
        hunts={[card]}
        ignoreTopComponentArea={true}
        topComponents={[
          <TheHungerButton key={ `deck` } onClick={ placeOnDeck } css={ placeOnDeckButton } labelKey="button.deck" />,
          <TheHungerButton key={ `discard` } onClick={ placeInDiscard } css={ placeInDiscardButton } labelKey="button.discard" />
        ]}
    />
  )
}

const placeInDiscardButton = css`
  top: 46%;
  left: 70%;
  font-size: 5em;
`;

const placeOnDeckButton = css`
  top: 46%;
  right: 70%; 
  font-size: 5em;
`;

export {
  ReadyChoice
}
