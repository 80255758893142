/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import GameView from '@gamepark/the-hunger/GameView';
import { Letterbox } from '@gamepark/react-components';
import { HuntTrack } from './material/hunt-track/HuntTrack';
import { GameBoard } from './material/board/GameBoard';
import { useAnimation, usePlay, usePlayerId, useTutorial } from '@gamepark/react-client';
import { PlayerBoard } from './material/player/PlayerBoard';
import { PlayingArea } from './material/player/PlayingArea';
import { PlayerHand } from './material/player/PlayerHand';
import { isMyPlayerView, isPlayer } from '@gamepark/the-hunger/player';
import { useCallback, useMemo, useState } from 'react';
import { ViewType } from './ViewType';
import { PlayerPanels } from './material/player/PlayerPanels';
import { HuntDeck } from './material/deck/HuntDeck';
import { gameDisplayTop } from './utils/Style';
import { TurnCounter } from './material/turn/TurnCounter';
import { PlayerMissions } from './material/player/PlayerMissions';
import { MissionChoice } from './material/player/MissionChoice';
import { isPushEffect, isReadyEffect } from '@gamepark/the-hunger/utils/EffectUtils';
import { ReadyChoice } from './material/player/ReadyChoice';
import { isOver } from '@gamepark/the-hunger/utils/IsOver';
import { isVisibleStack } from '@gamepark/the-hunger/mission';
import { DrawMissionsView, isDrawMission } from '@gamepark/the-hunger/moves/DrawMissions';
import { isChooseMissionMove } from '@gamepark/the-hunger/moves/ChooseMission';
import { getActivePlayerIndex, getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import { ViewChanger } from './ViewChanger';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { BoardButton } from './material/board/BoardButton';
import { PlayerEffects } from './material/player/effects/PlayerEffects';
import {
  GameDisplaySwitchTransition,
  SecondViewEnabled,
  StartupBeigeMissionCount,
} from '@gamepark/the-hunger/utils/GameConstants';
import { BoxHelp } from './help/BoxHelp';
import { MultiFactionChooser } from './material/bonus/MultiFactionChooser';
import { hasMultifactionToken } from '@gamepark/the-hunger/utils/BonusUtils';
import { getRanking, hasParasol } from '@gamepark/the-hunger/utils/GameUtils';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { getNextPendingEffect } from '@gamepark/the-hunger/utils/PendingUtils';
import { displayViewMove } from './moves/DisplayView';
import TutorialPopup from './tutorial/TutorialPopUp';
import { ScoreDetail } from './material/player/score';
import { displayScoreMove } from './moves/DisplayScore';

type Props = {
  game: GameView;
};

export default function GameDisplay({ game }: Props) {
  const currentView = game.displayedView || ViewType.Boards;
  const [isPlayerPanelsInBackground, setPlayerPanelsInBackground] = useState(false);

  const tutorial = useTutorial();
  const playerId = usePlayerId();
  const play = usePlay();
  const bonusList = Bonuses(game.oldTokensCount);
  const player = game.players.find((p) => p.vampire === playerId);
  const displayedVampire = game.displayedPlayer || playerId || game.players[0].vampire;
  const displayedPlayer = game.players.find((p) => p.vampire === displayedVampire)!;
  const actualPlayer = (playerId && game.players.find((p) => p.vampire === playerId)!) || game.players[0];
  const isGameOver = isOver(game.players, game.round, game.turnOrder, bonusList);
  const isFinished = isGameOver && !game.players.some((p) => hasMultifactionToken(p, bonusList));
  const multifactionToken = actualPlayer && isGameOver && hasMultifactionToken(actualPlayer, bonusList);
  const pendingEffect = getNextPendingEffect(displayedPlayer);

  const drawMissionAnimation = useAnimation<DrawMissionsView>(
    (animation) => isDrawMission(animation.move) && displayedPlayer.vampire === playerId
  );
  const chooseMissionAnimation = useAnimation<DrawMissionsView>((animation) => isChooseMissionMove(animation.move));
  const missionToDraw =
    !player || !isMyPlayerView(player) || chooseMissionAnimation || isPushEffect(pendingEffect)
      ? []
      : drawMissionAnimation
      ? drawMissionAnimation.move.missions
      : player.missionChoice.missions;

  const isInvisibleView = (view: ViewType) => currentView !== view && invisible;
  const onDragArea = useCallback(() => setPlayerPanelsInBackground(true), []);
  const onDropArea = useCallback(() => setPlayerPanelsInBackground(false), []);
  const closeScore = () => play(displayScoreMove(), { local: true });

  const setCurrentView = (viewType: ViewType) => {
    play(displayViewMove(viewType), { local: true });
  };

  // eslint-disable-next-line
  const sortedPlayers = useMemo(() => getOrderedPlayersForDisplay(game.players, playerId), [game.players]);
  return (
    <Letterbox css={letterBoxStyle} top={0} id="letterbox">
      <div css={gameDisplay(currentView, isPlayerPanelsInBackground)}>
        <GameBoard
          selectedVampire={game.selectedVampire}
          activePlayer={game.activePlayer}
          players={game.players}
          bonusTokens={game.bonusTokens}
          castleTokens={game.castleTokens}
          missions={game.missions}
          roses={game.roses}
          tavern={game.tavern}
          isGameOver={isFinished}
          gameMode={game.mode}
          visible={currentView === ViewType.Boards}
          oldTokensCount={game.oldTokensCount}
        />
        <HuntTrack
          player={actualPlayer}
          playerCount={game.players.length}
          hunt={game.hunt}
          css={[visible, isInvisibleView(ViewType.Boards)]}
          onDragArea={onDragArea}
          onDropArea={onDropArea}
          activePlayer={game.activePlayer}
          activePlayerIndex={getActivePlayerIndex(sortedPlayers, game.activePlayer)}
          round={game.round}
          isGameOver={isFinished}
        />
        <HuntDeck
          hunts={game.hunt.deck}
          players={game.players}
          activePlayer={game.activePlayer}
          tavernSize={game.tavern}
          css={[visible]}
          invisible={isInvisibleView(ViewType.Boards)}
          cardLimit={5}
        />
        {!!game.activePlayer && (
          <PlayerEffects
            activePlayer={game.activePlayer}
            players={game.players}
            isGameOver={isFinished}
            track={game.hunt.track}
            bonuses={game.bonusTokens}
            css={[visible, isInvisibleView(ViewType.Boards)]}
          />
        )}
        <PlayingArea
          player={displayedPlayer}
          activePlayer={game.activePlayer}
          css={[visible, isInvisibleView(ViewType.Player)]}
          discardStackSize={displayedPlayer.discard.length}
          isGameOver={isFinished}
        />
        <PlayerBoard
          player={displayedPlayer}
          activePlayer={game.activePlayer}
          css={[visible, isInvisibleView(ViewType.Player)]}
          isGameOver={isFinished}
          oldTokensCount={game.oldTokensCount}
        />
        <PlayerMissions
          missions={displayedPlayer.missions}
          playedMissions={displayedPlayer.playedMissions}
          css={[visible, isInvisibleView(ViewType.Player)]}
          track={game.hunt.track}
          isGameOver={isFinished}
          activePlayer={game.activePlayer}
          player={displayedPlayer}
          bonuses={game.bonusTokens}
        />
        <PlayerHand
          hand={displayedPlayer.hand}
          activePlayer={game.activePlayer}
          displayedPlayer={displayedVampire}
          playingAreaLength={displayedPlayer.playingArea.length}
          deckLength={!isPlayer(displayedPlayer) ? displayedPlayer.deck : displayedPlayer.deck.length}
          discardLength={displayedPlayer.discard.length}
          visible={currentView === ViewType.Player}
          css={[visible, isInvisibleView(ViewType.Player)]}
        />
        {!!player && isMyPlayerView(player) && !!missionToDraw?.length && (
          <MissionChoice
            vampire={player.vampire}
            playerMissions={
              game.mode === GameMode.Rookie
                ? []
                : player.missions.filter((m) => !player.playedMissions.includes(m.mission))
            }
            missions={missionToDraw}
          />
        )}
        {!!displayedPlayer && isMyPlayerView(displayedPlayer) && isReadyEffect(pendingEffect) && (
          <ReadyChoice card={pendingEffect.card} />
        )}
      </div>
      <BoardButton
        selected={currentView === ViewType.Boards}
        onButtonClick={() => setCurrentView(ViewType.Boards)}
        playerCount={game.players.length}
        player={actualPlayer}
        activePlayer={currentView && currentView !== ViewType.Boards ? game.activePlayer : undefined}
        track={game.hunt.track}
        tavernSize={game.tavern}
        round={game.round}
      />
      <TurnCounter
        round={game.round}
        playerCount={game.players.length}
        playerHasParasol={game.players.some((p) => hasParasol(p, bonusList))}
      />
      <PlayerPanels
        players={game.players}
        activePlayer={game.activePlayer}
        displayedVampire={displayedVampire}
        displayedScore={game.displayedScore}
        turnOrder={game.turnOrder}
        track={game.hunt.track}
        tavern={game.tavern}
        round={game.round}
        gameMode={game.mode}
        view={currentView}
        boardMissions={game.missions.filter((m) => isVisibleStack(m)).flat()}
        ranking={
          isGameOver
            ? getRanking(game.players, game.round, game.mode, BoardBoxes, bonusList).map((p) => p.vampire)
            : undefined
        }
        onVampireClick={() => setCurrentView(ViewType.Player)}
        oldTokensCount={game.oldTokensCount}
      />
      <BoxHelp box={game.hightlightedBox} mode={game.mode} />
      {multifactionToken && <MultiFactionChooser player={actualPlayer} oldTokensCount={game.oldTokensCount} />}
      <ViewChanger game={game} onChangeView={setCurrentView} />
      {tutorial && <TutorialPopup game={game} tutorial={tutorial} />}
      {isGameOver && (
        <ScoreDetail
          vampire={game.displayedScore}
          players={sortedPlayers}
          publicMissions={game.missions.slice(0, StartupBeigeMissionCount) as number[]}
          visible
          onClose={closeScore}
          round={game.round}
          mode={game.mode}
          ranking={getRanking(game.players, game.round, game.mode, BoardBoxes, bonusList).map((p) => p.vampire)}
          oldTokensCount={game.oldTokensCount}
        />
      )}
    </Letterbox>
  );
}

const gameDisplay = (currentView: ViewType, panelsInBackground: boolean) => css`
  position: absolute;
  height: 100%;
  top: ${gameDisplayTop}%;
  width: 100%;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  ${SecondViewEnabled ? `transform: translateX(${currentView === ViewType.Boards ? 0 : -100}%);` : ''}
  ${SecondViewEnabled ? `z-index: 1;` : ''}
  pointer-events: ${panelsInBackground ? 'none' : 'all'};
`;

const invisible = css`
  opacity: 0 !important;
  pointer-events: none;
`;

const visible = css`
  transition-duration: ${GameDisplaySwitchTransition}s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  opacity: 1;
`;

const fadeIn = keyframes`
  from, 50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const letterBoxStyle = css`
  animation: ${fadeIn} 3s ease-in forwards;
`;
