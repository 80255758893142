/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal } from '../../../modal/Modal';
import { PlayerType } from '@gamepark/the-hunger/player';
import { CSSProperties, FC } from 'react';
import { BrutPointScore } from './components/BrutPointScore';
import { PublicMissionScore } from './components/PublicMissionScore';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { PersonalMissionsScore } from './components/PersonalMissionsScore';
import { CardsScore } from './components/CardsScore';
import Scrollbars from 'react-custom-scrollbars-2';
import { MalusScore } from './components/MalusScore';
import { TotalScore } from './components/TotalScore';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { PlayerToken } from '../PlayerToken';
import { getPlayerName } from '@gamepark/the-hunger/TheHungerOptions';
import { usePlayer } from '@gamepark/react-client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type ScoreDetailProps = {
  publicMissions: number[];
  vampire?: Vampire;
  players: PlayerType[];
  visible: boolean;
  onClose: () => void;
  round: number;
  mode: GameMode;
  ranking: Array<Vampire>;
  oldTokensCount?: boolean;
};

const ScoreDetail: FC<ScoreDetailProps> = (props) => {
  const { publicMissions, visible, onClose, players, vampire, round, mode, ranking, oldTokensCount } = props;
  const { t } = useTranslation();
  const playerInfos = usePlayer(vampire);

  if (!vampire) {
    return <Modal key="score-detail-modal" css={invisibleModal} onClose={onClose} portal={false} />;
  }

  const player = players.find((v) => vampire === v.vampire)!;

  const title = (
    <div css={header}>
      <PlayerToken vampire={player.vampire} css={playerTokenStyle} />
      <h4>{t('scoring.header', { player: playerInfos?.name || getPlayerName(vampire, t) })}</h4>
    </div>
  );

  return (
    <Modal
      key="score-detail-modal"
      modalTitle={title}
      css={[modalStyle, visible ? visibleModal : invisibleModal]}
      onClose={onClose}
      portal={false}
    >
      <div css={closeModal} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div css={scrollbarContainer} key={player.vampire}>
        <Scrollbars
          hideTracksWhenNotNeeded
          renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, ...scrollTrackVertical }} />}
        >
          <div css={scoreModalDetailed}>
            <BrutPointScore
              player={player}
              players={players}
              publicMissions={publicMissions}
              mode={mode}
              oldTokensCount={oldTokensCount}
            />
            <PublicMissionScore
              player={player}
              players={players}
              missions={publicMissions}
              oldTokensCount={oldTokensCount}
            />
            <PersonalMissionsScore player={player} />
            <CardsScore player={player} oldTokensCount={oldTokensCount} />
            <MalusScore player={player} mode={mode} />
            <TotalScore
              player={player}
              round={round}
              mode={mode}
              rank={ranking.findIndex((v) => v === player.vampire)}
              oldTokensCount={oldTokensCount}
            />
          </div>
        </Scrollbars>
      </div>
    </Modal>
  );
};

const modalStyle = css`
  > div:first-of-type {
    font-size: 6em;
  }
`;

const closeModal = css`
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  padding: 0.3em 0.5em;
  font-size: 7em;
  color: lightgoldenrodyellow !important;
  cursor: pointer;

  &:hover {
    color: grey !important;
  }
`;

const scrollbarContainer = css`
  height: 90%;
  width: 100%;
`;

const scoreModalDetailed = css`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  row-gap: 2em;
  max-height: 90%;
`;

const invisibleModal = css`
  transition: opacity 0.5s;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
`;

const header = css`
  > h4 {
    display: inline;
    position: absolute;
    top: 0;
    margin: 0;
    left: 2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
  }
`;

const playerTokenStyle = css`
  height: 1.2em;
  width: 1.2em;
`;

const visibleModal = css`
  transition: opacity 0.5s;
  height: 90%;
  width: 85%;
  transform: translateX(0);
  left: 0;
  margin-left: 0;
  top: 8em;
`;

const scrollTrackVertical: CSSProperties = {
  width: '2em',
  top: 0,
  bottom: 0,
  right: '1%',
  borderRadius: '3em',
};

export { ScoreDetail };
