/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BoardBoxEffect } from '@gamepark/the-hunger/board';
import { Trans } from 'react-i18next';
import { SpeedSymbol } from '../../symbols';

const BoardEffectDescriptions = new Map<BoardBoxEffect, any>();
BoardEffectDescriptions.set(BoardBoxEffect.Chest, <Trans defaults="board.effect.desc.chest" components={[<strong />]}/>);
BoardEffectDescriptions.set(BoardBoxEffect.Castle, <Trans defaults="board.effect.desc.castle" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Cemetery, <Trans defaults="board.effect.desc.cemetery" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Ship, <Trans defaults="board.effect.desc.ship" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Well, <Trans defaults="board.effect.desc.well" components={[<strong />, <SpeedSymbol css={ css`top: 0.5em;` } light value="1" />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Market, <Trans defaults="board.effect.desc.market" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Mansion, <Trans defaults="board.effect.desc.mansion" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Barracks, <Trans defaults="board.effect.desc.barracks" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Church, <Trans defaults="board.effect.desc.church" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Tavern, <Trans defaults="board.effect.desc.tavern" components={[<strong />, <SpeedSymbol css={ css`top: 0.5em;` } light value="2" />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Labyrinth, <Trans defaults="board.effect.desc.labyrinth" components={[<strong />]} />);
BoardEffectDescriptions.set(BoardBoxEffect.Crypt, <Trans defaults="board.effect.desc.crypt" components={[<strong />]} />);

export {
  BoardEffectDescriptions
}
