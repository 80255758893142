import { BoardBox, BoardBoxEffect, BoardBoxes } from '../board';
import { PlayerType, Position } from '../player';
import Vampire from '../player/Vampire';
import GameState, { isGameState } from '../GameState';
import GameView from '../GameView';
import { computeSpeed, getPlayerHandSize } from './GameUtils';
import {
  getActivatableCards,
  getActiveConfuseEffect,
  hasPlayerSpicyEffect,
  isCastle,
  isPlayerOnLabyrinth,
  isWell,
} from './CardUtils';
import { ConfuseEffect } from '../effect/ConfuseEffect';
import { getNextPendingEffect } from './PendingUtils';
import { hasAnIgnore, hasMandatoryEffect, hasSpicyEffect, isTeleport } from './EffectUtils';
import { Card } from '../card/Card';
import { hasHunted } from './PlayerUtils';

/**
 * Indicate if the box is a labyrinth
 */
export const isTavern = (box: BoardBox) => box.effect === BoardBoxEffect.Tavern;

/**
 * Indicate if vampires on the box must be pushed
 * @param box The board box
 */
export const mayPushVampiresOnBox = (box: BoardBox) =>
  !box.effect || ![BoardBoxEffect.Castle, BoardBoxEffect.Cemetery].includes(box.effect);

/**
 * Get available paths for a given position and a max speed
 * @param maxSpeed The max speed
 * @param player The current user to extract position
 * @param board The game board
 * @param deck
 * @param useIgnore Indicate if the count must ignore card with Ignore Effect
 * @param playersPositions the player position (used for the Ignore effect)
 */
export const getMovablePaths = (
  maxSpeed: number,
  player: PlayerType,
  board: BoardBox[],
  deck: Card[],
  useIgnore?: boolean,
  playersPositions?: Array<Position>
): number[][] => {
  return computeBranch(
    [player.position.box],
    maxSpeed,
    player.position.box,
    board,
    deck,
    useIgnore ? hasAnIgnore(player.playingArea.map((c) => deck[c])) : false,
    playersPositions
  ).map((path) => path.slice(1));
};

/**
 * Compute a branch path
 * @param basePath The base path
 * @param maxSpeed THe player speed
 * @param actualPosition The actual position
 * @param board The game board
 * @param deck
 * @param hasIgnore Indicate if the player has an ignore card (used for the ignore effect)
 * @param playersPositions the player position (used for the Ignore effect)
 */
export const computeBranch = (
  basePath: number[],
  maxSpeed: number,
  actualPosition: number,
  board: BoardBox[],
  deck: Card[],
  hasIgnore?: boolean,
  playersPositions?: Array<Position>
): number[][] => {
  const paths: number[][] = [];
  const branches = board[actualPosition].branches.filter((b) => !basePath.includes(b));

  if (!branches.length || maxSpeed === 0) {
    return [[actualPosition]];
  }

  if (branches.length > 0) {
    branches.forEach((b) => {
      const branch = b;
      let newSpeed = maxSpeed;
      if (hasIgnore) {
        newSpeed = isWell(board[b].effect) || playersPositions?.some((p) => p.box === b) ? newSpeed : newSpeed - 1;
      } else {
        newSpeed = maxSpeed - 1;
      }

      const path = computeBranch([...basePath, branch], newSpeed, branch, board, deck, hasIgnore, playersPositions);

      if (path.length) {
        path.forEach((p) => paths.push([actualPosition, ...p]));
      }
    });
  }

  return paths;
};

export const numberSort = (a: number, b: number) => a - b;

export const getConfusedMovablePath = (
  maxSpeed: number,
  player: PlayerType,
  board: BoardBox[],
  deck: Card[],
  useIgnore?: boolean,
  playersPositions?: Array<Position>
) => {
  const baseMovablePaths = getMovablePaths(maxSpeed, player, board, deck, useIgnore, playersPositions);
  const playerPosition = player.position.box;

  const movablePaths = [];
  for (let path of baseMovablePaths) {
    const exception = 35;
    const exceptionIndex = path.findIndex((p) => p === exception);
    if (
      (!path.some((p) => p < playerPosition) &&
        [...path].slice(-2).sort(numberSort).join() === [...path].slice(-2).join()) ||
      (path[path.length - 1] === exception && !path.filter((p) => p !== exception).some((p) => p < playerPosition))
    ) {
      movablePaths.push(path);
    } else if (
      path.includes(exception) &&
      !path.slice(0, exceptionIndex).some((p) => p < playerPosition) &&
      !path.some((p) => p < exception) &&
      [...path].slice(-2).sort(numberSort).join() === [...path].slice(-2).join() &&
      // Not so beautiful but no better idea for now
      (playerPosition !== 36 || !path.join().startsWith([35, 44].join()))
    ) {
      movablePaths.push(path);
    }
  }

  return movablePaths.map((p) => p.slice(-1));
};

export const getPathsToClosestWell = (
  maxSpeed: number,
  player: PlayerType,
  board: BoardBox[],
  deck: Card[],
  useIgnore?: boolean,
  playersPositions?: Array<Position>,
  isTeleport?: boolean
): number[][] => {
  if (!isTeleport && isWell(board[player.position.box].effect)) {
    return [];
  }

  // Basically, the maximum number of boxes between a position and a well is 9
  let basePaths = getMovablePaths(9, player, board, deck, useIgnore, playersPositions);

  // Filtering paths with well and order them by player speed cost
  const pathsWithWell: number[][] = basePaths
    .filter((path) => path.some((b) => isWell(board[b].effect)))
    .sort((path1, path2) => {
      const wellPath1Index = path1.findIndex((b) => isWell(board[b].effect));
      const wellPath2Index = path2.findIndex((b) => isWell(board[b].effect));

      return (
        getMoveCost(path1[wellPath1Index], [path1], deck, player.playingArea, playersPositions) -
        getMoveCost(path2[wellPath2Index], [path2], deck, player.playingArea, playersPositions)
      );
    });

  if (!pathsWithWell.length) {
    console.error('There is a problem when computing path to closest well');
  }

  // Transform the previous array to an array of weight + path where the last element of the path is a well
  // If its a teleport effect, we took well around it
  const targetablePaths: (number | number[])[][] = pathsWithWell
    .map((path) => {
      let weight: number;
      let realPath: number[] = path;
      if (isTeleport) {
        let well = path.findIndex((b) => isWell(board[b].effect)) + 1;
        weight = path.slice(0, well).length;
        realPath = path.slice(0, well);
      } else {
        weight = getMoveCost(path[path.length - 1], [path], deck, player.playingArea, playersPositions);

        for (let i = 1; i < path.length; i++) {
          let lastBox = path[path.length - i - 1];
          if (isWell(board[lastBox].effect)) {
            const newWeight = getMoveCost(lastBox, [path], deck, player.playingArea, playersPositions);
            if (newWeight < weight) {
              weight = newWeight;
              realPath = path.slice(0, path.length - i);
            }
          }
        }
      }

      return [realPath, weight];
    })
    // Filtering only paths when its not a teleport effect and if the player has enough speed
    .filter((path) => isTeleport || path[1] <= maxSpeed);

  // The player can target a well
  if (targetablePaths.length) {
    if (isTeleport) {
      return teleportPaths(targetablePaths);
    }
    return computeShortestPath(targetablePaths);
  } else {
    const sortedPaths = pathsWithWell
      .map((path) =>
        path.slice(
          0,
          path.findIndex((b) => getMoveCost(b, [path], deck, player.playingArea, playersPositions) === maxSpeed) + 1
        )
      )
      .map((path) => [path, getMoveCost(path[path.length - 1], [path], deck, player.playingArea, playersPositions)])
      .sort((entry1, entry2) => (entry1[1] as number) - (entry2[1] as number));

    if (isTeleport) {
      return teleportPaths(sortedPaths);
    }
    return computeShortestPath(sortedPaths);
  }
};

export const teleportPaths = (targetablePaths: (number | number[])[][]) => {
  const teleportPaths: number[][] = [];

  targetablePaths.forEach((path) => {
    if (!teleportPaths.some((p) => p[0] === path[path.length - 1])) {
      teleportPaths.push((path[0] as number[]).slice(-1));
    }
  });

  return teleportPaths;
};

export const computeShortestPath = (targetablePaths: (number | number[])[][]): number[][] => {
  const shortestPaths: number[][] = [];
  let lastWeight = undefined;
  for (let path of targetablePaths) {
    if (lastWeight && lastWeight < path[1]) {
      break;
    }

    shortestPaths.push((path[0] as number[]).slice(-1));
    lastWeight = path[1];
  }
  return shortestPaths;
};

export const getActualMovablePaths = (
  maxSpeed: number,
  player: PlayerType,
  board: BoardBox[],
  deck: Card[],
  useIgnore?: boolean,
  playersPositions?: Array<Position>,
  activePlayer?: Vampire
): number[][] => {
  if (!canMove(player, deck, board, activePlayer, maxSpeed)) {
    return [];
  }

  const effect: ConfuseEffect | undefined = getActiveConfuseEffect(player, deck, board);
  // In case there is an active confuse effect legal moves are limited to card playing and confuse effect
  if (effect && !isPlayerOnLabyrinth(player, board)) {
    return getConfusedMovablePath(effect.distance, player, BoardBoxes, deck, useIgnore, playersPositions);
  }

  const playerEffect = getNextPendingEffect(player);
  if (isTeleport(playerEffect)) {
    return getPathsToClosestWell(5, player, BoardBoxes, deck, false, undefined, true);
  }

  if (player.playingArea.some((c) => hasSpicyEffect(deck[c]))) {
    return getPathsToClosestWell(maxSpeed, player, BoardBoxes, deck, useIgnore, playersPositions);
  }

  if (!effect || isPlayerOnLabyrinth(player, board)) {
    return getMovablePaths(maxSpeed, player, board, deck, useIgnore, playersPositions);
  }

  return [];
};

/**
 * Compute the cost for a movement. The cost is equals to the index of the position in the path
 * @param box The target position of the player
 * @param paths The possible paths.
 * @param deck
 * @param playingArea The player playing area
 * @param playersPositions The positions of players on the board
 * @param useIgnore Must use ignore effect
 */
export const getMoveCost = (
  box: number,
  paths: number[][],
  deck: Card[],
  playingArea?: number[],
  playersPositions?: Array<Position>,
  useIgnore: boolean = true
): number => {
  const ignoreEffect = useIgnore && hasAnIgnore(playingArea?.map((c) => deck[c]));

  // In case user has
  if (ignoreEffect) {
    return Math.min(
      ...paths
        .filter((path) => path.includes(box))
        .map(
          (path) =>
            path
              .filter((p) => p === box || !isWell(BoardBoxes[p].effect))
              .filter((p) => p === box || !playersPositions || !playersPositions.some((player) => player.box === p))
              .findIndex((p) => p === box) + 1
        )
    );
  }

  return Math.min(...paths.filter((path) => path.includes(box)).map((path) => path.findIndex((p) => p === box) + 1));
};

export const canMove = (
  player: PlayerType,
  deck: Card[],
  board: BoardBox[],
  activePlayer?: Vampire,
  speed?: number
): boolean => {
  const playerHand = getPlayerHandSize(player);
  const computedSpeed = speed !== undefined ? speed : computeSpeed(player, deck);
  const confuseEffect = getActiveConfuseEffect(player, deck, board);
  const pendingEffect = getNextPendingEffect(player);
  const hasMandatoryCard = player && getActivatableCards(player, deck, board).some((c) => hasMandatoryEffect(deck[c]));

  if (player.locked) {
    return false;
  }

  if (confuseEffect || isTeleport(pendingEffect)) {
    return true;
  }

  return (
    !player.locked &&
    !hasMandatoryCard &&
    computedSpeed > 0 &&
    activePlayer === player.vampire &&
    !playerHand &&
    !player.hasMoved &&
    !hasHunted(player) &&
    !pendingEffect &&
    (!hasPlayerSpicyEffect(player, deck) || !isWell(BoardBoxes[player.position.box].effect))
  );
};

/**
 * Get the player on the same position
 * @param activePlayer the current player
 * @param players All players
 */
export const getPlayerOnSamePosition = (activePlayer: PlayerType, players: Array<PlayerType>) =>
  players
    .filter((p) => activePlayer.vampire !== p.vampire && activePlayer.position.box === p.position.box)
    .sort((a, b) => b.position.z - a.position.z);

export const isActiveBoardEffect = (boxPosition: number, state: GameState | GameView) => {
  let boardBox = BoardBoxes[boxPosition];
  if (boardBox.effect) {
    switch (boardBox.effect) {
      case BoardBoxEffect.Chest:
        return state.bonusTokens.find((b) => b.position === boxPosition) !== undefined;
      case BoardBoxEffect.Crypt:
        return (
          boardBox.missionStack &&
          !!(isGameState(state) ? state.missions[boardBox.missionStack].length : state.missions[boardBox.missionStack])
        );
      case BoardBoxEffect.Labyrinth:
        return !!state.roses.length;
      case BoardBoxEffect.Church:
      case BoardBoxEffect.Mansion:
      case BoardBoxEffect.Barracks:
      case BoardBoxEffect.Market:
        return true;
      case BoardBoxEffect.Tavern:
        return !!(isGameState(state) ? state.tavern.length : state.tavern);
    }
  }

  return false;
};

export const comeBackToCastle = (player: PlayerType, newPosition: number, board: BoardBox[]) => {
  let castle = board.findIndex((b) => isCastle(b.effect));
  return player.position.box !== castle && newPosition === castle;
};
