import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import MoveView from './MoveView';
import Move from './Move';

export type ShuffleDiscardToDeck = {
  type: MoveType.ShuffleDiscardToDeck,
};

export type ShuffleDiscardToDeckRandomized = ShuffleDiscardToDeck & {
  type: MoveType.ShuffleDiscardToDeck,
  deck: number[]
};

export type ShuffleDiscardToDeckView = ShuffleDiscardToDeck & {
  deck: number
}

export const isShuffleDiscardToDeck = (move: Move | MoveView): move is ShuffleDiscardToDeckView => move.type === MoveType.ShuffleDiscardToDeck


export const shuffleDiscardToDeckMove: ShuffleDiscardToDeck = {type: MoveType.ShuffleDiscardToDeck};

export const shuffleDiscardToDeck = (state: GameState, move: ShuffleDiscardToDeckRandomized) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  player.deck = move.deck;
  player.discard = [];
}

export const shuffleDiscardToDeckView = (state: GameView, move: ShuffleDiscardToDeckView) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  player.deck = move.deck;
  player.discard = [];
}

export const getShuffleDiscardToDeckMoveView = (move: ShuffleDiscardToDeckRandomized): ShuffleDiscardToDeckView => {
  return {
    ...move,
    deck: move.deck.length
  };
}
