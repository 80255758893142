import { Player, PlayerType } from '../player';
import { Card } from '../card/Card';
import { BoardBox, BoardBoxEffect, Region } from '../board';
import { EffectTrigger } from '../effect/EffectTrigger';
import { isEndOfGameEffect } from './CardUtils';
import { PlayerMission } from '../player/PlayerMission';
import { Bonus } from '../bonus/Bonus';
import { CardType } from '../card/CardType';
import { isHuman, isMilitary, isNoble, isReligious, isVillager } from '../card/hunt/Human';
import { BonusTokenType } from '../bonus/BonusTokenType';
import { Mission } from '../mission';
import { GameMode } from '../GameMode';
import { GameEffects } from '../effect/GameEffect';

export const applyEndOfGame = (
  players: Array<Player>,
  boardMissions: number[],
  mode: GameMode,
  deck: Card[],
  missions: Mission[],
  board: BoardBox[],
  bonuses: Bonus[]
): Array<Player> => {
  const endPlayers: Array<Player> = players.map((p) => ({ ...p }));
  const playersBeforeScoring = players.map((p) => ({ ...p }));

  // Before mission score
  endPlayers.forEach((player) => {
    const otherPlayers = playersBeforeScoring.filter((p) => p.vampire !== player.vampire);
    player.missions = computePlayerMissionsScore(
      player,
      otherPlayers,
      deck,
      missions,
      board,
      bonuses,
      EffectTrigger.BeforeMissionScore
    );
    player.score += computeBoardMissionScore(
      player,
      otherPlayers,
      boardMissions.filter((m) => missions[m].scoreTrigger === EffectTrigger.BeforeMissionScore),
      deck,
      missions,
      board,
      bonuses
    );
  });

  // Mission score
  endPlayers.forEach((player) => {
    const otherPlayers = endPlayers.filter((p) => p.vampire !== player.vampire);
    player.missions = computePlayerMissionsScore(player, otherPlayers, deck, missions, board, bonuses);
    player.score += computeBoardMissionScore(
      player,
      otherPlayers,
      boardMissions.filter((m) => !missions[m].scoreTrigger),
      deck,
      missions,
      board,
      bonuses
    );
  });

  // After Mission score (reset the player to have the new missions set
  const playersAfterScoring = endPlayers.map((p) => ({ ...p }));
  endPlayers.forEach((player) => {
    const otherPlayers = playersAfterScoring.filter((p) => p.vampire !== player.vampire);
    const playerMissions = computePlayerMissionsScore(
      player,
      otherPlayers,
      deck,
      missions,
      board,
      bonuses,
      EffectTrigger.AfterMissionScore
    );
    const cardsScore = computeEndOfGameCards(player, deck, bonuses);
    player.score += computeBoardMissionScore(
      player,
      otherPlayers,
      boardMissions.filter((m) => missions[m].scoreTrigger === EffectTrigger.AfterMissionScore),
      deck,
      missions,
      board,
      bonuses
    );

    if (playerMissions.some((m) => m.score === undefined)) {
      throw Error('An error occurred on mission score computation');
    }

    player.missions = playerMissions;
    player.score += playerMissions.map((m) => m.score!).reduce((m1, m2) => m1 + m2, 0) + cardsScore;

    const boardBox = board[player.position.box];
    if (boardBox.effect === BoardBoxEffect.Cemetery) {
      player.score = Math.max(player.score - (boardBox.lostVictoryPoints || 0), 0);
    } else if (mode === GameMode.Rookie && boardBox.region === Region.Mountain) {
      player.score = Math.max(player.score - (boardBox.lostVictoryPoints || 0), 0);
    }
  });

  return endPlayers;
};

export const computeEndOfGameCards = (player: Player, deck: Card[], bonuses: Bonus[]): number => {
  const endOfGameEffects = [...player.discard, ...player.digest].flatMap((c) =>
    (deck[c].passiveEffects || []).filter((effect) => isEndOfGameEffect(effect))
  );

  return computeEndOfGameEffects(endOfGameEffects, player, deck, bonuses);
};

export const computeEndOfGameEffects = (effects: GameEffects[], player: Player, deck: Card[], bonuses: Bonus[]) => {
  return effects
    .map((e) => {
      if (e.computeScore) {
        return e.computeScore(player, deck, bonuses);
      }
      if (!e.computeScore && e.victoryPoint) {
        return e.victoryPoint;
      }

      return 0;
    })
    .reduce((accumulator, nextValue) => accumulator + nextValue, 0);
};

const computeBoardMissionScore = (
  player: Player,
  otherPlayers: Array<Player>,
  mission: number[],
  deck: Card[],
  missions: Mission[],
  board: BoardBox[],
  bonuses: Bonus[]
) => {
  let score = 0;

  mission.forEach((m) => {
    let mission = missions[m];
    if (mission.computeScore) {
      score += mission.computeScore(player, otherPlayers, deck, board, bonuses);
    }
  });

  return score;
};

const computePlayerMissionsScore = (
  player: Player,
  otherPlayers: Array<Player>,
  deck: Card[],
  missions: Mission[],
  board: BoardBox[],
  bonuses: Bonus[],
  trigger?: EffectTrigger
): Array<PlayerMission> => {
  return player.missions.map((m) => {
    let mission = missions[m.mission];
    if ((!trigger && !mission.scoreTrigger) || trigger === mission.scoreTrigger) {
      return {
        ...m,
        score: mission.computeScore ? mission.computeScore(player, otherPlayers, deck, board, bonuses) : 0,
      };
    }

    return m;
  });
};

export const computeHumans = (player: PlayerType, deck: Card[], bonuses: Bonus[], humanType?: CardType) => {
  switch (humanType) {
    case CardType.HumanVillager:
      return (
        [...player.digest, ...player.discard].filter((c) => isVillager(deck[c])).length +
        player.bonusTokens.filter((b) => bonuses[b].type === BonusTokenType.Villager).length
      );
    case CardType.HumanReligious:
      return (
        [...player.digest, ...player.discard].filter((c) => isReligious(deck[c])).length +
        player.bonusTokens.filter((b) => bonuses[b].type === BonusTokenType.Church).length
      );
    case CardType.HumanMilitary:
      return (
        [...player.digest, ...player.discard].filter((c) => isMilitary(deck[c])).length +
        player.bonusTokens.filter((b) => bonuses[b].type === BonusTokenType.Military).length
      );
    case CardType.HumanNoble:
      return (
        [...player.digest, ...player.discard].filter((c) => isNoble(deck[c])).length +
        player.bonusTokens.filter((b) => bonuses[b].type === BonusTokenType.Noble).length
      );
  }
  return (
    [...player.digest, ...player.discard].filter((c) => isHuman(deck[c])).length +
    player.bonusTokens.filter((b) =>
      [BonusTokenType.Villager, BonusTokenType.Noble, BonusTokenType.Military, BonusTokenType.Church].includes(
        bonuses[b].type
      )
    ).length
  );
};
