import { EffectTrigger } from './EffectTrigger';
import { GameEffect } from './GameEffect';
import { EffectType } from './EffectType';
import Move from '../moves/Move';
import GameState, { isGameState } from '../GameState';
import { drawHuntCardMove } from '../moves/DrawHuntCard';
import GameView from '../GameView';

export class GregariousEffect implements GameEffect {
  type: EffectType.Gregarious = EffectType.Gregarious;
  trigger: EffectTrigger = EffectTrigger.Hunted

  static automaticMove(state: GameState | GameView): Move | undefined {
    let hasCards = isGameState(state)? state.hunt.deck.length: state.hunt.deck;
    if (hasCards) {
      return drawHuntCardMove;
    }

    return;
  }


}
