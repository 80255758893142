import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import MoveType from './MoveType';
import Move from './Move';
import GameState from '../GameState';
import Vampire from '../player/Vampire';
import { BoardBox, BoardBoxes } from '../board';
import GameView from '../GameView';
import { comeBackToCastle, getMovablePaths, getMoveCost, isActiveBoardEffect } from '../utils/MoveUtils';
import { getActiveConfuseEffect, isCastle } from '../utils/CardUtils';
import {
  computeAdditionalHuntSpeed,
  computePassiveEffects,
  computeSpeed,
  constructEffect,
  recomputePlayerZPosition,
} from '../utils/GameUtils';
import { PlayerType } from '../player';
import { EffectTrigger } from '../effect/EffectTrigger';
import { hasConfuseEffect, isPushEffect, isTeleport } from '../utils/EffectUtils';
import { Card } from '../card/Card';
import { VictoryPointEffect } from '../effect/VictoryPointEffect';
import { EffectType } from '../effect/EffectType';

export type MoveVampire = {
  type: MoveType.MoveVampire;
  box: number;
  vampire: Vampire;
};

export const moveVampireMove = (box: number, vampire: Vampire): MoveVampire => ({
  type: MoveType.MoveVampire,
  box,
  vampire,
});

export const isMoveVampire = (move: Move): move is MoveVampire => move.type === MoveType.MoveVampire;

const computeCastleToken = (
  board: BoardBox[],
  movedPlayer: PlayerType,
  move: MoveVampire,
  state: GameState | GameView
) => {
  if (comeBackToCastle(movedPlayer, move.box, board)) {
    movedPlayer.locked = true;
    let nextCastleToken = state.castleTokens.shift();
    if (nextCastleToken) {
      movedPlayer.pendingEffects.unshift(constructEffect(new VictoryPointEffect(nextCastleToken)));
    }
  }
};

export const moveVampire = (state: GameState | GameView, move: MoveVampire, deck: Card[], board: BoardBox[]) => {
  const movedPlayer = state.players.find((p) => p.vampire === move.vampire)!;

  // Recomputing z position for all movedPlayer since the current movedPlayer leave the box
  const playerOnPreviousBox = state.players
    .filter((p) => p.vampire !== movedPlayer.vampire && p.position.box === movedPlayer.position.box)
    .sort((a, b) => a.position.z - b.position.z);
  if (playerOnPreviousBox.length) {
    playerOnPreviousBox.forEach((p, index) => {
      p.position.z = index + 1;
    });
  }

  // Getting the players on target box to get the next z position
  const playerOnTargetBox = state.players
    .filter((p) => p.vampire !== movedPlayer.vampire && p.position.box === move.box)
    .sort((a, b) => a.position.z - b.position.z);

  // active players must be set on top of resting player + the moved player
  recomputePlayerZPosition(movedPlayer, playerOnTargetBox, move.box);
  // There is no pending effect or the first is not a confuse effect
  if (movedPlayer.vampire === state.activePlayer) {
    // Non resting players must be set on top of resting player + the pushed player
    const confuseEffect = getActiveConfuseEffect(movedPlayer, deck, board);
    const pendingEffect = getNextPendingEffect(movedPlayer);
    if (isTeleport(pendingEffect)) {
      removeNextPendingEffect(movedPlayer);

      computePassiveEffects(state, movedPlayer, movedPlayer.playingArea, [], [EffectTrigger.BeforeMoveOrHunt], deck);
      movedPlayer.hasMoved = true;

      computeCastleToken(board, movedPlayer, move, state);
    } else if (confuseEffect) {
      movedPlayer.playedCards.push(...movedPlayer.playingArea.filter((c) => hasConfuseEffect(deck[c])));
    } else {
      let box = board[move.box];
      if (box && box.effect && isActiveBoardEffect(move.box, state)) {
        movedPlayer.boardEffect = box.effect;
      }

      computePassiveEffects(state, movedPlayer, movedPlayer.playingArea, [], [EffectTrigger.BeforeMoveOrHunt], deck);

      // Player must be considered as moved for the speed computing to take into account card with passive speed bonus on well
      const speed = computeSpeed({ ...movedPlayer, position: { ...movedPlayer.position, box: move.box } }, deck);
      let playersPositions = state.players.map((p: PlayerType) => p.position);
      movedPlayer.remainingSpeed =
        speed -
        getMoveCost(
          move.box,
          getMovablePaths(speed, movedPlayer, BoardBoxes, deck, true, playersPositions),
          deck,
          movedPlayer.playingArea,
          playersPositions
        );

      // Removing speed bonus gained by pending effects
      movedPlayer.pendingEffects = movedPlayer.pendingEffects.filter(
        (e) => e.trigger !== EffectTrigger.BeforeMoveOrHunt
      );
      // Add an effect when the player especially move on a box where there is players
      if (!isCastle(board[move.box].effect)) {
        // Push effect is added on top of effect to force player to push before playing
        movedPlayer.pendingEffects.unshift(
          ...playerOnTargetBox.map((p) =>
            JSON.parse(JSON.stringify({ type: EffectType.PushVampire, optional: true, vampire: p.vampire }))
          )
        );
      }

      movedPlayer.hasMoved = true;

      computeCastleToken(board, movedPlayer, move, state);
    }
  } else {
    const activePlayer = state.players.find((p) => p.vampire === state.activePlayer)!;
    computePassiveEffects(state, activePlayer, activePlayer.playingArea, [], [EffectTrigger.PushVampire], deck);
    activePlayer.pendingEffects = activePlayer.pendingEffects.filter(
      (e) => !isPushEffect(e) || e.vampire !== move.vampire
    );
  }

  movedPlayer.position.box = move.box;

  if (movedPlayer.vampire === state.activePlayer) {
    if (movedPlayer.additionalHuntSpeed === undefined) {
      movedPlayer.additionalHuntSpeed = computeAdditionalHuntSpeed(movedPlayer, deck);
    }
  }
};
