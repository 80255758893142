export const FillHuntCardDuration = 1.5;
export const FillHuntCardDelay = 0.3;

export const FillTavernCardDuration = 1.5;
export const FillTavernCardDelay = 0.3;

export const PlaceInPlayingAreaCardDuration = 1.5;
export const PlaceInPlayingAreaCardDelay = 0.3;

export const DrawCardDuration = 1.5;
export const DrawCardDelay = 0.3;

export const DiscardCardDuration = 1.5;
export const DiscardCardDelay = 0.3;

export const DigestCardDuration = 1.5;
export const DigestCardDelay = 0.3;

export const HuntInTavernCardDuration = 1.5;
export const HuntInTavernCardDelay = 0.3;

export const ShuffleDiscardToDeckCardDuration = 1.5;
export const ShuffleDiscardToDeckCardDelay = 0.3;
export const ShuffleDiscardToDeckShuffleDelay = 1;
