import { Dummy } from '@gamepark/rules-api';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import GameState from '@gamepark/the-hunger/GameState';
import Move from '@gamepark/the-hunger/moves/Move';
import TheHunger from '@gamepark/the-hunger/TheHunger';
import { isEndTurn } from '@gamepark/the-hunger/moves/EndTurn';
import { isMoveVampire } from '@gamepark/the-hunger/moves/MoveVampire';
import GameAI from '@gamepark/react-client/dist/Types/GameAI';

export const ai: GameAI<GameState, Move, Vampire> = (game: GameState, vampire: Vampire): Promise<Move[]> => {
  const dummy = new Dummy(TheHunger);
  let legalMoves = dummy.getLegalMoves(game, vampire);

  if (legalMoves.length === 1) {
    return Promise.resolve(legalMoves);
  }

  if (legalMoves.length > 1 && legalMoves.some(isEndTurn)) {
    legalMoves = legalMoves.filter((m) => !isEndTurn(m));
  }

  const player = game.players.find((p) => p.vampire === vampire)!;
  const moveVampireMoves = legalMoves.filter(isMoveVampire);
  if (game.round < 8) {
    const goFarFromCastleMoves = moveVampireMoves.filter((m) => m.box > player.position.box);
    if (goFarFromCastleMoves.length) {
      legalMoves = goFarFromCastleMoves;
    }
  } else {
    const goBackToCastleMoves = moveVampireMoves.filter((m) => m.box < player.position.box);
    if (goBackToCastleMoves.length) {
      legalMoves = goBackToCastleMoves;
    }
  }

  const legalMovesWithoutEnd = legalMoves.filter((l) => !isEndTurn(l));
  if (legalMovesWithoutEnd.length) {
    legalMoves = legalMovesWithoutEnd;
  }

  if (!legalMoves.length) return Promise.resolve([]);
  return Promise.resolve([legalMoves[Math.floor(Math.random() * legalMoves.length)]]);
};
