import GameState, { isGameState } from '../GameState';
import GameView from '../GameView';
import { TavernSize } from './GameConstants';
import { BoardBoxEffect, BoardBoxes } from '../board';


export const canRefillTavern = (state: GameState | GameView) => {
  const tavernLength = isGameState(state) ? state.tavern.length : state.tavern;
  const huntDeckLength = isGameState(state) ? state.hunt.deck.length : state.hunt.deck;
  if (tavernLength < TavernSize) {
    const tavernBox = BoardBoxes.findIndex(b => b.effect === BoardBoxEffect.Tavern);
    if (!state.players.some(p => p.position.box === tavernBox) && (TavernSize - tavernLength >= 1) && huntDeckLength) {
      return true;
    }
  }

  return false;
};
