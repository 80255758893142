import { Card } from '@gamepark/the-hunger/card/Card';
import { EffectDescription } from './EffectDescription';
import {
  BorisPouchkineHunger, BorisPouchkineVampiricStrength, BorisPouchkineVampiricThirst, DonGervasiHunger, DonGervasiVampiricStrength, DonGervasiVampiricThirst,
  JosephineLafayetteHunger, JosephineLafayetteVampiricStrength, JosephineLafayetteVampiricThirst,
  LadyBeatriceHunger,
  LadyBeatriceVampiricStrength,
  LadyBeatriceVampiricThirst,
  RajeshAmaraHunger,
  RajeshAmaraVampiricStrength,
  RajeshAmaraVampiricThirst, YokoChiyakoHunger, YokoChiyakoVampiricStrength, YokoChiyakoVampiricThirst
} from '@gamepark/the-hunger/card/vampire/Starters';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { VictoryPointSymbol } from '../../../symbols';

const StarterEffects = new Map<Card, Array<EffectDescription>>();

StarterEffects.set(LadyBeatriceHunger, [new EffectDescription(() => <Trans
  defaults="card.effect.starter.hunger"
  components={[
    <VictoryPointSymbol value="1" width={1.35}/>,
  ]}
/>, 5.7, undefined, 2.5)]);
StarterEffects.set(LadyBeatriceVampiricStrength, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricstrength'), 5.7)]);
StarterEffects.set(LadyBeatriceVampiricThirst, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricthirst'), 5.7)]);

StarterEffects.set(RajeshAmaraHunger, [new EffectDescription(() => <Trans
  defaults="card.effect.starter.hunger"
  components={[
    <VictoryPointSymbol value="1" width={1.35}/>,
  ]}
/>, 5.7)]);
StarterEffects.set(RajeshAmaraVampiricStrength, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricstrength'), 5.7)]);
StarterEffects.set(RajeshAmaraVampiricThirst, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricthirst'), 5.7)]);

StarterEffects.set(YokoChiyakoHunger, [new EffectDescription(() => <Trans
  defaults="card.effect.starter.hunger"
  components={[
    <VictoryPointSymbol value="1" width={1.35}/>,
  ]}
/>, 5.7)]);
StarterEffects.set(YokoChiyakoVampiricStrength, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricstrength'), 5.7)]);
StarterEffects.set(YokoChiyakoVampiricThirst, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricthirst'), 5.7)]);

StarterEffects.set(JosephineLafayetteHunger, [new EffectDescription(() => <Trans
  defaults="card.effect.starter.hunger"
  components={[
    <VictoryPointSymbol value="1" width={1.35}/>,
  ]}
/>, 5.7)]);
StarterEffects.set(JosephineLafayetteVampiricStrength, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricstrength'), 5.7, undefined, 2.5)]);
StarterEffects.set(JosephineLafayetteVampiricThirst, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricthirst'), 5.7)]);

StarterEffects.set(BorisPouchkineHunger, [new EffectDescription(() => <Trans
  defaults="card.effect.starter.hunger"
  components={[
    <VictoryPointSymbol value="1" width={1.35}/>,
  ]}
/>, 5.7)]);
StarterEffects.set(BorisPouchkineVampiricStrength, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricstrength'), 5.7)]);
StarterEffects.set(BorisPouchkineVampiricThirst, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricthirst'), 5.7)]);

StarterEffects.set(DonGervasiHunger, [new EffectDescription(() => <Trans
  defaults="card.effect.starter.hunger"
  components={[
    <VictoryPointSymbol value="1" width={1.35}/>,
  ]}
/>, 5.7)]);
StarterEffects.set(DonGervasiVampiricStrength, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricstrength'), 5.7)]);
StarterEffects.set(DonGervasiVampiricThirst, [new EffectDescription((t: TFunction) => t('card.effect.starter.vampiricthirst'), 5.7)]);

export {
  StarterEffects
};
