import { MissionType } from './MissionType';
import { GameMode } from '../GameMode';
import { GameEffects } from '../effect/GameEffect';
import { Player } from '../player';
import { Card } from '../card/Card';
import { BoardBox } from '../board';
import { EffectTrigger } from '../effect/EffectTrigger';
import { Bonus } from '../bonus/Bonus';
import { PlayerMission } from '../player/PlayerMission';

export type Mission = {
  type: MissionType,
  gameMode?: GameMode,
  minPlayers?: number,
  effects?: Array<GameEffects>,
  scoreTrigger?: EffectTrigger
  computeScore?: (player: Player, otherPlayers: Array<Player>, deck: Card[], board: BoardBox[], bonuses: Bonus[]) => number;
};

export const isStackCount = (missions: number | number[]): missions is number => typeof missions === 'number';
export const isPlayerMission = (mission: number | PlayerMission): mission is PlayerMission => typeof mission !== 'number';
export const isVisibleStack = (missions: number | number[]): missions is number[] => !isStackCount(missions);
