/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, useCallback, useState } from 'react';
import { DraggableTypes } from '../draggable/DraggableTypes';
import {
  getHuntTrackAreaUntranslateX,
  getNewTranslateX,
  getNewTranslateY,
  huntTrackAreaCardTranslateX,
  huntTrackAreaCardTranslateY,
  huntTrackAreaLeft,
  huntTrackAreaTop,
  huntTrackAreaTranslateX,
  huntTrackAreaTranslateY,
  huntTrackCardFocus,
  huntTrackCardHeight,
  huntTrackCardTranslateX,
  huntTrackCardTranslateY,
  huntTrackCardWidth,
  huntTrackLargeAreaHeight,
  huntTrackSmallAreaHeight,
  realHuntTrackAreaHeight,
  realHuntTrackAreaWidth,
} from '../../utils/Style';
import { HuntCard } from '../card/HuntCard';
import { Draggable } from '@gamepark/react-components';
import { Animation, useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { HuntView } from '@gamepark/the-hunger/hunt';
import { isHuntableTrackArea } from '@gamepark/the-hunger/utils/HuntUtils';
import { HuntCardsCatalog } from '../card/HuntCardsCatalog';
import { isMyPlayerView, PlayerType } from '@gamepark/the-hunger/player';
import { huntTrackArea } from '../draggable/HuntOnTrack';
import { canMoveCardTo, computeSpeedForHunt } from '@gamepark/the-hunger/utils/GameUtils';
import { HunTrackMoveOverlay } from './HuntTrackMoveOverlay';
import { getNextPendingEffect } from '@gamepark/the-hunger/utils/PendingUtils';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import {
  isMoveHuntTrack,
  MoveHuntTrackCard,
  MoveHuntTrackCardDirection,
} from '@gamepark/the-hunger/moves/MoveHuntTrackCard';
import { huntOnTrackMove, isHuntOnTrack } from '@gamepark/the-hunger/moves/HuntOnTrack';
import { hasMandatoryEffect, isHuntTrackMove, isWinHuntTrackCard } from '@gamepark/the-hunger/utils/EffectUtils';
import { TheHungerButton } from '../../utils/TheHungerButton';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { isOverLastTurn } from '@gamepark/the-hunger/utils/TurnUtils';
import { WinHuntTrackCardEffect } from '@gamepark/the-hunger/effect/WinHuntTrackCardEffect';
import { getActivatableCards } from '@gamepark/the-hunger/utils/CardUtils';
import { isHuntInTavern } from '@gamepark/the-hunger/moves/HuntInTavern';
import { BoardBoxes } from '@gamepark/the-hunger/board';

export type HuntTrackAreaProps = {
  lineIndex: number;
  areaIndex: number;
  hunt: HuntView;
  round: number;
  player: PlayerType;
  activePlayer?: Vampire;
  activePlayerIndex?: number;
  playerCount: number;
  area: number[];
  onDrag?: () => void;
  onDrop?: () => void;
  isGameOver?: boolean;
};

const HuntTrackArea: FC<HuntTrackAreaProps> = ({
  lineIndex,
  areaIndex,
  activePlayer,
  activePlayerIndex,
  player,
  playerCount,
  hunt,
  round,
  area,
  onDrag,
  onDrop,
  isGameOver,
}) => {
  const animation = useAnimation(
    (animation) => isMoveHuntTrack(animation.move) || isHuntOnTrack(animation.move) || isHuntInTavern(animation.move)
  );
  const moveTrackAnimation = animation && isMoveHuntTrack(animation.move) ? animation : undefined;
  const huntOnTrackAnimation =
    animation && isHuntOnTrack(animation.move) && animation.move.col === areaIndex && animation.move.row === lineIndex
      ? animation
      : undefined;
  const [isFocused, setFocused] = useState<boolean>(false);
  const play = usePlay();
  const playerId = usePlayerId();
  const playerSpeed = computeSpeedForHunt(player, GameDeck);
  const pendingEffect = getNextPendingEffect(player);
  const isHuntTrackMoveEffect = isHuntTrackMove(pendingEffect);
  const hasMandatoryCard =
    player && getActivatableCards(player, GameDeck, BoardBoxes).some((c) => hasMandatoryEffect(GameDeck[c]));

  let hideOverlay = (event?: any) => {
    setFocused(false);
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
  };

  const winHuntTrackCard = isWinHuntTrackCard(pendingEffect) ? new WinHuntTrackCardEffect(pendingEffect) : undefined;
  const hasHuntableCard: boolean =
    !!winHuntTrackCard && winHuntTrackCard.isHuntTrackAreaPlayable(area, areaIndex, player, GameDeck);
  const isCardHuntableForFree = (card: number): boolean =>
    hasHuntableCard && !!winHuntTrackCard && winHuntTrackCard.isCardHuntable(card, GameDeck);
  const hasMissionChoice = isMyPlayerView(player) && !!player.missionChoice.missions.length;

  const canHuntArea =
    !isGameOver &&
    !animation &&
    !hasMandatoryCard &&
    !hasMissionChoice &&
    !isOverLastTurn(round) &&
    !!area.length &&
    activePlayer === playerId &&
    player.vampire === playerId &&
    (hasHuntableCard ||
      isHuntTrackMoveEffect ||
      isHuntableTrackArea(area, areaIndex, player, GameDeck, BoardBoxes, playerSpeed));

  const selectArea = useCallback(() => {
    if (area.length) {
      setFocused(true);
    }
    // eslint-disable-next-line
  }, [area.length]);

  const huntArea = (event: any) => {
    if (isFocused && canHuntArea) {
      play(huntOnTrackMove(lineIndex, areaIndex));
      hideOverlay(event);
    }
  };

  const dragHuntTrackArea = useCallback(() => {
    if (onDrag) {
      onDrag();
    }

    if (winHuntTrackCard && area.length === 1) {
      return huntTrackArea(lineIndex, areaIndex, area[0]);
    }

    return huntTrackArea(lineIndex, areaIndex);
    // eslint-disable-next-line
  }, [winHuntTrackCard, area]);

  const getCardOverlay = (card: number) => {
    if (isHuntTrackMoveEffect) {
      return (
        <HunTrackMoveOverlay
          card={card}
          left={canMoveCardTo(lineIndex, areaIndex, playerCount, MoveHuntTrackCardDirection.LEFT)}
          right={canMoveCardTo(lineIndex, areaIndex, playerCount, MoveHuntTrackCardDirection.RIGHT)}
          top={canMoveCardTo(lineIndex, areaIndex, playerCount, MoveHuntTrackCardDirection.TOP)}
          bottom={canMoveCardTo(lineIndex, areaIndex, playerCount, MoveHuntTrackCardDirection.BOTTOM)}
          onMove={hideOverlay}
        />
      );
    } else {
      return undefined;
    }
  };

  const onHuntClick = (event: any, hunt: number) => {
    play(huntOnTrackMove(lineIndex, areaIndex, hunt));
    hideOverlay(event);
  };

  return (
    <>
      {isFocused && (
        <HuntCardsCatalog
          hunts={area}
          onClose={hideOverlay}
          getCardOverlay={getCardOverlay}
          isClickable={isCardHuntableForFree}
          onHuntClick={onHuntClick}
          topComponents={
            !isGameOver && canHuntArea && !winHuntTrackCard && !isHuntTrackMoveEffect
              ? [<TheHungerButton key={`hunt-button`} onClick={huntArea} css={huntButton} labelKey="button.hunt" />]
              : []
          }
        />
      )}
      <Draggable
        type={DraggableTypes.HuntTrackArea}
        canDrag={canHuntArea && (!winHuntTrackCard || area.length === 1)}
        item={dragHuntTrackArea}
        end={onDrop}
        drop={play}
        css={[
          areaStyle(playerCount, lineIndex, areaIndex),
          isFocused && hidden,
          huntOnTrackAnimation &&
            huntOnTrackAnimation.move.card &&
            area.includes(huntOnTrackAnimation.move.card) &&
            onTop,
          !moveTrackAnimation && !huntOnTrackAnimation && canHuntArea && selectableArea,
          huntOnTrackAnimation &&
            !huntOnTrackAnimation.move.card &&
            activePlayerIndex !== undefined &&
            activePlayerIndex !== -1 &&
            animateHuntOnTrack(playerCount, lineIndex, areaIndex, activePlayerIndex, huntOnTrackAnimation.duration),
          moveTrackAnimation &&
            moveTrackAnimation.move.card &&
            area.includes(moveTrackAnimation.move.card) &&
            moveTrackAnimation.move.direction === MoveHuntTrackCardDirection.BOTTOM &&
            topCard,
        ]}
        onClick={selectArea}
      >
        {area.map((card, cardIndex) => {
          return (
            <HuntCard
              key={`card-${card}`}
              css={[
                !!area.length && pointable,
                cardStyle(areaIndex, cardIndex, area.length),
                moveTrackAnimation &&
                  translatedCard(playerCount, lineIndex, areaIndex, cardIndex, hunt.track, moveTrackAnimation),
                huntOnTrackAnimation &&
                  !huntOnTrackAnimation.move.card &&
                  areaIndex === 2 &&
                  untranslateCards(areaIndex, huntOnTrackAnimation.duration),
                huntOnTrackAnimation &&
                  huntOnTrackAnimation.move.card === card &&
                  activePlayerIndex !== undefined &&
                  animateHuntCardOnTrack(
                    playerCount,
                    cardIndex,
                    area.length,
                    lineIndex,
                    areaIndex,
                    activePlayerIndex,
                    huntOnTrackAnimation.duration
                  ),
              ]}
              hunt={GameDeck[card]}
            />
          );
        })}
      </Draggable>
    </>
  );
};

const huntButton = css`
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 5em;
`;

const topCard = css`
  z-index: 99;
`;

const hidden = css`
  opacity: 0;
`;

const cardStyle = (areaIndex: number, cardIndex: number, areaSize: number) => css`
  position: absolute;
  transition-property: transform, z-index;
  transition-duration: 0.2s;
  transform: translate(${huntTrackCardTranslateX(cardIndex, areaIndex, areaSize)}%, ${huntTrackCardTranslateY}%)
    scale(${huntTrackCardFocus});
  height: ${huntTrackCardHeight}%;
  width: ${huntTrackCardWidth(areaIndex)}%;
  box-shadow: 0 0 0.1em black;
  font-size: ${huntTrackCardHeight / 100}em;
`;

const untranslateCards = (areaIndex: number, duration: number) => css`
  transition-property: transform;
  transition-duration: ${duration * 0.1}s;
  transform: translate(${getHuntTrackAreaUntranslateX(areaIndex)}%, ${huntTrackCardTranslateY}%)
    scale(${huntTrackCardFocus});
`;

const translatedCard = (
  playerCount: number,
  lineIndex: number,
  areaIndex: number,
  cardIndex: number,
  track: number[][][],
  animation: Animation<MoveHuntTrackCard, any>
) => css`
  transition-property: transform;
  transition-duration: ${animation.duration}s;
  transition-timing-function: ease-in-out;
  transform: translate(
      ${getNewTranslateX(cardIndex, lineIndex, areaIndex, track, animation.move.direction, animation.move.card)}%,
      ${getNewTranslateY(
        playerCount,
        cardIndex,
        lineIndex,
        areaIndex,
        track,
        animation.move.direction,
        animation.move.card
      )}%
    )
    scale(${huntTrackCardFocus});
`;

const pointable = css`
  cursor: pointer;
`;

const onTop = css`
  z-index: 99;
`;

const areaStyle = (playerCount: number, lineIndex: number, areaIndex: number) => css`
  position: absolute;
  height: ${realHuntTrackAreaHeight(playerCount, areaIndex)}%;
  width: ${realHuntTrackAreaWidth(areaIndex)}%;
  border-radius: 4.5em;
  font-size: ${(areaIndex < 2 ? huntTrackSmallAreaHeight(playerCount) : huntTrackLargeAreaHeight(playerCount)) / 100}em;
  top: ${huntTrackAreaTop(playerCount, lineIndex)}%;
  left: ${huntTrackAreaLeft(areaIndex)}%;
`;

const selectableArea = css`
  box-shadow: 0 0 0 2.5em gold;

  &:hover {
    background-color: rgba(255, 255, 255, 0.27);
    box-shadow: 0 0 0 2.5em green;
  }
`;

const animateHuntCardOnTrack = (
  playerCount: number,
  cardIndex: number,
  areaSize: number,
  lineIndex: number,
  areaIndex: number,
  playerIndex: number,
  duration: number
) => css`
  z-index: 99;
  animation: ${duration}s
    ${huntCardOnTrackAnimation(playerCount, cardIndex, areaSize, lineIndex, areaIndex, playerIndex)} ease-in-out both;
`;

const huntCardOnTrackAnimation = (
  playerCount: number,
  cardIndex: number,
  areaSize: number,
  lineIndex: number,
  areaIndex: number,
  playerIndex: number
) => keyframes`
  20%, 30% {
    transform-origin: center center;
    transform: translate(${huntTrackCardTranslateX(
      cardIndex,
      areaIndex,
      areaSize
    )}%, ${huntTrackCardTranslateY}%) scale(${huntTrackCardFocus * 1.5});
  }
  40% {
    transform-origin: center center;
    transform: translate(${huntTrackCardTranslateX(
      cardIndex,
      areaIndex,
      areaSize
    )}%, ${huntTrackCardTranslateY}%) scale(${huntTrackCardFocus});
  }
  70%, 80% {
    transform-origin: right center;
    transform: translate(${huntTrackAreaCardTranslateX(areaIndex, -1)}%, ${huntTrackAreaCardTranslateY(
  playerCount,
  lineIndex,
  areaIndex,
  playerIndex
)}%) scale(${huntTrackCardFocus});
  }
  100% {
    transform-origin: right center;
    transform: translate(${huntTrackAreaCardTranslateX(areaIndex, 3.5)}%, ${huntTrackAreaCardTranslateY(
  playerCount,
  lineIndex,
  areaIndex,
  playerIndex
)}%) scale(0);
  }
`;

const animateHuntOnTrack = (
  playerCount: number,
  lineIndex: number,
  areaIndex: number,
  playerIndex: number,
  duration: number
) => css`
  z-index: 99;
  animation: ${duration * 0.9}s ${huntOnTrackAnimation(playerCount, lineIndex, areaIndex, playerIndex)}
    ${duration * 0.1}s linear both;
`;

const huntOnTrackAnimation = (
  playerCount: number,
  lineIndex: number,
  areaIndex: number,
  playerIndex: number
) => keyframes`
  20%, 30% {
    transform-origin: center center;
    transform: scale(1.5);
  }
  40% {
    transform-origin: center center;
    transform: scale(1);
  }
  70%, 80% {
    transform-origin: right center;
    transform: translate(${huntTrackAreaTranslateX(areaIndex, -1)}%, ${huntTrackAreaTranslateY(
  playerCount,
  lineIndex,
  areaIndex,
  playerIndex
)}%) scale(1);
  }
  100% {
    transform-origin: right center;
    transform: translate(${huntTrackAreaTranslateX(areaIndex, 3.5)}%, ${huntTrackAreaTranslateY(
  playerCount,
  lineIndex,
  areaIndex,
  playerIndex
)}%) scale(0);
  }
`;

export { HuntTrackArea };
