import { EffectTrigger } from './EffectTrigger';
import { GameEffect } from './GameEffect';
import { EffectType } from './EffectType';
import { PlayerType } from '../player';

export class AdditionalHuntEffect implements GameEffect {
  type: EffectType.AdditionalHunt = EffectType.AdditionalHunt;
  huntCount: number;
  huntSpeed?: number;
  trigger?: EffectTrigger;

  constructor(huntCount: number, trigger?: EffectTrigger, huntSpeed?: number) {
    this.huntCount = huntCount;
    this.trigger = trigger;
    this.huntSpeed = huntSpeed;
  }

  isApplicable(player: PlayerType): boolean {
    return !(this.trigger === EffectTrigger.NotMoving && player.hasMoved);
  }


}
