/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { chooseMissionsMove } from "@gamepark/the-hunger/moves/ChooseMission";
import { FC, useState } from "react";
import { MissionCatalog } from "../mission/MissionCatalog";
import Vampire from "@gamepark/the-hunger/player/Vampire";
import { usePlay } from "@gamepark/react-client";
import { TheHungerButton } from "../../utils/TheHungerButton";
import { PlayerMission } from "@gamepark/the-hunger/player/PlayerMission";

export type MissionChoiceProps = {
  vampire: Vampire;
  playerMissions: PlayerMission[];
  activePlayer?: Vampire;
  missions: number[];
};

const MissionChoice: FC<MissionChoiceProps> = ({ vampire, playerMissions, missions }) => {
  const [chosenMissions, setChosenMission] = useState<Array<number>>(playerMissions.map((m) => m.mission));
  const isMissionSelected = (mission: number) => chosenMissions.includes(mission);
  const play = usePlay();

  const chooseMission = (mission: number) => {
    if (hasChosenAllMissions && !isMissionSelected(mission)) {
      return;
    }

    if (chosenMissions.some((m) => m === mission)) {
      return setChosenMission(chosenMissions.filter((m) => m !== mission));
    }

    setChosenMission([...chosenMissions, mission]);
  };

  const hasChosenAllMissions = chosenMissions.length === playerMissions.length + 1;

  const validateChoice = () => {
    if (hasChosenAllMissions) {
      play(chooseMissionsMove(vampire, chosenMissions));
    }
  };

  return (
    <MissionCatalog
      playerMissions={playerMissions.map((m) => m.mission)}
      vampire={vampire}
      missions={missions}
      missionStyle={(mission: number) => [isMissionSelected(mission) && selectedMissions, (!hasChosenAllMissions || isMissionSelected(mission)) && selectable]}
      onMissionClick={chooseMission}
      topComponents={[<TheHungerButton key="choose-missions" css={[validateMissionChoice(hasChosenAllMissions)]} onClick={validateChoice} labelKey="button.choose.mission" />]}
      portal={false}
      canReduce
    />
  );
};
const selectable = css`
  cursor: pointer;
`;

const selectedMissions = css`
  &:not(:active) {
    filter: drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold) drop-shadow(0 0 1em gold);
  }

  z-index: 99;
`;

const validateMissionChoice = (visible: boolean) => css`
  top: 4.5%;
  left: 50%;
  z-index: 100;
  opacity: ${visible ? 1 : 0};
  font-size: 5em;
  text-transform: uppercase;
  transform: translateX(-50%);
  height: 7%;
`;

export { MissionChoice };
