import { BaronChristien, Belle, Bridget, Catarina, Elois, Theresa, Veres, Wilma } from '@gamepark/the-hunger/card/hunt';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { HumanTypeSymbol, VictoryPointSymbol, RegionSymbol } from '../../../symbols';
import { EffectDescription } from './EffectDescription';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { Region } from '@gamepark/the-hunger/board';
import { Card } from '@gamepark/the-hunger/card/Card';


const HumanNobleEffects = new Map<Card, Array<EffectDescription>>();

HumanNobleEffects.set(Bridget, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.region"
  components={[
    <VictoryPointSymbol width={1.305} value="1"/>,
    <RegionSymbol region={Region.Plain}/>
  ]}
  values={{
    card: t('Bridget'),
    region: t('the.plains')
  }}
/>, 5.5, undefined, 2.5, 'white')]);

HumanNobleEffects.set(Belle, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.region"
  components={[
    <VictoryPointSymbol width={1.305} value="2"/>,
    <RegionSymbol region={Region.Forest}/>
  ]}
  values={{
    card: t('Belle'),
    region: t('the.forest')
  }}
/>, 5.5, undefined, 2.5, 'white')]);

HumanNobleEffects.set(Elois, [new EffectDescription(() => <Trans
  defaults="card.effect.holywater"
  components={[<strong/>]}
/>, 5.5)]);

HumanNobleEffects.set(Theresa, [new EffectDescription(() => <Trans
  defaults="card.effect.confused" components={[<strong/>]}
/>, 5.5)]);

HumanNobleEffects.set(Wilma, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.gregarious"
  components={[<strong/>]}
  values={{ card: t('Wilma') }}
/>, 7.4, undefined, undefined, 'white')]);

HumanNobleEffects.set(Catarina, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.gregarious"
  components={[<strong/>]}
  values={{ card: t('Catarina') }}
/>, 7.4, undefined, undefined, 'white')]);

HumanNobleEffects.set(BaronChristien, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.card"
  components={[
    <VictoryPointSymbol value="2"/>
  ]}
  values={{
    card: t('Roxane')
  }}
/>, 5.7)]);

HumanNobleEffects.set(Veres, [new EffectDescription(() => <Trans
  defaults="card.effect.human.hunt" components={[
  <VictoryPointSymbol value="1"/>,
  <HumanTypeSymbol category={CardType.HumanNoble}/>
]}/>, 7.5)]);

export {
  HumanNobleEffects
};
