/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import GameView from '@gamepark/the-hunger/GameView';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { Animation, Player, useAnimation, usePlay, usePlayerId, usePlayers } from '@gamepark/react-client';
import {
  canEndTurn,
  computeSpeed,
  computeSpeedForHunt,
  getPlayerHandSize,
  getRanking,
  hasMissionChoice,
  hasParasol,
  isBurned,
} from '@gamepark/the-hunger/utils/GameUtils';
import Move from '@gamepark/the-hunger/moves/Move';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import {
  getActivatableCards,
  getActiveConfuseEffect,
  getBeforePlayingCard,
  getMissionToPlayFirst,
  isShip,
  isWell,
} from '@gamepark/the-hunger/utils/CardUtils';
import { isMyPlayerView, isOtherPlayerView, MyPlayerView, PlayerType, TokenSide } from '@gamepark/the-hunger/player';
import { getPlayerName } from '@gamepark/the-hunger/TheHungerOptions';
import { HuntCardTitles } from './material/card/HuntCardTitles';
import { TheHungerButton } from './utils/TheHungerButton';
import { EffectType } from '@gamepark/the-hunger/effect/EffectType';
import { BoardBoxEffect, BoardBoxes } from '@gamepark/the-hunger/board';
import { dismissEffectMove } from '@gamepark/the-hunger/moves/DismissEffect';
import { endTurnMove, isEndTurn } from '@gamepark/the-hunger/moves/EndTurn';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { activateCardMove } from '@gamepark/the-hunger/moves/ActivateCard';
import { isPlaceInPlayingAreaMove, placeInPlayingAreaMove } from '@gamepark/the-hunger/moves/PlaceInPlayingArea';
import { getNextPendingEffect } from '@gamepark/the-hunger/utils/PendingUtils';
import { hasMandatoryEffect, hasSpicyEffect, isPushEffect } from '@gamepark/the-hunger/utils/EffectUtils';
import { isOver } from '@gamepark/the-hunger/utils/IsOver';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { Missions } from '@gamepark/the-hunger/mission';
import { MissionTitles } from './material/mission/MissionTitles';
import { activateMissionMove } from '@gamepark/the-hunger/moves/ActivateMission';
import { isDrawMission } from '@gamepark/the-hunger/moves/DrawMissions';
import { Bonuses } from '@gamepark/the-hunger/bonus/Bonuses';
import { isOverLastTurn } from '@gamepark/the-hunger/utils/TurnUtils';
import { isFillTavern } from '@gamepark/the-hunger/moves/FillTavern';
import { isFillHuntTrack } from '@gamepark/the-hunger/moves/FillHuntTrack';
import { isNewRound } from '@gamepark/the-hunger/moves/NewRound';
import { isFlipToken } from '@gamepark/the-hunger/moves/FlipToken';
import { hasMultifactionToken } from '@gamepark/the-hunger/utils/BonusUtils';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { canHunt } from '@gamepark/the-hunger/utils/HuntUtils';
import { canMove } from '@gamepark/the-hunger/utils/MoveUtils';
import { ViewType } from './ViewType';
import { useState } from 'react';
import { SkipTurnConfirmationModal } from './modal/SkipTurnConfirmationModal';

type Props = {
  loading: boolean;
  game?: GameView;
};

export default function HeaderText({ game, loading }: Props) {
  const { t } = useTranslation();
  const play = usePlay();
  const playerId = usePlayerId();
  const players = usePlayers<Vampire>();
  const animation = useAnimation();
  const [confirmSkipTurn, setConfirmSkipTurn] = useState(false);

  if (loading || !game) {
    return <>{t('game.loading')}</>;
  }
  const isGameOver =
    isOver(game!.players, game!.round, game!.turnOrder, Bonuses(game.oldTokensCount)) &&
    !game!.players.some((p) => hasMultifactionToken(p, Bonuses(game.oldTokensCount)));

  if (isGameOver) {
    return gameOverText(game!, players, playerId, t);
  }

  const player =
    game?.activePlayer && game.activePlayer === playerId
      ? game?.players.find((p) => p.vampire === playerId)
      : undefined;

  let canHuntOrMove = false;

  if (player) {
    const speedForMove = computeSpeed(player, GameDeck);
    const speedForHunt = computeSpeedForHunt(player, GameDeck);
    const itsMe = playerId && game.activePlayer === playerId;
    canHuntOrMove =
      itsMe &&
      (speedForMove || speedForHunt) &&
      (canHunt(player, speedForHunt, game.round, game.hunt.track, game.tavern, GameDeck, BoardBoxes) ||
        canMove(player, GameDeck, BoardBoxes, game.activePlayer, speedForMove));
  }

  const endTurn = () => play(endTurnMove);
  let playerCanEndTurn = false;
  const otherPlayers = player && game.players.filter((p) => p.vampire !== player.vampire);
  if (player && !animation) {
    playerCanEndTurn = player.vampire === playerId && canEndTurn(GameDeck, BoardBoxes, player, otherPlayers);
  }
  const pendingEffect = player && getNextPendingEffect(player);
  const canDismissEffect = player?.vampire === playerId && pendingEffect?.optional;

  let text = getText(t, play, players, game!, canHuntOrMove, playerId, animation);

  if (!text && game?.activePlayer) {
    const playerName = getName(game.activePlayer, players, t);

    return (
      <>
        {playerId === game.activePlayer ? (
          <>
            {t('header.must.end')}
            <TheHungerButton css={validButton} labelKey="end.turn" onClick={endTurn} />
          </>
        ) : (
          t('header.must.end.other', { player: playerName })
        )}
      </>
    );
  }

  return (
    <>
      {text}
      {(canDismissEffect || playerCanEndTurn) && ' / '}
      {canDismissEffect && (
        <TheHungerButton
          key="dismiss-effect"
          css={validButton}
          labelKey="button.ignore"
          onClick={() => play(dismissEffectMove)}
        />
      )}
      {playerCanEndTurn && (
        <TheHungerButton
          css={validButton}
          labelKey={canHuntOrMove ? 'skip.turn' : 'end.turn'}
          onClick={() => (canHuntOrMove ? setConfirmSkipTurn(true) : endTurn())}
        />
      )}
      {confirmSkipTurn && <SkipTurnConfirmationModal onClose={() => setConfirmSkipTurn(false)} />}
    </>
  );
}

const gameOverText = (
  game: GameView,
  playersInfo: Player<Vampire>[],
  playerId: Vampire,
  t: TFunction<'translation'>
) => {
  if (game) {
    if (!game.players.some((p) => isOtherPlayerView(p) || p.missions.some((m) => m.score === undefined))) {
      const winner = getRanking(game.players, game.round, game.mode, BoardBoxes, Bonuses(game.oldTokensCount))[0];
      if (winner && !isBurned(winner, game.round, game.mode, BoardBoxes, Bonuses(game.oldTokensCount))) {
        return (
          <>
            {winner.vampire === playerId
              ? t('game.winner.you', { score: winner.score })
              : t('game.winner.other', {
                  player: getName(winner.vampire, playersInfo, t),
                  score: winner.score,
                })}
          </>
        );
      }
    } else {
      return <>{t('game.over.scoring')}</>;
    }
  }

  return <>{t('game.over')}</>;
};

const getText = (
  t: TFunction,
  play: (move: Move) => void,
  playersInfo: Player<Vampire>[],
  game: GameView,
  canMoveOrHunt: boolean,
  playerId?: Vampire,
  animation?: Animation<Move>
) => {
  if (
    isOver(game!.players, game!.round, game!.turnOrder, Bonuses(game.oldTokensCount)) &&
    game!.players.some((p) => hasMultifactionToken(p, Bonuses(game.oldTokensCount)))
  ) {
    const playerWithMultifaction = game!.players.filter((p) => hasMultifactionToken(p, Bonuses(game.oldTokensCount)));
    if (playerWithMultifaction.length === 1) {
      let playerName = getName(playerWithMultifaction[0].vampire, playersInfo, t);
      return playerWithMultifaction[0].vampire === playerId
        ? t('header.multifaction.choose')
        : t('header.multifaction.choose.other', { player: playerName });
    } else if (playerWithMultifaction.length > 1) {
      return t('header.players.multifaction');
    }
  }

  if (
    animation &&
    (isFillTavern(animation.move) ||
      isFillHuntTrack(animation.move) ||
      isNewRound(animation.move) ||
      (isFlipToken(animation.move) && animation.move.side === TokenSide.Active))
  ) {
    return t('header.starting.new.round');
  }

  if (
    animation &&
    (isEndTurn(animation.action.move) || animation.action.consequences.some((c) => isEndTurn(c))) &&
    !isPlaceInPlayingAreaMove(animation.move)
  ) {
    return t('header.player.changing');
  }

  let waitingPlayers = game.players.filter((p) => hasMissionChoice(p));
  const hasDrawnAnimation = animation && isDrawMission(animation.move);
  if (!hasDrawnAnimation && waitingPlayers.length > 1) {
    return t('header.players.mission.choose');
  } else if (waitingPlayers.length === 1 || hasDrawnAnimation) {
    const player = hasDrawnAnimation ? game.players.find((p) => p.vampire === game.activePlayer)! : waitingPlayers[0];
    let playerName = getName(player.vampire, playersInfo, t);

    if (!isPushEffect(getNextPendingEffect(player))) {
      return playerId === player.vampire && isMyPlayerView(player)
        ? t('header.player.mission.choose', {
            missionCount:
              game.mode === GameMode.Rookie
                ? 1
                : player.missions.filter((m) => !player.playedMissions.includes(m.mission)).length + 1,
          })
        : t('header.player.mission.choose.other', { player: playerName });
    }
  }

  if (game.activePlayer) {
    const player = game.players.find((p) => p.vampire === game.activePlayer)!;
    const playerName = getName(player.vampire, playersInfo, t);

    let firstBeforePlayingCard = getBeforePlayingCard(player, GameDeck, BoardBoxes);
    let playerHasCardsInHand = !!getPlayerHandSize(player);
    if (playerHasCardsInHand && firstBeforePlayingCard && !player.playedCards.length) {
      if (firstBeforePlayingCard) {
        const cardTitle = HuntCardTitles.get(GameDeck[firstBeforePlayingCard])!.translate(t);
        if (playerId === player.vampire && isMyPlayerView(player)) {
          return (
            <Trans
              defaults="header.beforeplay"
              values={{
                card: cardTitle,
              }}
              components={[
                <TheHungerButton
                  key="activate-card"
                  css={validButton}
                  labelKey="button.yes"
                  onClick={() => play(activateCardMove(firstBeforePlayingCard!))}
                />,
                <TheHungerButton
                  key="dismiss-card"
                  color="red"
                  css={invalidButton}
                  labelKey="button.no"
                  onClick={() => play(placeInPlayingAreaMove(player.hand))}
                />,
              ]}
            />
          );
        } else {
          return t('header.beforeplay.other', {
            player: playerName,
            card: cardTitle,
          });
        }
      }
    }

    if (getActivatableCards(player, GameDeck, BoardBoxes).some((c) => hasMandatoryEffect(GameDeck[c]))) {
      return playerId === player.vampire
        ? t('header.play.mandatory')
        : t('header.play.mandatory.other', { player: playerName });
    }

    if (playerHasCardsInHand && isMyPlayerView(player)) {
      let missionToPlayFirst = getMissionToPlayFirst(player as MyPlayerView, Missions);
      if (missionToPlayFirst && !player.playedCards.length) {
        const missionTitle = MissionTitles.get(Missions[missionToPlayFirst.mission])!(t);
        if (playerId === player.vampire && isMyPlayerView(player)) {
          return (
            <Trans
              defaults="header.beforeplay.mission"
              values={{
                mission: missionTitle,
              }}
              components={[
                <TheHungerButton
                  key="activate-mission"
                  css={validButton}
                  labelKey="button.yes"
                  onClick={() => play(activateMissionMove(missionToPlayFirst!.mission))}
                />,
                <TheHungerButton
                  key="dismiss-mission"
                  css={invalidButton}
                  color="red"
                  labelKey="button.no"
                  onClick={() => play(placeInPlayingAreaMove(player.hand))}
                />,
              ]}
            />
          );
        }
      }
    }

    const pendingEffectMessage = getPendingEffectMessage(playersInfo, player, t, playerId);
    if (pendingEffectMessage) {
      return pendingEffectMessage;
    }

    const activeConfuseEffect = getActiveConfuseEffect(player, GameDeck, BoardBoxes);
    if (activeConfuseEffect) {
      return playerId === game.activePlayer ? (
        <Trans defaults="header.confuse" components={[<strong />]} />
      ) : (
        <Trans defaults="header.confuse.other" components={[<strong />]} values={{ player: playerName }} />
      );
    }

    const spicyEffect = player.playingArea.some((c) => hasSpicyEffect(GameDeck[c]));
    if (spicyEffect) {
      if (isWell(BoardBoxes[player.position.box].effect) && !player.hasMoved) {
        return playerId === game.activePlayer ? (
          <Trans defaults="header.spicy.locked" components={[<strong />]} />
        ) : (
          <Trans defaults="header.spicy.locked.other" components={[<strong />]} values={{ player: playerName }} />
        );
      }

      return playerId === game.activePlayer ? (
        <Trans defaults="header.spicy" components={[<strong />]} />
      ) : (
        <Trans defaults="header.spicy.other" components={[<strong />]} values={{ player: playerName }} />
      );
    }

    if (isShip(BoardBoxes[player.position.box].effect)) {
      return playerId === game.activePlayer ? t('header.ship') : t('header.ship.other', { player: playerName });
    }

    let boardEffect = player.hasMoved && player.boardEffect;
    if (boardEffect) {
      const label = getDigestCardTypeName(boardEffect, t);
      if (label) {
        return playerId === game.activePlayer
          ? t('header.digest.human', { humanType: label })
          : t('header.digest.human.other', {
              player: playerName,
              humanType: label,
            });
      }
    }

    let otherPlayerWithPending = game.players.find((p) => p.vampire !== game.activePlayer && !!p.pendingEffects.length);
    if (otherPlayerWithPending) {
      const pendingEffectMessage = getPendingEffectMessage(playersInfo, otherPlayerWithPending, t, playerId);
      if (pendingEffectMessage) {
        return pendingEffectMessage;
      }
    }

    let moveAndHuntMessages = getMoveAndHuntMessages(player, game, playerId, t, playersInfo, canMoveOrHunt);
    if (moveAndHuntMessages) {
      return moveAndHuntMessages;
    }

    return false;
  }

  return t(`header.waiting`);
};

/**
 * Messages related to pending effects, hunt and playing cards
 */
const getPendingEffectMessage = (
  playersInfo: Player<Vampire>[],
  player: PlayerType,
  t: TFunction,
  playerId?: Vampire,
  animation?: Animation<Move>
) => {
  const pendingEffect = getNextPendingEffect(player);
  if (!pendingEffect || animation) {
    return;
  }

  switch (pendingEffect.type) {
    case EffectType.Ready:
      return playerId === player.vampire
        ? t('header.ready', {
            card: HuntCardTitles.get(GameDeck[pendingEffect.card])!.translate(t),
          })
        : t('header.ready.other', {
            player: getName(player.vampire, playersInfo, t),
          });
    case EffectType.PushVampire:
      return playerId === player.vampire
        ? t('header.push', {
            player: getName(pendingEffect.vampire, playersInfo, t),
          })
        : t('header.push.other', {
            player: getName(player.vampire, playersInfo, t),
            otherPlayer: getName(pendingEffect.vampire, playersInfo, t),
          });
    case EffectType.HuntTrack:
      return playerId === player.vampire
        ? t('header.movehunttrack')
        : t('header.movehunttrack.other', {
            player: getName(player.vampire, playersInfo, t),
          });
    case EffectType.Inspiring:
      return playerId === player.vampire
        ? t('header.inspiring')
        : t('header.inspiring.other', {
            player: getName(player.vampire, playersInfo, t),
          });
    case EffectType.Digest:
      if (pendingEffect.auto) {
        return;
      }

      if (pendingEffect.playingAreaOnly) {
        return playerId === player.vampire
          ? t('header.digest.playingarea')
          : t('header.digest.other', {
              player: getName(player.vampire, playersInfo, t),
            });
      }

      return playerId === player.vampire
        ? t('header.digest')
        : t('header.digest.other', {
            player: getName(player.vampire, playersInfo, t),
          });
    case EffectType.Discard:
      if (pendingEffect.auto) {
        return;
      }

      if (pendingEffect.optional) {
        return playerId === player.vampire
          ? t('header.discard.optional')
          : t('header.discard.optional.other', {
              player: getName(player.vampire, playersInfo, t),
            });
      }

      if (pendingEffect.permanent) {
        return playerId === player.vampire
          ? t('header.discard.permanent')
          : t('header.discard.permanent.other', {
              player: getName(player.vampire, playersInfo, t),
            });
      }

      return playerId === player.vampire
        ? t('header.discard')
        : t('header.discard.other', {
            player: getName(player.vampire, playersInfo, t),
          });
    case EffectType.WinHuntTrackCard:
      if (pendingEffect.huntTypes?.length && pendingEffect.toPlayingArea) {
        return playerId === player.vampire
          ? t('header.winhunttrackcard', {
              type: CardTypeTitles.get(pendingEffect.huntTypes[0])!(t),
            })
          : t('header.winhunttrackcard.other', {
              player: getName(player.vampire, playersInfo, t),
              type: CardTypeTitles.get(pendingEffect.huntTypes[0])!(t),
            });
      }

      if (pendingEffect.huntTypes?.length && pendingEffect.region) {
        return playerId === player.vampire
          ? t('header.freehunt.samecolumn')
          : t('header.freehunt.samecolumn.other', {
              player: getName(player.vampire, playersInfo, t),
            });
      }
      return playerId === player.vampire
        ? t('header.freehunt')
        : t('header.freehunt.other', {
            player: getName(player.vampire, playersInfo, t),
          });

    case EffectType.WinBonusToken:
      return playerId === player.vampire
        ? t('header.freebonus')
        : t('header.freebonus.other', {
            player: getName(player.vampire, playersInfo, t),
          });

    case EffectType.Teleport:
      return playerId === player.vampire
        ? t('header.teleport')
        : t('header.teleport.other', {
            player: getName(player.vampire, playersInfo, t),
          });

    case EffectType.FreeHunt:
      return playerId === player.vampire
        ? t('header.freehunt')
        : t('header.freehunt.other', {
            player: getName(player.vampire, playersInfo, t),
          });
  }

  return;
};

/**
 * Messages related to move, hunt and playing cards
 */
const getMoveAndHuntMessages = (
  player: PlayerType,
  game: GameView,
  playerId: Vampire | undefined,
  t: TFunction,
  playersInfo: Player<Vampire>[],
  canMoveOrHunt: boolean
) => {
  if (game.players.some((p) => hasParasol(p, Bonuses(game.oldTokensCount))) && isOverLastTurn(game.round)) {
    const player = game.players.find((p) => p.vampire === game.activePlayer)!;
    const playerName = getName(player.vampire, playersInfo, t);
    return playerId === player.vampire ? t('header.parasol') : t('header.parasol.other', { player: playerName });
  }

  const isOnGameBoard = (game.displayedView || ViewType.Boards) === ViewType.Boards;
  let boardIndication = isOnGameBoard && canMoveOrHunt ? t('header.canmoveorhuntboard') : undefined;
  boardIndication = !isOnGameBoard && canMoveOrHunt ? t('header.canmoveorhunt') : boardIndication;
  return playerId === game.activePlayer
    ? canMoveOrHunt
      ? boardIndication
      : t('header.your.turn')
    : t('header.player.turn', {
        player: getName(player.vampire, playersInfo, t),
      });
};

const getName = (vampire: Vampire, playersInfo: Player<Vampire>[], t: TFunction) =>
  playersInfo.find((p) => p.id === vampire)?.name || getPlayerName(vampire, t);

const getDigestCardTypeName = (boardEffect: BoardBoxEffect, t: TFunction) => {
  switch (boardEffect) {
    case BoardBoxEffect.Barracks:
      return t('human.military');
    case BoardBoxEffect.Market:
      return t('human.villager');
    case BoardBoxEffect.Mansion:
      return t('human.noble');
    case BoardBoxEffect.Church:
      return t('human.religious');
  }

  return;
};

const validButton = css`
  position: relative;
  height: 1.2em;
  margin-left: 0.3em;
  margin-right: 0.1em;
  text-transform: uppercase;
`;

const invalidButton = css`
  position: relative;
  height: 1.2em;
  margin-left: 0.3em;
  margin-right: 0.1em;
  text-transform: uppercase;
`;

const CardTypeTitles = new Map<CardType, (t: TFunction) => string>();
CardTypeTitles.set(CardType.Familiar, (t) => t('card.familiar'));
