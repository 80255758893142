import { StarterCard } from './StarterCard';
import { CardType, HumanTypes } from '../CardType';
import { Card } from '../Card';
import Vampire from '../../player/Vampire';
import { VictoryPointEffect } from '../../effect/VictoryPointEffect';
import { EffectTrigger } from '../../effect/EffectTrigger';
import { SpeedBonusEffect } from '../../effect/SpeedBonusEffect';
import { DrawEffect } from '../../effect/DrawEffect';

const Hunger: Omit<StarterCard, 'vampire'> = {
  speed: 2,
  category: CardType.StartingCard,
  passiveEffects: [
    new VictoryPointEffect(1, {
      trigger: EffectTrigger.Hunting,
      huntTypes: HumanTypes,
      perHunt: true,
    }),
  ],
};

const VampiricThirst: Omit<StarterCard, 'vampire'> = {
  speed: 1,
  category: CardType.StartingCard,
  passiveEffects: [new SpeedBonusEffect(2, { huntTypes: HumanTypes })],
};

const VampiricStrength: Omit<StarterCard, 'vampire'> = {
  speed: 2,
  category: CardType.StartingCard,
  effects: [new DrawEffect(1, { huntTypes: HumanTypes, mandatory: true })],
};

const VampiricSpeed2: Omit<StarterCard, 'vampire'> = {
  speed: 2,
  category: CardType.StartingCard,
};

const VampiricSpeed3: Omit<StarterCard, 'vampire'> = {
  speed: 3,
  category: CardType.StartingCard,
};

const VampiricSpeed4: Omit<StarterCard, 'vampire'> = {
  speed: 4,
  category: CardType.StartingCard,
};

export const LadyBeatriceHunger: StarterCard = {
  ...Hunger,
  vampire: Vampire.LadyBeatrice,
};
export const LadyBeatriceVampiricThirst: StarterCard = {
  ...VampiricThirst,
  vampire: Vampire.LadyBeatrice,
};
export const LadyBeatriceVampiricStrength: StarterCard = {
  ...VampiricStrength,
  vampire: Vampire.LadyBeatrice,
};
export const LadyBeatriceVampiricSpeed2: StarterCard = {
  ...VampiricSpeed2,
  vampire: Vampire.LadyBeatrice,
};
export const LadyBeatriceVampiricSpeed3: StarterCard = {
  ...VampiricSpeed3,
  vampire: Vampire.LadyBeatrice,
};
export const LadyBeatriceVampiricSpeed4: StarterCard = {
  ...VampiricSpeed4,
  vampire: Vampire.LadyBeatrice,
};

export const BorisPouchkineHunger: StarterCard = {
  ...Hunger,
  vampire: Vampire.BorisPouchkine,
};
export const BorisPouchkineVampiricThirst: StarterCard = {
  ...VampiricThirst,
  vampire: Vampire.BorisPouchkine,
};
export const BorisPouchkineVampiricStrength: StarterCard = {
  ...VampiricStrength,
  vampire: Vampire.BorisPouchkine,
};
export const BorisPouchkineVampiricSpeed2: StarterCard = {
  ...VampiricSpeed2,
  vampire: Vampire.BorisPouchkine,
};
export const BorisPouchkineVampiricSpeed3: StarterCard = {
  ...VampiricSpeed3,
  vampire: Vampire.BorisPouchkine,
};
export const BorisPouchkineVampiricSpeed4: StarterCard = {
  ...VampiricSpeed4,
  vampire: Vampire.BorisPouchkine,
};

export const DonGervasiHunger: StarterCard = {
  ...Hunger,
  vampire: Vampire.DonGervasi,
};
export const DonGervasiVampiricThirst: StarterCard = {
  ...VampiricThirst,
  vampire: Vampire.DonGervasi,
};
export const DonGervasiVampiricStrength: StarterCard = {
  ...VampiricStrength,
  vampire: Vampire.DonGervasi,
};
export const DonGervasiVampiricSpeed2: StarterCard = {
  ...VampiricSpeed2,
  vampire: Vampire.DonGervasi,
};
export const DonGervasiVampiricSpeed3: StarterCard = {
  ...VampiricSpeed3,
  vampire: Vampire.DonGervasi,
};
export const DonGervasiVampiricSpeed4: StarterCard = {
  ...VampiricSpeed4,
  vampire: Vampire.DonGervasi,
};

export const YokoChiyakoHunger: StarterCard = {
  ...Hunger,
  vampire: Vampire.YokoChiyako,
};
export const YokoChiyakoVampiricThirst: StarterCard = {
  ...VampiricThirst,
  vampire: Vampire.YokoChiyako,
};
export const YokoChiyakoVampiricStrength: StarterCard = {
  ...VampiricStrength,
  vampire: Vampire.YokoChiyako,
};
export const YokoChiyakoVampiricSpeed2: StarterCard = {
  ...VampiricSpeed2,
  vampire: Vampire.YokoChiyako,
};
export const YokoChiyakoVampiricSpeed3: StarterCard = {
  ...VampiricSpeed3,
  vampire: Vampire.YokoChiyako,
};
export const YokoChiyakoVampiricSpeed4: StarterCard = {
  ...VampiricSpeed4,
  vampire: Vampire.YokoChiyako,
};

export const JosephineLafayetteHunger: StarterCard = {
  ...Hunger,
  vampire: Vampire.JosephineLafayette,
};
export const JosephineLafayetteVampiricThirst: StarterCard = {
  ...VampiricThirst,
  vampire: Vampire.JosephineLafayette,
};
export const JosephineLafayetteVampiricStrength: StarterCard = {
  ...VampiricStrength,
  vampire: Vampire.JosephineLafayette,
};
export const JosephineLafayetteVampiricSpeed2: StarterCard = {
  ...VampiricSpeed2,
  vampire: Vampire.JosephineLafayette,
};
export const JosephineLafayetteVampiricSpeed3: StarterCard = {
  ...VampiricSpeed3,
  vampire: Vampire.JosephineLafayette,
};
export const JosephineLafayetteVampiricSpeed4: StarterCard = {
  ...VampiricSpeed4,
  vampire: Vampire.JosephineLafayette,
};

export const RajeshAmaraHunger: StarterCard = {
  ...Hunger,
  vampire: Vampire.RajeshAmara,
};
export const RajeshAmaraVampiricThirst: StarterCard = {
  ...VampiricThirst,
  vampire: Vampire.RajeshAmara,
};
export const RajeshAmaraVampiricStrength: StarterCard = {
  ...VampiricStrength,
  vampire: Vampire.RajeshAmara,
};
export const RajeshAmaraVampiricSpeed2: StarterCard = {
  ...VampiricSpeed2,
  vampire: Vampire.RajeshAmara,
};
export const RajeshAmaraVampiricSpeed3: StarterCard = {
  ...VampiricSpeed3,
  vampire: Vampire.RajeshAmara,
};
export const RajeshAmaraVampiricSpeed4: StarterCard = {
  ...VampiricSpeed4,
  vampire: Vampire.RajeshAmara,
};

export const LadyBeatriceStarter: StarterCard[] = [
  LadyBeatriceHunger,
  LadyBeatriceVampiricThirst,
  LadyBeatriceVampiricStrength,
  LadyBeatriceVampiricSpeed2,
  LadyBeatriceVampiricSpeed3,
  LadyBeatriceVampiricSpeed4,
];

export const BorisPouchkineStarter: StarterCard[] = [
  BorisPouchkineHunger,
  BorisPouchkineVampiricThirst,
  BorisPouchkineVampiricStrength,
  BorisPouchkineVampiricSpeed2,
  BorisPouchkineVampiricSpeed3,
  BorisPouchkineVampiricSpeed4,
];

export const DonGervasiStarter: StarterCard[] = [
  DonGervasiHunger,
  DonGervasiVampiricThirst,
  DonGervasiVampiricStrength,
  DonGervasiVampiricSpeed2,
  DonGervasiVampiricSpeed3,
  DonGervasiVampiricSpeed4,
];

export const YokoChiyakoStarter: StarterCard[] = [
  YokoChiyakoHunger,
  YokoChiyakoVampiricThirst,
  YokoChiyakoVampiricStrength,
  YokoChiyakoVampiricSpeed2,
  YokoChiyakoVampiricSpeed3,
  YokoChiyakoVampiricSpeed4,
];

export const JosephineLafayetteStarter: StarterCard[] = [
  JosephineLafayetteHunger,
  JosephineLafayetteVampiricThirst,
  JosephineLafayetteVampiricStrength,
  JosephineLafayetteVampiricSpeed2,
  JosephineLafayetteVampiricSpeed3,
  JosephineLafayetteVampiricSpeed4,
];

export const RajeshAmaraStarter: StarterCard[] = [
  RajeshAmaraHunger,
  RajeshAmaraVampiricThirst,
  RajeshAmaraVampiricStrength,
  RajeshAmaraVampiricSpeed2,
  RajeshAmaraVampiricSpeed3,
  RajeshAmaraVampiricSpeed4,
];

export const Starters: StarterCard[] = [
  ...LadyBeatriceStarter,
  ...BorisPouchkineStarter,
  ...DonGervasiStarter,
  ...YokoChiyakoStarter,
  ...JosephineLafayetteStarter,
  ...RajeshAmaraStarter,
];

export const isStarting = (card: Card): card is StarterCard => CardType.StartingCard === card.category;
