/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, useMemo } from 'react';
import {
  gameBoardCardHeight,
  gameBoardCardWidth,
  tavernToMainViewScale,
  tavernToMainViewTranslateX,
  tavernToMainViewTranslateY,
  tavernToPlayerTranslateX,
  tavernToPlayerTranslateY,
  tavernTranslateX,
  tavernTranslateY,
  zoomedTavernCardTranslateX,
  zoomedTavernCardTranslateY,
} from '../../utils/Style';
import { PlayerType } from '@gamepark/the-hunger/player';
import { huntInTavernMove, isHuntInTavern } from '@gamepark/the-hunger/moves/HuntInTavern';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { computeSpeedForHunt } from '@gamepark/the-hunger/utils/GameUtils';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { HuntCard } from '../card/HuntCard';
import { getActivePlayerIndex, getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { canHuntTavern } from '@gamepark/the-hunger/utils/HuntUtils';

type TavernPileProps = {
  tavern: number;
  player?: PlayerType;
  players: PlayerType[];
  visible: boolean;
};

const TavernPile: FC<TavernPileProps> = ({ player, players, tavern, visible }) => {
  const { t } = useTranslation();
  const play = usePlay();
  const playerId = usePlayerId();
  const canDrawTavern = player && canHuntTavern(player, computeSpeedForHunt(player, GameDeck), tavern, GameDeck);
  const animation = useAnimation((animation) => isHuntInTavern(animation.move));

  const huntTavernMove = animation?.move;
  const huntTavernDuration: number = animation ? animation.duration : 0;

  // eslint-disable-next-line
  const realPlayers = useMemo(() => getOrderedPlayersForDisplay(players, playerId), [players]);
  const playerIndex = huntTavernMove && player && getActivePlayerIndex(realPlayers, player.vampire);

  const drawTavern = () => {
    if (canDrawTavern) {
      play(huntInTavernMove(tavern), { delayed: true });
    }
  };

  const canSelectTavern = canDrawTavern && !huntTavernMove;
  return (
    <>
      {!!tavern &&
        [...Array(tavern)].map((_, index) => (
          <HuntCard
            key={index}
            hunt={huntTavernMove ? GameDeck[huntTavernMove.hunts[index]] : undefined}
            css={[
              tavernCard,
              huntTavernMove && huntInTavernAnimation(index, playerIndex, players.length, tavern, huntTavernDuration),
              !visible && notTargetable,
            ]}
            preTransform={`translate(${zoomedTavernCardTranslateX(index)}%, ${zoomedTavernCardTranslateY(
              index
            )}%) rotateZ(-90deg) ${huntTavernMove && huntTavernMove.hunts[index] ? 'rotateY(180deg)' : ''} scale(${
              1 / tavernToMainViewScale
            })`}
            onClick={drawTavern}
          />
        ))}
      <div
        css={[tavernOverlay, canSelectTavern && tavernOverlaySelectable]}
        onClick={canSelectTavern ? drawTavern : undefined}
        data-tip
        data-for={`card-tavern`}
      />
      <ReactTooltip
        className="the-hunger-tooltip"
        offset={{ right: 10 }}
        id={`card-tavern`}
        type="info"
        effect="solid"
        place="right"
      >
        <span css={cardCount}>{t('card.count', { cardCount: tavern })} </span>
      </ReactTooltip>
    </>
  );
};

const huntInTavernAnimation = (
  index: number,
  playerIndex: number,
  playerCount: number,
  cardCount: number,
  duration: number
) => css`
  z-index: 99;
  animation: ${duration}s ${animateHuntInTavern(index, playerIndex, playerCount, cardCount)} ease-in-out both;
`;

const animateHuntInTavern = (index: number, playerIndex: number, playerCount: number, cardCount: number) => keyframes`
  30% {
    transform: translate(${tavernToMainViewTranslateX(
      index,
      cardCount
    )}%, ${tavernToMainViewTranslateY}%) rotateZ(0deg) rotateY(180deg);
  }
  50%, 70% {
    transform: translate(${tavernToMainViewTranslateX(
      index,
      cardCount
    )}%, ${tavernToMainViewTranslateY}%) rotateZ(0deg) rotateY(0deg);
  }
  90% {
    transform: translate(${tavernToPlayerTranslateX - 10}%, ${
  tavernToPlayerTranslateY(playerIndex, playerCount) + 7
}%) rotateZ(0deg) rotateY(0deg) scale(${1 / tavernToMainViewScale});
  }
  100% {
    transform: translate(${tavernToPlayerTranslateX + 10}%, ${
  tavernToPlayerTranslateY(playerIndex, playerCount) + 7
}%) rotateZ(0deg) rotateY(0deg) scale(0);
  }
`;

const tavernOverlay = css`
  position: absolute;
  height: ${gameBoardCardHeight + 1}%;
  width: ${gameBoardCardWidth + 1}%;
  border-radius: 1em;
  transform: translate(${tavernTranslateX(0) - 8}%, ${tavernTranslateY(0) - 10}%) rotateZ(-90deg);
`;

const tavernOverlaySelectable = css`
  box-shadow: 0 0 0 0.5em gold;

  &:hover {
    box-shadow: 0 0 0 0.5em green;
  }

  cursor: pointer;
`;

const tavernCard = css`
  position: absolute;
  height: ${gameBoardCardHeight * tavernToMainViewScale}%;
  width: ${gameBoardCardWidth * tavernToMainViewScale}%;
  font-size: ${(gameBoardCardHeight * tavernToMainViewScale) / 100}em;
`;

const notTargetable = css`
  cursor: default;
  pointer-events: none;
  transition-duration: 0.5s;
`;

const cardCount = css`
  white-space: nowrap;
  font-size: 1.4em;
`;

export { TavernPile };
