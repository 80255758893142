import GameView from '@gamepark/the-hunger/GameView'
import MoveType from '@gamepark/the-hunger/moves/MoveType'
import MoveView from '@gamepark/the-hunger/moves/MoveView'
import {Action, Rules, Undo} from '@gamepark/rules-api'
import {getPredictableAutomaticMoves} from '@gamepark/the-hunger/rules/AutomaticMoves'
import Move from '@gamepark/the-hunger/moves/Move'
import Vampire from '@gamepark/the-hunger/player/Vampire'
import {canUndo} from '@gamepark/the-hunger/utils/CanUndo'
import {SelectVampire, selectVampire} from './moves/SelectVampire'
import {drawHuntCardView} from '@gamepark/the-hunger/moves/DrawHuntCard'
import {DisplayVampire, displayVampire} from './moves/DisplayVampire'
import {dismissEffect} from '@gamepark/the-hunger/moves/DismissEffect'
import {placeInDiscard} from '@gamepark/the-hunger/moves/PlaceInDiscard'
import {activateBonus} from '@gamepark/the-hunger/moves/ActivateBonus'
import {victoryPoint} from '@gamepark/the-hunger/moves/VictoryPoint'
import {drawMissionsView} from '@gamepark/the-hunger/moves/DrawMissions'
import {endTurn} from '@gamepark/the-hunger/moves/EndTurn'
import {discardCards} from '@gamepark/the-hunger/moves/DiscardCards'
import {activateCard} from '@gamepark/the-hunger/moves/ActivateCard'
import {moveVampire} from '@gamepark/the-hunger/moves/MoveVampire'
import {chooseMissionsView} from '@gamepark/the-hunger/moves/ChooseMission'
import {huntOnTrack} from '@gamepark/the-hunger/moves/HuntOnTrack'
import {huntInTavernView} from '@gamepark/the-hunger/moves/HuntInTavern'
import {huntRose} from '@gamepark/the-hunger/moves/HuntRose'
import {nextPlayer} from '@gamepark/the-hunger/moves/NextPlayer'
import {flipToken} from '@gamepark/the-hunger/moves/FlipToken'
import {newRound} from '@gamepark/the-hunger/moves/NewRound'
import {shuffleDiscardToDeckView} from '@gamepark/the-hunger/moves/ShuffleDiscardToDeck'
import {fillHuntTrackView} from '@gamepark/the-hunger/moves/FillHuntTrack'
import {fillTavernView} from '@gamepark/the-hunger/moves/FillTavern'
import {moveHuntTrackCard} from '@gamepark/the-hunger/moves/MoveHuntTrackCard'
import {drawCardsView} from '@gamepark/the-hunger/moves/DrawCards'
import {acquireBonusTokenView} from '@gamepark/the-hunger/moves/AcquireBonus'
import {digestCard} from '@gamepark/the-hunger/moves/DigestCards'
import {placeInPlayingArea} from '@gamepark/the-hunger/moves/PlaceInPlayingArea'
import {placeOnDeckView} from '@gamepark/the-hunger/moves/PlaceOnDeck'
import {GameDeck} from '@gamepark/the-hunger/card/hunt/GameDeck'
import {endOfGameView} from '@gamepark/the-hunger/moves/EndOfGame'
import {activateMission} from '@gamepark/the-hunger/moves/ActivateMission'
import {Missions} from '@gamepark/the-hunger/mission'
import {BoardBoxes} from '@gamepark/the-hunger/board'
import {Bonuses} from '@gamepark/the-hunger/bonus/Bonuses'
import {HighlightBox, highlightBox} from './moves/HighlightBox'
import {DisplayView, displayView} from './moves/DisplayView'
import {displayScore, DisplayScore} from './moves/DisplayScore'
import {isActive} from '@gamepark/the-hunger/utils/IsActive'

type LocalMove = MoveView | SelectVampire | DisplayVampire | HighlightBox | DisplayView | DisplayScore;

/**
 * This class is useful when the game has "IncompleteInformation" (or "SecretInformation").
 * It allows to handle, in a different way than the backend side, the moves that involve hidden information.
 */
export default class TheHungerView extends Rules<GameView, MoveView, Vampire> implements Undo<GameView, MoveView, Vampire> {

  getAutomaticMoves(): MoveView[] {
    return getPredictableAutomaticMoves(
      this.state,
      Bonuses(this.state.oldTokensCount),
      this.state.players.find((p) => p.vampire === this.state.activePlayer)
    );
  }

  isTurnToPlay(playerId: Vampire): boolean {
    const player = this.state.players.find((p) => p.vampire === playerId)!;
    return isActive(
      player,
      this.state.players.filter((p) => p.vampire !== player.vampire),
      playerId,
      this.state.round,
      this.state.turnOrder,
      this.state.hunt.track,
      this.state.tavern,
      GameDeck,
      BoardBoxes,
      Bonuses(this.state.oldTokensCount),
      this.state.activePlayer
    );
  }

  play(move: LocalMove): MoveView[] {
    const bonusList = Bonuses(this.state.oldTokensCount);
    switch (move.type) {
      case MoveType.VictoryPoint:
        victoryPoint(this.state, move, GameDeck);
        break;
      case MoveType.DrawMissions:
        drawMissionsView(this.state, move, GameDeck);
        break;
      case MoveType.EndTurn:
        endTurn(this.state);
        break;
      case MoveType.DiscardCards:
        discardCards(this.state, move);
        break;
      case MoveType.ActivateCard:
        activateCard(this.state, move);
        break;
      case MoveType.MoveVampire:
        moveVampire(this.state, move, GameDeck, BoardBoxes);
        break;
      case MoveType.ChooseMission:
        chooseMissionsView(this.state, move);
        break;
      case MoveType.HuntOnTrack:
        huntOnTrack(this.state, move, GameDeck, BoardBoxes);
        break;
      case MoveType.HuntInTavern:
        huntInTavernView(this.state, move, BoardBoxes);
        break;
      case MoveType.HuntRose:
        huntRose(this.state, move, BoardBoxes);
        break;
      case MoveType.NextPlayer:
        nextPlayer(this.state);
        break;
      case MoveType.FlipToken:
        flipToken(this.state, move);
        break;
      case MoveType.NewRound:
        newRound(this.state, bonusList);
        break;
      case MoveType.ShuffleDiscardToDeck:
        shuffleDiscardToDeckView(this.state, move);
        break;
      case MoveType.FillHuntTrack:
        fillHuntTrackView(this.state, move);
        break;
      case MoveType.FillTavern:
        fillTavernView(this.state);
        break;
      case MoveType.MoveHuntTrackCard:
        moveHuntTrackCard(this.state, move);
        break;
      case MoveType.DrawCards:
        drawCardsView(this.state, move);
        break;
      case MoveType.AcquireBonusToken:
        acquireBonusTokenView(this.state, move, bonusList);
        break;
      case MoveType.DigestCards:
        digestCard(this.state, move, GameDeck);
        break;
      case MoveType.PlaceInPlayingArea:
        placeInPlayingArea(this.state, move);
        break;
      case MoveType.PlaceOnDeck:
        placeOnDeckView(this.state, move);
        break;
      case MoveType.DrawHuntCard:
        drawHuntCardView(this.state, move, GameDeck);
        break;
      case MoveType.DismissEffect:
        dismissEffect(this.state);
        break;
      case MoveType.PlaceInDiscard:
        placeInDiscard(this.state, move);
        break;
      case MoveType.ActivateBonus:
        activateBonus(this.state, move, GameDeck, bonusList);
        break;
      case MoveType.ActivateMission:
        activateMission(this.state, move, GameDeck, Missions);
        break;
      case MoveType.EndOfGame:
        endOfGameView(this.state, move, GameDeck, BoardBoxes, bonusList, Missions);
        break;
      case 'SelectVampire':
        selectVampire(this.state, move);
        break;
      case 'DisplayVampire':
        displayVampire(this.state, move);
        break;
      case 'HighlightBox':
        highlightBox(this.state, move);
        break;
      case 'DisplayView':
        displayView(this.state, move);
        break;
      case 'DisplayScore':
        displayScore(this.state, move);
        break;
    }
    return []
  }

  // @ts-ignore
  canUndo(action: Action<Move, Vampire>, consecutiveActions: Action<Move, Vampire>[]): boolean {
    return canUndo(this.state, action, consecutiveActions, Bonuses(this.state.oldTokensCount));
  }

  restoreLocalMoves(localState: GameView) {
    this.state.displayedPlayer = localState.displayedPlayer;
    this.state.displayedView = localState.displayedView;
    this.state.hightlightedBox = localState.hightlightedBox;
    this.state.selectedVampire = localState.selectedVampire;
    this.state.displayedScore = localState.displayedScore;
  }
}
