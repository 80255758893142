/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { humanTypeSymbolHeight, humanTypeSymbolWidth } from '../utils/Style';
import { Images } from '../images/Images';

type HumanTypeSymbolProps = {
  category: CardType
  width?: number
  hd?: boolean
} & HTMLAttributes<HTMLDivElement>;

const HumanTypeSymbol: FC<HumanTypeSymbolProps> = ({ category, hd, width, ...props }) => {
  return (
    <span css={mini(category, hd, width)} {...props} />
  );
};

const mini = (humanType: CardType, hd?: boolean, width?: number) => css`
  height: ${humanTypeSymbolHeight(width)}em;
  width: ${width || humanTypeSymbolWidth}em;
  background-image: url(${(hd? humanTypeSymbolsHD: humanTypeSymbols).get(humanType)!});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  top: 0.65em;
  position: relative;
  margin-bottom: -0.5em;
  transform: translateY(-50%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
`;

const humanTypeSymbols = new Map<CardType, any>();
humanTypeSymbols.set(CardType.HumanNoble, Images.HumanNobleSymbol);
humanTypeSymbols.set(CardType.HumanVillager, Images.HumanVillagerSymbol);
humanTypeSymbols.set(CardType.HumanReligious, Images.HumanReligiousSymbol);
humanTypeSymbols.set(CardType.HumanMilitary, Images.HumanMilitarySymbol);
const humanTypeSymbolsHD = new Map<CardType, any>();
humanTypeSymbolsHD.set(CardType.HumanNoble, Images.HumanNobleSymbolHD);
humanTypeSymbolsHD.set(CardType.HumanVillager, Images.HumanVillagerSymbolHD);
humanTypeSymbolsHD.set(CardType.HumanReligious, Images.HumanReligiousSymbolHD);
humanTypeSymbolsHD.set(CardType.HumanMilitary, Images.HumanMilitarySymbolHD);

export {
  HumanTypeSymbol
};
