/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PlayerType } from '@gamepark/the-hunger/player';
import { FC, HTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import {
  playerEffectsSummaryHeight,
  playerEffectsSummaryLeft,
  playerEffectsSummaryTop,
  playerEffectsSummaryWidth,
} from '../../../utils/Style';
import { PlayerEffect } from './PlayerEffect';
import { useOutsideAlerter } from '../../../utils/useOutsideAlerter';
import {
  isAdditionalHunt,
  isDiscard,
  isDraw,
  isGregariousEffect,
  isInspiring,
  isPushEffect,
  isReadyEffect,
  isSpeedBonus,
  isVictoryPoint,
  isVictoryPointPerTrait,
} from '@gamepark/the-hunger/utils/EffectUtils';
import { SpeedBonusEffect } from '@gamepark/the-hunger/effect/SpeedBonusEffect';
import { EffectTrigger } from '@gamepark/the-hunger/effect/EffectTrigger';
import { GameEffects, isGameEffect } from '@gamepark/the-hunger/effect/GameEffect';
import { isPeacefulEffect } from '@gamepark/the-hunger/utils/GameUtils';
import { BoardBoxEffect, BoardBoxes } from '@gamepark/the-hunger/board';
import { PositionedBonus } from '@gamepark/the-hunger/bonus/PositionedBonus';
import { BoardEffectBubble } from './bubbles/BoardEffectBubble';
import { HuntCardBubble } from './bubbles/HuntCardBubble';
import { MissionBubble } from './bubbles/MissionBubble';
import { getActivePlayerIndex, getOrderedPlayersForDisplay } from '@gamepark/the-hunger/utils/PlayerUtils';
import { usePlayerId } from '@gamepark/react-client';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { isActivableCard } from '@gamepark/the-hunger/utils/CardUtils';
import { useSelector } from 'react-redux';

type PlayerEffectsProps = {
  activePlayer: Vampire;
  players: Array<PlayerType>;
  track: number[][][];
  bonuses: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>;
  isGameOver: boolean;
} & HTMLAttributes<HTMLDivElement>;

const transformEffect = (e: GameEffects, player: PlayerType) =>
  isSpeedBonus(e)
    ? {
        mission: undefined,
        card: e.card,
        effect: e,
        speed: SpeedBonusEffect.computeSpeedBonus(player, e, GameDeck),
      }
    : {
        mission: undefined,
        card: e.card,
        effect: e,
      };

const getIndexDependingOnActivePlayer = (
  index: number,
  playerCount: number,
  activePlayerIndex: number,
  playerInfo: any
): number => {
  if (!playerInfo?.time?.playing) {
    return index;
  }

  if (playerCount < 6 && activePlayerIndex === 1) {
    return index + 2;
  }

  if (playerCount === 6 && (activePlayerIndex === 3 || (index >= 6 && activePlayerIndex === 4))) {
    return index + 2;
  }

  return index;
};

const PlayerEffects: FC<PlayerEffectsProps> = ({ activePlayer, players, track, bonuses, isGameOver, ...props }) => {
  const playerId = usePlayerId();
  const [displayed, setDisplayed] =
    useState<{ card?: number; mission?: number; effect?: GameEffects | BoardBoxEffect; index: number }>();
  // eslint-disable-next-line
  const sortedPlayers = useMemo(() => getOrderedPlayersForDisplay(players, playerId), []);
  const player = players.find((p) => p.vampire === activePlayer)!;
  const playerInfo = useSelector((state: any) => state.players.find((p: any) => p.id === player.vampire));
  const playerIndex = getActivePlayerIndex(sortedPlayers, activePlayer);

  const toggleEffect = (index: number, card?: number, mission?: number, effect?: GameEffects | BoardBoxEffect) => {
    if (!card && !mission) {
      if (effect && !displayed) {
        setDisplayed({ card, mission, effect, index });
      }
      return;
    }

    if (!displayed) {
      setDisplayed({ card, mission, index });
    }
  };

  const isIncludedPassiveEffect = useCallback(
    (e: GameEffects) => {
      return (
        !isInspiring(e) &&
        !isGregariousEffect(e) &&
        !isVictoryPointPerTrait(e) &&
        e.trigger !== EffectTrigger.EndOfGame &&
        e.trigger !== EffectTrigger.Hunted &&
        (!isSpeedBonus(e) || SpeedBonusEffect.computeSpeedBonus(player, e, GameDeck) > 0)
      );
    },
    // eslint-disable-next-line
    [player]
  );

  //const isIncludedActiveEffect = useCallback((e: GameEffects) => isDiscard(e) || isDigest(e) || isDraw(e), []);
  const isIncludedPendingEffect = useCallback(
    (e: GameEffects) => !isPushEffect(e) && !isVictoryPoint(e) && !isReadyEffect(e) && !isGregariousEffect(e),
    []
  );
  const tooltipRef = useRef(null);

  const onClickOutsideTooltip = useCallback(() => {
    if (displayed) {
      setDisplayed(undefined);
    }
    // eslint-disable-next-line
  }, [displayed]);

  useOutsideAlerter(tooltipRef, onClickOutsideTooltip);

  const deduplicateAdditionalHunts = (
    pendingEffects: Array<{ card?: number; mission?: number; effect: GameEffects | BoardBoxEffect }>
  ) => {
    const nonFreeHunts = player.hunts.filter((h) => !h.free);
    const additionalHunts = pendingEffects.filter((e) => isGameEffect(e.effect) && isAdditionalHunt(e.effect));
    if (!additionalHunts.length) {
      return pendingEffects;
    }

    const remainingAdditionalHunts =
      nonFreeHunts.length === 0 ? additionalHunts.length : additionalHunts.length - (nonFreeHunts.length - 1);
    return [
      ...(remainingAdditionalHunts === 0 ? [] : additionalHunts.slice(-remainingAdditionalHunts)),
      ...pendingEffects.filter((e) => !isGameEffect(e.effect) || !isAdditionalHunt(e.effect)),
    ];
  };

  const computedEffects = useMemo(() => {
    let effects: Array<{ card?: number; mission?: number; effect: GameEffects | BoardBoxEffect }> = [];

    effects.push(
      ...player.playingArea
        .filter((c) => isActivableCard(c, player, GameDeck, BoardBoxes))
        .flatMap((c) =>
          (GameDeck[c].effects || [])
            .filter((e) => isDraw(e) || isDiscard(e))
            .map((e) => {
              e.card = c;
              return e;
            })
            .slice(0, 1)
        )
        .map((e) => transformEffect(e, player))
    );

    effects.push(
      ...player.playingArea.flatMap((c) =>
        (GameDeck[c].passiveEffects || [])
          .map((e) => {
            e.card = c;
            return e;
          })
          .filter((e) => !isInspiring(e) && (!isDiscard(e) || !e.otherPlayer))
          .filter((e) => !e.isApplicable || e.isApplicable(player, GameDeck, BoardBoxes, track, bonuses))
          .filter(isIncludedPassiveEffect)
          .map((e) => transformEffect(e, player))
      )
    );

    effects.push(...player.pendingEffects.filter(isIncludedPendingEffect).map((e) => transformEffect(e, player)));

    let boardEffect = BoardBoxes[player.position.box].effect;
    if (boardEffect && isPeacefulEffect(boardEffect)) {
      effects.push({ mission: undefined, card: undefined, effect: boardEffect });
    } else if (player.boardEffect) {
      effects.push({ mission: undefined, card: undefined, effect: player.boardEffect });
    }

    return deduplicateAdditionalHunts(effects);
    // eslint-disable-next-line
  }, [player.playingArea, player.pendingEffects, player.playedCards, player.boardEffect, player.position.box]);

  const playerCount = players.length;
  return (
    <>
      {displayed && displayed.card === undefined && displayed.mission === undefined && (
        <BoardEffectBubble
          ref={tooltipRef}
          playerCount={playerCount}
          effect={displayed!.effect as BoardBoxEffect}
          index={displayed!.index}
        />
      )}
      {displayed && (
        <HuntCardBubble ref={tooltipRef} playerCount={playerCount} card={displayed.card} index={displayed.index} />
      )}
      {displayed && (
        <MissionBubble ref={tooltipRef} playerCount={playerCount} mission={displayed.mission} index={displayed.index} />
      )}
      <div css={playerEffects(playerCount)} {...props}>
        {computedEffects.map((entry, index: number) => {
          const { card, mission, effect, ...rest } = entry;
          return (
            <PlayerEffect
              key={index}
              effect={effect}
              index={getIndexDependingOnActivePlayer(index, playerCount, playerIndex, playerInfo)}
              context={rest}
              opened={displayed?.index === index}
              onIconClick={() =>
                toggleEffect(
                  getIndexDependingOnActivePlayer(index, playerCount, playerIndex, playerInfo),
                  card,
                  mission,
                  effect
                )
              }
            />
          );
        })}
      </div>
    </>
  );
};

const playerEffects = (playerCount: number) => css`
  position: absolute;
  background-color: rgba(1, 94, 6, 0.7);
  box-shadow: green 0 0 2em, green 0 0 1em;
  border-radius: 2em;
  top: ${playerEffectsSummaryTop(playerCount)}%;
  left: ${playerEffectsSummaryLeft}%;
  height: ${playerEffectsSummaryHeight}%;
  font-size: ${playerEffectsSummaryHeight / 100}em;
  width: ${playerEffectsSummaryWidth}%;
`;

export { PlayerEffects };
