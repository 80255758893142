enum DraggableTypes {
  MissionTile = 'MissionTile',
  HuntCardFromHand = 'HuntCardFromHand',
  PlayableCard = 'PlayableCard',
  PlayerTokenFromBoard = 'PlayerTokenFromBoard',
  HuntTrackArea = 'HuntTrackArea'
}

export {
  DraggableTypes
}
