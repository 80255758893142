/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { popupBackgroundStyle } from '@gamepark/the-hunger-app/src/utils/Style';
import { BonusToken } from './BonusToken';
import {
  AdditionalHunt,
  AdditionalMission,
  Bonuses,
  Church,
  Discard,
  Draw,
  Military,
  MultiFaction,
  Noble,
  OneSpeed,
  Parasol,
  TwoSpeed,
  VelvetBoots,
  VelvetCoat,
  VelvetGloves,
  VelvetHat,
  VelvetPants,
  VelvetScarf,
  Villager,
} from '@gamepark/the-hunger/bonus/Bonuses';
import { Bonus } from '@gamepark/the-hunger/bonus/Bonus';
import { Trans, useTranslation } from 'react-i18next';
import { TheHungerButton } from '../../utils/TheHungerButton';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import { activateBonusMove } from '@gamepark/the-hunger/moves/ActivateBonus';
import { acquireBonusTokenMove } from '@gamepark/the-hunger/moves/AcquireBonus';
import { isDiscard, isDraw } from '@gamepark/the-hunger/utils/EffectUtils';

export type FullscreenBonusProps = {
  bonus: number;
  position?: number;
  playable?: boolean;
  acquire?: boolean;
  fromPlayer?: boolean;
  used?: boolean;
  onClose: () => void;
  oldTokensCount?: boolean;
};

const FullscreenBonus: FC<FullscreenBonusProps> = ({
  bonus,
  position,
  playable,
  acquire,
  used,
  fromPlayer,
  oldTokensCount,
  onClose,
}) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { t } = useTranslation();

  const playBonus = () => {
    if (!playable) {
      return;
    }

    play(activateBonusMove(bonus));

    if (onClose) {
      onClose();
    }
  };

  const acquireBonus = () => {
    if (!acquire || !position) {
      return;
    }

    play(acquireBonusTokenMove(playerId, position), { delayed: true });

    if (onClose) {
      onClose();
    }
  };

  const bonusList = Bonuses(oldTokensCount);

  const b = bonusList[bonus];
  const effect = b.effects?.length ? b.effects[0] : undefined;
  const mustBePlayedFirst = !playable && !used && (isDiscard(effect) || isDraw(effect));

  const token = (
    <>
      <div key="bonus-token-overlay" css={popupBackgroundStyle} onClick={onClose} />
      {playable && (
        <TheHungerButton key="play-token" css={playBonusToken} onClick={playBonus} labelKey="button.play.token" />
      )}
      {mustBePlayedFirst && fromPlayer && <div css={bonusTokenNotEnabled}>{t('bonustoken.playfirst')}</div>}
      {used && fromPlayer && <div css={bonusTokenNotEnabled}>{t('bonustoken.used')}</div>}
      {acquire && position && (
        <TheHungerButton
          key="choose-token"
          css={playBonusToken}
          onClick={acquireBonus}
          labelKey="button.acquire.token"
        />
      )}
      <BonusToken key="bonus-token" css={bonusToken} type={bonusList[bonus]} />
      <div css={bonusTokenDescription}>{BonusTokenDescriptions.get(bonusList[bonus])} </div>
    </>
  );

  const letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(token, letterBox);
  }

  return null;
};

const bonusToken = css`
  position: absolute;
  z-index: 999;
  height: 13em;
  width: 13em;
  top: 35%;
  left: calc(50% - 6.5em);
`;

const playBonusToken = css`
  position: absolute;
  z-index: 999;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 5em;
  padding: 0.8em;
`;

const bonusTokenDescription = css`
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 55%;
  transform: translateX(-50%);
  color: white;
  font-size: 3em;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.6em;
  border-radius: 0.3em;
`;

const bonusTokenNotEnabled = css`
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  color: white;
  font-size: 3em;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.6em;
  border-radius: 0.3em;
`;

const BonusTokenDescriptions = new Map<Bonus, any>();
BonusTokenDescriptions.set(Church, <Trans defaults="bonus.desc.church" components={[<strong />]} />);
BonusTokenDescriptions.set(Military, <Trans defaults="bonus.desc.military" components={[<strong />]} />);
BonusTokenDescriptions.set(Villager, <Trans defaults="bonus.desc.villager" components={[<strong />]} />);
BonusTokenDescriptions.set(Noble, <Trans defaults="bonus.desc.noble" components={[<strong />]} />);
BonusTokenDescriptions.set(MultiFaction, <Trans defaults="bonus.desc.multifaction" components={[<strong />]} />);
BonusTokenDescriptions.set(OneSpeed, <Trans defaults="bonus.desc.onespeed" components={[<strong />]} />);
BonusTokenDescriptions.set(TwoSpeed, <Trans defaults="bonus.desc.twospeed" components={[<strong />]} />);
BonusTokenDescriptions.set(AdditionalHunt, <Trans defaults="bonus.desc.additionalhunt" components={[<strong />]} />);
BonusTokenDescriptions.set(Discard, <Trans defaults="bonus.desc.discard" components={[<strong />]} />);
BonusTokenDescriptions.set(Draw, <Trans defaults="bonus.desc.draw" components={[<strong />]} />);
BonusTokenDescriptions.set(
  AdditionalMission,
  <Trans defaults="bonus.desc.additionalMission" components={[<strong />]} />
);
BonusTokenDescriptions.set(Parasol, <Trans defaults="bonus.desc.parasol" components={[<strong />]} />);
BonusTokenDescriptions.set(VelvetBoots, <Trans defaults="bonus.desc.velvelboots" components={[<strong />]} />);
BonusTokenDescriptions.set(VelvetCoat, <Trans defaults="bonus.desc.velvetcoat" components={[<strong />]} />);
BonusTokenDescriptions.set(VelvetGloves, <Trans defaults="bonus.desc.velvetgloves" components={[<strong />]} />);
BonusTokenDescriptions.set(VelvetHat, <Trans defaults="bonus.desc.velvethat" components={[<strong />]} />);
BonusTokenDescriptions.set(VelvetPants, <Trans defaults="bonus.desc.velvetpants" components={[<strong />]} />);
BonusTokenDescriptions.set(VelvetScarf, <Trans defaults="bonus.desc.velvetscarf" components={[<strong />]} />);
export { FullscreenBonus };
