import {
  Agnes,
  Angus,
  Anton,
  Archibald,
  Arthur,
  Bagoly,
  BaronChristien,
  Belle,
  Bernard,
  Billy,
  Bippo,
  Bo,
  Bolat,
  Boo,
  Boris,
  Bradford,
  Bridget,
  Bruce,
  Caine,
  Calvin,
  Campbell,
  Capra,
  Carlyle,
  Catarina,
  ChantreSami,
  Chop,
  Cotton,
  Cyrana,
  DeadRose,
  Dee,
  Diego,
  Echo,
  Eglantine,
  Eleanor,
  Eli,
  Elois,
  Eponime,
  EternalRose,
  Eunice,
  Faith,
  Faviana,
  FormOfBat,
  FormOfMist,
  FrereStewart,
  FrereTunk,
  Grant,
  Gray,
  Harper,
  Henrietta,
  Hypnosis,
  Isabel,
  Ivan,
  Ivo,
  Jack,
  Jahda,
  Juri,
  Kaa,
  Khasar,
  Kutya,
  LaPretresse,
  Lockjaw,
  Malac,
  Marcel,
  Marie,
  Marilyn,
  Mindy,
  Momo,
  Murdoch,
  Mycroft,
  Nanny,
  Nanoosh,
  Nemes,
  OlNel,
  Ophelia,
  Ozmo,
  Patcani,
  Patricia,
  PereEli,
  PerfectRose,
  Peter,
  Porumbel,
  PrinceGodfrey,
  Reyda,
  Roxane,
  Rufus,
  Ruth,
  Simone,
  Sova,
  Szalai,
  Tania,
  Teddy,
  Theresa,
  Titus,
  Tyre,
  Tyson,
  Ursa,
  Uwe,
  VampireSpeedThree,
  VampireSpeedTwo,
  VampireStrength,
  VampireStrengthAlt,
  VampireWill,
  VampireWillAlt,
  VampiricStealth,
  Veres,
  Victoria,
  Wadsworth,
  WeeVlad,
  Wentworth,
  Wiggles,
  Wilma,
  Wright,
  Yaga,
  Zara,
  Zephania
} from '@gamepark/the-hunger/card/hunt';
import { TFunction } from 'i18next';
import {
  BorisPouchkineHunger,
  BorisPouchkineVampiricSpeed2,
  BorisPouchkineVampiricSpeed3,
  BorisPouchkineVampiricSpeed4,
  BorisPouchkineVampiricStrength,
  BorisPouchkineVampiricThirst,
  DonGervasiHunger,
  DonGervasiVampiricSpeed2,
  DonGervasiVampiricSpeed3,
  DonGervasiVampiricSpeed4,
  DonGervasiVampiricStrength,
  DonGervasiVampiricThirst,
  JosephineLafayetteHunger,
  JosephineLafayetteVampiricSpeed2,
  JosephineLafayetteVampiricSpeed3,
  JosephineLafayetteVampiricSpeed4,
  JosephineLafayetteVampiricStrength,
  JosephineLafayetteVampiricThirst,
  LadyBeatriceHunger,
  LadyBeatriceVampiricSpeed2,
  LadyBeatriceVampiricSpeed3,
  LadyBeatriceVampiricSpeed4,
  LadyBeatriceVampiricStrength,
  LadyBeatriceVampiricThirst,
  RajeshAmaraHunger,
  RajeshAmaraVampiricSpeed2,
  RajeshAmaraVampiricSpeed3,
  RajeshAmaraVampiricSpeed4,
  RajeshAmaraVampiricStrength,
  RajeshAmaraVampiricThirst,
  YokoChiyakoHunger,
  YokoChiyakoVampiricSpeed2,
  YokoChiyakoVampiricSpeed3,
  YokoChiyakoVampiricSpeed4,
  YokoChiyakoVampiricStrength,
  YokoChiyakoVampiricThirst
} from '@gamepark/the-hunger/card/vampire/Starters';
import { Card } from '@gamepark/the-hunger/card/Card';

class TitleContent {
  translate: (t: TFunction) => string;
  bottom?: number;

  constructor(translate: (t: TFunction) => string, bottom?: number) {
    this.translate = translate;
    this.bottom = bottom;

  }

}

const HuntCardTitles = new Map<Card, TitleContent>();
HuntCardTitles.set(Tyre, new TitleContent((t: TFunction) => t('Tyre'), 10.4));
HuntCardTitles.set(Murdoch, new TitleContent((t: TFunction) => t('Murdoch'), 15.3));
HuntCardTitles.set(Peter, new TitleContent((t: TFunction) => t('Peter'), 19.3));
HuntCardTitles.set(Harper, new TitleContent((t: TFunction) => t('Harper'), 10.4));
HuntCardTitles.set(Isabel, new TitleContent((t: TFunction) => t('Isabel'), 15.3));
HuntCardTitles.set(Arthur, new TitleContent((t: TFunction) => t('Arthur'), 10.4));
HuntCardTitles.set(Khasar, new TitleContent((t: TFunction) => t('Khasar'), 10.4));
HuntCardTitles.set(Uwe, new TitleContent((t: TFunction) => t('Uwe'), 10.4));
HuntCardTitles.set(Archibald, new TitleContent((t: TFunction) => t('Archibald'), 10.4));
HuntCardTitles.set(Grant, new TitleContent((t: TFunction) => t('Grant'), 10.4));
HuntCardTitles.set(Eglantine, new TitleContent((t: TFunction) => t('Eglantine'), 15.3));
HuntCardTitles.set(Ivan, new TitleContent((t: TFunction) => t('Ivan'), 10.4));
HuntCardTitles.set(Diego, new TitleContent((t: TFunction) => t('Diego'), 10.4));
HuntCardTitles.set(Titus, new TitleContent((t: TFunction) => t('Titus'), 19.3));
HuntCardTitles.set(Victoria, new TitleContent((t: TFunction) => t('Victoria'), 15.3));
HuntCardTitles.set(Calvin, new TitleContent((t: TFunction) => t('Calvin'), 15.3));
HuntCardTitles.set(Marcel, new TitleContent((t: TFunction) => t('Marcel'), 15.3));
HuntCardTitles.set(Cyrana, new TitleContent((t: TFunction) => t('Cyrana'), 15.3));
HuntCardTitles.set(Campbell, new TitleContent((t: TFunction) => t('Campbell'), 15.3));
HuntCardTitles.set(Eli, new TitleContent((t: TFunction) => t('Eli'), 19.2));

HuntCardTitles.set(Agnes, new TitleContent((t: TFunction) => t('Agnes'), 10.4));
HuntCardTitles.set(Mindy, new TitleContent((t: TFunction) => t('Mindy'), 10.4));
HuntCardTitles.set(Bridget, new TitleContent((t: TFunction) => t('Bridget'), 15.3));
HuntCardTitles.set(Marilyn, new TitleContent((t: TFunction) => t('Marilyn'), 10.4));
HuntCardTitles.set(Zara, new TitleContent((t: TFunction) => t('Zara'), 10.4));
HuntCardTitles.set(Jack, new TitleContent((t: TFunction) => t('Jack'), 10.4));
HuntCardTitles.set(Belle, new TitleContent((t: TFunction) => t('Belle'), 15.3));
HuntCardTitles.set(Elois, new TitleContent((t: TFunction) => t('Elois'), 15.3));
HuntCardTitles.set(Ophelia, new TitleContent((t: TFunction) => t('Ophelia'), 10.4));
HuntCardTitles.set(Carlyle, new TitleContent((t: TFunction) => t('Carlyle'), 10.4));
HuntCardTitles.set(Wentworth, new TitleContent((t: TFunction) => t('Wentworth'), 10.4));
HuntCardTitles.set(Henrietta, new TitleContent((t: TFunction) => t('Henrietta'), 10.4));
HuntCardTitles.set(Theresa, new TitleContent((t: TFunction) => t('Theresa'), 15.3));
HuntCardTitles.set(Wadsworth, new TitleContent((t: TFunction) => t('Wadsworth'), 10.4));
HuntCardTitles.set(Wilma, new TitleContent((t: TFunction) => t('Wilma'), 19.3));
HuntCardTitles.set(Catarina, new TitleContent((t: TFunction) => t('Catarina'), 19.3));
HuntCardTitles.set(Tania, new TitleContent((t: TFunction) => t('Tania'), 10.4));
HuntCardTitles.set(BaronChristien, new TitleContent((t: TFunction) => t('Baron Christien'), 15.3));
HuntCardTitles.set(PrinceGodfrey, new TitleContent((t: TFunction) => t('Prince Godfrey'), 10.4));
HuntCardTitles.set(Veres, new TitleContent((t: TFunction) => t('Veres'), 19.3));

HuntCardTitles.set(OlNel, new TitleContent((t: TFunction) => t('Ol Nel'), 10.4));
HuntCardTitles.set(Billy, new TitleContent((t: TFunction) => t('Billy'), 10.4));
HuntCardTitles.set(Bruce, new TitleContent((t: TFunction) => t('Bruce'), 10.4));
HuntCardTitles.set(Momo, new TitleContent((t: TFunction) => t('Momo'), 10.4));
HuntCardTitles.set(Boo, new TitleContent((t: TFunction) => t('Boo'), 10.4));
HuntCardTitles.set(Anton, new TitleContent((t: TFunction) => t('Anton'), 19.3));
HuntCardTitles.set(Boris, new TitleContent((t: TFunction) => t('Boris'), 10.4));
HuntCardTitles.set(Ivo, new TitleContent((t: TFunction) => t('Ivo'), 10.4));
HuntCardTitles.set(Yaga, new TitleContent((t: TFunction) => t('Yaga'), 19.6));
HuntCardTitles.set(Roxane, new TitleContent((t: TFunction) => t('Roxane'), 10.4));
HuntCardTitles.set(Eunice, new TitleContent((t: TFunction) => t('Eunice'), 19.3));
HuntCardTitles.set(Angus, new TitleContent((t: TFunction) => t('Angus'), 19.3));
HuntCardTitles.set(Bippo, new TitleContent((t: TFunction) => t('Bippo'), 15.3));
HuntCardTitles.set(Juri, new TitleContent((t: TFunction) => t('Juri'), 15.3));
HuntCardTitles.set(Reyda, new TitleContent((t: TFunction) => t('Reyda'), 15.3));
HuntCardTitles.set(Patricia, new TitleContent((t: TFunction) => t('Patricia'), 15.3));
HuntCardTitles.set(Faviana, new TitleContent((t: TFunction) => t('Faviana'), 10.4));
HuntCardTitles.set(Eponime, new TitleContent((t: TFunction) => t('Eponime'), 10.4));
HuntCardTitles.set(Bernard, new TitleContent((t: TFunction) => t('Bernard'), 19.3));
HuntCardTitles.set(Szalai, new TitleContent((t: TFunction) => t('Szalai'), 19.3));

HuntCardTitles.set(Dee, new TitleContent((t: TFunction) => t('Dee'), 15.3));
HuntCardTitles.set(Ruth, new TitleContent((t: TFunction) => t('Ruth'), 10.4));
HuntCardTitles.set(Rufus, new TitleContent((t: TFunction) => t('Rufus'), 19.3));
HuntCardTitles.set(Faith, new TitleContent((t: TFunction) => t('Faith'), 15.3));
HuntCardTitles.set(Mycroft, new TitleContent((t: TFunction) => t('Mycroft'), 10.4));
HuntCardTitles.set(Ozmo, new TitleContent((t: TFunction) => t('Ozmo'), 10.4));
HuntCardTitles.set(Cotton, new TitleContent((t: TFunction) => t('Cotton'), 10.4));
HuntCardTitles.set(Marie, new TitleContent((t: TFunction) => t('Marie'), 10.4));
HuntCardTitles.set(Eleanor, new TitleContent((t: TFunction) => t('Eleanor'), 10.4));
HuntCardTitles.set(Bradford, new TitleContent((t: TFunction) => t('Bradford'), 10.4));
HuntCardTitles.set(Simone, new TitleContent((t: TFunction) => t('Simone'), 10.4));
HuntCardTitles.set(Zephania, new TitleContent((t: TFunction) => t('Zephania'), 19.3));
HuntCardTitles.set(Bolat, new TitleContent((t: TFunction) => t('Bolat'), 15.3));
HuntCardTitles.set(FrereTunk, new TitleContent((t: TFunction) => t('Friar Tunk'), 10.4));
HuntCardTitles.set(PereEli, new TitleContent((t: TFunction) => t('Father Eli'), 15.3));
HuntCardTitles.set(Wright, new TitleContent((t: TFunction) => t('Wright'), 15.3));
HuntCardTitles.set(FrereStewart, new TitleContent((t: TFunction) => t('Brother Stewart'), 10.4));
HuntCardTitles.set(LaPretresse, new TitleContent((t: TFunction) => t('The Priestess'), 10.4));
HuntCardTitles.set(ChantreSami, new TitleContent((t: TFunction) => t('Cantor Sami'), 15.3));
HuntCardTitles.set(Nemes, new TitleContent((t: TFunction) => t('Nemes'), 19.3));

HuntCardTitles.set(VampireWill, new TitleContent((t: TFunction) => t('Vampiric Will'), 15.4));
HuntCardTitles.set(VampireWillAlt, new TitleContent((t: TFunction) => t('Vampiric Will'), 15.4));
HuntCardTitles.set(VampireStrength, new TitleContent((t: TFunction) => t('Vampiric Strength'), 19.3));
HuntCardTitles.set(VampireStrengthAlt, new TitleContent((t: TFunction) => t('Vampiric Strength'), 19.3));
HuntCardTitles.set(VampireSpeedTwo, new TitleContent((t: TFunction) => t('Vampiric Speed'), 10.4));
HuntCardTitles.set(VampireSpeedThree, new TitleContent((t: TFunction) => t('Vampiric Speed'), 10.4));
HuntCardTitles.set(FormOfMist, new TitleContent((t: TFunction) => t('Form of Mist'), 19.3));
HuntCardTitles.set(FormOfBat, new TitleContent((t: TFunction) => t('Form of Bat'), 19.3));
HuntCardTitles.set(VampiricStealth, new TitleContent((t: TFunction) => t('Vampiric Stealth'), 15.3));
HuntCardTitles.set(Hypnosis, new TitleContent((t: TFunction) => t('Hypnosis'), 19.3));

HuntCardTitles.set(Kutya, new TitleContent((t: TFunction) => t('Kutya'), 20.4));
HuntCardTitles.set(Caine, new TitleContent((t: TFunction) => t('Caine'), 20.4));
HuntCardTitles.set(Ursa, new TitleContent((t: TFunction) => t('Ursa'), 21));
HuntCardTitles.set(Teddy, new TitleContent((t: TFunction) => t('Teddy'), 21));
HuntCardTitles.set(Malac, new TitleContent((t: TFunction) => t('Malac'), 20.4));
HuntCardTitles.set(Chop, new TitleContent((t: TFunction) => t('Chop'), 20.4));
HuntCardTitles.set(Nanoosh, new TitleContent((t: TFunction) => t('Nanoosh'), 19.3));
HuntCardTitles.set(Lockjaw, new TitleContent((t: TFunction) => t('Lockjaw'), 19.3));
HuntCardTitles.set(Echo, new TitleContent((t: TFunction) => t('Echo'), 19.2));
HuntCardTitles.set(Jahda, new TitleContent((t: TFunction) => t('Jahda'), 19.2));
HuntCardTitles.set(Bo, new TitleContent((t: TFunction) => t('Bo'), 19.2));
HuntCardTitles.set(Gray, new TitleContent((t: TFunction) => t('Gray'), 19.2));
HuntCardTitles.set(Patcani, new TitleContent((t: TFunction) => t('Patcani'), 19.3));
HuntCardTitles.set(WeeVlad, new TitleContent((t: TFunction) => t('Wee Vlad'), 19.3));
HuntCardTitles.set(Porumbel, new TitleContent((t: TFunction) => t('Porumbel'), 19.2));
HuntCardTitles.set(Tyson, new TitleContent((t: TFunction) => t('Tyson'), 19.2));
HuntCardTitles.set(Bagoly, new TitleContent((t: TFunction) => t('Bagoly'), 20.3));
HuntCardTitles.set(Sova, new TitleContent((t: TFunction) => t('Sova'), 20.3));
HuntCardTitles.set(Capra, new TitleContent((t: TFunction) => t('Capra'), 20.4));
HuntCardTitles.set(Nanny, new TitleContent((t: TFunction) => t('Nanny'), 20.4));
HuntCardTitles.set(Kaa, new TitleContent((t: TFunction) => t('Kaa'), 20.3));
HuntCardTitles.set(Wiggles, new TitleContent((t: TFunction) => t('Wiggles'), 20.3));

HuntCardTitles.set(EternalRose, new TitleContent((t: TFunction) => t('Eternal Rose'), 15.5));
HuntCardTitles.set(PerfectRose, new TitleContent((t: TFunction) => t('Perfect Rose'), 19.2));
HuntCardTitles.set(DeadRose, new TitleContent((t: TFunction) => t('Dead Rose'), 19.2));

HuntCardTitles.set(LadyBeatriceHunger, new TitleContent((t: TFunction) => t('The Hunger'), 15.3));
HuntCardTitles.set(LadyBeatriceVampiricThirst, new TitleContent((t: TFunction) => t('Vampire Thirst'), 15.3));
HuntCardTitles.set(LadyBeatriceVampiricStrength, new TitleContent((t: TFunction) => t('Vampire Strength'), 15.3));
HuntCardTitles.set(LadyBeatriceVampiricSpeed2, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(LadyBeatriceVampiricSpeed3, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(LadyBeatriceVampiricSpeed4, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));

HuntCardTitles.set(BorisPouchkineHunger, new TitleContent((t: TFunction) => t('The Hunger'), 15.3));
HuntCardTitles.set(BorisPouchkineVampiricThirst, new TitleContent((t: TFunction) => t('Vampire Thirst'), 15.3));
HuntCardTitles.set(BorisPouchkineVampiricStrength, new TitleContent((t: TFunction) => t('Vampire Strength'), 15.3));
HuntCardTitles.set(BorisPouchkineVampiricSpeed2, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(BorisPouchkineVampiricSpeed3, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(BorisPouchkineVampiricSpeed4, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));

HuntCardTitles.set(RajeshAmaraHunger, new TitleContent((t: TFunction) => t('The Hunger'), 15.3));
HuntCardTitles.set(RajeshAmaraVampiricThirst, new TitleContent((t: TFunction) => t('Vampire Thirst'), 15.3));
HuntCardTitles.set(RajeshAmaraVampiricStrength, new TitleContent((t: TFunction) => t('Vampire Strength'), 15.3));
HuntCardTitles.set(RajeshAmaraVampiricSpeed2, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(RajeshAmaraVampiricSpeed3, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(RajeshAmaraVampiricSpeed4, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));

HuntCardTitles.set(YokoChiyakoHunger, new TitleContent((t: TFunction) => t('The Hunger'), 15.3));
HuntCardTitles.set(YokoChiyakoVampiricThirst, new TitleContent((t: TFunction) => t('Vampire Thirst'), 15.3));
HuntCardTitles.set(YokoChiyakoVampiricStrength, new TitleContent((t: TFunction) => t('Vampire Strength'), 15.3));
HuntCardTitles.set(YokoChiyakoVampiricSpeed2, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(YokoChiyakoVampiricSpeed3, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(YokoChiyakoVampiricSpeed4, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));

HuntCardTitles.set(JosephineLafayetteHunger, new TitleContent((t: TFunction) => t('The Hunger'), 15.3));
HuntCardTitles.set(JosephineLafayetteVampiricThirst, new TitleContent((t: TFunction) => t('Vampire Thirst'), 15.3));
HuntCardTitles.set(JosephineLafayetteVampiricStrength, new TitleContent((t: TFunction) => t('Vampire Strength'), 15.3));
HuntCardTitles.set(JosephineLafayetteVampiricSpeed2, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(JosephineLafayetteVampiricSpeed3, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(JosephineLafayetteVampiricSpeed4, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));

HuntCardTitles.set(DonGervasiHunger, new TitleContent((t: TFunction) => t('The Hunger'), 15.3));
HuntCardTitles.set(DonGervasiVampiricThirst, new TitleContent((t: TFunction) => t('Vampire Thirst'), 15.3));
HuntCardTitles.set(DonGervasiVampiricStrength, new TitleContent((t: TFunction) => t('Vampire Strength'), 15.3));
HuntCardTitles.set(DonGervasiVampiricSpeed2, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(DonGervasiVampiricSpeed3, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));
HuntCardTitles.set(DonGervasiVampiricSpeed4, new TitleContent((t: TFunction) => t('Vampire Speed'), 10.4));

export {
  HuntCardTitles,
};
