import { EffectType } from './EffectType';
import { PlayerType } from '../player';
import { CardType } from '../card/CardType';
import { EffectTrigger } from './EffectTrigger';
import { GameEffect, GameEffects } from './GameEffect';
import GameState from '../GameState';
import { BoardBox } from '../board';
import GameView from '../GameView';
import { Card } from '../card/Card';
import { VictoryPoint, victoryPointMove } from '../moves/VictoryPoint';
import { isHuman } from '../card/hunt/Human';
import { isRose } from '../card/hunt/Rose';
import { Bonus } from '../bonus/Bonus';
import { computeHumans } from '../utils/EndOfGameUtils';


class VictoryPointEffect implements GameEffect {
  type: EffectType.VictoryPoint = EffectType.VictoryPoint;
  victoryPoint: number;
  huntTypes?: Array<CardType>;
  huntCount?: number;
  roseCount?: number;
  trigger?: EffectTrigger;
  hunt?: Card;
  perHunt?: boolean;
  card?: number;
  once?: boolean;

  constructor(victoryPoint: number, options?: Partial<Omit<VictoryPointEffect, 'victoryPoint'>>) {
    this.victoryPoint = victoryPoint;

    if (options) {
      this.huntTypes = options.huntTypes;
      this.huntCount = options.huntCount;
      this.trigger = options.trigger;
      this.roseCount = options.roseCount;
      this.hunt = options.hunt;
      this.perHunt = options.perHunt;
      this.card = options.card;
      this.once = options.once;
    }
  }

  static automaticMove(effect: VictoryPointEffect): VictoryPoint | undefined {
    return victoryPointMove(effect.victoryPoint, effect.card);
  }

  computePendingEffects(_state: GameState | GameView, player: PlayerType, _board: BoardBox[], contextCards: Card[], deck: Card[]): Array<GameEffects> {
    // Brut victory points without criteria
    if (!this.perHunt && !this.huntTypes?.length && !this.huntCount && !this.roseCount) {
      return [this];
    }

    if (!this.perHunt && this.huntTypes && this.huntCount && this.trigger === EffectTrigger.PlaceInPlayingArea) {
      const playingArea = player.playingArea.filter(c => !this.card || c !== this.card);
      if (playingArea.filter(c => isHuman(deck[c])).length >= this.huntCount) {
        return [new VictoryPointEffect(this.victoryPoint)];
      } else {
        return [];
      }
    }

    // Winning an amount of VP for each hunt of "hunt types"
    if (contextCards && this.perHunt && this.huntTypes && this.trigger === EffectTrigger.Hunting) {
      const factor = contextCards.filter(c => this.huntTypes?.includes(c.category)).length;

      if (factor) {
        return [new VictoryPointEffect(factor * this.victoryPoint)];
      } else {
        return [];
      }
    }

    // Winning victory point once when hunting card of "hunt types"
    if (contextCards && !this.perHunt && this.huntTypes && this.trigger === EffectTrigger.Hunting) {
      if (contextCards.some(c => this.huntTypes?.includes(c.category))) {
        return [new VictoryPointEffect(this.victoryPoint)];
      } else {
        return [];
      }
    }

    // Winning Victory point only if its the first hunt of types
    if (contextCards && !this.perHunt && this.huntTypes && this.trigger === EffectTrigger.Hunting) {
      if (contextCards.some(c => this.huntTypes?.includes(c.category))) {
        return [new VictoryPointEffect(this.victoryPoint)];
      } else {
        return [];
      }
    }

    return [];
  }

  computeScore(player: PlayerType, deck: Card[], bonuses: Bonus[]): number {
    if (this.trigger === EffectTrigger.EndOfGame) {
      const cards = [...player.digest, ...player.discard];
      if (this.roseCount && cards.filter(c => isRose(deck[c])).length >= this.roseCount) {
        return this.victoryPoint;
      }

      if (this.hunt && cards.some(c => this.hunt === deck[c])) {
        return this.victoryPoint;
      }

      if (this.huntTypes?.length && this.perHunt) {
        return this.victoryPoint * (
          this.huntTypes
            .map(t => computeHumans(player, deck, bonuses, t))
            .reduce((a, b) => a + b, 0)
        );
      }
    }
    return 0;
  }

  isApplicable(player: PlayerType): boolean {
    return !(this.once && this.card && player.playedCards.includes(this.card));
  }

}

export {
  VictoryPointEffect
};
