import { GameEffect } from './GameEffect';
import { PlayerType } from '../player';
import Move from '../moves/Move';
import { BoardBox, BoardBoxes } from '../board';
import { moveVampireMove } from '../moves/MoveVampire';
import { EffectType } from './EffectType';
import { getPathsToClosestWell } from '../utils/MoveUtils';
import GameState from '../GameState';
import { Card } from '../card/Card';
import { getActiveConfuseEffect } from '../utils/CardUtils';

export class TeleportEffect implements GameEffect {
  type: EffectType.Teleport = EffectType.Teleport;

  legalMoves(_state: GameState, player: PlayerType, deck: Card[]): Move[] {
    return getPathsToClosestWell(5, player, BoardBoxes, deck, false, undefined, true).map((path) =>
      moveVampireMove(path[0], player.vampire)
    );
  }

  isPlayable(player: PlayerType, deck: Card[], board: BoardBox[]): boolean {
    return !player.locked && !getActiveConfuseEffect(player, deck, board);
  }
}
