import {Animations} from '@gamepark/react-client'
import MoveView from '@gamepark/the-hunger/moves/MoveView'
import Vampire from '@gamepark/the-hunger/player/Vampire'
import MoveType from '@gamepark/the-hunger/moves/MoveType'
import {comeBackToCastle} from '@gamepark/the-hunger/utils/MoveUtils'
import {BoardBoxes} from '@gamepark/the-hunger/board'
import AnimationContext from '@gamepark/react-client/dist/animations/AnimationContext'
import GameView from '@gamepark/the-hunger/GameView'
import {
  DigestCardDelay,
  DigestCardDuration,
  DiscardCardDelay,
  DiscardCardDuration,
  DrawCardDelay,
  DrawCardDuration,
  FillHuntCardDelay,
  FillHuntCardDuration,
  PlaceInPlayingAreaCardDelay,
  PlaceInPlayingAreaCardDuration,
  ShuffleDiscardToDeckCardDelay,
  ShuffleDiscardToDeckCardDuration
} from '@gamepark/the-hunger/utils/AnimationsConstants'
import {isOtherPlayerView} from '@gamepark/the-hunger/player'
import {isDrawCardMoveView} from '@gamepark/the-hunger/moves/DrawCards'
import {DiscardLimit} from '@gamepark/the-hunger/utils/GameConstants'
import {ViewType} from './ViewType'

class TheHungerAnimation extends Animations<GameView, MoveView, Vampire> {
  override getPreDuration(move: MoveView, context: AnimationContext<GameView, MoveView, Vampire>): number {
    switch (move.type) {
      case MoveType.MoveVampire:
        const movedPlayer = context.state.players.find(
          (p) => context.state.activePlayer === p.vampire && p.vampire === move.vampire
        );
        return movedPlayer && comeBackToCastle(movedPlayer, move.box, BoardBoxes) ? 2 : 0;
      case MoveType.MoveHuntTrackCard:
        return 0.5;
      case MoveType.EndOfGame:
        return 1;
      case MoveType.VictoryPoint:
        return Math.min(2, 0.15 * move.victoryPoint);
      case MoveType.DrawMissions:
        return context.playerId && context.playerId === context.state.activePlayer ? 0 : 2;
      case MoveType.ChooseMission:
        return !context.state.activePlayer || (context.playerId && context.playerId === context.state.activePlayer)
          ? 0
          : 2;
      case MoveType.HuntOnTrack:
        return 3;
      case MoveType.HuntInTavern:
        return 10;
      case MoveType.NextPlayer:
        return 0.5;
      case MoveType.FillHuntTrack:
        return FillHuntCardDuration + (move.hunts.length - 1) * FillHuntCardDelay;
      case MoveType.FillTavern:
        return 2;
      case MoveType.PlaceInPlayingArea:
        if (
          context.playerId !== context.state.activePlayer &&
          (context.state.displayedView === ViewType.Boards ||
            context.state.displayedPlayer !== context.state.activePlayer)
        ) {
          return 0;
        }
        return PlaceInPlayingAreaCardDuration + (move.cards.length - 1) * PlaceInPlayingAreaCardDelay;
      case MoveType.ShuffleDiscardToDeck:
        if (
          context.playerId !== context.state.activePlayer &&
          (context.state.displayedView === ViewType.Boards ||
            context.state.displayedPlayer !== context.state.activePlayer)
        ) {
          return 0;
        }
        return (
          ShuffleDiscardToDeckCardDuration + (Math.min(move.deck, DiscardLimit) - 1) * ShuffleDiscardToDeckCardDelay
        );
      case MoveType.DiscardCards:
        if (
          context.playerId !== context.state.activePlayer &&
          (context.state.displayedView === ViewType.Boards ||
            context.state.displayedPlayer !== context.state.activePlayer)
        ) {
          return 0;
        }
        return DiscardCardDuration + (move.cards.length - 1) * DiscardCardDelay;
      case MoveType.DigestCards:
        if (
          context.playerId !== context.state.activePlayer &&
          (context.state.displayedView === ViewType.Boards ||
            context.state.displayedPlayer !== context.state.activePlayer)
        ) {
          return 0;
        }

        return DigestCardDuration + (move.cards.length - 1) * DigestCardDelay;
      case MoveType.AcquireBonusToken:
        return move.position !== undefined ? 5 : 0;
      case MoveType.DrawCards:
        if (
          context.playerId !== context.state.activePlayer &&
          (context.state.displayedView === ViewType.Boards ||
            context.state.displayedPlayer !== context.state.activePlayer)
        ) {
          return 0;
        }

        const player = context.state.players.find((p) => p.vampire === context.state.activePlayer)!;

        if (isOtherPlayerView(player)) {
          return DrawCardDuration + Math.min(move.count, player.deck) * DrawCardDelay;
        } else if (isDrawCardMoveView(move)) {
          return DrawCardDuration + (move.cards.length - 1) * DrawCardDelay;
        }

        throw new Error('There is a draw card move view since its an other player view');
      case MoveType.ActivateMission:
        return context.state.activePlayer !== context.playerId ? 4 : 0;
      case MoveType.DrawHuntCard:
        return 3;
      default:
        return 0;
    }
  }
}

export { TheHungerAnimation };
