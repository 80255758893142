import { EffectTrigger } from './EffectTrigger';
import { GameEffect, GameEffects } from './GameEffect';
import { EffectType } from './EffectType';
import { BoardBox, Region } from '../board';
import { PlayerType } from '../player';
import GameView from '../GameView';
import GameState from '../GameState';
import { VictoryPointEffect } from './VictoryPointEffect';

export class RegionEffect implements GameEffect {
  type: EffectType.Region = EffectType.Region;
  victoryPoint: number;
  region: Region;
  trigger: EffectTrigger = EffectTrigger.Hunted;

  constructor(victoryPoint: number, region: Region) {
    this.victoryPoint = victoryPoint;
    this.region = region;
  }

  computePendingEffects(_state: GameState | GameView, player: PlayerType, board: BoardBox[]): Array<GameEffects> {
    const playerRegion = board[player.position.box].region;

    if (playerRegion === this.region) {
      return [new VictoryPointEffect(this.victoryPoint)];
    }

    return [];
  }

}
