import { MyPlayerView } from './MyPlayerView';
import { PlayerType } from './Player';
import { PlayerMission } from './PlayerMission';

export type PlayerView = Omit<MyPlayerView, 'hand' | 'missions' | 'missionChoice'> & {
  hand: number,
  missionChoice: {
    missions: number,
    stack?: number
  },
  missions: number | PlayerMission[]
};

export const isOtherPlayerView = (player: PlayerType): player is PlayerView => {
  return typeof player.hand === 'number';
}
