import { isPlayer, MyPlayerView, Player, PlayerType, Position } from '../player';
import { CardTrait } from '../card/CardTrait';
import { GameEffects } from '../effect/GameEffect';
import { EffectType } from '../effect/EffectType';
import { CardType } from '../card/CardType';
import { BoardBox, BoardBoxEffect, BoardBoxes } from '../board';
import { EffectTrigger } from '../effect/EffectTrigger';
import { Card } from '../card/Card';
import { ConfuseEffect } from '../effect/ConfuseEffect';
import { getNextPendingEffect } from './PendingUtils';
import { hasConfuseEffect, hasSpicyEffect } from './EffectUtils';
import { Mission } from '../mission';
import { hasHunted } from './PlayerUtils';

export const isWell = (effect?: BoardBoxEffect) =>
  effect && [BoardBoxEffect.Well, BoardBoxEffect.Castle].includes(effect);
export const isShip = (effect?: BoardBoxEffect) => effect && BoardBoxEffect.Ship === effect;
export const isCastle = (effect?: BoardBoxEffect) => effect && BoardBoxEffect.Castle === effect;

/**
 * Indicate if the effect is a end of game effect
 * @param effect
 */
export const isEndOfGameEffect = (effect: GameEffects) => {
  return effect.trigger === EffectTrigger.EndOfGame;
};

/**
 * Does a player can draw a card
 */
export const getMaximumDrawableCard = (player: PlayerType, count: number) => {
  if (isPlayer(player)) {
    return Math.min(count, player.deck.length + player.discard.length);
  } else {
    return Math.min(count, player.deck + player.discard.length);
  }
};

/**
 * Indicate is an effect is an end of turn effect
 * @param effect the card effect
 */
export const isEndOfTurnEffect = (effect: GameEffects) => {
  return EffectTrigger.EndOfTurn === effect.trigger;
};

/** Traits */
export const hasTrait = (card: Card, trait: CardTrait) => card.traits && card.traits.some((t) => t === trait);
export const hasPermanentTrait = (card: Card) => hasTrait(card, CardTrait.Permanent);
export const hasSlowTrait = (card: Card) => hasTrait(card, CardTrait.Slow);
export const hasUniqueTrait = (card: Card) => hasTrait(card, CardTrait.Unique);
export const isType = (card: Card, type: CardType) => card.category == type;
export const isFamiliar = (card: Card) => isType(card, CardType.Familiar);

export const mustBePlayedFirst = (card: Card) => {
  return card.effects?.length && card.effects[0].trigger === EffectTrigger.BeforePlaying;
};

export const mustMissionBePlayedFirst = (mission: Mission) => {
  return mission.effects?.length && mission.effects[0].trigger === EffectTrigger.BeforePlaying;
};

/**
 * Indicate if a card has a discard effect
 * @param card The card
 */
export const hasDiscardEffect = (card: Card) => card.effects && card.effects.some((e) => e.type === EffectType.Discard);
export const isMissionHasDiscardEffect = (mission: Mission) =>
  mission.effects && mission.effects.some((e) => e.type === EffectType.Discard);

export const firstCardEffects = [EffectType.Discard, EffectType.Draw];

export const isFirstCardsToPlay = (card: Card) =>
  card.effects && card.effects.some((e) => firstCardEffects.includes(e.type));

export const hasPlayerSpicyEffect = (player: PlayerType, deck: Card[]) =>
  player.playingArea.some((c) => hasSpicyEffect(deck[c]));

export const hasHolyWater = (player: PlayerType, deck: Card[]) =>
  player.playingArea.some((c) => (deck[c].passiveEffects || []).some((e) => e.type === EffectType.HolyWater));

export const getActivatableCards = (player: PlayerType, deck: Card[], board: BoardBox[]) =>
  player.playingArea.filter(
    (c) =>
      isActivableCard(c, player, deck, board) &&
      !player.playedCards.includes(c) &&
      canActivateCard(player, c, deck, board)
  );

export const canActivateCard = (player: PlayerType, card: number, deck: Card[], board: BoardBox[]) => {
  const currentPendingEffect = getNextPendingEffect(player);
  return !currentPendingEffect && isActivableCard(card, player, deck, board);
};

export const getActiveConfuseEffect = (
  player: PlayerType,
  deck: Card[],
  board: BoardBox[]
): ConfuseEffect | undefined => {
  if (player.locked || player.hasMoved || isPlayerOnLabyrinth(player, board)) {
    return;
  }

  let find = player.playingArea
    .filter((c) => !player.playedCards.includes(c) && hasConfuseEffect(deck[c]))
    .flatMap((c) => deck[c].passiveEffects || [])
    .find((e) => e.type === EffectType.Confuse);
  return find as ConfuseEffect;
};

export const isActivableCard = (cardIndex: number, player: PlayerType, deck: Card[], board: BoardBox[]) => {
  if (player.end || player.hasMoved || hasHunted(player)) {
    return false;
  }

  const card = deck[cardIndex];
  if (card.effects?.length) {
    const first = card.effects[0];
    return !player.playedCards.includes(cardIndex) && (!first.isPlayable || first.isPlayable(player, deck, board));
  }

  return false;
};

export const getCardToDiscard = (cards: number[], position: Position, deck: Card[]): number[] => {
  const cardToDiscard = [];
  const isOnWell = isWell(BoardBoxes[position.box].effect);
  if (isOnWell) {
    // If there is a spicy effect in area and the player is on a well, discard these card too.
    cardToDiscard.push(...cards.filter((c) => !hasPermanentTrait(deck[c]) || hasSpicyEffect(deck[c])));
  } else {
    cardToDiscard.push(...cards.filter((c) => !hasPermanentTrait(deck[c])));
  }

  return cardToDiscard;
};

export const getBeforePlayingCard = (player: PlayerType, deck: Card[], board: BoardBox[]) => {
  return getActivatableCards(player, deck, board).find((c) => {
    const card = deck[c];
    return hasDiscardEffect(card) && card.effects?.some((e) => e.trigger === EffectTrigger.BeforePlaying);
  });
};

export const getMissionToPlayFirst = (player: Player | MyPlayerView, missions: Mission[]) => {
  return player.missions.find((m) => {
    const mission = missions[m.mission];
    return (
      !player.playedMissions.includes(m.mission) &&
      isMissionHasDiscardEffect(mission) &&
      mission.effects?.some((e) => e.trigger === EffectTrigger.BeforePlaying)
    );
  });
};

export const isPlayerOnLabyrinth = (player: PlayerType, board: BoardBox[]) =>
  board[player.position.box].effect === BoardBoxEffect.Labyrinth;
