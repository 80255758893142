import HuntTrackLine from '../material/hunt-track/visuals/hunt-track-line.jpg';

import GameBoard from '../material/board/visuals/board.jpg';
import Speed from '../material/board/visuals/speed.png';
import Speed2 from '../material/board/visuals/speed-2.png';
import Blood from '../material/board/visuals/blood.png';

import Tyre from '../material/card/visuals/military/tyre.jpg';
import Murdoch from '../material/card/visuals/military/murdoch.jpg';
import Peter from '../material/card/visuals/military/peter.jpg';
import Harper from '../material/card/visuals/military/harper.jpg';
import Isabel from '../material/card/visuals/military/isabel.jpg';
import Arthur from '../material/card/visuals/military/arthur.jpg';
import Khasar from '../material/card/visuals/military/khasar.jpg';
import Uwe from '../material/card/visuals/military/uwe.jpg';
import Archibald from '../material/card/visuals/military/archibald.jpg';
import Grant from '../material/card/visuals/military/grant.jpg';
import Eglantine from '../material/card/visuals/military/eglantine.jpg';
import Ivan from '../material/card/visuals/military/ivan.jpg';
import Diego from '../material/card/visuals/military/diego.jpg';
import Titus from '../material/card/visuals/military/titus.jpg';
import Victoria from '../material/card/visuals/military/victoria.jpg';
import Calvin from '../material/card/visuals/military/calvin.jpg';
import Marcel from '../material/card/visuals/military/marcel.jpg';
import Cyrana from '../material/card/visuals/military/cyrana.jpg';
import Campbell from '../material/card/visuals/military/campbell.jpg';
import Eli from '../material/card/visuals/military/eli.jpg';

import Agnes from '../material/card/visuals/noble/agnes.jpg';
import Mindy from '../material/card/visuals/noble/mindy.jpg';
import Bridget from '../material/card/visuals/noble/bridget.jpg';
import Marilyn from '../material/card/visuals/noble/marilyn.jpg';
import Zara from '../material/card/visuals/noble/zara.jpg';
import Jack from '../material/card/visuals/noble/jack.jpg';
import Belle from '../material/card/visuals/noble/belle.jpg';
import Elois from '../material/card/visuals/noble/elois.jpg';
import Ophelia from '../material/card/visuals/noble/ophelia.jpg';
import Carlyle from '../material/card/visuals/noble/carlyle.jpg';
import Wentworth from '../material/card/visuals/noble/wentworth.jpg';
import Henrietta from '../material/card/visuals/noble/henrietta.jpg';
import Theresa from '../material/card/visuals/noble/theresa.jpg';
import Wadsworth from '../material/card/visuals/noble/wadsworth.jpg';
import Wilma from '../material/card/visuals/noble/wilma.jpg';
import Catarina from '../material/card/visuals/noble/catarina.jpg';
import Tania from '../material/card/visuals/noble/tania.jpg';
import BaronChristien from '../material/card/visuals/noble/baron-christien.jpg';
import PrinceGodfrey from '../material/card/visuals/noble/prince-godfrey.jpg';
import Veres from '../material/card/visuals/noble/veres.jpg';

import OlNel from '../material/card/visuals/villager/ol-nel.jpg';
import Billy from '../material/card/visuals/villager/billy.jpg';
import Bruce from '../material/card/visuals/villager/bruce.jpg';
import Momo from '../material/card/visuals/villager/momo.jpg';
import Boo from '../material/card/visuals/villager/boo.jpg';
import Anton from '../material/card/visuals/villager/anton.jpg';
import Boris from '../material/card/visuals/villager/boris.jpg';
import Ivo from '../material/card/visuals/villager/ivo.jpg';
import Yaga from '../material/card/visuals/villager/yaga.jpg';
import Roxane from '../material/card/visuals/villager/roxane.jpg';
import Eunice from '../material/card/visuals/villager/eunice.jpg';
import Angus from '../material/card/visuals/villager/angus.jpg';
import Bippo from '../material/card/visuals/villager/bippo.jpg';
import Juri from '../material/card/visuals/villager/juri.jpg';
import Reyda from '../material/card/visuals/villager/reyda.jpg';
import Patricia from '../material/card/visuals/villager/patricia.jpg';
import Faviana from '../material/card/visuals/villager/faviana.jpg';
import Eponime from '../material/card/visuals/villager/eponime.jpg';
import Bernard from '../material/card/visuals/villager/bernard.jpg';
import Szalai from '../material/card/visuals/villager/szalai.jpg';

import Dee from '../material/card/visuals/religious/dee.jpg';
import Ruth from '../material/card/visuals/religious/ruth.jpg';
import Rufus from '../material/card/visuals/religious/rufus.jpg';
import Faith from '../material/card/visuals/religious/faith.jpg';
import Mycroft from '../material/card/visuals/religious/mycroft.jpg';
import Ozmo from '../material/card/visuals/religious/ozmo.jpg';
import Cotton from '../material/card/visuals/religious/cotton.jpg';
import Marie from '../material/card/visuals/religious/marie.jpg';
import Eleanor from '../material/card/visuals/religious/eleanor.jpg';
import Bradford from '../material/card/visuals/religious/bradford.jpg';
import Simone from '../material/card/visuals/religious/simone.jpg';
import Zephania from '../material/card/visuals/religious/zephania.jpg';
import Bolat from '../material/card/visuals/religious/bolat.jpg';
import FrereTunk from '../material/card/visuals/religious/frere-tunk.jpg';
import PereEli from '../material/card/visuals/religious/pere-eli.jpg';
import Wright from '../material/card/visuals/religious/wright.jpg';
import FrereStewart from '../material/card/visuals/religious/frere-stewart.jpg';
import LaPretresse from '../material/card/visuals/religious/la-pretresse.jpg';
import ChantreSami from '../material/card/visuals/religious/chantre-sami.jpg';
import Nemes from '../material/card/visuals/religious/nemes.jpg';

import VampireWill from '../material/card/visuals/power/vampire-will.jpg';
import VampireWillAlt from '../material/card/visuals/power/vampire-will-alt.jpg';
import VampireForce from '../material/card/visuals/power/vampire-force.jpg';
import VampireForceAlt from '../material/card/visuals/power/vampire-force-alt.jpg';
import VampireSpeedTwo from '../material/card/visuals/power/vampire-speed-two.jpg';
import VampireSpeedThree from '../material/card/visuals/power/vampire-speed-three.jpg';
import MistyForm from '../material/card/visuals/power/misty-form.jpg';
import AnimalForm from '../material/card/visuals/power/animal-form.jpg';
import Stealth from '../material/card/visuals/power/stealth.jpg';
import Hypnosis from '../material/card/visuals/power/hypnosis.jpg';

import Kutya from '../material/card/visuals/familiar/kutya.jpg';
import Caine from '../material/card/visuals/familiar/caine.jpg';
import Ursa from '../material/card/visuals/familiar/ursa.jpg';
import Teddy from '../material/card/visuals/familiar/teddy.jpg';
import Malac from '../material/card/visuals/familiar/malac.jpg';
import Chop from '../material/card/visuals/familiar/chop.jpg';
import Nanoosh from '../material/card/visuals/familiar/nanoosh.jpg';
import Lockjaw from '../material/card/visuals/familiar/lockjaw.jpg';
import Echo from '../material/card/visuals/familiar/echo.jpg';
import Jahda from '../material/card/visuals/familiar/jahda.jpg';
import Bo from '../material/card/visuals/familiar/bo.jpg';
import Gray from '../material/card/visuals/familiar/gray.jpg';
import Patcani from '../material/card/visuals/familiar/patcani.jpg';
import WeeVlad from '../material/card/visuals/familiar/wee-vlad.jpg';
import Porumbel from '../material/card/visuals/familiar/porumbel.jpg';
import Tyson from '../material/card/visuals/familiar/tyson.jpg';
import Bagoly from '../material/card/visuals/familiar/bagoly.jpg';
import Sova from '../material/card/visuals/familiar/sova.jpg';
import Capra from '../material/card/visuals/familiar/capra.jpg';
import Nanny from '../material/card/visuals/familiar/nanny.jpg';
import Kaa from '../material/card/visuals/familiar/kaa.jpg';
import Wiggles from '../material/card/visuals/familiar/wiggles.jpg';

import CardBack from '../material/card/visuals/back.jpg';

import EternalRose from '../material/card/visuals/roses/eternal-rose.jpg';
import IdealRose from '../material/card/visuals/roses/ideal-rose.jpg';
import FadedRose from '../material/card/visuals/roses/faded-rose.jpg';

import MissionBack from '../material/mission/visuals/mission-back.png';
import MissionGold from '../material/mission/visuals/gold-mission.png';
import MissionBeige from '../material/mission/visuals/beige-mission.png';
import MissionTitle from '../material/mission/visuals/mission-title.png';

import BonusTokenBack from '../material/bonus/visuals/bonus.png';
import BonusTokenChurch from '../material/bonus/visuals/church.png';
import BonusTokenNoble from '../material/bonus/visuals/noble.png';
import BonusTokenVillager from '../material/bonus/visuals/villager.png';
import BonusTokenMilitary from '../material/bonus/visuals/military.png';
import BonusTokenMultiFaction from '../material/bonus/visuals/multifaction.png';
import BonusTokenSpeed1 from '../material/bonus/visuals/speed1.png';
import BonusTokenSpeed2 from '../material/bonus/visuals/speed2.png';
import BonusTokenHunt from '../material/bonus/visuals/hunt.png';
import BonusTokenDiscard from '../material/bonus/visuals/discard.png';
import BonusTokenDraw from '../material/bonus/visuals/draw.png';
import BonusTokenMission from '../material/bonus/visuals/mission.png';
import BonusTokenParasol from '../material/bonus/visuals/parasol.png';
import BonusTokenVelvet from '../material/bonus/visuals/velvet.png';

/**
 * Starter cards
 */
import LadyBeatriceHunger from '../material/card/visuals/starters/lady-beatrice-hunger.jpg';
import LadyBeatriceVampiricForce from '../material/card/visuals/starters/lady-beatrice-vampiric-force.jpg';
import LadyBeatriceVampiricThirst from '../material/card/visuals/starters/lady-beatrice-vampiric-thirst.jpg';
import LadyBeatriceVampiricSpeed2 from '../material/card/visuals/starters/lady-beatrice-vampiric-speed-2.jpg';
import LadyBeatriceVampiricSpeed3 from '../material/card/visuals/starters/lady-beatrice-vampiric-speed-3.jpg';
import LadyBeatriceVampiricSpeed4 from '../material/card/visuals/starters/lady-beatrice-vampiric-speed-4.jpg';

import BorisPouchkineHunger from '../material/card/visuals/starters/boris-pouchkine-hunger.jpg';
import BorisPouchkineVampiricForce from '../material/card/visuals/starters/boris-pouchkine-vampiric-force.jpg';
import BorisPouchkineVampiricThirst from '../material/card/visuals/starters/boris-pouchkine-vampiric-thirst.jpg';
import BorisPouchkineVampiricSpeed2 from '../material/card/visuals/starters/boris-pouchkine-vampiric-speed-2.jpg';
import BorisPouchkineVampiricSpeed3 from '../material/card/visuals/starters/boris-pouchkine-vampiric-speed-3.jpg';
import BorisPouchkineVampiricSpeed4 from '../material/card/visuals/starters/boris-pouchkine-vampiric-speed-4.jpg';

import RajeshAmaraHunger from '../material/card/visuals/starters/rajesh-amara-hunger.jpg';
import RajeshAmaraVampiricForce from '../material/card/visuals/starters/rajesh-amara-vampiric-force.jpg';
import RajeshAmaraVampiricThirst from '../material/card/visuals/starters/rajesh-amara-vampiric-thirst.jpg';
import RajeshAmaraVampiricSpeed2 from '../material/card/visuals/starters/rajesh-amara-vampiric-speed-2.jpg';
import RajeshAmaraVampiricSpeed3 from '../material/card/visuals/starters/rajesh-amara-vampiric-speed-3.jpg';
import RajeshAmaraVampiricSpeed4 from '../material/card/visuals/starters/rajesh-amara-vampiric-speed-4.jpg';

import JosephineLafayetteHunger from '../material/card/visuals/starters/josephine-lafayette-hunger.jpg';
import JosephineLafayetteVampiricForce from '../material/card/visuals/starters/josephine-lafayette-vampiric-force.jpg';
import JosephineLafayetteVampiricThirst from '../material/card/visuals/starters/josephine-lafayette-vampiric-thirst.jpg';
import JosephineLafayetteVampiricSpeed2 from '../material/card/visuals/starters/josephine-lafayette-vampiric-speed-2.jpg';
import JosephineLafayetteVampiricSpeed3 from '../material/card/visuals/starters/josephine-lafayette-vampiric-speed-3.jpg';
import JosephineLafayetteVampiricSpeed4 from '../material/card/visuals/starters/josephine-lafayette-vampiric-speed-4.jpg';

import DonGervasiHunger from '../material/card/visuals/starters/don-gervasi-hunger.jpg';
import DonGervasiVampiricForce from '../material/card/visuals/starters/don-gervasi-vampiric-force.jpg';
import DonGervasiVampiricThirst from '../material/card/visuals/starters/don-gervasi-vampiric-thirst.jpg';
import DonGervasiVampiricSpeed2 from '../material/card/visuals/starters/don-gervasi-vampiric-speed-2.jpg';
import DonGervasiVampiricSpeed3 from '../material/card/visuals/starters/don-gervasi-vampiric-speed-3.jpg';
import DonGervasiVampiricSpeed4 from '../material/card/visuals/starters/don-gervasi-vampiric-speed-4.jpg';

import YokoChiyakoHunger from '../material/card/visuals/starters/yoko-chiyako-hunger.jpg';
import YokoChiyakoVampiricForce from '../material/card/visuals/starters/yoko-chiyako-vampiric-force.jpg';
import YokoChiyakoVampiricThirst from '../material/card/visuals/starters/yoko-chiyako-vampiric-thirst.jpg';
import YokoChiyakoVampiricSpeed2 from '../material/card/visuals/starters/yoko-chiyako-vampiric-speed-2.jpg';
import YokoChiyakoVampiricSpeed3 from '../material/card/visuals/starters/yoko-chiyako-vampiric-speed-3.jpg';
import YokoChiyakoVampiricSpeed4 from '../material/card/visuals/starters/yoko-chiyako-vampiric-speed-4.jpg';

/**
 * Player boards
 */
import BorisPouchkinePlayerBoard from '../material/player/visuals/board/boris-pouchkine.jpg';
import DonGervasiPlayerBoard from '../material/player/visuals/board/don-gervasi.jpg';
import JosephineLafayettePlayerBoard from '../material/player/visuals/board/josephine-lafayette.jpg';
import LadyBeatricePlayerBoard from '../material/player/visuals/board/lady-beatrice.jpg';
import YokoChiyakoPlayerBoard from '../material/player/visuals/board/yoko-chiyako.jpg';
import RajeshAmaraPlayerBoard from '../material/player/visuals/board/rajesh-amara.jpg';

/**
 * Player  token
 */
import BorisPouchkinePlayerToken from '../material/player/visuals/token/boris-pouchkine.png';
import DonGervasiPlayerToken from '../material/player/visuals/token/don-gervasi.png';
import JosephineLafayettePlayerToken from '../material/player/visuals/token/josephine-lafayette.png';
import LadyBeatricePlayerToken from '../material/player/visuals/token/lady-beatrice.png';
import YokoChiyakoPlayerToken from '../material/player/visuals/token/yoko-chiyako.png';
import RajeshAmaraPlayerToken from '../material/player/visuals/token/rajesh-amara.png';

/**
 * Card images
 */
import VictoryPointSymbol from '../material/card/visuals/symbols/victory-points.png';
import HumanNobleSymbol from '../material/card/visuals/symbols/human-noble.png';
import HumanVillagerSymbol from '../material/card/visuals/symbols/human-villager.png';
import HumanReligiousSymbol from '../material/card/visuals/symbols/human-religious.png';
import HumanMilitarySymbol from '../material/card/visuals/symbols/human-military.png';
import HumanNobleSymbolHD from '../material/card/visuals/symbols/human-noble-hd.png';
import HumanVillagerSymbolHD from '../material/card/visuals/symbols/human-villager-hd.png';
import HumanReligiousSymbolHD from '../material/card/visuals/symbols/human-religious-hd.png';
import HumanMilitarySymbolHD from '../material/card/visuals/symbols/human-military-hd.png';

import SpeedSymbol from '../material/card/visuals/symbols/speed.png';
import RegionPlainSymbol from '../material/card/visuals/symbols/plain.png';
import RegionForestSymbol from '../material/card/visuals/symbols/forest.png';
import WolfSymbol from '../material/card/visuals/symbols/wolf.png';
import ConfuseSymbol from '../material/card/visuals/symbols/confuse.png';
import FamiliarSymbol from '../material/card/visuals/symbols/familiar.png';
import PowerSymbol from '../material/card/visuals/symbols/power.png';
import HolyWaterSymbol from '../material/card/visuals/symbols/holy-water.png';
import SpicySymbol from '../material/card/visuals/symbols/spicy.png';

import FamiliarSymbolHD from '../material/card/visuals/symbols/familiar-hd.png';
import PowerSymbolHD from '../material/card/visuals/symbols/power-hd.png';
import ItemSymbolHD from '../material/card/visuals/symbols/item-hd.png';
import VictoryPointSymbolHD from '../material/card/visuals/symbols/victory-points-hd.png';
import DigestSymbolHD from '../material/card/visuals/symbols/digest-hd.png';
import InspiringSymbolHD from '../material/card/visuals/symbols/inspiring-hd.png';
import HolyWaterSymbolHD from '../material/card/visuals/symbols/holywater-hd.png';
import ConfuseSymbolHD from '../material/card/visuals/symbols/confuse-hd.png';
import AdditionalHuntSymbolHD from '../material/card/visuals/symbols/additional-hunt-hd.png';
import SpicySymbolHD from '../material/card/visuals/symbols/spicy-hd.png';
import DiscardDrawSymbolHD from '../material/card/visuals/symbols/discard-draw-hd.png';
import DrawSymbolHD from '../material/card/visuals/symbols/draw-hd.png';
import HuntSymbolHD from '../material/card/visuals/symbols/hunt-symbol-hd.png';
import CantHuntSymbolHD from '../material/card/visuals/symbols/cant-hunt-hd.png';
import WinBonusSymbolHD from '../material/card/visuals/symbols/win-bonus.png';
import SpeedBonusSymbolHD from '../material/card/visuals/symbols/speed-effect.png';

import Arrow from '../material/player/visuals/arrow-white.png';

/**
 * Turn
 */
import Turn from '../material/turn/turn.png';
import LastTurn from '../material/turn/last-turn.png';

import ArrowWhite from '../material/card/visuals/catalog/arrow-white.png';

import BasicArrow from '../material/card/visuals/arrow.png';

import TheHungerButton from '../material/button.png';
import TheHungerButtonGreen from '../material/button-green.png';
import TheHungerButtonRed from '../material/button-red.png';

import CastleToken from '../material/castle/visuals/castle-token.png';
import BoardButton from '../material/board/visuals/board-button.png';

/**
 * Box help
 */
import BoxHelpShip from '../material/boxhelp/ship.jpg';
import BoxHelpCrypt from '../material/boxhelp/crypt.jpg';
import BoxHelpWell from '../material/boxhelp/well.jpg';
import BoxHelpChest from '../material/boxhelp/chest.jpg';
import BoxHelpChurch from '../material/boxhelp/church.jpg';
import BoxHelpMansion from '../material/boxhelp/mansion.jpg';
import BoxHelpBarrack from '../material/boxhelp/barrack.jpg';
import BoxHelpMarket from '../material/boxhelp/market.jpg';
import BoxHelpLabyrinth from '../material/boxhelp/labyrinth.jpg';
import BoxHelpTavern from '../material/boxhelp/tavern.jpg';
import BoxHelpCemetery from '../material/boxhelp/cemetery.jpg';
import BoxHelpCastle from '../material/boxhelp/castle.jpg';

import PopupBackground from '../tutorial/visuals/background.jpg';

import TutorialArrow from '../tutorial/visuals/tutorial-arrow-white.png';

/**
 * Region HD
 */
import ForestHDSymbol from '../symbols/visuals/forest-hd.png';
import PlainHDSymbol from '../symbols/visuals/plain-hd.png';
import CemeteryHDSymbol from '../symbols/visuals/cemetery-hd.png';
import MountainHDSymbol from '../symbols/visuals/mountains-hd.png';

/**
 * Road types
 */
import Road from '../tutorial/visuals/road.jpg';
import Railroad from '../tutorial/visuals/railroad.jpg';
import Water from '../tutorial/visuals/water.jpg';

import RegionHelp from '../material/board/visuals/regions.png';

export const Images = {
  HuntTrackLine,
  GameBoard,
  Blood,
  Speed,
  Speed2,
  CardBack,
  Tyre,
  Murdoch,
  Peter,
  Harper,
  Isabel,
  Arthur,
  Khasar,
  Uwe,
  Archibald,
  Grant,
  Eglantine,
  Ivan,
  Diego,
  Titus,
  Victoria,
  Calvin,
  Marcel,
  Cyrana,
  Campbell,
  Eli,
  Agnes,
  Mindy,
  Bridget,
  Marilyn,
  Zara,
  Jack,
  Belle,
  Elois,
  Ophelia,
  Carlyle,
  Wentworth,
  Henrietta,
  Theresa,
  Wadsworth,
  Wilma,
  Catarina,
  Tania,
  BaronChristien,
  PrinceGodfrey,
  Veres,
  OlNel,
  Billy,
  Bruce,
  Momo,
  Boo,
  Anton,
  Boris,
  Ivo,
  Yaga,
  Roxane,
  Eunice,
  Angus,
  Bippo,
  Juri,
  Reyda,
  Patricia,
  Faviana,
  Eponime,
  Bernard,
  Szalai,
  Dee,
  Ruth,
  Rufus,
  Faith,
  Mycroft,
  Ozmo,
  Cotton,
  Marie,
  Eleanor,
  Bradford,
  Simone,
  Zephania,
  Bolat,
  FrereTunk,
  PereEli,
  Wright,
  FrereStewart,
  LaPretresse,
  ChantreSami,
  Nemes,
  VampireWill,
  VampireWillAlt,
  VampireForce,
  VampireForceAlt,
  VampireSpeedTwo,
  VampireSpeedThree,
  MistyForm,
  AnimalForm,
  Stealth,
  Hypnosis,
  Kutya,
  Caine,
  Ursa,
  Teddy,
  Malac,
  Chop,
  Nanoosh,
  Lockjaw,
  Echo,
  Jahda,
  Bo,
  Gray,
  Patcani,
  WeeVlad,
  Porumbel,
  Tyson,
  Bagoly,
  Sova,
  Capra,
  Nanny,
  Kaa,
  Wiggles,
  LadyBeatriceHunger,
  LadyBeatriceVampiricThirst,
  LadyBeatriceVampiricForce,
  LadyBeatriceVampiricSpeed2,
  LadyBeatriceVampiricSpeed3,
  LadyBeatriceVampiricSpeed4,
  BorisPouchkineHunger,
  BorisPouchkineVampiricThirst,
  BorisPouchkineVampiricForce,
  BorisPouchkineVampiricSpeed2,
  BorisPouchkineVampiricSpeed3,
  BorisPouchkineVampiricSpeed4,
  RajeshAmaraHunger,
  RajeshAmaraVampiricThirst,
  RajeshAmaraVampiricForce,
  RajeshAmaraVampiricSpeed2,
  RajeshAmaraVampiricSpeed3,
  RajeshAmaraVampiricSpeed4,
  JosephineLafayetteHunger,
  JosephineLafayetteVampiricThirst,
  JosephineLafayetteVampiricForce,
  JosephineLafayetteVampiricSpeed2,
  JosephineLafayetteVampiricSpeed3,
  JosephineLafayetteVampiricSpeed4,
  YokoChiyakoHunger,
  YokoChiyakoVampiricThirst,
  YokoChiyakoVampiricForce,
  YokoChiyakoVampiricSpeed2,
  YokoChiyakoVampiricSpeed3,
  YokoChiyakoVampiricSpeed4,
  DonGervasiHunger,
  DonGervasiVampiricThirst,
  DonGervasiVampiricForce,
  DonGervasiVampiricSpeed2,
  DonGervasiVampiricSpeed3,
  DonGervasiVampiricSpeed4,
  EternalRose,
  IdealRose,
  FadedRose,
  MissionBack,
  MissionBeige,
  MissionGold,
  MissionTitle,
  BonusTokenBack,
  BonusTokenChurch,
  BonusTokenNoble,
  BonusTokenMilitary,
  BonusTokenVillager,
  BonusTokenMultiFaction,
  BonusTokenSpeed1,
  BonusTokenSpeed2,
  BonusTokenHunt,
  BonusTokenDiscard,
  BonusTokenDraw,
  BonusTokenMission,
  BonusTokenParasol,
  BonusTokenVelvet,
  BorisPouchkinePlayerBoard,
  DonGervasiPlayerBoard,
  JosephineLafayettePlayerBoard,
  LadyBeatricePlayerBoard,
  YokoChiyakoPlayerBoard,
  RajeshAmaraPlayerBoard,
  BorisPouchkinePlayerToken,
  DonGervasiPlayerToken,
  JosephineLafayettePlayerToken,
  LadyBeatricePlayerToken,
  YokoChiyakoPlayerToken,
  RajeshAmaraPlayerToken,
  VictoryPointSymbol,
  HumanReligiousSymbol,
  HumanNobleSymbol,
  HumanVillagerSymbol,
  HumanMilitarySymbol,
  SpeedSymbol,
  RegionPlainSymbol,
  RegionForestSymbol,
  WolfSymbol,
  ConfuseSymbol,
  FamiliarSymbol,
  FamiliarSymbolHD,
  PowerSymbol,
  PowerSymbolHD,
  ItemSymbolHD,
  HolyWaterSymbol,
  SpicySymbol,
  HumanReligiousSymbolHD,
  HumanNobleSymbolHD,
  HumanVillagerSymbolHD,
  HumanMilitarySymbolHD,
  Arrow,
  ArrowWhite,
  Turn,
  LastTurn,
  BasicArrow,
  TheHungerButton,
  TheHungerButtonGreen,
  TheHungerButtonRed,
  CastleToken,
  BoardButton,
  VictoryPointSymbolHD,
  DigestSymbolHD,
  InspiringSymbolHD,
  HolyWaterSymbolHD,
  ConfuseSymbolHD,
  AdditionalHuntSymbolHD,
  SpicySymbolHD,
  DiscardDrawSymbolHD,
  DrawSymbolHD,
  HuntSymbolHD,
  CantHuntSymbolHD,
  WinBonusSymbolHD,
  BoxHelpBarrack,
  BoxHelpWell,
  BoxHelpShip,
  BoxHelpMarket,
  BoxHelpChest,
  BoxHelpChurch,
  BoxHelpMansion,
  BoxHelpLabyrinth,
  BoxHelpTavern,
  BoxHelpCrypt,
  BoxHelpCemetery,
  BoxHelpCastle,
  SpeedBonusSymbolHD,
  PopupBackground,
  TutorialArrow,
  ForestHDSymbol,
  PlainHDSymbol,
  CemeteryHDSymbol,
  MountainHDSymbol,
  Road,
  Railroad,
  Water,
  RegionHelp,
};
