/** @jsxImportSource @emotion/react */
import { FC, HTMLAttributes } from 'react';
import { Token } from '../Token';
import { Images } from '../../images/Images';
import { Bonus } from '@gamepark/the-hunger/bonus/Bonus';
import {
  AdditionalHunt,
  AdditionalMission,
  Church,
  Discard,
  Draw,
  Military,
  MultiFaction,
  Noble,
  OneSpeed,
  Parasol,
  TwoSpeed, VelvetBoots, VelvetCoat, VelvetGloves, VelvetHat,
  VelvetPants,
  VelvetScarf,
  Villager
} from '@gamepark/the-hunger/bonus/Bonuses';

type BonusTokenProps = {
  type?: Bonus,
  preTransform?: string
  postTransform?: string
} & HTMLAttributes<HTMLDivElement>;

const BonusToken: FC<BonusTokenProps> = ({ type, preTransform, postTransform, ...props }) => {
  return (
    <Token back={Images.BonusTokenBack}
           front={ type && images.get(type) }
           preTransform={preTransform}
           postTransform={postTransform}
           {...props}
    />
  );
};

const images = new Map<Bonus, any>();
images.set(Church, Images.BonusTokenChurch);
images.set(Noble, Images.BonusTokenNoble);
images.set(Military, Images.BonusTokenMilitary);
images.set(Villager, Images.BonusTokenVillager);
images.set(MultiFaction, Images.BonusTokenMultiFaction);
images.set(OneSpeed, Images.BonusTokenSpeed1);
images.set(TwoSpeed, Images.BonusTokenSpeed2);
images.set(AdditionalHunt, Images.BonusTokenHunt);
images.set(Discard, Images.BonusTokenDiscard);
images.set(Draw, Images.BonusTokenDraw);
images.set(AdditionalMission, Images.BonusTokenMission);
images.set(Parasol, Images.BonusTokenParasol);
images.set(VelvetScarf, Images.BonusTokenVelvet);
images.set(VelvetPants, Images.BonusTokenVelvet);
images.set(VelvetHat, Images.BonusTokenVelvet);
images.set(VelvetBoots, Images.BonusTokenVelvet);
images.set(VelvetCoat, Images.BonusTokenVelvet);
images.set(VelvetGloves, Images.BonusTokenVelvet);

export {
  BonusToken
};
