import MoveType from './MoveType';
import GameState from '../GameState';
import { NewRoundPhase } from '../round/NewRoundPhase';
import GameView from '../GameView';
import { hasSlowTrait } from '../utils/CardUtils';
import { canRefillTavern } from '../utils/TavernUtils';
import { GameDeck } from '../card/hunt/GameDeck';
import Move from './Move';
import MoveView from './MoveView';

export type FillHuntTrack = {
  type: MoveType.FillHuntTrack
}

export type FillHuntTrackView = FillHuntTrack & { hunts: number[] }

export const isFillHuntTrackMoveView = (move: Move | MoveView): move is FillHuntTrackView => move.type === MoveType.FillHuntTrack && (move as FillHuntTrackView).hunts !== undefined;

export const isFillHuntTrack = (move: Move | MoveView): move is FillHuntTrack => move.type === MoveType.FillHuntTrack;

export const fillHuntTrackMove: FillHuntTrack = ({
  type: MoveType.FillHuntTrack
});

export const fillHuntTrack = (state: GameState) => {
  const drawnCards = state.hunt.deck.splice(0, state.hunt.track.length);
  state.hunt.track.forEach((line, index) => {
    let card = drawnCards[index];
    if (drawnCards.length > index) {
      if (GameDeck[card]) {
        if (hasSlowTrait(GameDeck[card])) {
          line[1].unshift(card);
        } else {
          line[0].unshift(card);
        }
      }
    }
  });

  if (state.newRoundPhase === NewRoundPhase.FillHuntTrack) {
    if (canRefillTavern(state)) {
      state.newRoundPhase = NewRoundPhase.FillTavern;
    } else {
      state.players.forEach(p => delete p.end);
      delete state.newRoundPhase;
    }
  }
}

export const fillHuntTrackView = (state: GameView, move: FillHuntTrackView) => {
  for (let index = 0; index < state.hunt.track.length; index++){
    const line = state.hunt.track[index];
    if (move.hunts.length > index) {
      let card = move.hunts[index];
      if (GameDeck[card]) {
        if (hasSlowTrait(GameDeck[card])) {
          line[1].unshift(card);
        } else {
          line[0].unshift(card);
        }
      }

    }
  }

  state.hunt.deck = Math.max(0, state.hunt.deck - move.hunts.length);
  if (state.newRoundPhase === NewRoundPhase.FillHuntTrack) {
    if (canRefillTavern(state)) {
      state.newRoundPhase = NewRoundPhase.FillTavern;
    } else {
      state.players.forEach(p => delete p.end);
      delete state.newRoundPhase;
    }
  }
}

export const getFillHuntTrackMoveView = (state: GameState, move: FillHuntTrack): FillHuntTrackView => {
  return {
    ...move,
    hunts: state.hunt.deck.slice(0, state.hunt.track.length) };
}
