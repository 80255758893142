import MoveType from './MoveType';
import GameState from '../GameState';
import GameView, { isInvisibleMissions } from '../GameView';
import { isMyPlayerView, isOtherPlayerView } from '../player';
import { computePassiveEffects } from '../utils/GameUtils';
import { EffectTrigger } from '../effect/EffectTrigger';
import Vampire from '../player/Vampire';
import { Card } from '../card/Card';
import Move from './Move';
import MoveView from './MoveView';

export type DrawMissions = {
  type: MoveType.DrawMissions,
  stack: number
}

export type DrawMissionsView = DrawMissions & { missions: number[] };

export const isDrawMission = (move: Move | MoveView): move is DrawMissions => move.type === MoveType.DrawMissions;
export const isDrawMissionsVisible = (move: DrawMissions | DrawMissionsView): move is DrawMissionsView => (move as DrawMissionsView).missions !== undefined

export const drawMissionMove = (stack: number): DrawMissions => ({
  type: MoveType.DrawMissions,
  stack
})

  export const drawMissions = (state: GameState, move: DrawMissions, deck: Card[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  player.missionChoice.missions = state.missions[move.stack];
  player.missionChoice.stack = move.stack;
  state.missions[move.stack] = [];
  computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.GainMission], deck);
}

export const drawMissionsView = (state: GameView, move: DrawMissions | DrawMissionsView, deck: Card[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  const missionStack = state.missions[move.stack];
  if (!isInvisibleMissions(missionStack)) {
    return;
  }

  if (isOtherPlayerView(player)) {
    player.missionChoice.missions = missionStack;
  }

  if (isMyPlayerView(player) && isDrawMissionsVisible(move)) {
    player.missionChoice.missions = move.missions;
  }

  player.missionChoice.stack = move.stack;
  state.missions[move.stack] = 0;
  computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.GainMission], deck);
}

export const getDrawMissionsMoveView = (state: GameState, move: DrawMissions, playerId?: Vampire): DrawMissions | DrawMissionsView => {
  if (!playerId || playerId !== state.activePlayer) {
    return move;
  }

  return {
    ...move,
    missions: state.missions[move.stack]
  };

}
