/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player, PlayerType } from '@gamepark/the-hunger/player';
import { FC, useState } from 'react';
import { Missions, MissionType } from '@gamepark/the-hunger/mission';
import { VictoryPointSymbol } from '../../../../symbols';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { PersonalMissionScore } from './PersonalMissionScore';
import { useTranslation } from 'react-i18next';

type PersonalMissionsScoreProps = {
  player: PlayerType;
};

const PersonalMissionsScore: FC<PersonalMissionsScoreProps> = (props) => {
  const player = props.player as Player;
  const [expanded, setExpanded] = useState(false);
  const scoringMission = (player as Player).missions.filter((m) => Missions[m.mission].type === MissionType.Beige);
  const totalScore = scoringMission.map((m) => m.score || 0).reduce((acc, nV) => acc + nV, 0);
  const { t } = useTranslation();

  return (
    <>
      <div css={[subtitle]} onClick={() => setExpanded((expanded) => !expanded)}>
        <span>{t('scoring.playermission')}</span>
        <div>
          <span> + </span>
          <VictoryPointSymbol css={victoryPointStyle} value={totalScore?.toString()} width={3} hd bold />
        </div>
        <div css={search}>
          <FontAwesomeIcon icon={expanded ? faSearchMinus : faSearchPlus} />
        </div>
      </div>
      {expanded && scoringMission.map((m) => <PersonalMissionScore key={m.mission} mission={m} />)}
    </>
  );
};

const subtitle = css`
  grid-column: span 24;
  font-size: 4em;
  padding: 0.2em 0.2em 0.2em 0.5em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.06em solid lightgoldenrodyellow;
  cursor: pointer;

  > span {
    text-align: left;
    flex: 2;
  }

  > div:first-of-type {
    flex: 3;
    display: flex;
    align-items: center;
    justify-self: center;

    > span {
      font-family: monospace;
    }
  }
`;

const victoryPointStyle = css`
  top: unset;
  transform: none;
  font-size: 0.7em;
  margin: 0 0.2em;
`;

const search = css`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  right: 0.5em;

  > svg {
    cursor: pointer;
  }
`;

export { PersonalMissionsScore };
