import Move from '../moves/Move';
import GameState from '../GameState';
import { Player } from '../player';
import { EffectType } from '../effect/EffectType';
import { ReadyEffect } from '../effect/ReadyEffect';
import { PushEffect } from '../effect/PushEffect';
import { GameEffects } from '../effect/GameEffect';
import { getNextPendingEffect } from '../utils/PendingUtils';
import { DiscardEffect } from '../effect/DiscardEffect';
import { DigestEffect } from '../effect/DigestEffect';
import { ConfuseEffect } from '../effect/ConfuseEffect';
import { FreeHuntEffect } from '../effect/FreeHuntEffect';
import { HuntTrackMoveEffect } from '../effect/HuntTrackMoveEffect';
import { InspiringEffect } from '../effect/InspiringEffect';
import { TeleportEffect } from '../effect/TeleportEffect';
import { GameDeck } from '../card/hunt/GameDeck';
import { WinHuntTrackCardEffect } from '../effect/WinHuntTrackCardEffect';
import { WinBonusTokenEffect } from '../effect/WinBonusTokenEffect';

export const getPendingEffectLegalMoves = (player: Player, state: GameState): Move[] => {
  let first = getNextPendingEffect(player);
  if (first) {
    let effect: GameEffects  | undefined = undefined;
    switch (first.type) {
      case EffectType.Discard:
        effect = new DiscardEffect(first);
        break;
      case EffectType.Digest:
        effect = new DigestEffect(first);
        break;
      case EffectType.Confuse:
        effect = new ConfuseEffect(first)
        break;
      case EffectType.FreeHunt:
        effect = new FreeHuntEffect(first)
        break;
      case EffectType.HuntTrack:
        effect = new HuntTrackMoveEffect()
        break;
      case EffectType.Inspiring:
        effect = new InspiringEffect()
        break;
      case EffectType.Teleport:
        effect = new TeleportEffect()
        break;
      case EffectType.Ready:
        effect = new ReadyEffect(first);
        break;
      case EffectType.PushVampire:
        effect = new PushEffect(first);
        break;
      case EffectType.WinHuntTrackCard:
        effect = new WinHuntTrackCardEffect(first);
        break
      case EffectType.WinBonusToken:
        effect = new WinBonusTokenEffect();
    }

    if (effect && effect.legalMoves) {
      return effect.legalMoves(state, player, GameDeck);
    }
  }

  return [];
};
