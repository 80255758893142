import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { computePassiveEffects } from '../utils/GameUtils';
import { EffectTrigger } from '../effect/EffectTrigger';
import { GameDeck } from '../card/hunt/GameDeck';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { EffectType } from '../effect/EffectType';
import Move from './Move';
import MoveView from './MoveView';

export type EndTurn = {
  type: MoveType.EndTurn;
};

export const endTurnMove: EndTurn = {
  type: MoveType.EndTurn,
};

export const isEndTurn = (move: Move | MoveView): move is EndTurn => move.type === MoveType.EndTurn;

export const endTurn = (state: GameState | GameView) => {
  const player = state.players.find((p) => p.vampire === state.activePlayer)!;
  player.pendingEffects = [];
  player.end = true;

  // For this case only, we must count every hunts (free and not free)
  if (!player.hunts.length) {
    computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.EndOfTurnWithoutHunt], GameDeck);
  } else {
    computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.EndOfTurnWithHunt], GameDeck);
  }

  computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.EndOfTurn], GameDeck);

  delete player.boardEffect;
  delete player.remainingSpeed;
  delete player.additionalHuntSpeed;
  delete player.hasMoved;
  player.playedCards = [];
  player.hunts = [];

  const nextPendingEffect = getNextPendingEffect(player);
  if (nextPendingEffect && nextPendingEffect.type === EffectType.SkipTurn) {
    removeNextPendingEffect(player);
  }
};
