/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useRef } from 'react';
import { BoardBoxEffect, BoardBoxes, Region } from '@gamepark/the-hunger/board';
import { gameDisplayTop, huntTrackLeft } from '../utils/Style';
import { useOutsideAlerter } from '../utils/useOutsideAlerter';
import { usePlay } from '@gamepark/react-client';
import { highlightBoxMove } from '../moves/HighlightBox';
import { Images } from '../images/Images';
import { BoardEffectDescriptions } from '../material/board/BoardEffectDescriptions';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { VictoryPointSymbol } from '../symbols';
import { Trans } from 'react-i18next';

type BoxHelpProps = {
  box?: number;
  mode: GameMode;
};

const BoxHelp: FC<BoxHelpProps> = ({ box, mode }) => {
  const ref = useRef(null);
  const play = usePlay();

  const onOutsideClick = () => {
    play(highlightBoxMove(), { local: true });
  };

  useOutsideAlerter(ref, onOutsideClick);

  const boardBox = box !== undefined ? BoardBoxes[box] : undefined;

  if (!boardBox) {
    return null;
  }

  const lostVP: number =
    boardBox.effect === BoardBoxEffect.Cemetery
      ? boardBox.lostVictoryPoints || 0
      : mode === GameMode.Rookie && boardBox.region === Region.Mountain
      ? boardBox.lostVictoryPoints || 0
      : 0;
  if (boardBox.effect === undefined && !lostVP) {
    return null;
  }

  return (
    <div ref={ref} css={boxContainer} onClick={onOutsideClick}>
      {boardBox.effect !== undefined && <div css={boxImage(boardBox.effect!)} />}
      <div css={boxDescription}>
        {BoardEffectDescriptions.get(boardBox.effect!)}
        {lostVP !== 0 && (
          <div css={[boardBox.effect !== undefined && boxDescriptionMountain]}>
            <Trans
              defaults="board.effect.lost"
              components={[<strong />, <VictoryPointSymbol css={vpStyle} bold width={1} value={lostVP + ''} />]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const vpStyle = css`
  top: 0.5em;

  > div {
    font-size: 0.65em;
  }
`;

const boxContainer = css`
  position: absolute;
  z-index: 999;
  top: ${gameDisplayTop}%;
  left: ${huntTrackLeft}%;
  border-radius: 1em;
  min-height: 15%;
  width: 30%;
  padding: 2.5em;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1em 0.3em white;
`;

const boxImage = (effect: BoardBoxEffect) => css`
  flex: 1;
  min-height: 11em;
  background-size: contain;
  background: url(${BoxHelpImages.get(effect)}) no-repeat center / contain;
`;

const boxDescription = css`
  flex: 2;
  position: relative;
  padding-left: 1em;
  font-size: 2.4em;
  color: white;
  pointer-events: none;
`;

const boxDescriptionMountain = css`
  margin-top: 1em;
`;

const BoxHelpImages = new Map<BoardBoxEffect, any>();
BoxHelpImages.set(BoardBoxEffect.Chest, Images.BoxHelpChest);
BoxHelpImages.set(BoardBoxEffect.Castle, Images.BoxHelpCastle);
BoxHelpImages.set(BoardBoxEffect.Cemetery, Images.BoxHelpCemetery);
BoxHelpImages.set(BoardBoxEffect.Ship, Images.BoxHelpShip);
BoxHelpImages.set(BoardBoxEffect.Well, Images.BoxHelpWell);
BoxHelpImages.set(BoardBoxEffect.Market, Images.BoxHelpMarket);
BoxHelpImages.set(BoardBoxEffect.Mansion, Images.BoxHelpMansion);
BoxHelpImages.set(BoardBoxEffect.Barracks, Images.BoxHelpBarrack);
BoxHelpImages.set(BoardBoxEffect.Church, Images.BoxHelpChurch);
BoxHelpImages.set(BoardBoxEffect.Tavern, Images.BoxHelpTavern);
BoxHelpImages.set(BoardBoxEffect.Labyrinth, Images.BoxHelpLabyrinth);
BoxHelpImages.set(BoardBoxEffect.Crypt, Images.BoxHelpCrypt);

export { BoxHelp };
