import MoveType from './MoveType';
import GameState from '../GameState';
import { BoardBoxEffect } from '../board';
import GameView, { isInvisibleMissions } from '../GameView';
import { isMyPlayerView, isOtherPlayerView } from '../player';
import Vampire from '../player/Vampire';
import { isInspiring } from '../utils/EffectUtils';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { computePassiveEffects } from '../utils/GameUtils';
import { EffectTrigger } from '../effect/EffectTrigger';
import { GameDeck } from '../card/hunt/GameDeck';
import { PlayerMission } from '../player/PlayerMission';
import MoveView from './MoveView';
import { GameMode } from '../GameMode';

export type ChooseMission = {
  type: MoveType.ChooseMission,
  vampire: Vampire,
  missions: number[]
}

export type ChooseMissionView = Omit<ChooseMission, 'missions'> & { missions: number };
export const isChooseMissionMoveView = (move: MoveView): move is ChooseMissionView => move.type === MoveType.ChooseMission && isChooseMissionView(move);
export const isChooseMissionMove = (move: MoveView): move is ChooseMission => move.type === MoveType.ChooseMission && !isChooseMissionView(move);

export const chooseMissionsMove = (vampire: number, missions: number[]): ChooseMission => ({
  type: MoveType.ChooseMission,
  missions: missions.sort((a, b) => a - b),
  vampire
});

export const isChooseMissionView = (move: ChooseMission | ChooseMissionView): move is ChooseMissionView => typeof move.missions === 'number';

export const chooseMissions = (state: GameState, move: ChooseMission) => {
  const player = state.players.find(p => p.vampire === move.vampire)!;

  // In case the player was on a crypt, we put the missions without those chosen by the player on the map area
  if (player.missionChoice.stack) {
    if (state.mode === GameMode.Rookie) {
      state.missions[player.missionChoice.stack] = player.missionChoice.missions.filter(m => !move.missions.includes(m));
    } else {
      state.missions[player.missionChoice.stack] = [
        ...player.missionChoice.missions,
        ...player.missions.filter(m => !player.playedMissions.includes(m.mission)).map(m => m.mission)
      ].filter(m => !move.missions.includes(m));
    }
  }

  if (state.mode === GameMode.Rookie) {
    player.missions = [
      ...player.missions,
      ...move.missions.map(m => ({ mission: m }))
    ];
  } else {
    player.missions = [
      ...player.missions.filter(m => player.playedMissions.includes(m.mission)),
      ...move.missions.map(m => ({ mission: m }))
    ];
  }

  player.missionChoice.missions = [];
  delete player.missionChoice.stack;

  const pendingEffect = getNextPendingEffect(player);
  if (isInspiring(pendingEffect)) {
    removeNextPendingEffect(player)
  }

  computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.GainMission], GameDeck);

  if (player.boardEffect && player.boardEffect === BoardBoxEffect.Crypt) {
    delete player.boardEffect;
  }
};

const getMissionSize = (missions: number | PlayerMission[]) => isInvisibleMissions(missions)? missions: missions.length
export const chooseMissionsView = (state: GameView, move: ChooseMission | ChooseMissionView) => {
  const player = state.players.find(p => p.vampire === move.vampire)!;

  if (isOtherPlayerView(player) && isChooseMissionView(move)) {
    if (player.missionChoice.stack) {
      if (state.mode === GameMode.Rookie) {
        state.missions[player.missionChoice.stack] = player.missionChoice.missions - move.missions;
      } else {
        state.missions[player.missionChoice.stack] = getMissionSize(player.missions) - player.playedMissions.length + player.missionChoice.missions - move.missions;
      }
    }

    if (state.mode === GameMode.Rookie) {
      player.missions = getMissionSize(player.missions) + move.missions;
    } else {
      player.missions = player.playedMissions.length + move.missions;
    }
    player.missionChoice.missions = 0;
    delete player.missionChoice.stack;
  } else if (isMyPlayerView(player) && !isChooseMissionView(move)) {
    if (player.missionChoice.stack) {
      if (state.mode === GameMode.Rookie) {
        state.missions[player.missionChoice.stack] = player.missionChoice.missions.length - move.missions.length;
      } else {
        state.missions[player.missionChoice.stack] = player.missions.length - player.playedMissions.length + player.missionChoice.missions.length - move.missions.length;
      }
    }

    if (state.mode === GameMode.Rookie) {
      player.missions = [
        ...player.missions,
        ...move.missions.map(m => ({ mission: m }))
      ]
    } else {
      player.missions = [
        ...player.missions.filter(m => player.playedMissions.includes(m.mission)),
        ...move.missions.map(m => ({ mission: m }))
      ];
    }

    player.missionChoice.missions = [];
    delete player.missionChoice.stack;
  }

  const pendingEffect = getNextPendingEffect(player);
  if (isInspiring(pendingEffect)) {
    removeNextPendingEffect(player)
  }

  computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.GainMission], GameDeck);

  if (player.boardEffect && player.boardEffect === BoardBoxEffect.Crypt) {
    delete player.boardEffect;
  }
};

export const getChooseMissionMoveView = (move: ChooseMission, playerId?: Vampire): ChooseMission | ChooseMissionView => {
  if (playerId && playerId === move.vampire) {
    return move;
  }

  return {
    ...move,
    missions: move.missions.length
  };

};
