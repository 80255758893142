export enum BonusTokenType {
  Church = 1,
  Military,
  Villager,
  Noble,
  MultiFaction,
  OneSpeed,
  TwoSpeed,
  Hunt,
  Discard,
  Draw,
  Mission,
  Parasol,
  VelvetClothing,
}
