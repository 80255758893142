/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import TheHunger from '@gamepark/the-hunger/TheHunger';
import { TheHungerOptionsSpec } from '@gamepark/the-hunger/TheHungerOptions';
import { GameProvider, setupTranslation } from '@gamepark/react-client';
import normalize from 'emotion-normalize';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import translations from './translations.json';
import TheHungerView from './TheHungerView';
import { TheHungerAnimation } from './Animations';
import TheHungerTutorial from './tutorial/Tutorial';
import { ai } from './tutorial/ai/TutorialAI';

setupTranslation(translations);

const style = css`
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: 'Arial', serif;
    font-size: 1vh;
    @media (max-aspect-ratio: 16/9) {
      font-size: calc(9vw / 16);
    }
  }

  #root {
    position: absolute;
    height: 100vh;
    width: 100vw;
    user-select: none;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    color: #eee;
    background-image: url(${process.env.PUBLIC_URL + '/cover-1920.jpg'});

    .the-hunger-tooltip {
      padding: 0.6em 1.3em;
      font-size: 1.4vh;
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

ReactDOM.render(
  <StrictMode>
    <GameProvider
      game="the-hunger"
      Rules={TheHunger}
      RulesView={TheHungerView}
      animations={new TheHungerAnimation()}
      tutorial={TheHungerTutorial}
      optionsSpec={TheHungerOptionsSpec}
      ai={ai}
    >
      <App />
    </GameProvider>
    <Global styles={[normalize, style]} />
  </StrictMode>,
  document.getElementById('root')
);
