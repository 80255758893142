import MoveType from './MoveType';
import GameState from '../GameState';
import { NewRoundPhase } from '../round/NewRoundPhase';
import GameView from '../GameView';
import Move from './Move';
import MoveView from './MoveView';

export type FillTavern = {
  type: MoveType.FillTavern
};

export const fillTavernMove: FillTavern = ({
  type: MoveType.FillTavern
});

export const isFillTavern = (move: Move | MoveView): move is FillTavern => move.type === MoveType.FillTavern;

export const fillTavern = (state: GameState) => {
  state.tavern.push(state.hunt.deck.shift()!);

  if (state.newRoundPhase === NewRoundPhase.FillTavern) {
    delete state.newRoundPhase;
  }
};

export const fillTavernView = (state: GameView) => {
  state.tavern++;
  state.hunt.deck--;

  if (state.newRoundPhase === NewRoundPhase.FillTavern) {
    delete state.newRoundPhase;
  }
}
