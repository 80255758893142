/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tutorial, useActions, useAnimation, useFailures, usePlay, usePlayerId } from '@gamepark/react-client';
import { Picture } from '@gamepark/react-components';
import { TFunction } from 'i18next';
import { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Move from '@gamepark/the-hunger/moves/Move';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import GameView from '@gamepark/the-hunger/GameView';
import { Images } from '../images/Images';
import { TheHungerButton } from '../utils/TheHungerButton';
import { getPlayerName } from '@gamepark/the-hunger/TheHungerOptions';
import { PlayerToken } from '../material/player/PlayerToken';
import { displayViewMove } from '../moves/DisplayView';
import { ViewType } from '../ViewType';
import { RegionSymbol, SpeedSymbol } from '../symbols';
import { Path, Region } from '@gamepark/the-hunger/board';
import { displayVampireMove } from '../moves/DisplayVampire';
import { isMyPlayerView } from '@gamepark/the-hunger/player';
import { PathImage } from './paths/PathImage';
import { MissionTile } from '../material/mission/MissionTile';
import { EarlyNight, HolierThanThou } from '@gamepark/the-hunger/mission';
import { Box } from '../material/board/box/Box';
import { RookieBoxPosition } from '../material/board/box/BoxPositions';
import { cardRatio, gameBoardHeight, gameBoardLeft, gameBoardWidth, gameDisplayTop } from '../utils/Style';
import { HuntCard } from '../material/card/HuntCard';
import { Boo } from '@gamepark/the-hunger/card/hunt';
import { BorisPouchkineHunger } from '@gamepark/the-hunger/card/vampire/Starters';

type TutorialStepProps = {
  message?: TutorialStepDescription;
};

const TutorialStep: FC<TutorialStepProps> = ({ message }) => {
  const { t } = useTranslation();

  if (!message || !message.title || !message.text) {
    return null;
  }

  const Component = message.component;
  const BottomComponent = message.bottomComponent;

  return (
    <>
      {message && <h2>{message.title(t)}</h2>}
      <div css={messageTextContainer}>
        {Component}
        {message && (
          <p css={[messageContent(message.align)]}>
            &nbsp;
            {typeof message.text === 'string' && <Trans defaults={message.text} components={[<strong />]} />}
            {typeof message.text !== 'string' && message.text(t)}
            &nbsp;
          </p>
        )}
      </div>
      {BottomComponent}
    </>
  );
};

const messageTextContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const messageContent = (align?: string) => css`
  flex: 3;
  //line-height: 1.1em;
  text-align: ${align || 'center'};
`;

const TutorialPopup: FC<{ game: GameView; tutorial: Tutorial }> = ({ game, tutorial }) => {
  const playerId = usePlayerId<Vampire>();
  const actions = useActions<Move, Vampire>();
  const actionsNumber = (actions || []).filter((a) => !a.delayed).length || 0;
  const previousActionNumber = useRef(actionsNumber);
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [tutorialDisplay, setTutorialDisplay] = useState(tutorialDescription.length > actionsNumber);
  const [failures] = useFailures();
  const animation = useAnimation<Move>();
  const play = usePlay();
  const player = game.players.find((p) => p.vampire === game.activePlayer)!;
  const hasMissionChoice = player && isMyPlayerView(player) && !!player.missionChoice.missions.length;

  const moveTutorial = (deltaMessage: number) => {
    if (
      ((!actionsNumber && tutorialIndex === 8) ||
        (actionsNumber === 1 && tutorialIndex === 1) ||
        (actionsNumber === 6 && tutorialIndex === 1) ||
        (actionsNumber === 12 && tutorialIndex === 0)) &&
      deltaMessage > 0
    ) {
      setTutorialDisplay(false);
      return;
    } else if (!actionsNumber && tutorialIndex === 9 && deltaMessage === -1) {
      play(displayViewMove(ViewType.Boards), { local: true });
      setTutorialIndex((index) => index - 1);
      setTutorialDisplay(true);
      return;
    } else if (
      ((actionsNumber === 1 && tutorialIndex === 2) ||
        (actionsNumber === 6 && tutorialIndex === 2) ||
        (actionsNumber === 12 && tutorialIndex === 1)) &&
      deltaMessage === -1
    ) {
      play(displayVampireMove(playerId), { local: true });
      play(displayViewMove(ViewType.Player), { local: true });
      setTutorialIndex((index) => index - 1);
      setTutorialDisplay(true);
      return;
    }

    let currentStep = actionsNumber;
    if (
      tutorialDescription[currentStep] &&
      tutorialDescription[currentStep][tutorialIndex] &&
      tutorialDescription[currentStep][tutorialDescription[currentStep].length - 1].opponentAction
    ) {
      tutorial.playNextMoves(tutorialDescription[currentStep][tutorialIndex].opponentAction);
    }
    setTutorialIndex(tutorialIndex + deltaMessage);
    setTutorialDisplay(true);
  };

  const resetTutorialDisplay = (resetIndex?: boolean) => {
    if (resetIndex) {
      setTutorialIndex(0);
    } else if (
      tutorialDescription[actionsNumber] &&
      tutorialDescription[actionsNumber].length &&
      tutorialIndex >= tutorialDescription[actionsNumber].length
    ) {
      setTutorialIndex(tutorialDescription[actionsNumber].length - 1);
    }
    setTutorialDisplay(true);
  };

  const tutorialMessage = (index: number) => {
    if (actionsNumber === 1 && hasMissionChoice) {
      return tutorialDescription[actionsNumber + 1][0];
    }

    let currentStep = actionsNumber;
    while (!tutorialDescription[currentStep]) {
      currentStep--;
    }

    if (!tutorialDescription[currentStep][index]?.text || !tutorialDescription[currentStep][index]?.title) {
      return;
    }

    return tutorialDescription[currentStep][index];
  };

  useEffect(() => {
    if (actionsNumber >= tutorialDescription.length) {
      tutorial.setOpponentsPlayAutomatically(true);
    }

    if (previousActionNumber.current > actionsNumber) {
      setTutorialDisplay(false);
    } else if (tutorialDescription[actionsNumber]) {
      resetTutorialDisplay(true);
    }

    if (tutorialDescription[actionsNumber]?.[0]?.opponentActionBefore) {
      tutorial.playNextMoves(tutorialDescription[actionsNumber][0]?.opponentActionBefore);
    }

    previousActionNumber.current = actionsNumber;
    // eslint-disable-next-line
  }, [actionsNumber]);

  const switchViewIndex = 9;
  useEffect(() => {
    if (
      ((actionsNumber === 1 && tutorialIndex === 1) ||
        (actionsNumber === 6 && tutorialIndex === 1) ||
        (actionsNumber === 12 && tutorialIndex === 0)) &&
      !tutorialDisplay &&
      game.displayedView === ViewType.Boards
    ) {
      setTutorialIndex((index) => index + 1);
      setTutorialDisplay(true);
      return;
    }

    if (
      ((!actionsNumber && tutorialIndex > 8) || (actionsNumber === 12 && tutorialIndex === 0)) &&
      (game.displayedPlayer !== playerId || game.displayedView !== ViewType.Player)
    ) {
      play(displayViewMove(ViewType.Player), { local: true });
      play(displayVampireMove(playerId), { local: true });
      setTutorialDisplay(true);
      return;
    }

    if (
      !actionsNumber &&
      tutorialIndex === 8 &&
      !tutorialDisplay &&
      (!game.displayedPlayer || game.displayedPlayer === playerId) &&
      game.displayedView === ViewType.Player
    ) {
      setTutorialIndex((index) => index + 1);
      setTutorialDisplay(true);
      return;
    }

    if (
      (!actionsNumber && tutorialIndex >= 8 && game.displayedPlayer === playerId) ||
      actionsNumber >= tutorialDescription.length
    ) {
      return;
    }

    if (tutorialIndex > switchViewIndex && playerId !== game.displayedPlayer) {
      play(displayVampireMove(playerId), { local: true });
      setTutorialDisplay(true);
      return;
    }

    if (!actionsNumber) {
      if (tutorialIndex <= switchViewIndex && game.displayedPlayer && game.displayedView !== ViewType.Boards) {
        play(displayViewMove(ViewType.Boards), { local: true });
        setTutorialDisplay(true);
      } else if (tutorialIndex > switchViewIndex && game.displayedView !== ViewType.Player) {
        play(displayViewMove(ViewType.Player), { local: true });
        setTutorialDisplay(true);
      }
    } else if (actionsNumber === 1 && tutorialIndex < 1 && game.displayedView === ViewType.Boards) {
      play(displayViewMove(ViewType.Player), { local: true });
      play(displayVampireMove(playerId), { local: true });
      setTutorialDisplay(true);
    }
    // eslint-disable-next-line
  }, [game.displayedView, game.displayedPlayer]);

  useEffect(() => {
    if (actionsNumber === 1 && hasMissionChoice) {
      setTutorialIndex(2);

      if (game.displayedView !== ViewType.Boards) {
        play(displayViewMove(ViewType.Boards), { local: true });
      }
    }
    // eslint-disable-next-line
  }, [actionsNumber, hasMissionChoice, game.displayedView]);

  useEffect(() => {
    if (actionsNumber >= tutorialDescription.length) {
      return;
    }

    if (
      ((!actionsNumber && tutorialIndex === switchViewIndex + 1) || (actionsNumber === 1 && tutorialIndex === 0)) &&
      game.displayedView !== ViewType.Player
    ) {
      play(displayViewMove(ViewType.Player), { local: true });
      return;
    }

    if (actionsNumber === 6 && tutorialIndex === 0 && game.displayedView !== ViewType.Player && !animation) {
      play(displayViewMove(ViewType.Player), { local: true });
      play(displayVampireMove(playerId), { local: true });
      return;
    }

    if (tutorialIndex === 13) {
      tutorial.playNextMove();
      return;
    }
    // eslint-disable-next-line
  }, [tutorialIndex, animation, actionsNumber, player]);

  useEffect(() => {
    if (failures.length) {
      setTutorialIndex(tutorialDescription[actionsNumber].length - 1);
      setTutorialDisplay(true);
    }
  }, [actionsNumber, failures]);

  const currentMessage = tutorialMessage(tutorialIndex);

  const displayPopup = tutorialDisplay && !animation && currentMessage && !failures.length;

  const onOutsideClick = () => {
    if (!displayPopup) {
      return;
    }

    let currentStep = actionsNumber;
    if (tutorialDescription[currentStep] && tutorialDescription[currentStep][tutorialIndex]?.opponentAction) {
      tutorial.playNextMoves(tutorialDescription[currentStep][tutorialIndex].opponentAction);
    }

    setTutorialDisplay(false);
  };

  const highlightBox1 = actionsNumber === 1 && tutorialIndex === 4 && game.selectedVampire === playerId;
  const highlightBox2 = actionsNumber === 6 && tutorialIndex === 3 && game.selectedVampire === playerId;
  return (
    <>
      {highlightBox1 && <Box mode={game.mode} position={-1} isTargetable css={targetBox1} />}
      {highlightBox2 && <Box mode={game.mode} position={-2} isTargetable css={targetBox2} />}
      <div css={[popupOverlayStyle, displayPopup ? undefined : hidePopupOverlayStyle]} onClick={onOutsideClick} />
      <div
        css={[popupStyle, displayPopup ? popupPosition(currentMessage) : hidePopupStyle]}
        onClick={(event) => event.stopPropagation()}
      >
        <div css={closePopupStyle} onClick={() => setTutorialDisplay(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <TutorialStep key={tutorialIndex} message={currentMessage} />
        <div css={buttonContainer}>
          {tutorialIndex > 0 && <TheHungerButton css={button} labelKey="<<" onClick={() => moveTutorial(-1)} />}
          <TheHungerButton css={button} labelKey="OK" onClick={() => moveTutorial(1)} />
        </div>
      </div>

      {!displayPopup && tutorialDescription.length > actionsNumber && (
        <TheHungerButton
          css={[buttonTutoStyle, resetStyle]}
          labelKey="Show Tutorial"
          onClick={() => resetTutorialDisplay()}
        />
      )}

      {currentMessage && currentMessage.arrow && (
        <Picture
          alt="Arrow pointing toward current tutorial interest"
          src={Images.TutorialArrow}
          draggable="false"
          css={[
            arrowStyle(currentMessage.arrow.angle),
            displayPopup ? showArrowStyle(currentMessage.arrow.top, currentMessage.arrow.left) : hideArrowStyle,
          ]}
        />
      )}
    </>
  );
};

export function resetTutorial() {
  localStorage.removeItem('the-hunger');
  window.location.reload();
}

export const hidePopupStyle = css`
  top: 85%;
  left: 90%;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: solid 0 #fff;
  font-size: 0;
`;

export const hidePopupOverlayStyle = css`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const buttonTutoStyle = css`
  //width: 5em;
  height: 2em;
  margin-right: 1em;
  top: 54%;
  right: -1%;
`;

const popupOverlayStyle = css`
  position: absolute;
  transform: translateZ(30em);
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: transparent;
  z-index: 105;
  transition: all 0.5s ease;
`;

const popupStyle = css`
  position: absolute;
  text-align: center;
  z-index: 120;
  box-sizing: border-box;
  align-self: center;
  padding: 2%;
  margin: 0 2%;
  outline: none;
  border: 0.4em gold solid;
  box-shadow: 0 0 1em 0.3em black inset, 0 0 1em 0.3em black;
  background: url(${Images.PopupBackground});
  background-size: cover;
  border-radius: 2em;
  color: lightgoldenrodyellow;
  font-family: 'Mulish', sans-serif;

  & > h2 {
    position: relative;
    font-size: 4.5em;
    margin: 0 1em;
  }

  & > div > p {
    position: relative;
    font-size: 3em;
    white-space: break-spaces;

    strong {
      font-weight: bold;
    }
  }

  & > button {
    font-size: 3em;
  }
`;

const buttonContainer = css`
  height: 5em;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
`;

const button = css`
  position: relative;
  display: block;
  width: 22em;
  height: 5em;
  margin: 0 0.5em;

  > div {
    font-size: 2em;
  }
`;

const closePopupStyle = css`
  position: relative;
  float: right;
  text-align: center;
  margin-top: -2%;
  margin-right: -0%;
  font-size: 4em;
  color: #fdedb4;

  &:hover {
    cursor: pointer;
    color: black;
  }
`;

export const popupPosition = ({ boxWidth, boxTop, boxBottom, boxLeft, arrow }: TutorialStepDescription) => css`
  width: ${boxWidth}%;
  ${boxBottom ? 'bottom' : 'top'}: ${boxBottom || boxTop}%;
  left: ${boxLeft}%;
  transform: translate(-50%, ${!arrow ? '-50%' : arrow.angle % 360 === 0 || arrow.angle % 90 === 0 ? '0%' : '-100%'})
    translateZ(30em);
`;

const arrowStyle = (angle: number) => css`
  position: absolute;
  transform: rotate(${angle}deg) translateZ(30em);
  will-change: transform;
  z-index: 106;
  filter: drop-shadow(0 0 0.5em black) drop-shadow(0 0 0.5em black) drop-shadow(0 0 0.5em black);
`;

const showArrowStyle = (top: number, left: number) => css`
  top: ${top}%;
  left: ${left}%;
  width: 15%;
`;

const hideArrowStyle = css`
  top: 90%;
  left: 90%;
  width: 0;
`;

const resetStyle = css`
  position: absolute;
  text-align: center;
  top: 54%;
  right: -1%;
  font-size: 3em;
  width: auto;
`;

const symbol = css`
  font-family: Arial, serif;
  margin-bottom: -0.3em;

  > span {
    font-size: 0.7em;
  }
`;

const targetBox1 = css`
  box-shadow: 0 0 0.2em 0.5em green, 0 0 0.2em 0.5em green;
  pointer-events: none;
  position: absolute;
  width: 3.7em;
  height: 3.7em;
  left: ${gameBoardLeft + (RookieBoxPosition[9].left / 100) * gameBoardWidth}%;
  top: ${gameDisplayTop + (RookieBoxPosition[9].top / 100) * gameBoardHeight}%;
  z-index: 10;
`;

const targetBox2 = css`
  box-shadow: 0 0 0.2em 0.5em green, 0 0 0.2em 0.5em green;
  pointer-events: none;
  position: absolute;
  width: 3.7em;
  height: 3.7em;
  left: ${gameBoardLeft + (RookieBoxPosition[12].left / 100) * gameBoardWidth}%;
  top: ${gameDisplayTop + (RookieBoxPosition[12].top / 100) * gameBoardHeight}%;
  z-index: 10;
`;

type TutorialStepDescription = {
  title?: (t: TFunction) => string;
  text?: ((t: TFunction) => any) | string;
  align?: string;
  component?: any;
  bottomComponent?: any;
  boxTop?: number;
  boxBottom?: number;
  boxLeft?: number;
  flexDirection?: string;
  boxWidth?: number;
  arrow?: {
    angle: number;
    top: number;
    left: number;
  };
  image?: string;
  opponentActionBefore?: number;
  opponentAction?: number;
};

const tutorialDescription: TutorialStepDescription[][] = [
  [
    {
      title: (t: TFunction) => t('tuto.title.welcome'),
      text: 'tuto.welcome',
      boxTop: 50,
      boxLeft: 48,
      boxWidth: 60,
    },
    {
      title: (t: TFunction) => t('tuto.title.turncounter'),
      text: 'tuto.turncounter',
      boxTop: 28,
      boxLeft: 64,
      boxWidth: 60,
      arrow: {
        angle: 0,
        top: 17,
        left: 75,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.you'),
      text: (t: TFunction) => (
        <Trans
          defaults="tuto.you"
          values={{ vampire: getPlayerName(Vampire.BorisPouchkine, t) }}
          components={[
            <div
              css={css`
                height: 1.5em;
                width: 1.5em;
                background-image: url(${Images.Blood});
                background-size: 100% 100%;
                display: inline-block;
                transform: translateY(0.3em);
                margin-right: 0.1em;
                margin-top: 0.5em;
              `}
            />,
            <SpeedSymbol
              light={false}
              css={css`
                height: 1.5em;
                width: 1.5em;
                background-size: 100% 100%;
                margin-top: 0.5em;
                margin-right: 0.1em;
              `}
            />,
            <div
              css={css`
                height: 1.2em;
                width: 1.2em;
                background-color: white;
                border: 0.01em solid black;
                border-radius: 3em;
                display: inline-block;
                transform: translateY(0.3em);
                margin-top: 0.5em;
                margin-right: 0.1em;
              `}
            />,
          ]}
        />
      ),
      align: 'left',
      component: (
        <PlayerToken
          vampire={Vampire.BorisPouchkine}
          css={[
            css`
              width: 10em;
              height: 10em;
              margin-right: 3em;
            `,
          ]}
        />
      ),
      boxTop: 13,
      boxLeft: 50,
      boxWidth: 65,
      arrow: {
        angle: 90,
        top: 23,
        left: 80.5,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.opponent'),
      text: (t: TFunction) => (
        <Trans defaults="tuto.opponent" values={{ vampire: getPlayerName(Vampire.JosephineLafayette, t) }} />
      ),
      component: (
        <PlayerToken
          vampire={Vampire.JosephineLafayette}
          css={[
            css`
              width: 10em;
              height: 10em;
              margin-right: 2em;
            `,
          ]}
        />
      ),
      boxTop: 27,
      boxLeft: 52.5,
      boxWidth: 60,
      arrow: {
        angle: 90,
        top: 38,
        left: 80.5,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.world'),
      text: () => (
        <Trans
          defaults="tuto.world"
          components={[
            <RegionSymbol region={Region.Mountain} hd />,
            <RegionSymbol region={Region.Cemetery} hd />,
            <RegionSymbol region={Region.Plain} hd />,
            <RegionSymbol region={Region.Forest} hd />,
            <PathImage type={Path.Railroad} />,
            <PathImage type={Path.Road} />,
            <PathImage type={Path.Boat} />,
          ]}
        />
      ),
      boxTop: 25,
      boxLeft: 65,
      boxWidth: 60,
      arrow: {
        angle: -90,
        top: 45,
        left: 26,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.publicmission'),
      text: 'tuto.publicmission',
      boxTop: 8,
      boxLeft: 37,
      boxWidth: 50,
      flexDirection: 'column',
      arrow: {
        angle: -90,
        top: 15,
        left: 3,
      },
      bottomComponent: (
        <div
          css={css`
            height: 20em;
            display: flex;
            width: 85%;
            margin: 0 7.5% 7.5%;
          `}
        >
          <MissionTile
            mission={HolierThanThou}
            css={css`
              position: relative;
              height: 100%;
              width: 100%;
              font-size: 0.2em;
            `}
          />
          <MissionTile
            mission={EarlyNight}
            css={css`
              position: relative;
              height: 100%;
              width: 100%;
              font-size: 0.2em;
              margin-left: 21em;
            `}
          />
        </div>
      ),
    },
    {
      title: (t: TFunction) => t('tuto.title.labyrinth'),
      text: 'tuto.labyrinth',
      boxTop: 8,
      boxLeft: 49.5,
      boxWidth: 50,
      arrow: {
        angle: -90,
        top: 15.5,
        left: 15.5,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.hunttrack'),
      text: () => (
        <Trans
          defaults="tuto.hunttrack"
          components={[
            <SpeedSymbol light={false} value="3" css={symbol} />,
            <SpeedSymbol light={false} value="2" css={symbol} />,
            <SpeedSymbol light={false} value="1" css={symbol} />,
          ]}
        />
      ),
      boxTop: 10,
      boxLeft: 25,
      boxWidth: 50,
      arrow: {
        angle: 90,
        top: 21.5,
        left: 48,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.gotoplayer'),
      text: 'tuto.gotoplayer',
      boxTop: 10,
      boxLeft: 51,
      boxWidth: 62,
      arrow: {
        angle: 90,
        top: 22.5,
        left: 80,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.player'),
      text: 'tuto.player',
      boxTop: 53,
      boxLeft: 48,
      boxWidth: 75,
    },
    {
      title: (t: TFunction) => t('tuto.title.hand'),
      text: 'tuto.hand',
      boxTop: 31,
      boxLeft: 48,
      boxWidth: 75,
      arrow: {
        angle: 180,
        top: 66.5,
        left: 37,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.missions'),
      text: 'tuto.missions',
      boxTop: 21,
      boxLeft: 46,
      boxWidth: 65,
      arrow: {
        angle: -90,
        top: 39,
        left: 4.5,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.playerboard'),
      text: 'tuto.playerboard',
      boxTop: 25,
      boxLeft: 76.5,
      boxWidth: 40,
      arrow: {
        angle: -90,
        top: 35.5,
        left: 47.7,
      },
    },
  ],
  [
    {
      title: (t: TFunction) => t('tuto.title.playingarea'),
      text: 'tuto.playingarea',
      boxTop: 40,
      boxLeft: 37,
      boxWidth: 75,
      arrow: {
        angle: 0,
        top: 29,
        left: 31.5,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.gotoworld'),
      text: 'tuto.gotoworld',
      boxTop: 7,
      boxLeft: 45,
      boxWidth: 75,
      arrow: {
        angle: 90,
        top: 12,
        left: 80,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.here'),
      text: 'tuto.here',
      boxTop: 33.5,
      boxLeft: 51.9,
      boxWidth: 40,
      arrow: {
        angle: -90,
        top: 42,
        left: 23,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.move'),
      text: 'tuto.move',
      boxTop: 13,
      boxLeft: 56.5,
      boxWidth: 58,
      arrow: {
        angle: -90,
        top: 21.8,
        left: 18.5,
      },
    },
  ],
  [
    {
      title: (t: TFunction) => t('tuto.title.crypt'),
      text: 'tuto.crypt',
      boxTop: 23,
      boxLeft: 68.4,
      boxWidth: 53,
      arrow: {
        angle: -90,
        top: 36,
        left: 33,
      },
    },
  ],
  [
    {
      title: (t: TFunction) => t('tuto.title.endturn'),
      text: 'tuto.endturn',
      boxTop: 17.5,
      boxLeft: 46,
      boxWidth: 53,
      arrow: {
        angle: 0,
        top: 6,
        left: 55.5,
      },
    },
  ],
  [
    {
      opponentActionBefore: 2,
    },
  ],
  [],
  [
    {
      title: (t: TFunction) => t('tuto.title.playingarea2'),
      text: () => (
        <Trans
          defaults="tuto.playingarea2"
          components={[
            <span
              css={css`
                text-decoration: underline;
              `}
            />,
          ]}
        />
      ),
      boxTop: 45,
      boxLeft: 47,
      boxWidth: 90,
      arrow: {
        angle: 0,
        top: 34,
        left: 9.5,
      },
      opponentActionBefore: 2,
    },
    {
      title: (t: TFunction) => t('tuto.title.gotoworld'),
      text: 'tuto.gotoworld2',
      boxTop: 7,
      boxLeft: 45,
      boxWidth: 75,
      arrow: {
        angle: 90,
        top: 12,
        left: 80,
      },
    },
    {
      title: (t: TFunction) => t('tuto.title.move2'),
      text: 'tuto.move2',
      boxTop: 32,
      boxLeft: 44.5,
      boxWidth: 40,
      arrow: {
        angle: -90,
        top: 43,
        left: 15.5,
      },
      opponentActionBefore: 2,
    },
  ],
  [
    {
      title: (t: TFunction) => t('tuto.title.hunt'),
      text: 'tuto.hunt',
      boxTop: 13.5,
      boxLeft: 30,
      boxWidth: 53,
      arrow: {
        angle: 90,
        top: 23,
        left: 54.5,
      },
    },
  ],
  [
    {
      title: (t: TFunction) => t('tuto.title.hunt'),
      text: () => {
        return (
          <div css={huntDetailContainer}>
            <div css={huntCardsContainer}>
              <HuntCard hunt={Boo} css={huntCardStyle} />
              <HuntCard hunt={BorisPouchkineHunger} css={huntCardStyle} />
            </div>
            <div css={huntCardsDescriptionContainer}>
              <p>
                <Trans defaults="tuto.huntdetail1" components={[<strong />]} />
              </p>
              <p>
                <Trans defaults="tuto.huntdetail2" components={[<strong />]} />
              </p>
            </div>
          </div>
        );
      },
      boxTop: 50,
      boxLeft: 46,
      boxWidth: 65,
    },
    {
      title: (t: TFunction) => t('tuto.title.endturn'),
      text: 'tuto.endturn2',
      boxTop: 17.5,
      boxLeft: 46,
      boxWidth: 53,
      arrow: {
        angle: 0,
        top: 6,
        left: 56,
      },
    },
  ],
  [
    {
      opponentActionBefore: 3,
    },
  ],
  [],
  [],
  [
    {
      title: (t: TFunction) => t('tuto.title.gowhenready'),
      text: 'tuto.gowhenready',
      boxTop: 50,
      boxLeft: 50,
      boxWidth: 60,
    },
    {
      title: (t: TFunction) => t('tuto.title.bonus'),
      text: 'tuto.bonus',
      //boxTop: 44,
      boxBottom: 5,
      boxLeft: 53.5,
      boxWidth: 65,
      arrow: {
        angle: -90,
        top: 76.5,
        left: 12,
      },
    },
  ],
  [
    {
      title: (t: TFunction) => t('tuto.title.conclusion'),
      text: 'tuto.conclusion',
      boxTop: 50,
      boxLeft: 48,
      boxWidth: 60,
    },
  ],
];

const hungCardHeight = 8;
const huntCardStyle = css`
  height: ${hungCardHeight}em;
  width: ${hungCardHeight * cardRatio}em;

  > div {
    font-size: ${hungCardHeight / 100}em;
  }
`;

const huntCardsContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;

  > div:first-child {
    margin: 0 0 1em 0;
  }
`;

const huntDetailContainer = css`
  display: flex;
  flex-direction: row;
`;

const huntCardsDescriptionContainer = css`
  display: flex;
  flex-direction: column;
  flex: 3;

  > p {
    flex: 1;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;

export default TutorialPopup;
