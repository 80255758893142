import { EffectTrigger } from './EffectTrigger';
import { GameEffect } from './GameEffect';
import { EffectType } from './EffectType';
import { PlayerType } from '../player';
import Move from '../moves/Move';
import { moveVampireMove } from '../moves/MoveVampire';
import { getConfusedMovablePath } from '../utils/MoveUtils';
import { BoardBoxes } from '../board';
import GameState from '../GameState';
import { Card } from '../card/Card';

export class ConfuseEffect implements GameEffect {
  type: EffectType.Confuse = EffectType.Confuse;
  distance: number;
  trigger: EffectTrigger = EffectTrigger.BeforeMoveOrHunt

  constructor(object: Partial<ConfuseEffect>) {
    this.distance = object.distance!;
  }

  legalMoves(state: GameState, player: PlayerType, deck: Card[]): Move[] {
    return getConfusedMovablePath(
      this.distance,
      player,
      BoardBoxes,
      deck,
      true,
      state.players.map(p => p.position)
    )
      .map(path => path.slice(-1))
      .map(path => moveVampireMove(path[0], player.vampire));
  }
}
