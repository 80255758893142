/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { Modal } from './Modal';
import { Trans, useTranslation } from 'react-i18next';
import { TheHungerButton } from '../utils/TheHungerButton';
import { RegionSymbol, VictoryPointSymbol } from '../symbols';
import { Region } from '@gamepark/the-hunger/board';

type BoardHelpModalProps = {
  onClose: () => void;
};

const BoardHelpModal: FC<BoardHelpModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  const actions = <TheHungerButton css={button} labelKey={'ok'} onClick={onClose} />;

  const description = (
    <>
      <div css={titleStyle}>{t('help.title.turnorder')}</div>
      <Trans
        defaults="help.turnorder"
        components={[
          <RegionSymbol region={Region.Forest} hd css={regionStyle} />,
          <RegionSymbol region={Region.Plain} hd css={regionStyle} />,
          <RegionSymbol region={Region.Mountain} hd css={regionStyle} />,
          <RegionSymbol region={Region.Cemetery} hd css={regionStyle} />,
        ]}
      />
      <div css={titleStyle}>{t('help.title.regionbonus')}</div>
      <Trans
        defaults="help.regionbonus"
        components={[
          <VictoryPointSymbol value="1" css={victoryPointStyle} />,
          <RegionSymbol region={Region.Plain} hd css={regionStyle} />,
          <VictoryPointSymbol value="2" css={victoryPointStyle} />,
          <RegionSymbol region={Region.Forest} hd css={regionStyle} />,
        ]}
      />
    </>
  );

  return (
    <>
      <div css={modalOverlay} onClick={onClose} />
      <Modal description={description} onClose={onClose} actions={actions} css={modalStyle} />
    </>
  );
};

const titleStyle = css`
  font-size: 1.3em;
  margin-bottom: 0.4em;
  text-decoration: underline;

  &:not(:first-child) {
    border-top: 0.01em solid white;
    margin-top: 0.4em;
    padding-top: 0.3em;
  }
`;

const modalOverlay = css`
  position: absolute;
  left: -1000%;
  top: -1000%;
  right: -1000%;
  bottom: -1000%;
  background-color: transparent;
  z-index: 20;
  cursor: pointer;
`;

const victoryPointStyle = css`
  font-size: 0.7em;
`;

const modalStyle = css`
  transform: unset;
  top: 9em;
  width: 73em;
  left: 99.5em;
  padding: 3em;
  height: auto;
  max-height: 89em;
  overflow-y: auto;
  scrollbar-color: rgba(74, 74, 74, 0.3) transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 1);
  }
  z-index: 150;
`;

const regionStyle = css`
  margin-bottom: -0.4em;
  transform: translateY(2%);
`;

const button = css`
  position: relative;
  width: 6em;
  height: 1.4em;
  margin-left: 0.3em;
  margin-right: 0.1em;
  display: block;
  font-size: 3.5em;
  text-transform: uppercase;
`;

export { BoardHelpModal };
