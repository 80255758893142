import { GameEffect } from './GameEffect';
import { EffectType } from './EffectType';
import { EndTurn, endTurnMove } from '../moves/EndTurn';

export class SkipTurnEffect implements GameEffect {
  type: EffectType.SkipTurn = EffectType.SkipTurn;

  static automaticMove(): EndTurn {
    return endTurnMove;
  }
}
