import GameState from './GameState';
import { PlayerType } from './player';
import { PositionedBonus } from './bonus/PositionedBonus';
import { HuntView } from './hunt';
import Vampire from './player/Vampire';
import { PlayerMission } from './player/PlayerMission';
import { ViewType } from '@gamepark/the-hunger-app/src/ViewType';

/**
 * In here, you describe what a GameView will look like at any time during a game.
 * It usually derives from the GameState, because only a few properties change.
 */
// Here is a example of a "Game View": the deck content is hidden, instead it is replaced with the number of card remaining inside
type GameView = Omit<GameState, 'players' | 'bonusTokens' | 'missions' | 'tavern' | 'hunt'> & {
  players: PlayerType[];
  bonusTokens: (PositionedBonus | Omit<PositionedBonus, 'bonus'>)[];
  missions: (number | number[])[];
  tavern: number;
  hunt: HuntView;
  displayedPlayer?: Vampire;
  selectedVampire?: Vampire;
  hightlightedBox?: number;
  displayedView?: ViewType;
  displayedScore?: Vampire;
};

export const isInvisibleMissions = (missions: number | number[] | PlayerMission[]): missions is number =>
  typeof missions === 'number';

export default GameView;
