import { EffectType } from './EffectType';
import { GameEffect } from './GameEffect';

export class FastEffect implements GameEffect {
  type: EffectType.Fast = EffectType.Fast;
  additionalCost: number;

  constructor(additionalCost: number) {
    this.additionalCost = additionalCost;
  }
}
