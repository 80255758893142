import { BonusTokenType } from './BonusTokenType';
import { Bonus } from './Bonus';
import { SpeedBonusEffect } from '../effect/SpeedBonusEffect';
import { AdditionalHuntEffect } from '../effect/AdditionalHuntEffect';
import { DiscardEffect } from '../effect/DiscardEffect';
import { DrawEffect } from '../effect/DrawEffect';
import { InspiringEffect } from '../effect/InspiringEffect';
import { VictoryPointEffect } from '../effect/VictoryPointEffect';

export const Church: Bonus = {
  type: BonusTokenType.Church,
};

export const Military: Bonus = {
  type: BonusTokenType.Military,
};

export const Villager: Bonus = {
  type: BonusTokenType.Villager,
};

export const Noble: Bonus = {
  type: BonusTokenType.Noble,
};

export const MultiFaction: Bonus = {
  type: BonusTokenType.MultiFaction,
};

export const OneSpeed: Bonus = {
  type: BonusTokenType.OneSpeed,
  copies: 2,
  effects: [new SpeedBonusEffect(1)],
};

export const TwoSpeed: Bonus = {
  type: BonusTokenType.TwoSpeed,
  copies: 2,
  effects: [new SpeedBonusEffect(2)],
};

export const AdditionalHunt: Bonus = {
  type: BonusTokenType.Hunt,
  copies: 2,
  effects: [new AdditionalHuntEffect(1)],
};

export const Discard: Bonus = {
  type: BonusTokenType.Discard,
  copies: 2,
  effects: [new DiscardEffect(), new DrawEffect(1)],
};

export const Draw: Bonus = {
  type: BonusTokenType.Draw,
  copies: 2,
  effects: [new DrawEffect(1)],
};

export const AdditionalMission: Bonus = {
  type: BonusTokenType.Mission,
  copies: 2,
  effects: [new InspiringEffect()],
};

export const Parasol: Bonus = {
  type: BonusTokenType.Parasol,
  copies: 3,
};

export const VelvetCoat: Bonus = {
  type: BonusTokenType.VelvetClothing,
  effects: [new VictoryPointEffect(2)],
};

export const VelvetGloves: Bonus = {
  type: BonusTokenType.VelvetClothing,
  effects: [new VictoryPointEffect(2)],
};

export const VelvetScarf: Bonus = {
  type: BonusTokenType.VelvetClothing,
  effects: [new VictoryPointEffect(2)],
};

export const VelvetPants: Bonus = {
  type: BonusTokenType.VelvetClothing,
  effects: [new VictoryPointEffect(2)],
};

export const VelvetHat: Bonus = {
  type: BonusTokenType.VelvetClothing,
  effects: [new VictoryPointEffect(2)],
};

export const VelvetBoots: Bonus = {
  type: BonusTokenType.VelvetClothing,
  effects: [new VictoryPointEffect(2)],
};

export const BonusesNew: Bonus[] = [
  Church,
  Military,
  Villager,
  Noble,
  MultiFaction,
  OneSpeed,
  TwoSpeed,
  AdditionalHunt,
  Discard,
  Draw,
  AdditionalMission,
  Parasol,
  VelvetBoots,
  VelvetCoat,
  VelvetGloves,
  VelvetHat,
  VelvetPants,
  VelvetScarf,
].flatMap((bonus) => Array(bonus.copies || 1).fill(bonus));

export const BonusesOld: Bonus[] = [
  Church,
  Military,
  Villager,
  Noble,
  MultiFaction,
  OneSpeed,
  TwoSpeed,
  AdditionalHunt,
  Discard,
  Draw,
  AdditionalMission,
  Parasol,
  VelvetBoots,
  VelvetCoat,
  VelvetGloves,
  VelvetHat,
  VelvetPants,
  VelvetScarf,
].flatMap((bonus, index) => Array(index > 11 ? 1 : 2).fill(bonus));

export const Bonuses = (oldTokensCount?: boolean) => (oldTokensCount ? BonusesOld : BonusesNew);
