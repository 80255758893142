/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef, HTMLAttributes } from 'react';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { HuntCard } from './HuntCard';
import { cardRatio } from '../../utils/Style';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

type Place = 'top' | 'right' | 'bottom' | 'left';
type HuntCardStackProps = {
  hunts: number[] | number,
  position: number,
  preTransform?: string
  horizontalDirection?: 'left' | 'center' | 'right',
  verticalDirection?: 'top' | 'center' | 'bottom',
  tooltipPosition?: Place,
  highlightFirst?: any
  showOnlyLast?: boolean
  cardLimit?: number;
  displayTooltip?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const HuntCardStack = forwardRef<HTMLDivElement, HuntCardStackProps>(({
                                                                        hunts,
                                                                        showOnlyLast,
                                                                        cardLimit,
                                                                        highlightFirst,
                                                                        position,
                                                                        preTransform,
                                                                        verticalDirection,
                                                                        horizontalDirection,
                                                                        tooltipPosition,
                                                                        displayTooltip = true,
                                                                        ...props
                                                                      }, ref) => {
  const { t } = useTranslation();
  if ((isStackCount(hunts) && !hunts) || (!isStackCount(hunts) && !hunts.length)) {
    return null;
  }

  const getCardCount = (hunts: number) => cardLimit && cardLimit <= hunts ? cardLimit : hunts;

  const getCards = (hunts: number[]) => [...(cardLimit && cardLimit <= hunts.length ? hunts.slice(-cardLimit) : hunts)].reverse();

  return (
    <>
      <div ref={ ref } css={ cardStackStyle(preTransform || 's') } { ...props } data-tip data-for={ `card-${ position }` }>
        { isStackCount(hunts) &&
        [...Array(getCardCount(hunts))].map((_, index) => {
          let cardCount = getCardCount(hunts);
          return <HuntCard
            key={ index }
            css={ huntCard(getCardZIndex(cardCount, index)) }
            preTransform={ `translate(${ getCardTranslateX(cardCount, index, horizontalDirection) }%, ${ getCardTranslateY(cardCount, index, verticalDirection) }%)` }
          />;
        })
        }

        { !isStackCount(hunts) && getCards(hunts).map((hunt, index) => {
            let cards = getCards(hunts);
            return <HuntCard
              key={ hunt }
              css={ huntCard(getCardZIndex(cards.length, index)) }
              hunt={ (!showOnlyLast || !index) ? GameDeck[hunt] : undefined }
              preTransform={ `translate(${ getCardTranslateX(cards.length, index, horizontalDirection) }%, ${ getCardTranslateY(cards.length, index, verticalDirection) }%)` }
            />;
          }
        ) }
      </div>
      { displayTooltip && <ReactTooltip className="the-hunger-tooltip"
                                        offset={ { top: 1, bottom: tooltipPosition === 'bottom' ? 5 : 0, left: tooltipPosition === 'left' ? 5 : 0, right: tooltipPosition === 'right' ? 10 : 0 } }
                                        id={ `card-${ position }` }
                                        type="info" effect="solid" place={ tooltipPosition }>
          <span css={ cardCount }>{ t('card.count', { cardCount: isStackCount(hunts) ? hunts : hunts.length }) }  </span>
      </ReactTooltip> }
    </>
  );
});

const getCardZIndex = (cardCount: number, cardIndex: number) => cardCount - cardIndex;

const getCardTranslateX = (cardCount: number, cardIndex: number, horizontalDirection: 'left' | 'center' | 'right' = 'right') =>
  horizontalDirection === 'center' ? 0 : (cardCount - (cardIndex + 1)) * (horizontalDirection !== 'left' ? 1 : -1);

const getCardTranslateY = (cardCount: number, cardIndex: number, verticalDirection: 'top' | 'center' | 'bottom' = 'bottom') =>
  verticalDirection === 'center' ? 0 : (cardCount - (cardIndex + 1)) * cardRatio * (verticalDirection !== 'top' ? 1 : -1);

const isStackCount = (hunts: number | number[]): hunts is number => typeof hunts === 'number';

const cardStackStyle = (preTransform: string) => css`
  transform: ${ preTransform };
`;

const huntCard = (zIndex: number) => css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: ${ zIndex };
  box-shadow: unset;
`;

const cardCount = css`
  white-space: nowrap;
  font-size: 1.4em;
`;

export {
  HuntCardStack
};
