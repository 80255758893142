import { HumanMilitaryEffects } from './HumanMilitaryEffects';
import { HumanNobleEffects } from './HumanNobleEffects';
import { HumanVillagerEffects } from './HumanVillagerEffects';
import { HumanReligiousEffects } from './HumanReligiousEffects';
import { PowerEffects } from './PowerEffects';
import { FamiliarEffects } from './FamiliarEffects';
import { RoseEffects } from './RoseEffects';
import { StarterEffects } from './StarterEffects';

const EffectDescriptions = new Map([
  ...HumanMilitaryEffects,
  ...HumanNobleEffects,
  ...HumanVillagerEffects,
  ...HumanReligiousEffects,
  ...PowerEffects,
  ...FamiliarEffects,
  ...RoseEffects,
  ...StarterEffects
]);

export {
  EffectDescriptions
};
