/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Path } from '@gamepark/the-hunger/board';
import { FC } from 'react';
import { Picture } from '@gamepark/react-components';
import { Images } from '../../images/Images';

type PathImageProps = {
  type: Path;
};

const PathImage: FC<PathImageProps> = (props) => {
  const { type } = props;

  return <Picture src={PathImages.get(type)!} css={pathStyle} />;
};

const pathRatio = 99 / 61;
const pathWidth = 3;
const pathHeight = pathWidth / pathRatio;
const pathStyle = css`
  width: ${pathWidth}em;
  height: ${pathHeight}em;
  transform: translateY(0.5em);
`;

const PathImages = new Map<Path, any>();
PathImages.set(Path.Road, Images.Road);
PathImages.set(Path.Railroad, Images.Railroad);
PathImages.set(Path.Boat, Images.Water);

export { PathImage };
