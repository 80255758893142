import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { getNextPendingEffect } from '../utils/PendingUtils';

export type DismissEffect = {
  type: MoveType.DismissEffect,
}

export const dismissEffectMove: DismissEffect = ({
  type: MoveType.DismissEffect
})

export const dismissEffect = (state: GameState | GameView) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  const firstEffect = getNextPendingEffect(player);
  if (firstEffect && firstEffect.optional) {
    let nextOptionalEffect = player.pendingEffects.findIndex((e, index) => !!index && e.optional);
    player.pendingEffects.splice(0, nextOptionalEffect !== -1? nextOptionalEffect: player.pendingEffects.length);
  }
}
