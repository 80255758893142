/**
 * Base class for all cards
 */
import { CardType } from './CardType';
import { CardTrait } from './CardTrait';
import { GameEffects } from '../effect/GameEffect';

export type Card = {
  category: CardType,
  speed: number,
  victoryPoints?: number | string,
  traits?: Array<CardTrait>
  effects?: Array<GameEffects>,
  passiveEffects?: Array<GameEffects>,
}

export const isBrutVictoryPoint = (victoryPoints?: number | string): victoryPoints is number => typeof victoryPoints === 'number';

