/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RookieBoxPosition } from '../material/board/box/BoxPositions';
import { MoveHuntTrackCardDirection } from '@gamepark/the-hunger/moves/MoveHuntTrackCard';
import { SecondViewEnabled } from '@gamepark/the-hunger/utils/GameConstants';

const SecondViewTranslate = SecondViewEnabled ? 100 : 0;
export const screenRatio = 16 / 9;

export const platformUri = process.env.REACT_APP_PLATFORM_URI || 'http://localhost:3000';
export const discordUri = 'https://discord.gg/nMSDRag';

export const cardRatio = 50 / 70;
export const cardHeight = 20;
export const cardWidth = (cardHeight * cardRatio) / screenRatio;

export const missionRatio = 187 / 300;

export const gameDisplayTop = 8.5;

/**
 * BOARD
 */
export const gameBoardRatio = 1500 / 1359;
export const gameBoardHeight = 90;
export const gameBoardCardZoom = 1.3;
export const gameBoardWidth = (gameBoardHeight / screenRatio) * gameBoardRatio;
export const gameBoardLeft = 0.8;
export const gameBoardMissionHeight = (6.3 * 100) / gameBoardHeight;
export const gameBoardMissionWidth = gameBoardMissionHeight / missionRatio / gameBoardRatio;

/**
 * Player panels
 */

export const playerPanelsRight = 0.7;
export const playerPanelsTop = (playerCount: number) =>
  gameDisplayTop + (playerCount === 6 ? 0 : (playerPanelTop(1) / 100) * playerPanelsHeight);
export const playerPanelsRatio = 19.3 / 90;
export const playerPanelsHeight = 90;
export const playerPanelsWidth = (playerPanelsHeight * playerPanelsRatio) / screenRatio;

/**
 * Player panel activate mission
 */
export const playerPanelMissionTop = (index: number) => playerPanelTop(index);
export const playerPanelMissionTranslateX = -12;
export const playerPanelMissionTranslateY = -17;

export const playerPanelMissionTranslatedX = -90;

export const playerPanelMissionHeight = 15;
export const playerPanelMissionWidth = playerPanelMissionHeight / missionRatio / playerPanelsRatio;

/**
 * Player panel acquire bonus
 */
export const playerPanelTokenTop = (index: number) => playerPanelTop(index) + 2;
export const playerPanelTokenTranslateX = 43;
export const playerPanelTokenTranslateY = -20;

export const playerPanelTokenTranslatedX = -80;

export const playerPanelTokenHeight = 10;
export const playerPanelTokenWidth = playerPanelTokenHeight / playerPanelsRatio;

/**
 * Player panel acquire bonus
 */
export const playerPanelDrawHuntCardTop = (index: number) => playerPanelTop(index) + 2;
export const playerPanelDrawHuntCardTranslateX = 15;
export const playerPanelDrawHuntCardTranslateY = -35;

export const playerPanelDrawHuntCardTranslatedX = -90;

export const playerPanelDrawHuntCardHeight = 20;
export const playerPanelDrawHuntCardWidth = (playerPanelDrawHuntCardHeight * cardRatio) / playerPanelsRatio;

/**
 * Player Counter
 */
export const playerCountersRatio = 10 / 5;
export const playerCountersWidth = 10;
export const playerCountersHeight = (playerCountersWidth / playerCountersRatio) * screenRatio;

export const playerMedalHeight = 7;
export const playerMedalWidth = playerMedalHeight / playerPanelsRatio;
export const playerMedalTop = (index: number): number => index * (playerPanelHeight + 7) + 1.4;
export const playerMedalLeft = 27;

export const playerPanelHeight = 10;
export const playerPanelWidth = playerPanelHeight / playerPanelsRatio;
export const playerPanelTop = (index: number): number => index * (playerPanelHeight + 7);
export const playerPanelLeft = 20;

export const playerPanelCountersHeight = 13;
export const playerPanelCountersWidth = playerPanelCountersHeight / playerCountersRatio / playerPanelsRatio;
export const playerPanelCountersTop = (index: number) => playerPanelTop(index) - 1.2;

export const playerPanelInfoTop = (index: number) => playerPanelTop(index) + playerPanelHeight + 1.5;
export const playerPanelAvatarHeight = 6;
export const playerPanelAvatarWidth = playerPanelAvatarHeight / playerPanelsRatio;

export const playerPanelTurnOrderHeight = 4;
export const playerPanelTurnOrderWidth = playerPanelTurnOrderHeight / playerPanelsRatio;
/**
 * Mission stack on board
 */
export const boardMissionStackTranslateX = (index: number, positions: Array<{ top: number; left: number }>) =>
  (positions[index].left * 100) / gameBoardMissionWidth;
export const boardMissionStackTranslateY = (index: number, positions: Array<{ top: number; left: number }>) =>
  (positions[index].top * 100) / gameBoardMissionHeight;

const getPlayerLeftRelativeToLetterBox = () =>
  100 - playerPanelsRight - playerPanelsWidth + (playerPanelLeft / 100) * playerPanelsWidth;
const getPlayerTopRelativeToLetterBox = (index: number, playerCount: number): number =>
  (playerPanelTop(index) / 100) * playerPanelsHeight + playerPanelsTop(playerCount);

export const boardMissionStackTranslateToPlayerX =
  ((((getPlayerLeftRelativeToLetterBox() - gameBoardLeft) * 100) / gameBoardMissionWidth) * 100) / gameBoardWidth;

export const boardMissionStackTranslateToPlayerY = (playerIndex: number, playerCount: number) =>
  ((((getPlayerTopRelativeToLetterBox(playerIndex, playerCount) - gameDisplayTop) * 100) / gameBoardMissionHeight) *
    100) /
  gameBoardHeight;

/**
 * Board boxes
 */
export const boxRatio = 1;
export const boxHeight = 5;
export const boxWidth = (boxHeight * boxRatio) / gameBoardRatio;

/**
 * Token
 */
export const tokenHeight = 3;
export const tokenWidth = tokenHeight / gameBoardRatio;

/**
 * HUNT TRACK
 */
export const huntTrackWidth = 21.1;
export const huntTrackLeft = gameBoardLeft + gameBoardWidth + 1;
export const huntTrackReducedTranslateX = 80;
export const huntTrackHeight = 100;
export const huntTrackRatio = (screenRatio * huntTrackWidth) / huntTrackHeight;
// Hunt track full computed height
export const huntTracksHeight = (playerCount: number) => huntTrackLineHeight * (playerCount + 1);
export const realHuntTrackHeight = (playerCount: number) => (huntTracksHeight(playerCount) * huntTrackHeight) / 100;
export const realHuntTrackRatio = (playerCount: number) =>
  (screenRatio * huntTrackWidth) / realHuntTrackHeight(playerCount);

export const huntTrackLineRatio = 700 / 240;
export const huntTrackLineWidth = 100;
export const huntTrackLineHeight = (huntTrackLineWidth / huntTrackLineRatio) * huntTrackRatio;
export const realHuntTrackLineHeight = (playerCount: number) =>
  (huntTrackLineWidth / huntTrackLineRatio) * realHuntTrackRatio(playerCount);

// Hunt track line computed
export const huntTrackLineTop = (index: number, playerCount: number) => index * realHuntTrackLineHeight(playerCount);

// Hunt track area
export const huntTrackSmallAreaRatio = 74 / 100;
export const huntTrackSmallAreaWidth = 23.5;
export const huntTrackSmallAreaHeight = (playerCount: number) =>
  (huntTrackSmallAreaWidth / huntTrackSmallAreaRatio) * realHuntTrackRatio(playerCount);
export const huntTrackLargeAreaWidth = 44;
export const huntTrackLargeAreaRatio = ((74 / huntTrackSmallAreaWidth) * huntTrackLargeAreaWidth) / 100;
export const huntTrackLargeAreaHeight = (playerCount: number) =>
  (huntTrackLargeAreaWidth / huntTrackLargeAreaRatio) * realHuntTrackRatio(playerCount);
export const realHuntTrackAreaHeight = (playerCount: number, areaIndex: number) =>
  areaIndex < 2 ? huntTrackSmallAreaHeight(playerCount) : huntTrackLargeAreaHeight(playerCount);
export const realHuntTrackAreaWidth = (areaIndex: number) =>
  areaIndex < 2 ? huntTrackSmallAreaWidth : huntTrackLargeAreaWidth;

export const huntTrackAreaLeft = (areaIndex: number) => areaIndex * (huntTrackSmallAreaWidth + 2.5) + 2;
export const huntTrackAreaTop = (playerCount: number, lineIndex: number) =>
  (0.4 * huntTrackHeight) / realHuntTrackHeight(playerCount) + lineIndex * realHuntTrackLineHeight(playerCount);

export const getHuntTrackAreaUntranslateX = (areaIndex: number) => {
  return (
    ((100 - huntTrackCardBaseX / huntTrackCardFocus / 2 - huntTrackCardWidth(areaIndex) * huntTrackCardFocus) * 100) /
      huntTrackCardWidth(areaIndex) -
    huntTrackCardFocusTranslate
  );
};

export const huntTrackAreaTranslateY = (
  playerCount: number,
  lineIndex: number,
  areaIndex: number,
  playerIndex: number
) => {
  const areaTopRelativeToLetterBox =
    (huntTrackAreaTop(playerCount, lineIndex) / 100) * realHuntTrackHeight(playerCount) + gameDisplayTop;
  const playerTopRelativeToLetterBox = getPlayerTopRelativeToLetterBox(playerIndex, playerCount);

  return (
    ((((-areaTopRelativeToLetterBox + playerTopRelativeToLetterBox) * 100) /
      realHuntTrackAreaHeight(playerCount, areaIndex)) *
      100) /
      realHuntTrackHeight(playerCount) -
    10
  );
};

export const huntTrackAreaTranslateX = (areaIndex: number, delta: number) => {
  const areaLeftRelativeToLetterBox = (huntTrackAreaLeft(areaIndex) / 100) * huntTrackWidth + huntTrackLeft;
  const playerLeftRelativeToLetterBox = getPlayerLeftRelativeToLetterBox();

  return (
    ((((-areaLeftRelativeToLetterBox + playerLeftRelativeToLetterBox + delta) * 100) /
      realHuntTrackAreaWidth(areaIndex)) *
      100) /
      huntTrackWidth -
    100
  );
};

export const huntTrackAreaCardTranslateY = (
  playerCount: number,
  lineIndex: number,
  areaIndex: number,
  playerIndex: number
) => {
  const areaTopRelativeToLetterBox =
    (huntTrackAreaTop(playerCount, lineIndex) / 100) * realHuntTrackHeight(playerCount) + gameDisplayTop;
  const playerTopRelativeToLetterBox = getPlayerTopRelativeToLetterBox(playerIndex, playerCount);

  return (
    ((((((-areaTopRelativeToLetterBox + playerTopRelativeToLetterBox) * 100) / huntTrackCardHeight) * 100) /
      realHuntTrackAreaHeight(playerCount, areaIndex)) *
      100) /
      realHuntTrackHeight(playerCount) -
    huntTrackCardFocusTranslate -
    10 * huntTrackCardFocus
  );
};

export const huntTrackAreaCardTranslateX = (areaIndex: number, delta: number) => {
  const areaLeftRelativeToLetterBox = (huntTrackAreaLeft(areaIndex) / 100) * huntTrackWidth + huntTrackLeft;
  const playerLeftRelativeToLetterBox = getPlayerLeftRelativeToLetterBox();

  return (
    ((((((-areaLeftRelativeToLetterBox + playerLeftRelativeToLetterBox + delta) * 100) /
      huntTrackCardWidth(areaIndex)) *
      100) /
      realHuntTrackAreaWidth(areaIndex)) *
      100) /
      huntTrackWidth -
    100
  );
};

export const huntTrackAreaRatio = (areaIndex: number) =>
  areaIndex === 2 ? huntTrackLargeAreaRatio : huntTrackSmallAreaRatio;
export const huntTrackAreaWidth = (areaIndex: number) =>
  areaIndex === 2 ? huntTrackLargeAreaWidth : huntTrackSmallAreaWidth;
// Hunt track line card

export const huntTrackCardFocus = 0.2;
export const huntTrackCardFocusPadding = 0.1;
export const huntTrackCardHeight = 95 / huntTrackCardFocus;
export const huntTrackCardWidth = (areaIndex: number) =>
  (huntTrackCardHeight * cardRatio) / huntTrackAreaRatio(areaIndex);

export const huntTrackCardFocusTranslate = (100 * (1 - huntTrackCardFocus)) / 2;

export const huntTrackCardBaseX = 4 * huntTrackCardFocus;
export const huntTrackCardTranslateX = (
  cardIndex: number,
  areaIndex: number,
  areaSize: number,
  withBase: boolean = true
) => {
  return (
    (withBase ? huntTrackCardBaseX : 0) +
    (Math.max(
      ((100 - huntTrackCardBaseX / huntTrackCardFocus - huntTrackCardWidth(areaIndex) * huntTrackCardFocus) /
        (areaSize - 1 || 1)) *
        cardIndex,
      0
    ) *
      100) /
      huntTrackCardWidth(areaIndex) -
    huntTrackCardFocusTranslate
  );
};
export const huntTrackCardTranslateY =
  (((100 - huntTrackCardHeight * huntTrackCardFocus) / 2) * 100) / huntTrackCardHeight - huntTrackCardFocusTranslate;

/**
 * Board button
 */
export const boardButtonLabelTop = (playerCount: number): number =>
  gameDisplayTop + (playerCount === 6 ? 0 : boardButtonHeight(playerCount) - 1);
export const boardButtonTop = (playerCount: number): number => gameDisplayTop + (playerCount === 6 ? 5 : 0);
export const boardButtonLeft = (playerCount: number) =>
  playerCount === 6
    ? huntTrackLeft + huntTrackWidth + 1
    : 100 - playerPanelsWidth - playerPanelsRight + (playerPanelLeft / 100) * playerPanelsWidth;
export const boardButtonWidth = (playerCount: number) =>
  playerCount === 6 ? 6 : (playerPanelWidth / 100) * playerPanelsWidth;
export const boardButtonHeight = (playerCount: number): number => boardButtonWidth(playerCount) * screenRatio;

/**
 * Turn order
 */
export const turnCounterTop = (playerCount: number) =>
  playerCount === 6 ? boardButtonTop(playerCount) + boardButtonHeight(playerCount) + 1 : gameDisplayTop;
export const turnCounterLeft = huntTrackLeft + huntTrackWidth + 1;
export const turnCounterWidth = 6;
export const turnCounterHeight = turnCounterWidth * screenRatio;

/**
 * Player hunts summary
 */
export const playerEffectsSummaryRatio = 6.7 / 30;
export const playerEffectsSummaryTop = (playerCount: number) => huntDeckTop(playerCount) + huntDeckHeight + 2;
export const playerEffectsSummaryLeft = huntTrackLeft + huntTrackWidth + 1;
export const playerEffectsSummaryWidth = 6.7;
export const playerEffectsSummaryHeight = playerEffectsSummaryWidth / playerEffectsSummaryRatio;

export const playerEffectSummaryWidth = 40;
export const playerEffectSummaryHeight = playerEffectSummaryWidth * playerEffectsSummaryRatio * screenRatio;
export const playerEffectLines = 5;
export const playerEffectSummaryTop = (index: number) => {
  if (index % 2 === 0) {
    return (
      (index / 2 + 1) * ((100 - playerEffectSummaryHeight * playerEffectLines) / (playerEffectLines + 1)) +
      (index / 2) * playerEffectSummaryHeight
    );
  }

  return (
    Math.ceil(index / 2) * ((100 - playerEffectSummaryHeight * playerEffectLines) / (playerEffectLines + 1)) +
    Math.floor(index / 2) * playerEffectSummaryHeight
  );
};
export const playerEffectSummaryLeft = (index: number) => {
  if (index % 2 === 0) {
    return (100 - playerEffectSummaryWidth * 2) / 3;
  }

  return (2 * (100 - playerEffectSummaryWidth * 2)) / 3 + playerEffectSummaryWidth;
};

export const playerEffectSummaryBubbleHeight = 70;
export const playerEffectSummaryBubbleWidth = (playerEffectSummaryBubbleHeight * cardRatio) / screenRatio;
export const playerEffectSummaryBubbleLeft = (index: number) => playerEffectsSummaryLeft - 0.3 + (index % 2) * 3.1;

export const playerEffectSummaryMissionBubbleHeight = 19;
export const playerEffectSummaryMissionBubbleWidth =
  playerEffectSummaryMissionBubbleHeight / missionRatio / screenRatio;
export const playerEffectSummaryMissionBubbleLeft = (index: number) => playerEffectsSummaryLeft + (index % 2) * 3.1;
export const playerEffectSummaryMissionBubbleTop = (index: number, playerCount: number) =>
  -6.5 + playerEffectsSummaryTop(playerCount) + Math.floor(index / 2) * 5.8;

export const playerEffectSummaryBoardBubbleHeight = 25;
export const playerEffectSummaryBoardBubbleWidth = playerEffectSummaryBoardBubbleHeight / missionRatio / screenRatio;
export const playerEffectSummaryBoardBubbleLeft = (index: number) => playerEffectsSummaryLeft - 0.4 + (index % 2) * 3.1;
export const playerEffectSummaryBoardBubbleTop = (index: number, playerCount: number) =>
  -0.9 + playerEffectsSummaryTop(playerCount) + Math.floor(index / 2) * 5.8;

export const computeEffectTooltipArrow = (index: number, playerCount: number) =>
  (playerCount === 6 ? 23.8 : 0) + (13 + (index % 2 === 0 ? (index / 2) * 8.2 : Math.floor(index / 2) * 8.2));

/**
 * Hunt deck
 */
export const huntDeckTop = (playerCount: number) =>
  turnCounterTop(playerCount) - gameDisplayTop + turnCounterHeight + 1;
export const huntDeckLeft = huntTrackLeft + huntTrackWidth + 1;
export const huntDeckWidth = 6;
export const huntDeckHeight = (huntDeckWidth / cardRatio) * screenRatio;

export const gameBoardCardHeight = (huntDeckHeight * 100) / gameBoardHeight;
export const gameBoardCardWidth = (gameBoardCardHeight * cardRatio) / gameBoardRatio;

export const globalCardHeight = 25;

export const getNewCardIndex = (
  cardIndex: number,
  lineIndex: number,
  areaIndex: number,
  track: number[][][],
  direction: MoveHuntTrackCardDirection,
  card?: number
) => {
  switch (direction) {
    case MoveHuntTrackCardDirection.RIGHT:
      if (card) {
        if (track[lineIndex][areaIndex][cardIndex] === card) {
          return 0;
        }

        if (track[lineIndex][areaIndex] && track[lineIndex][areaIndex].includes(card)) {
          return track[lineIndex][areaIndex]
            .filter((c) => c !== card)
            .findIndex((c) => track[lineIndex][areaIndex][cardIndex] === c);
        }

        if (track[lineIndex][areaIndex - 1] && track[lineIndex][areaIndex - 1].includes(card)) {
          return cardIndex + 1;
        }
      }

      if (!card && areaIndex === 2 && track[lineIndex][areaIndex - 1]) {
        return cardIndex + track[lineIndex][areaIndex - 1].length;
      }

      break;
    case MoveHuntTrackCardDirection.LEFT:
      if (card) {
        if (track[lineIndex][areaIndex][cardIndex] === card) {
          return track[lineIndex][areaIndex - 1].length;
        }

        if (track[lineIndex][areaIndex] && track[lineIndex][areaIndex].includes(card)) {
          return track[lineIndex][areaIndex]
            .filter((c) => c !== card)
            .findIndex((c) => track[lineIndex][areaIndex][cardIndex] === c);
        }
      }

      if (!card && track[lineIndex][areaIndex - 1]) {
        return track[lineIndex][areaIndex - 1].length + cardIndex;
      }

      break;
    case MoveHuntTrackCardDirection.TOP:
      if (card) {
        if (track[lineIndex][areaIndex][cardIndex] === card) {
          return track[lineIndex - 1][areaIndex].length;
        }

        if (track[lineIndex][areaIndex] && track[lineIndex][areaIndex].includes(card)) {
          return track[lineIndex][areaIndex]
            .filter((c) => c !== card)
            .findIndex((c) => track[lineIndex][areaIndex][cardIndex] === c);
        }
      }

      break;
    case MoveHuntTrackCardDirection.BOTTOM:
      if (card) {
        if (track[lineIndex][areaIndex][cardIndex] === card) {
          return track[lineIndex + 1][areaIndex].length;
        }

        if (track[lineIndex][areaIndex] && track[lineIndex][areaIndex].includes(card)) {
          return track[lineIndex][areaIndex]
            .filter((c) => c !== card)
            .findIndex((c) => track[lineIndex][areaIndex][cardIndex] === c);
        }
      }
      break;
  }

  return cardIndex;
};

export const getNewAreaLength = (
  cardIndex: number,
  lineIndex: number,
  areaIndex: number,
  track: number[][][],
  direction: MoveHuntTrackCardDirection,
  card?: number
) => {
  switch (direction) {
    case MoveHuntTrackCardDirection.LEFT:
      if (card && track[lineIndex][areaIndex - 1] && track[lineIndex][areaIndex][cardIndex] === card) {
        return track[lineIndex][areaIndex - 1].length + 1;
      }

      if (card && track[lineIndex][areaIndex][cardIndex] !== card && track[lineIndex][areaIndex].includes(card)) {
        return track[lineIndex][areaIndex].length - 1;
      }

      if (card && track[lineIndex][areaIndex + 1] && track[lineIndex][areaIndex + 1].includes(card)) {
        return track[lineIndex][areaIndex].length + 1;
      }

      return track[lineIndex][areaIndex].length;
    case MoveHuntTrackCardDirection.RIGHT:
      if (card && track[lineIndex][areaIndex + 1] && track[lineIndex][areaIndex][cardIndex] === card) {
        return track[lineIndex][areaIndex + 1].length + 1;
      }

      if (card && track[lineIndex][areaIndex][cardIndex] !== card && track[lineIndex][areaIndex].includes(card)) {
        return track[lineIndex][areaIndex].length - 1;
      }

      if (card && track[lineIndex][areaIndex - 1] && track[lineIndex][areaIndex - 1].includes(card)) {
        return track[lineIndex][areaIndex].length + 1;
      }

      if (card) {
        return track[lineIndex][areaIndex].length;
      }

      if (areaIndex < 2) {
        return (
          track[lineIndex][areaIndex].length +
          (track[lineIndex][areaIndex + 1] ? track[lineIndex][areaIndex + 1].length : 0)
        );
      } else {
        return (
          track[lineIndex][areaIndex].length +
          (track[lineIndex][areaIndex - 1] ? track[lineIndex][areaIndex - 1].length : 0)
        );
      }
    case MoveHuntTrackCardDirection.TOP:
      if (card && track[lineIndex - 1] && track[lineIndex][areaIndex][cardIndex] === card) {
        return track[lineIndex - 1][areaIndex].length + 1;
      }

      if (card && track[lineIndex][areaIndex][cardIndex] !== card && track[lineIndex][areaIndex].includes(card)) {
        return track[lineIndex][areaIndex].length - 1;
      }

      if (card && track[lineIndex + 1] && track[lineIndex + 1][areaIndex].includes(card)) {
        return track[lineIndex][areaIndex].length + 1;
      }

      return track[lineIndex][areaIndex].length;
    case MoveHuntTrackCardDirection.BOTTOM:
      if (card && track[lineIndex + 1] && track[lineIndex][areaIndex][cardIndex] === card) {
        return track[lineIndex + 1][areaIndex].length + 1;
      }

      if (card && track[lineIndex][areaIndex][cardIndex] !== card && track[lineIndex][areaIndex].includes(card)) {
        return track[lineIndex][areaIndex].length - 1;
      }

      if (card && track[lineIndex - 1] && track[lineIndex - 1][areaIndex].includes(card)) {
        return track[lineIndex][areaIndex].length + 1;
      }

      return track[lineIndex][areaIndex].length;
  }

  return 0;
};

export const getNewArea = (
  direction: MoveHuntTrackCardDirection,
  lineIndex: number,
  areaIndex: number,
  cardIndex: number,
  track: number[][][],
  card?: number
) => {
  if (!card || card === track[lineIndex][areaIndex][cardIndex]) {
    switch (direction) {
      case MoveHuntTrackCardDirection.LEFT:
        if (areaIndex === 0) {
          return areaIndex;
        }
        return areaIndex - 1;
      case MoveHuntTrackCardDirection.RIGHT:
        if (areaIndex === 2) {
          return areaIndex;
        }
        return areaIndex + 1;
    }
  }

  return areaIndex;
};

export const getNewTranslateX = (
  cardIndex: number,
  lineIndex: number,
  areaIndex: number,
  track: number[][][],
  direction: MoveHuntTrackCardDirection,
  card?: number
) => {
  let newArea = getNewArea(direction, lineIndex, areaIndex, cardIndex, track, card);

  switch (areaIndex) {
    case 0:
      return (
        huntTrackCardTranslateX(
          getNewCardIndex(cardIndex, lineIndex, areaIndex, track, direction, card),
          newArea,
          getNewAreaLength(cardIndex, lineIndex, newArea, track, direction, card)
        ) +
        ((((huntTrackAreaLeft(newArea) - huntTrackAreaLeft(areaIndex)) * 100) / huntTrackCardWidth(areaIndex)) * 100) /
          huntTrackAreaWidth(areaIndex)
      );
    case 1:
    case 2:
      const newAreaTranslateRelativeToHT = huntTrackAreaLeft(newArea);
      const actualAreaTranslateRelativeToHT = huntTrackAreaLeft(areaIndex);
      const areaTranslateDiffRelativeToHT =
        ((((newAreaTranslateRelativeToHT - actualAreaTranslateRelativeToHT) * 100) / huntTrackCardWidth(areaIndex)) *
          100) /
        huntTrackAreaWidth(areaIndex);

      const newCardAreaTranslateX = huntTrackCardTranslateX(
        getNewCardIndex(cardIndex, lineIndex, areaIndex, track, direction, card),
        newArea,
        getNewAreaLength(cardIndex, lineIndex, areaIndex, track, direction, card)
      );

      return areaTranslateDiffRelativeToHT + newCardAreaTranslateX;
  }

  return 0;
};

export const getNewCardLine = (playerCount: number, lineIndex: number, direction: MoveHuntTrackCardDirection) => {
  switch (direction) {
    case MoveHuntTrackCardDirection.TOP:
      if (lineIndex === 0) {
        return lineIndex;
      }

      return lineIndex - 1;
    case MoveHuntTrackCardDirection.BOTTOM:
      if (lineIndex === playerCount) {
        return lineIndex;
      }
      return lineIndex + 1;
  }

  return lineIndex;
};

export const getNewTranslateY = (
  playerCount: number,
  cardIndex: number,
  lineIndex: number,
  areaIndex: number,
  track: number[][][],
  direction: MoveHuntTrackCardDirection,
  card?: number
) => {
  if (
    track[lineIndex][areaIndex][cardIndex] !== card ||
    (MoveHuntTrackCardDirection.TOP !== direction && MoveHuntTrackCardDirection.BOTTOM !== direction)
  ) {
    return huntTrackCardTranslateY;
  }

  const newLine = getNewCardLine(playerCount, lineIndex, direction);
  const newLineTop = huntTrackAreaTop(playerCount, newLine);
  const oldLineTop = huntTrackAreaTop(playerCount, lineIndex);
  const deltaLineTop =
    ((((newLineTop - oldLineTop) * 100) / huntTrackCardHeight) * 100) / huntTrackLargeAreaHeight(playerCount);
  return deltaLineTop + huntTrackCardTranslateY;
};

/**
 * Caslt token
 */
export const castleTokenLeft = 50;
export const castleTokenTop = 33;
export const castleTokenHeight = 7;
export const castleTokenWidth = castleTokenHeight / gameBoardRatio;

export const translateYCastleTokenToPlayer = (index: number, playerCount: number) => {
  const tokenTopRelativeToLetterBox = (castleTokenTop / 100) * gameBoardHeight + gameDisplayTop;
  const playerTopRelativeToLetterBox =
    (playerPanelTop(index) / 100) * playerPanelsHeight + playerPanelsTop(playerCount);
  return (
    ((((-tokenTopRelativeToLetterBox + playerTopRelativeToLetterBox + 1.3) * 100) / castleTokenHeight) * 100) /
    gameBoardHeight
  );
};

export const translateXCastleTokenToPlayer = () => {
  const tokenLeftRelativeToLetterBox = (castleTokenLeft / 100) * gameBoardWidth + gameBoardLeft;
  const playerLeftRelativeToLetterBox =
    100 - playerPanelsRight - playerPanelsWidth + (playerPanelLeft / 100) * playerPanelsWidth;
  return (
    ((((-tokenLeftRelativeToLetterBox + playerLeftRelativeToLetterBox + 0.7) * 100) / castleTokenWidth) * 100) /
    gameBoardWidth
  );
};

// PLaying area
export const playingAreaCardCount = 7;
export const playingAreaWidth =
  ((globalCardHeight * cardRatio) / screenRatio) * playingAreaCardCount + playingAreaCardCount;
export const playingAreaHeight = globalCardHeight + 2;
export const playingAreaRatio = playingAreaWidth / playingAreaHeight;

export const playingAreaLeft = SecondViewTranslate + playerPanelsRight;

export const playingAreaCardLeft = 1;
export const playingAreaCardHeight = (globalCardHeight * 100) / playingAreaHeight;
export const playingAreaCardWidth = (playingAreaCardHeight * cardRatio) / playingAreaRatio / screenRatio;
export const playingAreaCardTranslateX = (cardCount: number, index: number) => {
  const playingAreaCardGap = (playingAreaCardLeft * 100) / playingAreaCardWidth;
  if (cardCount <= playingAreaCardCount) {
    return (
      playingAreaCardGap +
      (((100 - playingAreaCardLeft * 2 - playingAreaCardWidth) / (playingAreaCardCount - 1)) * index * 100) /
        playingAreaCardWidth
    );
  }
  return (
    playingAreaCardGap +
    (((100 - playingAreaCardLeft * 2 - playingAreaCardWidth) / (cardCount - 1)) * index * 100) / playingAreaCardWidth
  );
};
export const playingAreaCardTranslateY = (50 * 100) / playingAreaCardHeight - 50;

/**
 * Card catalog
 */
export const catalogButtonHeight = 3;

export const catalogButtonTop = gameDisplayTop / 2;
export const catalogCardHeight = 74;

export const catalogCardWidth = (catalogCardHeight * cardRatio) / screenRatio;
export const catalogTop = (ignoreTopComponentArea: boolean, hasTopComponents?: boolean) =>
  50 -
  catalogCardHeight / 2 +
  (!ignoreTopComponentArea && hasTopComponents ? catalogButtonTop + catalogButtonHeight + 1 : gameDisplayTop / 2);

export const catalogSwipeAreaRatio = 1472 / 660;
export const catalogSwipeAreaWidth = 95;
export const catalogSwipeAreaHeight = (catalogSwipeAreaWidth / catalogSwipeAreaRatio) * screenRatio;
export const catalogWithoutScrollSwipeAreaRatio = 1550 / 670;
export const catalogScrollWidth = 1;
/**
 * Player board
 */

export const playerBoardRatio = 800 / 559;
export const playerBoardHeight = globalCardHeight * 1.02;
export const playerBoardWidth = (playerBoardHeight * playerBoardRatio) / screenRatio;

export const playerBoardCardHeight = (globalCardHeight * 100) / playerBoardHeight;
export const playerBoardCardWidth = (playerBoardCardHeight * cardRatio) / playerBoardRatio;
export const playerBoardLeft = SecondViewTranslate + (100 - playerPanelsWidth - playerBoardWidth) / 2;
export const playerBoardTop = playingAreaHeight + 2;

export const playerBoardDeckStackTranslateX = -92.5;
export const playerBoardDeckStackTranslateY = 100 / playerBoardCardHeight;

export const playerBoardDiscardStackTranslateX = (96.5 * 100) / playerBoardCardWidth;
export const playerBoardDiscardStackTranslateY = 100 / playerBoardCardHeight;

export const playerBoardDigestStackTranslateX = (50.1 * 100) / playerBoardCardWidth - 50;
export const playerBoardDigestStackTranslateY = (81.4 * 100) / playerBoardCardHeight;

export const playerBoardTokenHeight = 22;
export const playerBoardTokenWidth = playerBoardTokenHeight / playerBoardRatio;

export const playerBoardTokenTranslateX = (index: number) =>
  ((index % 2 === 0 ? 73.25 : 56.7) * 100) / playerBoardTokenWidth;
export const playerBoardTokenTranslateY = (index: number) =>
  ((index % 2 === 0 ? 5.8 + Math.floor(index / 2) * 29.7 : 20.8 + Math.floor(index / 2) * 29.7) * 100) /
  playerBoardTokenHeight;

/**
 * Player Hand
 */
export const playerHandCardHeight = globalCardHeight;
export const playerHandCardWidth = (playerHandCardHeight * cardRatio) / screenRatio;

export const playerHandLeft = SecondViewTranslate + (100 - playerPanelsWidth) / 2 - playerHandCardWidth / 2;
export const playerHandTop = 66;

export const tavernToMainViewHeight = 70;
export const tavernToMainViewScale = tavernToMainViewHeight / ((gameBoardCardHeight / 100) * gameBoardHeight);

export const tavernTranslateX = (index: number) => 64 + index;
export const tavernTranslateY = (index: number) => 115 - index;

export const zoomedTavernCardTranslateX = (index: number) =>
  (tavernTranslateX(0) - (tavernToMainViewScale - 1) * 50) / tavernToMainViewScale +
  (index * 1.3) / tavernToMainViewScale;
export const zoomedTavernCardTranslateY = (index: number) =>
  (tavernTranslateY(0) - (tavernToMainViewScale - 1) * 50) / tavernToMainViewScale -
  (index * 1.3) / tavernToMainViewScale;

export const deckToTavernTranslateX = (index: number) =>
  ((-huntDeckLeft + gameBoardLeft + (((tavernTranslateX(index) / 100) * gameBoardCardWidth) / 100) * gameBoardWidth) *
    100) /
  huntDeckWidth;
export const deckToTavernTranslateY = (index: number, playerCount: number) =>
  ((-huntDeckTop(playerCount) + (((tavernTranslateY(index) / 100) * gameBoardCardHeight) / 100) * gameBoardHeight) *
    100) /
  huntDeckHeight;

export const deckToPlayerTranslateX = ((-huntDeckLeft + getPlayerLeftRelativeToLetterBox()) * 100) / huntDeckWidth - 9;
export const deckToPlayerTranslateY = (playerIndex: number, playerCount: number) =>
  ((-huntDeckTop(playerCount) - gameDisplayTop + getPlayerTopRelativeToLetterBox(playerIndex, playerCount)) * 100) /
    huntDeckHeight -
  20;

export const tavernToMainViewGap = 3;
export const tavernToMainViewTranslateX = (index: number, cardCount: number) => {
  const cardWidthRelativeToLetterBox = ((gameBoardCardWidth * tavernToMainViewScale) / 100) * gameBoardWidth;
  const leftRelativeToLetterBox = gameBoardLeft;
  const remainingWidth = 100 - cardWidthRelativeToLetterBox * cardCount - tavernToMainViewGap * (cardCount - 1);
  const finalX = remainingWidth / 2 + index * cardWidthRelativeToLetterBox + index * tavernToMainViewGap;

  return (
    ((((-leftRelativeToLetterBox + finalX) * 100) / (gameBoardCardWidth * tavernToMainViewScale)) * 100) /
    gameBoardWidth
  );
};
export const tavernToMainViewTranslateY =
  (((((90 - ((gameBoardCardHeight * tavernToMainViewScale) / 100) * gameBoardHeight) / 2) * 100) /
    gameBoardCardHeight) *
    100) /
    gameBoardHeight -
  50;

export const tavernToPlayerTranslateX =
  ((((getPlayerLeftRelativeToLetterBox() - gameBoardLeft) * 100) / (gameBoardCardWidth * tavernToMainViewScale)) *
    100) /
    gameBoardWidth -
  50;
export const tavernToPlayerTranslateY = (playerIndex: number, playerCount: number) => {
  return (
    ((((getPlayerTopRelativeToLetterBox(playerIndex, playerCount) - gameDisplayTop) * 100) /
      (gameBoardCardHeight * tavernToMainViewScale)) *
      100) /
      gameBoardHeight -
    50
  );
};

/**
 * Animations
 */

export const getHandCardToPlayingAreaCardTranslateX = (
  index: number,
  playingAreaSize: number,
  newPlayingAreaSize: number
) => {
  const cardTranslate =
    (playingAreaCardTranslateX(newPlayingAreaSize, playingAreaSize + index) / 100) * playingAreaCardWidth;
  const translateXRelativeToLetterBox = -playerHandLeft + playingAreaLeft + (cardTranslate / 100) * playingAreaWidth;
  return (translateXRelativeToLetterBox * 100) / playerHandCardWidth;
};
export const handCardToPlayingAreaCardScale = globalCardHeight / playerHandCardHeight;
export const handCardToPlayingAreaCardTranslateY =
  ((-playerHandTop + (playingAreaCardTranslateY / 100) * globalCardHeight) * 100) / playerHandCardHeight;

/**
 * Animations
 */

/**
 * Deck card from deck
 */
export const getDeckCardFromDeckTranslateX = (index: number, deckStackSize: number, deckLimit: number) => {
  const cardTranslate =
    ((playerBoardDeckStackTranslateX + getDeckCardTranslateX(deckStackSize + index, deckLimit)) / 100) *
    playerBoardCardWidth;
  const translateXRelativeToLetterBox = -playerHandLeft + playerBoardLeft + (cardTranslate / 100) * playerBoardWidth;
  return (translateXRelativeToLetterBox * 100) / playerHandCardWidth;
};
export const getDeckCardFromDeckTranslateY = (index: number, deckStackSize: number, deckLimit: number) => {
  const cardTranslate =
    ((playerBoardDeckStackTranslateY + getDeckCardTranslateY(deckStackSize + index, deckLimit)) / 100) *
    playerBoardCardHeight;
  const translateYRelativeToLetterBox = -playerHandTop + playerBoardTop + (cardTranslate / 100) * playerBoardHeight;
  return (translateYRelativeToLetterBox * 100) / playerHandCardHeight;
};

export const getDeckCardTranslateX = (index: number, limit: number) => (index >= limit ? -1 * (limit - 1) : -1 * index);
export const getDeckCardTranslateY = (index: number, limit: number) =>
  cardRatio * -(index >= limit ? limit - 1 : index);

/**
 * Discard card from hand
 */
export const getDiscardCardFromDiscardTranslateX = (index: number, discardStackSize: number, discardLimit: number) => {
  const cardTranslate =
    ((playerBoardDiscardStackTranslateX + getDiscardCardTranslateX(discardStackSize + index, discardLimit)) / 100) *
    playerBoardCardWidth;
  const translateXRelativeToLetterBox = -playerHandLeft + playerBoardLeft + (cardTranslate / 100) * playerBoardWidth;
  return (translateXRelativeToLetterBox * 100) / playerHandCardWidth;
};
export const getDiscardCardFromDiscardTranslateY = (index: number, discardStackSize: number, discardLimit: number) => {
  const cardTranslate =
    ((playerBoardDiscardStackTranslateY + getDiscardCardTranslateY(discardStackSize + index, discardLimit)) / 100) *
    playerBoardCardHeight;
  const translateYRelativeToLetterBox = -playerHandTop + playerBoardTop + (cardTranslate / 100) * playerBoardHeight;
  return (translateYRelativeToLetterBox * 100) / playerHandCardHeight;
};

/**
 * discard cards from playing area
 */
export const getDiscardCardFromPlayingAreaTranslateX = (
  index: number,
  discardStackSize: number,
  discardLimit: number
) => {
  const discardCardTranslate =
    ((playerBoardDiscardStackTranslateX + getDiscardCardTranslateX(discardStackSize + index, discardLimit)) / 100) *
    playerBoardCardWidth;
  const translateXRelativeToLetterBox =
    -playingAreaLeft + playerBoardLeft + (discardCardTranslate / 100) * playerBoardWidth;
  return (((translateXRelativeToLetterBox * 100) / playingAreaCardWidth) * 100) / playingAreaWidth;
};
export const getDiscardCardFromPlayingAreaTranslateY = (
  index: number,
  discardStackSize: number,
  discardLimit: number
) => {
  const discardCardTranslate =
    ((playerBoardDiscardStackTranslateY + getDiscardCardTranslateY(discardStackSize + index, discardLimit)) / 100) *
    playerBoardCardHeight;
  const translateYRelativeToLetterBox = playerBoardTop + (discardCardTranslate / 100) * playerBoardHeight;
  return (((translateYRelativeToLetterBox * 100) / playingAreaCardHeight) * 100) / playingAreaHeight;
};

export const digestCardFromPlayingAreaTranslateY =
  ((((playerBoardTop + (((playerBoardDigestStackTranslateY / 100) * playerBoardCardHeight) / 100) * playerBoardHeight) *
    100) /
    playingAreaCardHeight) *
    100) /
  playingAreaHeight;
export const digestCardFromPlayingAreaTranslateX =
  ((((-playingAreaLeft +
    playerBoardLeft +
    (((playerBoardDigestStackTranslateX / 100) * playerBoardCardWidth) / 100) * playerBoardWidth) *
    100) /
    playingAreaCardWidth) *
    100) /
  playingAreaWidth;

export const digestCardFromDiscardTranslateX = playerBoardDigestStackTranslateX;
export const digestCardFromDiscardTranslateY = playerBoardDigestStackTranslateY;

export const getDiscardCardTranslateX = (index: number, limit: number) =>
  index >= limit ? 1 * (limit - 1) : 1 * index;
export const getDiscardCardTranslateY = (index: number, limit: number) =>
  cardRatio * -(index >= limit ? limit - 1 : index);

/**
 * Player missions
 */
export const playerMissionWidth = 10;
export const playerMissionHeight = playerMissionWidth * missionRatio * screenRatio;

export const missionDescriptionRatio = 100 / 75;
export const missionDescriptionHeight = 75;
export const missionDescriptionWidth = missionDescriptionHeight * missionDescriptionRatio;

export const playerMissionTranslateX = (index: number) =>
  (playingAreaLeft * 100) / playerMissionWidth + (index >= 8 ? ((index - 8) % 2) * 105 + 600 : (index % 2) * 105);
export const playerMissionTranslateY = (index: number) => 260 + Math.floor((index >= 8 ? index - 8 : index) / 2) * 115;

/**
 * Mission choice
 */
//export const missionRatio = 118 / 180;
export const missionChoiceGridGap = 1;
export const missionChoiceMissionHeight = 20;
export const missionChoiceMissionWidth = missionChoiceMissionHeight / missionRatio / screenRatio;

export const getColumnCount = (missionCount: number) => Math.ceil(Math.sqrt(missionCount));
export const getRowCount = (missionCount: number) => Math.ceil(missionCount / Math.ceil(Math.sqrt(missionCount)));

export const missionCatalogButtonHeight = 3;
export const missionCatalogButtonTop = gameDisplayTop / 2;

export const missionCatalogGridHeight = (missionCount: number) =>
  missionChoiceMissionHeight * getRowCount(missionCount) + missionChoiceGridGap * (getRowCount(missionCount) - 1);
export const missionCatalogGridWidth = (missionCount: number) =>
  missionChoiceMissionWidth * getColumnCount(missionCount) + missionChoiceGridGap * (getColumnCount(missionCount) - 1);
export const missionCatalogGridLeft = (missionCount: number) => 50 - missionCatalogGridWidth(missionCount) / 2;
export const missionCatalogGridTop = (missionCount: number, portal: boolean) =>
  50 - missionCatalogGridHeight(missionCount) / 2 + gameDisplayTop / 2 - (!portal ? gameDisplayTop : 0);

export const realMissionCatalogMissionWidth = (missionCount: number) =>
  (missionChoiceMissionWidth * 100) / missionCatalogGridWidth(missionCount);
export const realMissionCatalogMissionHeight = (missionCount: number) =>
  (missionChoiceMissionHeight * 100) / missionCatalogGridHeight(missionCount);

export const missionCatalogMissionTranslateX = (missionCount: number, missionIndex: number) =>
  (missionIndex % getColumnCount(missionCount)) * (100 + (missionChoiceGridGap * 100) / missionChoiceMissionWidth);
export const missionCatalogMissionTranslateY = (missionCount: number, missionIndex: number) =>
  Math.floor(missionIndex / getColumnCount(missionCount)) *
  (100 + (missionChoiceGridGap * 100) / missionChoiceMissionHeight);

/**
 * Card symbols
 */
export const victoryPointSymbolRatio = 39 / 49;
export const victoryPointSymbolWidth = 1.45;
export const victoryPointSymbolHeight = (width?: number, hd?: boolean) =>
  (width || victoryPointSymbolWidth) / (hd ? 1 : victoryPointSymbolRatio);

export const humanTypeSymbolRatio = 1;
export const humanTypeSymbolWidth = 1.305;
export const humanTypeSymbolHeight = (width?: number) => (width || humanTypeSymbolWidth) / humanTypeSymbolRatio;

export const speedSymbolRatio = 1;
export const speedSymbolWidth = 1.305;
export const speedSymbolHeight = (width?: number) => (width || speedSymbolWidth) / speedSymbolRatio;

export const regionSymbolRatio = 60 / 49;
export const regionSymbolWidth = 1.885;
export const regionSymbolHeight = (width?: number) => (width || regionSymbolWidth) / regionSymbolRatio;

export const regionSymbolHDRatio = 101 / 79;
export const regionSymbolHDWidth = 1.885;
export const regionSymbolHDHeight = (width?: number) => (width || regionSymbolHDWidth) / regionSymbolHDRatio;

export const wolfSymbolRatio = 38 / 45;
export const wolfSymbolWidth = 1.16;
export const wolfSymbolHeight = (width?: number) => (width || wolfSymbolWidth) / wolfSymbolRatio;

export const confuseSymbolRatio = 87 / 100;
export const confuseSymbolWidth = 1.35;
export const confuseSymbolHeight = (width?: number) => (width || confuseSymbolWidth) / confuseSymbolRatio;

export const familiarSymbolRatio = 45 / 45;
export const familiarSymbolWidth = 1.5;
export const familiarSymbolHeight = (width?: number) => (width || familiarSymbolWidth) / familiarSymbolRatio;

export const powerSymbolRatio = 45 / 45;
export const powerSymbolWidth = 1.45;
export const powerSymbolHeight = (width?: number) => (width || powerSymbolWidth) / powerSymbolRatio;

export const holyWaterSymbolRatio = 59 / 100;
export const holyWaterSymbolWidth = 1.1;
export const holyWaterSymbolHeight = (width?: number) => (width || holyWaterSymbolWidth) / holyWaterSymbolRatio;

export const spicySymbolRatio = 100 / 85;
export const spicySymbolWidth = 1.35;
export const spicySymbolHeight = (width?: number) => (width || spicySymbolWidth) / spicySymbolRatio;

/**
 * Rose from board
 */

export const cardFromBoardFocus = 4;
export const cardFromBoardFocusPadding = 0.1;
export const focusedRoseFromBoardTranslateX = (cardIndex: number, cardCount: number) => {
  const globalCardWidth = ((gameBoardCardWidth * cardFromBoardFocus) / 100) * gameBoardWidth;
  const baseLeft =
    (100 - (globalCardWidth * cardCount + globalCardWidth * cardFromBoardFocusPadding * (cardCount - 1))) / 2;

  return (
    ((((baseLeft +
      cardIndex * globalCardWidth +
      (cardIndex ? cardIndex * globalCardWidth * cardFromBoardFocusPadding : 0)) *
      100) /
      (gameBoardCardWidth * cardFromBoardFocus)) *
      100) /
    gameBoardWidth
  );
};
export const focusedRoseFromBoardTranslateY =
  (((45 * 100) / (gameBoardCardHeight * cardFromBoardFocus)) * 100) / gameBoardHeight - 50;

/**
 * Fill hunt track card translate
 */
export const getFillHuntTrackCardTargetTranslateY = (playerCount: number, lineIndex: number): number => {
  const deckTopRelativeToLetterBox = huntDeckTop(playerCount);
  const huntTrackAreaTopRelativeToLetterBox =
    (huntTrackAreaTop(playerCount, lineIndex) / 100) * realHuntTrackHeight(playerCount);
  const huntTrackAreaCardTop =
    ((((((huntTrackCardTranslateY + huntTrackCardFocusTranslate) / 100) * huntTrackCardHeight) / 100) *
      realHuntTrackAreaHeight(playerCount, 0)) /
      100) *
    realHuntTrackHeight(playerCount);
  return (
    ((-deckTopRelativeToLetterBox + huntTrackAreaTopRelativeToLetterBox + huntTrackAreaCardTop) * 100) / huntDeckHeight
  );
};

/**
 * Fill hunt track card translate
 */
export const getFillHuntTrackCardTargetTranslateX = (areaIndex: number): number => {
  const deckLeftRelativeToLetterBox = huntDeckLeft;
  const huntTrackAreaLeftRelativeToLetterBox =
    huntTrackLeft +
    ((huntTrackAreaLeft(areaIndex) +
      (((huntTrackCardBaseX / 100) * huntTrackCardWidth(areaIndex)) / 100) * huntTrackAreaWidth(areaIndex)) /
      100) *
      huntTrackWidth;
  return ((-deckLeftRelativeToLetterBox + huntTrackAreaLeftRelativeToLetterBox) * 100) / huntDeckWidth;
};

/**
 * Fill hunt track card scale
 */
export const getFillHuntTrackCardTargetScale = (playerCount: number) => {
  const huntTrackCardHeightRelativeToLetterBox =
    (((huntTrackCardHeight / 100) * realHuntTrackAreaHeight(playerCount, 0)) / 100) * realHuntTrackHeight(playerCount);
  return (huntTrackCardHeightRelativeToLetterBox * huntTrackCardFocus) / huntDeckHeight;
};

/**
 * Bonus token translate
 */
export const getTokenTranslate = (position: number) =>
  `translateZ(0) translate(${(RookieBoxPosition[position].left * 100) / boxWidth}%, ${
    (RookieBoxPosition[position].top * 100) / boxHeight
  }%)`;

export const getAcquireBonusTokenTranslateX =
  ((((-gameBoardLeft + getPlayerLeftRelativeToLetterBox()) * 100) / boxWidth) * 100) / gameBoardWidth + 50;
export const getAcquireBonusTokenTranslateY = (playerIndex: number, playerCount: number) =>
  ((((-gameDisplayTop + getPlayerTopRelativeToLetterBox(playerIndex, playerCount)) * 100) / boxHeight) * 100) /
    gameBoardHeight +
  50;

export const popupBackgroundStyle = css`
  position: fixed;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const cardViewerBackgroundStyle = css`
  position: fixed;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 96;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;
