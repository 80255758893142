export enum EffectType {
  Discard = 1,
  Draw,
  Rose,
  VictoryPoint,
  HuntTrack,
  Confuse,
  Digest,
  Fast,
  Gregarious,
  HolyWater,
  Inspiring,
  Spicy,
  Region,
  Wolf,
  Teleport,
  Ignore,
  AdditionalHunt,
  Hunger,
  SpeedBonus,
  FreeHunt,
  PointPerTrait,
  PointPerRoseEffect,
  PointPerHunts,
  Ready,
  PushVampire,
  WinHuntTrackCard,
  WinBonusToken,
  SkipTurn
}
