import { BoardBoxEffect } from '../board';
import { TokenSide } from './TokenSide';
import Vampire from './Vampire';
import { MyPlayerView } from './MyPlayerView';
import { PlayerView } from './PlayerView';
import { Position } from './Position';
import { GameEffects } from '../effect/GameEffect';
import { PlayerHunt } from './PlayerHunt';
import { CardType } from '../card/CardType';
import { PlayerMission } from './PlayerMission';

export type Player = {
  vampire: Vampire;
  position: Position;
  tokenSide: TokenSide;
  uniques: CardType[];
  discard: number[];
  deck: number[];
  digest: number[];
  hand: number[];
  playingArea: number[];
  playedCards: number[];
  hunts: Array<PlayerHunt>;
  drawCount: number;
  missions: PlayerMission[];
  playedMissions: number[];
  missionChoice: {
    missions: number[];
    stack?: number;
  };
  bonusTokens: number[];
  usedBonuses: number[];
  score: number;
  remainingSpeed?: number;
  additionalHuntSpeed?: number;
  boardEffect?: BoardBoxEffect;
  pendingEffects: GameEffects[];
  hasMoved?: boolean;
  locked?: boolean;
  end?: boolean;
};

export type PlayerType = Player | MyPlayerView | PlayerView;
export const isPlayer = (player: PlayerType): player is Player => {
  return typeof player.deck !== 'number';
};

export const isMyPlayerHand = (hand: number[] | number): hand is number[] => typeof hand !== 'number';
