/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef, useCallback } from 'react';
import { Draggable } from '@gamepark/react-components';
import { DraggableProps } from '@gamepark/react-components/dist/Draggable/Draggable';
import { DraggableTypes } from './draggable/DraggableTypes';
import { DragLayerMonitor } from 'react-dnd';

type TokenProps = {
  front?: any;
  frontColor?: any;
  back?: any;
  backColor?: any;
  preTransform?: string;
  postTransform?: string;
  semiTransparent?: boolean,
  overlayText?: string,
  overlayColor?: string,
  type?: DraggableTypes;
  item?: any
  drop?: (result?: any) => void
  zoom?: number,
} & Omit<DraggableProps, 'type'>;

const Token = forwardRef<HTMLDivElement, TokenProps>(({
                                                        front,
                                                        frontColor,
                                                        back,
                                                        backColor,
                                                        preTransform,
                                                        postTransform,
                                                        semiTransparent,
                                                        canDrag,
                                                        type,
                                                        item,
                                                        drop,
                                                        end,
                                                        overlayText,
                                                        overlayColor,
                                                        zoom = 1,
                                                        ...props
                                                      }, ref) => {

  const projection = useCallback((monitor: DragLayerMonitor) => {
    const offset = monitor.getDifferenceFromInitialOffset();
    if (!offset) return offset;
    return { x: offset.x / zoom, y: offset.y / zoom };
  }, [zoom]);

  if (!type) {
    return (
      <div
        ref={ ref }
        css={ [tokenStyle(back, backColor, semiTransparent), tokenStyleTranslate(preTransform, postTransform), !front && hidden(preTransform, postTransform)] }
        { ...props }
      >
        { !!overlayColor && <div css={ overlayColorStyle(overlayColor) } />}
        { !!overlayText?.length && <div css={ overlayTextStyle }>{ overlayText }</div>}
        <div css={[frontFace, getBackgroundImage(front, frontColor), semiTransparent && opacity]} />
      </div>
    );
  }

  const stopPropagation = (e: any) => e?.stopPropagation? e.stopPropagation(): undefined;

  return (
    <Draggable
      css={[
        tokenStyle(back, backColor, semiTransparent)
      ]}
      projection={ projection }
      type={ type }
      item={ item }
      preTransform={ preTransform }
      draggable={ !!canDrag }
      canDrag={ canDrag }
      postTransform={ postTransform || !front? `${postTransform || ''} ${!front? 'rotateY(180deg)': ''}`: undefined }
      drop={ drop }
      end={ end }
      onMouseDown={ stopPropagation }
      onTouchStart={ stopPropagation }
      { ...props }
    >
      { !!overlayColor && <div css={ overlayColorStyle(overlayColor) } />}
      { !!overlayText?.length && <div css={ overlayTextStyle }>{ overlayText }</div>}
      <div css={[frontFace, getBackgroundImage(front, frontColor), semiTransparent && opacity]} />
    </Draggable>
  )
});

const tokenStyleTranslate = (preTransform?: string, postTransform?: string) => css`
  transform: ${ preTransform } translateZ(0) ${ postTransform };
`;

const tokenStyle = (back?: any, backColor?: string, semiTransparent?: boolean) => css`
  box-shadow: 0 0 0.2em black;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 50%;
  transform-style: preserve-3d;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${ back });
    background-color: ${ backColor };
    background-size: 100% 100%;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    border-radius: 50%;
    border: 0.01em solid white;
    image-rendering: -webkit-optimize-contrast;
    opacity: ${ semiTransparent ? 0.5 : 1 };
  }
`;

const frontFace = css`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-size: 100% 100%;
  border-radius: 50%;
  border: 0.01em solid white;
`;

const getBackgroundImage = (front?: string, frontColor?: string) => css`
  image-rendering: -webkit-optimize-contrast;
  background-image: url(${ front });
  background-color: ${ frontColor };
`;

const hidden = (preTransform?: string, postTransform?: string) => css`
  transform: ${ preTransform || '' } rotateY(180deg) ${ postTransform || '' };
`;

const opacity = css`
  opacity: 0.5;
`;

const overlayTextStyle = css`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8em;
  z-index: 1;
  opacity: 0.8;
  pointer-events: none;
`

const overlayColorStyle = (color: string) => css`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8em;
  z-index: 1;
  opacity: 0.4;
  pointer-events: none;
  border-radius: 50%;
  background-color: ${color};
`

export {
  Token
};
