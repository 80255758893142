import {
  AHankering,
  AnimalLover,
  ASoldiersLife,
  ASoldiersLife5P,
  BeastMaster,
  CatchUp,
  CommonTaste,
  CommonTaste5P,
  DangerousDiet,
  Digestion,
  EarlyNight,
  EatThemAll,
  EatThemAll5P,
  FamilyBusiness,
  Gluttony,
  Gluttony5P,
  Gourmet,
  HauteCuisine,
  HauteCuisine5P,
  HolierThanThou,
  HolierThanThou5p,
  Hungry,
  Landlord,
  Meh,
  Meh5P,
  Mission,
  Missionary,
  MyBodyIsATemple,
  OnADiet,
  Picky,
  Picky5P,
  Revolutionary,
  RichGetRicher,
  Romantic,
  Royal,
  Royal5P,
  Selective,
  Strategist,
  TheCollector,
  TheDevout,
  TheHost,
  TheOpportunist,
  ThreeStarDinner,
  ThreeStarDinner5P,
  Tipsy,
  TreasureChest,
  VampireOfTheCoast,
  VampireOnTop,
  VariedDiet,
  WildVampire,
  Zoologist
} from '@gamepark/the-hunger/mission';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { HumanTypeSymbol, SpeedSymbol, VictoryPointSymbol } from '../../symbols';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { ConfuseSymbol } from '../../symbols/ConfuseSymbol';
import { FamiliarSymbol } from '../../symbols/FamiliarSymbol';
import { PowerSymbol } from '../../symbols/PowerSymbol';
import { HolyWaterSymbol } from '../../symbols/HolyWaterSymbol';
import { SpicySymbol } from '../../symbols/SpicySymbol';

const MissionVictoryPointWidth = 1.35
const MissionDescription = new Map<Mission, (t: TFunction) => string | any>();

MissionDescription.set(AHankering, () => <Trans
  defaults="mission.desc.hankering"
  components={ [
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>
  ] }
/>);
MissionDescription.set(ASoldiersLife, () => <Trans
  defaults="mission.desc.soldierlife"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanMilitary} />
  ]}
/>);
MissionDescription.set(ASoldiersLife5P, () => <Trans
  defaults="mission.desc.soldierlife"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanMilitary} />
  ]}
/>);
MissionDescription.set(AnimalLover, () => <Trans
  defaults="mission.desc.animallover"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <FamiliarSymbol />
  ]}
/>);
MissionDescription.set(BeastMaster, () => <Trans
  defaults="mission.desc.beastmaster"
  components={[
    <FamiliarSymbol />
  ]}
/>);
MissionDescription.set(CatchUp, () => <Trans
  defaults="mission.desc.catchup"
  components={[
    <VictoryPointSymbol value="10" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(CommonTaste, () => <Trans
  defaults="mission.desc.commontaste"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanVillager} />
  ]}
/>);
MissionDescription.set(CommonTaste5P, () => <Trans
  defaults="mission.desc.commontaste"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanVillager} />
  ]}
/>);
MissionDescription.set(DangerousDiet, () => <Trans
  defaults="mission.desc.dangerousdiet"
  components={[
    <VictoryPointSymbol value="2" width={MissionVictoryPointWidth}/>,
    <ConfuseSymbol />,
    <SpicySymbol />,
    <HolyWaterSymbol />
  ]}
/>);
MissionDescription.set(Digestion, (t: TFunction) => t('mission.desc.digestion'));
MissionDescription.set(EarlyNight, () => <Trans
  defaults="mission.desc.earlynight"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(EatThemAll, () => <Trans
  defaults="mission.desc.eatthemall"
  components={[
    <VictoryPointSymbol value="5" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(EatThemAll5P, () => <Trans
  defaults="mission.desc.eatthemall"
  components={[
    <VictoryPointSymbol value="5" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(FamilyBusiness, () => <Trans
  defaults="mission.desc.familybusiness"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Gluttony, () => <Trans
  defaults="mission.desc.gluttony"
  components={[
    <VictoryPointSymbol value="5" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Gluttony5P, () => <Trans
  defaults="mission.desc.gluttony"
  components={[
    <VictoryPointSymbol value="5" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Gourmet, () => <Trans
  defaults="mission.desc.gourmet"
  components={[
    <VictoryPointSymbol value="2" width={MissionVictoryPointWidth}/>
  ]}
/>);

MissionDescription.set(HauteCuisine, () => <Trans
  defaults="mission.desc.hautecuisine"
  components={ [
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="4+" width={MissionVictoryPointWidth}/>
  ] }
/>);
MissionDescription.set(HauteCuisine5P, () => <Trans
  defaults="mission.desc.hautecuisine"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="4+" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(HolierThanThou, () => <Trans
  defaults="mission.desc.holierthanthou"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanReligious} />
  ]}
/>);
MissionDescription.set(HolierThanThou5p, () => <Trans
  defaults="mission.desc.holierthanthou"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanReligious} />
  ]}
/>);
MissionDescription.set(Hungry, () => <Trans
  defaults="mission.desc.hungry"
  components={[
    <SpeedSymbol value="3" width={1.45} />
  ]}
/>);
MissionDescription.set(Landlord, () => <Trans
  defaults="mission.desc.landlord"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanVillager} />
  ]}
/>);
MissionDescription.set(Meh, () => <Trans
  defaults="mission.desc.meh"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="2" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Meh5P, () => <Trans
  defaults="mission.desc.meh"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="2" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Missionary, () => <Trans
  defaults="mission.desc.missionary"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(MyBodyIsATemple, () => <Trans
  defaults="mission.desc.mybodyisatemple"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <PowerSymbol />
  ]}
/>);
MissionDescription.set(OnADiet, () => <Trans
  defaults="mission.desc.onadiet"
  components={[
    <VictoryPointSymbol value="5" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Picky, () => <Trans
  defaults="mission.desc.picky"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="5+" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Picky5P, () => <Trans
  defaults="mission.desc.picky"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="5+" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Revolutionary, () => <Trans
  defaults="mission.desc.revolutionary"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanNoble} />
  ]}
/>);
MissionDescription.set(RichGetRicher, () => <Trans
  defaults="mission.desc.richgetricher"
  components={[
    <VictoryPointSymbol value="4" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Romantic, () => <Trans
  defaults="mission.desc.romantic"
  components={[
    <VictoryPointSymbol value="5" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Royal, () => <Trans
  defaults="mission.desc.royal"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanNoble} />
  ]}
/>);
MissionDescription.set(Royal5P, () => <Trans
  defaults="mission.desc.royal"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanNoble} />
  ]}
/>);
MissionDescription.set(Selective, () => <Trans
  defaults="mission.desc.selective"
  components={[
    <VictoryPointSymbol value="2" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Strategist, () => <Trans
  defaults="mission.desc.strategist"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanMilitary} />
  ]}
/>);
MissionDescription.set(TheCollector, () => <Trans
  defaults="mission.desc.thecollector"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(TheDevout, () => <Trans
  defaults="mission.desc.thedevout"
  components={[
    <VictoryPointSymbol value="6" width={MissionVictoryPointWidth}/>,
    <HumanTypeSymbol hd={ true } width={1.45} category={CardType.HumanReligious} />
  ]}
/>);
MissionDescription.set(TheHost, () => <Trans
  defaults="mission.desc.thehost"
  components={[
    <VictoryPointSymbol value="2" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="+2" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(TheOpportunist, () => <Trans
  defaults="mission.desc.theopportunist"
  components={[
    <SpeedSymbol value="1" width={1.45} />
  ]}
/>);
MissionDescription.set(ThreeStarDinner, () => <Trans
  defaults="mission.desc.threestardinner"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="3" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(ThreeStarDinner5P, () => <Trans
  defaults="mission.desc.threestardinner"
  components={[
    <VictoryPointSymbol value="1" width={MissionVictoryPointWidth}/>,
    <VictoryPointSymbol value="3" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(Tipsy, () => <Trans
  defaults="mission.desc.tipsy"
  components={[
    <VictoryPointSymbol value="4" width={MissionVictoryPointWidth}/>,
    <ConfuseSymbol />
  ]}
/>);
MissionDescription.set(TreasureChest, (t: TFunction) => t('mission.desc.treasurechest'));
MissionDescription.set(VampireOfTheCoast, (t: TFunction) => t('mission.desc.vampireofthecoast'));
MissionDescription.set(VampireOnTop, (t: TFunction) => t('mission.desc.vampireontop'));
MissionDescription.set(VariedDiet, () => <Trans
  defaults="mission.desc.varieddiet"
  components={[
    <VictoryPointSymbol value="3" width={MissionVictoryPointWidth}/>
  ]}
/>);
MissionDescription.set(WildVampire, (t: TFunction) => t('mission.desc.wildvampire'));
MissionDescription.set(Zoologist, () => <Trans
  defaults="mission.desc.zoologist"
  components={[
    <VictoryPointSymbol value="4" width={MissionVictoryPointWidth}/>,
    <FamiliarSymbol />
  ]}
/>);

export {
  MissionDescription
};
