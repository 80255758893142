export enum CardTrait {
  Ready = 1,
  Permanent,
  Unique,
  Slow,
  Wolf,
  Dog,
  Cat,
  Aries,
  Pig,
  Bear,
  Rat,
  Dove,
  Owl,
  Snake
}
