/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CSSProperties, FC, useState } from 'react';
import { createPortal } from 'react-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import { cardViewerBackgroundStyle, gameDisplayTop } from '../../../utils/Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { PlayerType } from '@gamepark/the-hunger/player';
import { HuntCardsCatalog } from '../../card/HuntCardsCatalog';
import { CardsViewerCard } from './CardsViewerCard';

export type CardViewerProps = {
  cards: number[];
  player: PlayerType;
  onClose: () => void;
  oldTokensCount?: boolean;
};

const CardsViewer: FC<CardViewerProps> = ({ cards, player, onClose, oldTokensCount }) => {
  const { t } = useTranslation();
  const [focusedCard, setFocusedCard] = useState<number | undefined>();


  const catalog = (
    <>
      <div
        key='overlay-viewer'
        css={ [
          cardViewerBackgroundStyle,
          css`
            background: rgba(0, 0, 0, 0.8);
          `
        ] }
        onClick={ onClose }
      />
      <button key='close-viewer' css={ button } onClick={ onClose }>
        <FontAwesomeIcon icon={ faTimes } />
        { t('button.close.missions') }
      </button>
      <Scrollbars
        key='catalog-viewer'
        hideTracksWhenNotNeeded
        renderTrackVertical={ ({ style, ...props }) => <div { ...props } style={ { ...style, ...scrollTrackVertical } } /> }
        renderThumbVertical={ ({ style, ...props }) => <div { ...props } style={ { ...style, ...scrollThumbVertical } } /> }
        style={ catalogViewerStyle }
        onClick={ onClose }
      >
        { cards.map((c) => (
          <CardsViewerCard
            key={c}
            hunt={ c }
            player={ player }
            oldTokensCount={oldTokensCount}
            onCardLongPress={ () => setFocusedCard(c) } />
        )) }
      </Scrollbars>
      { focusedCard && (
        <HuntCardsCatalog hunts={ [focusedCard] } hideActions onEndPress={() => setFocusedCard(undefined)}/>
      ) }
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(catalog, letterBox);
  }

  return null;
};

const catalogViewerStyle: CSSProperties = {
  position: 'absolute',
  top: `${ gameDisplayTop }%`,
  zIndex: 97,
  height: '90%',
  fontSize: `${ 90 / 100 }em`,
  cursor: 'pointer'
};

const scrollTrackVertical: CSSProperties = {
  width: '2em',
  top: 0,
  bottom: 0,
  right: '1%',
  borderRadius: '3em'
};

const scrollThumbVertical: CSSProperties = {
  borderRadius: '3em',
  backgroundColor: 'white'
};

const button = css`
  position: absolute;
  z-index: 98;
  right: 1.5em;
  font-size: 3.2em;
  color: black;
  background: rgba(255, 255, 255);
  border-radius: 1em;
  padding: 0.2em 0.4em;
  top: ${ gameDisplayTop }%;
  cursor: pointer;

  &:hover {
    background: rgb(197, 197, 197);
  }

  & svg {
    margin-right: 0.3em;
  }
`;

export { CardsViewer };
