import MoveType from './MoveType';
import GameView from '../GameView';
import GameState from '../GameState';
import { BoardBoxEffect } from '../board';
import { isDigest } from '../utils/EffectUtils';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { isMilitary, isNoble, isReligious, isVillager } from '../card/hunt/Human';
import { Card } from '../card/Card';
import { isOtherPlayerView } from '../player';
import Move from './Move';

export type DigestCards = {
  type: MoveType.DigestCards,
  cards: number[],
  hand?: boolean,
};

export const digestCardsMove = (cards: number[], hand?: boolean): DigestCards => {
  if (hand) {
    return ({
      type: MoveType.DigestCards,
      cards,
      hand
    })
  }

  return ({
    type: MoveType.DigestCards,
    cards
  });
};

export const isDigestCardMove = (move: Move): move is DigestCards => move.type === MoveType.DigestCards;

export const digestCardMove = (card: number): DigestCards => ({
  type: MoveType.DigestCards,
  cards: [card]
});

export const digestCard = (state: GameState | GameView, move: DigestCards, deck: Card[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  player.digest.push(...move.cards);

  if (move.hand) {
    if (isOtherPlayerView(player)) {
      player.hand -= move.cards.length;
    } else {
      player.hand = player.hand.filter(c => !move.cards.includes(c));
    }
  } else {
    player.discard = player.discard.filter(c => !move.cards.includes(c));
    player.playingArea = player.playingArea.filter(c => !move.cards.includes(c));
  }

  const effect = getNextPendingEffect(player);
  if (isDigest(effect)) {
    removeNextPendingEffect(player);
  } else {
    // Board effect must not be applied at the end of the turn (on automatic discard that happened at the end of the turn
    if (player.hasMoved && player.boardEffect) {
      switch (player.boardEffect) {
        case BoardBoxEffect.Market:
          if (move.cards.some(c => isVillager(deck[c]))) {
            delete player.boardEffect;
          }
          break;
        case BoardBoxEffect.Mansion:
          if (move.cards.some(c => isNoble(deck[c]))) {
            delete player.boardEffect
          }
          break;
        case BoardBoxEffect.Barracks:
          if (move.cards.some(c => isMilitary(deck[c]))) {
            delete player.boardEffect
          }
          break;
        case BoardBoxEffect.Church:
          if (move.cards.some(c => isReligious(deck[c]))) {
            delete player.boardEffect
          }
          break;
      }
    }
  }
}
