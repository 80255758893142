import { Action } from '@gamepark/rules-api';
import Move from '../moves/Move';
import MoveView from '../moves/MoveView';
import Vampire from '../player/Vampire';
import GameState from '../GameState';
import GameView from '../GameView';
import { isFillTavern } from '../moves/FillTavern';
import { isFillHuntTrack } from '../moves/FillHuntTrack';
import { isDrawCard } from '../moves/DrawCards';
import { isDrawMission } from '../moves/DrawMissions';
import { isDrawHuntCard } from '../moves/DrawHuntCard';
import { isOver } from './IsOver';
import { Bonus } from '../bonus/Bonus';
import { isHuntInTavern } from '../moves/HuntInTavern';
import { isAcquireBonus } from '../moves/AcquireBonus';
import { VisibleTokenPosition } from './GameConstants';
import { PlayerType } from '../player';
import { isShuffleDiscardToDeck } from '../moves/ShuffleDiscardToDeck';
import { isEndTurn } from '../moves/EndTurn';

type TheHungerAction = Action<Move | MoveView, Vampire>

const canUndoMove = (actionMove: Move | MoveView, consequences: Array<Move | MoveView>, activePlayer: PlayerType) => {
  return ![actionMove, ...consequences].some(move => isFillTavern(move)
    || isFillHuntTrack(move)
    || isDrawCard(move)
    || isDrawMission(move)
    || isDrawHuntCard(move)
    || isHuntInTavern(move)
    || (isAcquireBonus(move) && !VisibleTokenPosition.includes(activePlayer.position.box))
    || isShuffleDiscardToDeck(move)
    || isEndTurn(move));
};

// @ts-ignore
export const canUndo = (state: GameState | GameView, action: TheHungerAction, consecutiveActions: TheHungerAction[], bonuses: Bonus[]): boolean => {
  if (isOver(state.players, state.round, state.turnOrder, bonuses)) {
    return false;
  }

  if (!state.activePlayer) {
    return true;
  }

  if (consecutiveActions.length > 0) {
    return false;
  }

  const activePlayer = state.players.find(p => p.vampire === state.activePlayer)!;
  return action.playerId === state.activePlayer
    && canUndoMove(action.move, action.consequences, activePlayer);
}
