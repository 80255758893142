import MoveType from './MoveType';
import Move from './Move';
import GameState from '../GameState';
import GameView from '../GameView';
import { applyHunting } from '../utils/HuntUtils';
import { GameDeck } from '../card/hunt/GameDeck';
import { BoardBox } from '../board';

export type HuntRose ={
  type: MoveType.HuntRose
  rose: number
};

export const huntRoseMove = (rose: number): HuntRose => ({
  type: MoveType.HuntRose,
  rose
});

export const isHuntRose = (move: Move): move is HuntRose => move.type === MoveType.HuntRose

export const huntRose = (state: GameState | GameView, move: HuntRose, board: BoardBox[]) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  player.hunts.push({
    hunts: [move.rose],
    region: board[player.position.box].region
  });

  applyHunting(state, [move.rose], GameDeck);

  state.roses = state.roses.filter(r => r !== move.rose);
}

