import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { computeAdditionalHuntSpeed, computePassiveEffects, computeSpeed } from '../utils/GameUtils';
import { applyHunting, getAreaCost } from '../utils/HuntUtils';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { EffectTrigger } from '../effect/EffectTrigger';
import { isFreeHunt, isReady, isWinHuntTrackCard } from '../utils/EffectUtils';
import { Card } from '../card/Card';
import { GameEffects } from '../effect/GameEffect';
import { WinHuntTrackCardEffect } from '../effect/WinHuntTrackCardEffect';
import Move from './Move';
import { BoardBox } from '../board';

export type HuntOnTrack = {
  type: MoveType.HuntOnTrack;
  row: number;
  col: number;
  card?: number;
};

export const huntOnTrackMove = (row: number, col: number, card?: number): HuntOnTrack => {
  if (card) {
    return {
      type: MoveType.HuntOnTrack,
      row,
      col,
      card,
    };
  }

  return {
    type: MoveType.HuntOnTrack,
    row,
    col,
  };
};
export const isHuntOnTrack = (move: Move): move is HuntOnTrack => move.type === MoveType.HuntOnTrack;
const isFreeCard = (effect?: GameEffects, card?: number): effect is WinHuntTrackCardEffect =>
  isWinHuntTrackCard(effect) && !!card;

export const huntOnTrack = (state: GameState | GameView, move: HuntOnTrack, deck: Card[], board: BoardBox[]) => {
  const player = state.players.find((p) => p.vampire === state.activePlayer)!;
  const cards: number[] = move.card ? [move.card] : state.hunt.track[move.row][move.col];

  const effect = getNextPendingEffect(player, !move.card);

  let free =
    (!move.card && isFreeHunt(effect) && (!effect.huntColumn || move.col === effect.huntColumn)) ||
    isFreeCard(effect, move.card);

  if (move.card && isWinHuntTrackCard(effect)) {
    free = true;
  }

  player.hunts.push({
    free: free,
    hunts: cards,
    col: move.col,
    region: board[player.position.box].region,
  });

  const cost = free ? 0 : getAreaCost(state.hunt.track[move.row][move.col], move.col, deck);
  let remainingCost = cost;

  if (player.remainingSpeed === undefined) {
    computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.BeforeMoveOrHunt], deck);

    const playerSpeed = computeSpeed(player, deck);
    player.remainingSpeed = Math.max(0, playerSpeed - cost);
    remainingCost -= Math.max(0, playerSpeed - player.remainingSpeed);

    // Removing speed bonus gained by pending effects
    player.pendingEffects = player.pendingEffects.filter((e) => e.trigger !== EffectTrigger.BeforeMoveOrHunt);
  } else {
    const playerSpeed = player.remainingSpeed;
    player.remainingSpeed = Math.max(0, playerSpeed - cost);
    remainingCost -= Math.max(0, playerSpeed - player.remainingSpeed);
  }

  if (player.remainingSpeed < 0) {
    player.remainingSpeed = 0
  }

  if (player.additionalHuntSpeed === undefined) {
    player.additionalHuntSpeed = computeAdditionalHuntSpeed(player, deck) - Math.max(0, remainingCost);
  } else {
    player.additionalHuntSpeed -= Math.max(0, remainingCost);
  }

  if (player.additionalHuntSpeed < 0) {
    player.additionalHuntSpeed = 0
  }

  if (free) {
    removeNextPendingEffect(player, !move.card);
  }

  let toPlayingArea = isFreeCard(effect, move.card) && effect.toPlayingArea;
  applyHunting(state, cards, deck, toPlayingArea);
  state.hunt.track[move.row][move.col] = move.card
    ? state.hunt.track[move.row][move.col].filter((c) => c !== move.card)
    : [];

  if (toPlayingArea) {
    player.playingArea.push(...cards);
  } else {
    player.discard.push(...cards.filter((c) => !isReady(deck[c])));
  }
};
