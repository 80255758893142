/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { Modal } from './Modal';
import { useTranslation } from 'react-i18next';
import { TheHungerButton } from '../utils/TheHungerButton';
import { usePlay } from '@gamepark/react-client';
import { endTurnMove } from '@gamepark/the-hunger/moves/EndTurn';

type SkipTurnConfirmationModalProps = {
  onClose: () => void;
};

const SkipTurnConfirmationModal: FC<SkipTurnConfirmationModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const play = usePlay();

  const confirm = () => {
    play(endTurnMove);
    onClose();
  };

  const actions = (
    <>
      <TheHungerButton css={button} labelKey={'modal.skipturn.cancel'} onClick={onClose} color="red" />
      <TheHungerButton css={button} labelKey={'modal.skipturn.confirm'} onClick={confirm} />
    </>
  );

  return (
    <Modal
      title={t('modal.skipturn.title')}
      description={t('modal.skipturn.description')}
      onClose={onClose}
      actions={actions}
    />
  );
};

const button = css`
  position: relative;
  height: 1.2em;
  margin-left: 0.3em;
  margin-right: 0.1em;
  display: block;
  font-size: 3.5em;
  text-transform: uppercase;
`;

export { SkipTurnConfirmationModal };
