import MoveType from './MoveType';
import GameState from '../GameState';
import { TokenSide } from '../player';
import { NewRoundPhase } from '../round/NewRoundPhase';
import Vampire from '../player/Vampire';
import GameView from '../GameView';
import { recomputePlayerZPosition } from '../utils/GameUtils';
import Move from './Move';
import MoveView from './MoveView';

export type FlipToken = {
  type: MoveType.FlipToken;
  vampires: Vampire[];
  side: TokenSide;
};

export const flipTokenMove = (vampire: Vampire, side: TokenSide): FlipToken => ({
  type: MoveType.FlipToken,
  vampires: [vampire],
  side,
});

export const flipTokensMove = (vampires: Vampire[], side: TokenSide): FlipToken => ({
  type: MoveType.FlipToken,
  vampires,
  side,
});

export const isFlipToken = (move: Move | MoveView): move is FlipToken => move.type === MoveType.FlipToken;

export const flipToken = (state: GameState | GameView, move: FlipToken) => {
  state.players.filter((p) => move.vampires.includes(p.vampire)).map((p) => (p.tokenSide = move.side));

  if (!state.newRoundPhase && move.vampires.length === 1) {
    const [vampire] = move.vampires;
    const movedPlayer = state.players.find((p) => p.vampire === vampire)!;
    if (!movedPlayer.hasMoved) {
      const playerOnTargetBox = state.players
        .filter((p) => p.vampire !== movedPlayer.vampire && p.position.box === movedPlayer.position.box)
        .sort((a, b) => a.position.z - b.position.z);

      // Castle
      if (movedPlayer.position.box !== 0) {
        recomputePlayerZPosition(movedPlayer, playerOnTargetBox);
      }
    }
  }

  if (state.newRoundPhase === NewRoundPhase.FlipVampires) {
    state.newRoundPhase = NewRoundPhase.MoveHuntTrackCards;
  }
};
