import { EffectTrigger } from './EffectTrigger';
import { GameEffect } from './GameEffect';
import { drawMissionMove } from '../moves/DrawMissions';
import Move from '../moves/Move';
import GameState, { isGameState } from '../GameState';
import { EffectType } from './EffectType';
import { StartupBeigeMissionCount } from '../utils/GameConstants';

export class InspiringEffect implements GameEffect {
  type: EffectType.Inspiring = EffectType.Inspiring;
  trigger?: EffectTrigger = EffectTrigger.Hunted;

  legalMoves(state: GameState): Move[] {
    if (isGameState(state)) {
      return Array.from(state.missions.entries()).filter(entry => entry[0] >= StartupBeigeMissionCount).map(entry => drawMissionMove(entry[0]));
    }

    return [];
  }
}
