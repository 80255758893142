/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes, useState } from 'react';
import { isInvisibleMissions } from '@gamepark/the-hunger/GameView';
import { MissionTile } from '../mission/MissionTile';
import { Missions } from '@gamepark/the-hunger/mission';
import {
  playerMissionHeight,
  playerMissionTranslateX,
  playerMissionTranslateY,
  playerMissionWidth,
} from '../../utils/Style';
import { MissionCatalog } from '../mission/MissionCatalog';
import { TheHungerButton } from '../../utils/TheHungerButton';
import { isPlayableMission } from '@gamepark/the-hunger/utils/GameUtils';
import { PlayerType } from '@gamepark/the-hunger/player';
import { BoardBoxes } from '@gamepark/the-hunger/board';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { activateMissionMove, isActivateMission } from '@gamepark/the-hunger/moves/ActivateMission';
import { PlayerMission } from '@gamepark/the-hunger/player/PlayerMission';
import {
  ChooseMission,
  ChooseMissionView,
  isChooseMissionMove,
  isChooseMissionMoveView,
} from '@gamepark/the-hunger/moves/ChooseMission';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import { PositionedBonus } from '@gamepark/the-hunger/bonus/PositionedBonus';

export type PlayerMissionsProps = {
  track: number[][][];
  player: PlayerType;
  bonuses: Array<PositionedBonus | Omit<PositionedBonus, 'bonus'>>;
  activePlayer?: Vampire;
  missions: PlayerMission[] | number;
  playedMissions: number[];
  isGameOver?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const getRealMissions = (missions: number | PlayerMission[], chooseMission?: ChooseMission | ChooseMissionView) => {
  if (chooseMission) {
    if (!isInvisibleMissions(chooseMission.missions) && !isInvisibleMissions(missions)) {
      const isInspiring = chooseMission.missions.length && chooseMission.missions.length !== missions.length + 1;
      return isInspiring
        ? [...missions, ...chooseMission.missions.map((m) => ({ mission: m } as PlayerMission))]
        : chooseMission.missions.map((m) => ({ mission: m } as PlayerMission));
    } else if (isInvisibleMissions(chooseMission.missions) && isInvisibleMissions(missions)) {
      const isInspiring = chooseMission.missions && chooseMission.missions !== missions + 1;
      return isInspiring ? missions + chooseMission.missions : chooseMission.missions;
    }
  }

  return missions;
};

const PlayerMissions: FC<PlayerMissionsProps> = ({
  missions,
  playedMissions,
  player,
  activePlayer,
  track,
  bonuses,
  isGameOver,
  ...props
}) => {
  const [missionToDisplay, setMissionToDisplay] = useState<number>();
  const play = usePlay();
  const playerId = usePlayerId();
  const animation = useAnimation();
  const canPlayMission = (mission?: number) =>
    playerId === player.vampire &&
    activePlayer === playerId &&
    !animation &&
    !!mission &&
    !isGameOver &&
    isPlayableMission(Missions[mission], mission, player, GameDeck, BoardBoxes, track, bonuses);
  const componentAnimation = useAnimation(
    (animation) =>
      (isChooseMissionMove(animation.move) ||
        isChooseMissionMoveView(animation.move) ||
        isActivateMission(animation.move)) &&
      activePlayer === player.vampire
  );
  const chooseMission =
    componentAnimation &&
    (isChooseMissionMove(componentAnimation.move) || isChooseMissionMoveView(componentAnimation.move))
      ? componentAnimation.move
      : undefined;
  const activateMission =
    componentAnimation && isActivateMission(componentAnimation.move) ? componentAnimation.move : undefined;
  const realMissions: number | PlayerMission[] = getRealMissions(missions, chooseMission);

  const playMission = () => {
    if (!canPlayMission(missionToDisplay)) {
      return;
    }

    play(activateMissionMove(missionToDisplay!));
    setMissionToDisplay(undefined);
  };

  let missionScore = (mission: number) =>
    !isInvisibleMissions(realMissions)
      ? realMissions.find((m: PlayerMission) => m.mission === mission)!.score
      : undefined;
  return (
    <>
      {missionToDisplay !== undefined && (
        <MissionCatalog
          key="mission-catalog"
          missions={[missionToDisplay]}
          onClose={() => setMissionToDisplay(undefined)}
          missionScore={missionScore}
          topComponents={
            canPlayMission(missionToDisplay)
              ? [
                  <TheHungerButton
                    key="play-mission"
                    labelKey="button.play.mission"
                    css={activateMissionButton}
                    onClick={playMission}
                  />,
                ]
              : []
          }
        />
      )}
      {isInvisibleMissions(realMissions) && (
        <>
          {!!playedMissions.length &&
            playedMissions.map((m, index: number) => (
              <MissionTile
                key={`player-mission-v-${m}`}
                css={[missionTile, missionShadow, missionToDisplay === m && hideMission, missionPlayed]}
                mission={Missions[m]}
                onClick={() => setMissionToDisplay(m)}
                preTransform={`translate(${playerMissionTranslateX(index)}%, ${playerMissionTranslateY(index)}%)`}
                {...props}
              />
            ))}
          {[...Array(realMissions - playedMissions.length)].map((_, index) => (
            <MissionTile
              key={`player-mission-${index}`}
              mission={activateMission && index === 0 ? Missions[activateMission.mission] : undefined}
              css={[
                missionTile,
                activePlayer !== playerId &&
                  activateMission &&
                  activateMissionAnimation(index + playedMissions.length, componentAnimation!.duration),
              ]}
              backCss={missionShadow}
              preTransform={`translate(${playerMissionTranslateX(
                index + playedMissions.length
              )}%, ${playerMissionTranslateY(index + playedMissions.length)}%)`}
              {...props}
            />
          ))}
        </>
      )}
      {!isInvisibleMissions(realMissions) &&
        realMissions.map((m, index: number) => (
          <MissionTile
            key={`player-mission-${m.mission}`}
            css={[
              missionTile,
              missionShadow,
              missionToDisplay === m.mission && hideMission,
              canPlayMission(m.mission) && missionPlayable,
              playedMissions.includes(m.mission) && missionPlayed,
            ]}
            mission={Missions[m.mission]}
            score={m.score}
            onClick={() => setMissionToDisplay(m.mission)}
            preTransform={`translate(${playerMissionTranslateX(index)}%, ${playerMissionTranslateY(index)}%)`}
            {...props}
          />
        ))}
    </>
  );
};
const missionTile = css`
  position: absolute;
  height: ${playerMissionHeight}%;
  width: ${playerMissionWidth}%;
  font-size: ${playerMissionHeight / 100}em;
  cursor: pointer;
`;

const missionShadow = css`
  filter: drop-shadow(black 0 0 2.3em);
`;

const hideMission = css`
  opacity: 0;
`;

const activateMissionButton = css`
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 5em;
`;

const missionPlayable = css`
  filter: drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold)
    drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold)
    drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold) drop-shadow(0 0 0.6em gold);

  &:hover {
    filter: drop-shadow(0 0 0.6em green) drop-shadow(0 0 0.6em green) drop-shadow(0 0 0.6em green)
      drop-shadow(0 0 0.6em green) drop-shadow(0 0 0.6em green) drop-shadow(0 0 0.6em green)
      drop-shadow(0 0 0.6em green) drop-shadow(0 0 0.6em green) drop-shadow(0 0 0.6em green)
      drop-shadow(0 0 0.6em green);
  }

  cursor: pointer;
`;

const missionPlayed = css`
  filter: drop-shadow(black 0 0 2.3em) grayscale(1);
`;

const activateMissionAnimation = (index: number, duration: number) => css`
  z-index: 99;
  animation: ${duration}s ${animateActivateMission(index)} ease-in-out both;
`;

const animateActivateMission = (index: number) => keyframes`
  0% {
    transform: translate(${playerMissionTranslateX(index)}%, ${playerMissionTranslateY(index)}%) rotateY(180deg);
  }
  30% {
    transform: translate(${playerMissionTranslateX(index)}%, ${playerMissionTranslateY(index)}%);
  }
  60% {
    transform: translate(${playerMissionTranslateX(index)}%, ${playerMissionTranslateY(index)}%) scale(2);
  }
  100% {
    transform: translate(${playerMissionTranslateX(index)}%, ${playerMissionTranslateY(index)}%) rotateY(180deg);
  }
  
`;

export { PlayerMissions };
