import { Card } from '../Card';
import { CardType } from '../CardType';

export interface Human extends Card {
}

export const isMilitary = (card: Card) => !!card && card.category === CardType.HumanMilitary;
export const isNoble = (card: Card) => !!card && card.category === CardType.HumanNoble;
export const isReligious = (card: Card) => !!card && card.category === CardType.HumanReligious;
export const isVillager = (card: Card) => !!card && card.category === CardType.HumanVillager;
export const isHuman = (card?: Card): card is Human => !!card && [CardType.HumanNoble, CardType.HumanReligious, CardType.HumanMilitary, CardType.HumanVillager].includes(card.category);