import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { Player, TokenSide } from '../player';
import { isVisibleStack, Mission } from '../mission';
import { Card } from '../card/Card';
import { BoardBox } from '../board';
import { StartupBeigeMissionCount } from '../utils/GameConstants';
import Move from './Move';
import { applyEndOfGame } from '../utils/EndOfGameUtils';
import { Bonus } from '../bonus/Bonus';

export type EndOfGame = {
  type: MoveType.EndOfGame
}

export type EndOfGameView = EndOfGame & { players: Array<Player> };

export const endOfGameMove: EndOfGame = ({
  type: MoveType.EndOfGame
});

export const isEndOfGame = (move: Move): move is EndOfGameView => move.type === MoveType.EndOfGame;

export const endOfGame = (state: GameState, deck: Card[], board: BoardBox[], bonuses: Bonus[], missions: Mission[]) => {
  state.players = applyEndOfGame(initEndOfGame(state.players), state.missions.slice(0, StartupBeigeMissionCount).flat(), state.mode, deck, missions, board, bonuses);
};

export const endOfGameView = (state: GameView, move: EndOfGameView, deck: Card[], board: BoardBox[], bonuses: Bonus[], missions: Mission[]) => {
  state.players = applyEndOfGame(move.players, state.missions.filter(m => isVisibleStack(m)).flat(), state.mode, deck, missions, board, bonuses);
};

export const getEndOfGameView = (state: GameState, move: EndOfGame): EndOfGameView => {
  return {
    ...move,
    players: initEndOfGame(state.players)
  };
};

const initEndOfGame = ((players: Array<Player>): Array<Player> => {
  return players.map(p => ({
    ...p,
    discard: [...p.discard, ...p.deck, ...p.hand, ...p.playingArea],
    hand: [],
    deck: [],
    playingArea: [],
    tokenSide: TokenSide.Active
  }))
})
