import { TFunction } from 'i18next';

export class EffectDescription {
  translate: (t: TFunction) => string | any;
  bottom?: number;
  fontSize?: number;
  height?: number;
  color?: string;
  lineHeight?: number;
  width?: number;
  left?: number;

  constructor(translate: (t: TFunction) => string | any, bottom?: number, height?: number, fontSize?: number, color?: string, lineHeight? :number, width?: number, left?: number) {
    this.translate = translate;
    this.bottom = bottom;
    this.fontSize = fontSize;
    this.height = height;
    this.color = color;
    this.lineHeight = lineHeight;
    this.width = width;
    this.left = left;
  }
}
