import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { isMyPlayerView, isOtherPlayerView } from '../player';
import Vampire from '../player/Vampire';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import { isDraw } from '../utils/EffectUtils';
import Move from './Move';
import MoveView from './MoveView';

export type DrawCards = {
  type: MoveType.DrawCards,
  count: number
}

export type DrawCardsView = DrawCards & {
  cards: number[]
};

export const isDrawCardMoveView = (move: Move): move is DrawCardsView => move.type === MoveType.DrawCards && (move as DrawCardsView).cards !== undefined
export const isDrawCardMove = (move: Move): move is DrawCards => move.type === MoveType.DrawCards && (move as DrawCardsView).cards === undefined
export const isDrawCard = (move: Move | MoveView): move is DrawCards => move.type === MoveType.DrawCards


export const drawCardsMove = (count: number): DrawCards => ({
  type: MoveType.DrawCards,
  count,
});

/**
 * Basically took the number of card in the move.
 * It is possible that the draw is not complete, then moves must be set to shuffle
 * @param state
 * @param move
 */
export const drawCards = (state: GameState, move: DrawCards) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  const cards = player.deck.splice(0, move.count);

  player.drawCount = move.count - cards.length;
  player.hand.push(...cards);

  const pendingEffect = getNextPendingEffect(player);
  if (isDraw(pendingEffect)) {
    removeNextPendingEffect(player);
  }
};

export const drawCardsView = (state: GameView, move: DrawCardsView | DrawCards) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  if (isMyPlayerView(player) && isDrawCardView(move)) {
    player.drawCount = move.count - move.cards.length;
    player.hand.push(...move.cards);
    player.deck -= Math.min(move.count, player.deck);
  }

  if (isOtherPlayerView(player)) {
    player.drawCount = Math.max(0, move.count - player.deck);
    player.hand += Math.min(move.count, player.deck);
    player.deck -= Math.min(move.count, player.deck);
  }

  const pendingEffect = getNextPendingEffect(player);
  if (isDraw(pendingEffect)) {
    removeNextPendingEffect(player);
  }
};

export const getDrawCardMoveView = (state: GameState, move: DrawCards, playerId?: Vampire): DrawCards | DrawCardsView => {
  if (!playerId || playerId !== state.activePlayer) {
    return move;
  }

  const player = state.players.find(p => p.vampire === state.activePlayer)!;

  return {
    ...move,
    cards: player.deck.slice(0, move.count)
  };
};

export function isDrawCardView(move: DrawCardsView | DrawCards): move is DrawCardsView {
  return (move as DrawCardsView).cards !== undefined;
}
