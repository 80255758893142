import { EffectDescription } from './EffectDescription';
import { DeadRose, EternalRose, PerfectRose } from '@gamepark/the-hunger/card/hunt';
import { Trans } from 'react-i18next';
import { VictoryPointSymbol } from '../../../symbols';
import { Card } from '@gamepark/the-hunger/card/Card';

const RoseEffects = new Map<Card, Array<EffectDescription>>();

RoseEffects.set(EternalRose, [
  new EffectDescription(() => <Trans
    defaults="card.effect.rose.eternal"
    components={[
      <strong />,
      <VictoryPointSymbol value="1" width={1.305}/>
    ]}
  />,5.7)
]);

RoseEffects.set(PerfectRose, [
  new EffectDescription(() => <Trans
    defaults="card.effect.rose.perfect"
    components={[
      <strong />,
      <VictoryPointSymbol value="2" width={1.305}/>
    ]}
  />, 8)
]);

RoseEffects.set(DeadRose, [
  new EffectDescription(() => <Trans
    defaults="card.effect.rose.dead"
    components={[
      <strong />,
      <VictoryPointSymbol value="1" width={1.305}/>
    ]}
  />, 8)
]);

export {
  RoseEffects
}
