/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React, {HTMLAttributes} from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar} from '@gamepark/react-client';
import {PlayerType} from '@gamepark/the-hunger/player';
import {getPlayerName} from '@gamepark/the-hunger/TheHungerOptions';
import {useSelector} from 'react-redux';
import {playerPanelAvatarHeight, playerPanelAvatarWidth} from '../../utils/Style';

type PlayerInfosProps = {
  player: PlayerType
} & HTMLAttributes<HTMLDivElement>

const PlayerInfos: React.FC<PlayerInfosProps> = ({ player, ...props }) => {
  const { t } = useTranslation();
  const playerInfo = useSelector((state: any) => state.players.find((p: any) => p.id === player.vampire));

  return (
    <>
      <div css={ avatarContainer } { ...props } >
        { !!playerInfo.avatar && <Avatar css={ avatarCss } playerId={ player.vampire }/> }
      </div>
      <div css={ titleStyle } { ...props } >
        <span css={ [nameStyle, playerInfo.quit && quit] } title={ playerInfo?.name || getPlayerName(player.vampire, t) }>{ playerInfo?.name || getPlayerName(player.vampire, t) }</span>
      </div>
    </>
  );

};

const avatarCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  color: black;
`;

const quit = css`
  text-decoration: line-through;
`;

const avatarContainer = css`
  position: absolute;
  height: ${ playerPanelAvatarHeight }%;
  width: ${ playerPanelAvatarWidth }%;
  border-radius: 4em;
  transform: translate(27%, -102%);
  left: -5%;
`;

const titleStyle = css`
  position: absolute;
  width: 100%;
  height: 4%;
  font-size: 2.5em;
  font-weight: normal;
  display: flex;
`;

const nameStyle = css`
  width: 95%;
  margin-left: 2.5%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  position: absolute;
  color: white;
  letter-spacing: 0.03em;
`;

export {
  PlayerInfos
};
