import { EffectTrigger } from './EffectTrigger';
import { isOtherPlayerView, PlayerType } from '../player';
import Move from '../moves/Move';
import { digestCardMove, digestCardsMove } from '../moves/DigestCards';
import { EffectType } from './EffectType';
import { GameEffect } from './GameEffect';
import GameState from '../GameState';
import { CardType } from '../card/CardType';
import { Card } from '../card/Card';
import { getPlayerHandSize } from '../utils/GameUtils';
import { hasHunted } from '../utils/PlayerUtils';
import { isHuman } from '../card/hunt/Human';

export class DigestEffect implements GameEffect {
  type: EffectType.Digest = EffectType.Digest;
  digestCount: number;
  itself?: boolean;
  card?: number;
  trigger?: EffectTrigger;
  optional?: boolean;
  auto?: boolean;
  playingAreaOnly?: boolean;
  huntTypes?: Array<CardType>;
  hand?: boolean;

  constructor(options: Partial<DigestEffect>) {
    this.digestCount = options.digestCount!;
    this.itself = options.itself;
    this.trigger = options.trigger;
    this.optional = options.optional;
    this.auto = options.auto;
    this.playingAreaOnly = options.playingAreaOnly;
    this.hand = options.hand;
    this.huntTypes = options.huntTypes;
  }

  legalMoves(_state: GameState, player: PlayerType, deck: Card[]): Move[] {
    if (this.itself && this.card && !this.auto) {
      return [digestCardMove(this.card)];
    }

    const cards = this.playingAreaOnly ? player.playingArea : player.playingArea.concat(player.discard);

    return cards.filter((c) => isHuman(deck[c])).map((c) => digestCardMove(c));
  }

  static automaticMove(player: PlayerType, effect: DigestEffect, deck: Card[]) {
    if (effect.hand && effect.huntTypes?.length && effect.auto) {
      if (isOtherPlayerView(player)) {
        throw Error('The digest move is executed on the wrong side');
      }

      return digestCardsMove(
        player.hand.filter((c) => effect.huntTypes?.includes(deck[c].category)),
        true
      );
    }

    if (effect.itself && effect.card && effect.auto) {
      return digestCardMove(effect.card);
    }
    return;
  }

  isPlayable(player: PlayerType, deck: Card[]): boolean {
    if (this.trigger === EffectTrigger.BeforePlaying) {
      return (
        !isOtherPlayerView(player) &&
        !!getPlayerHandSize(player) &&
        !player.hasMoved &&
        !hasHunted(player) &&
        !player.playedCards.length
      );
    }

    if (this.itself && this.auto && this.huntTypes?.length) {
      return player.playingArea.some((c) => this.huntTypes?.includes(deck[c].category));
    }

    return true;
  }
}
