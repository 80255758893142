/**
 * Enumeration of all the types of Move in you game.
 * Even though it is not strictly required to use a type like that, it helps a lot in practice!
 */
enum MoveType {
  DiscardCards = 1,
  ActivateCard,
  MoveVampire,
  HuntOnTrack,
  HuntInTavern,
  HuntRose,
  ChooseMission,
  EndTurn,
  NextPlayer,
  FlipToken,
  DrawCards,
  DrawHuntCard,
  NewRound,
  ShuffleDiscardToDeck,
  MoveHuntTrackCard,
  FillHuntTrack,
  FillTavern,
  DrawMissions,
  AcquireBonusToken,
  DigestCards,
  PlaceOnDeck,
  PlaceInPlayingArea,
  VictoryPoint,
  DismissEffect,
  PlaceInDiscard,
  ActivateBonus,
  EndOfGame,
  ActivateMission
}

export default MoveType
