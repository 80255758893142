import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { isMyPlayerHand } from '../player';
import { isDiscard } from '../utils/EffectUtils';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';
import Vampire from '../player/Vampire';
import Move from './Move';

export type DiscardCards = {
  type: MoveType.DiscardCards,
  cards: number[],
  hand?: boolean,
  vampire: Vampire
};

export const isDiscardCardMove = (move: Move): move is DiscardCards => move.type === MoveType.DiscardCards;

export const discardCardsMove = (cards: number[], vampire: Vampire, hand?: boolean): DiscardCards => {
  if (hand) {
    return {
      type: MoveType.DiscardCards,
      cards,
      vampire,
      hand
    }
  }

  return {
    type: MoveType.DiscardCards,
    vampire,
    cards,
  }
};

export const discardCardMove = (card: number, vampire: Vampire, hand?: boolean): DiscardCards => discardCardsMove([card], vampire, hand);

export const discardCards = (state: GameState | GameView, move: DiscardCards) => {
  const player = state.players.find(p => p.vampire === move.vampire)!;

  player.discard.push(...move.cards);

  if (move.hand) {
    if (isMyPlayerHand(player.hand)) {
      player.hand = player.hand.filter(c => !move.cards.includes(c));
    } else {
      player.hand -= move.cards.length
    }
  } else {
    player.playingArea = player.playingArea.filter(c => !move.cards.includes(c));
    player.playedCards = player.playedCards.filter(c => !move.cards.includes(c));
  }

  const nextPendingEffect = getNextPendingEffect(player);
  if (isDiscard(nextPendingEffect)) {
    removeNextPendingEffect(player)
  }
}
