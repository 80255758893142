import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { isReadyEffect } from '../utils/EffectUtils';
import { getNextPendingEffect, removeNextPendingEffect } from '../utils/PendingUtils';

export type PlaceInDiscard = {
  type: MoveType.PlaceInDiscard,
  cards: number[]
};

export const placeInDiscardMove = (cards: number[]): PlaceInDiscard => ({
  type: MoveType.PlaceInDiscard,
  cards
})

export const placeInDiscard = (state: GameState | GameView, move: PlaceInDiscard) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  player.discard.push(...move.cards)

  const pendingEffect = getNextPendingEffect(player)
  if (isReadyEffect(pendingEffect)) {
    removeNextPendingEffect(player);
  }
}
