import { LastTurn } from './GameConstants';
import { hasParasol } from './GameUtils';
import { Bonus } from '../bonus/Bonus';
import { PlayerType } from '../player';
import Vampire from '../player/Vampire';

export const isOver = (players: Array<PlayerType>, round: number, turnOrder: Array<Vampire>,  bonuses: Bonus[]) => {
  if (players.some(p => hasParasol(p, bonuses))) {
    return round === LastTurn + 1 &&
      players
      .filter(p => turnOrder.includes(p.vampire))
      .every(p => p.end);
  }

  return round === LastTurn && players.every(p => p.end);
}
