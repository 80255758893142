import GameView from '@gamepark/the-hunger/GameView';
import { ViewType } from './ViewType';
import { FC, useEffect } from 'react';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { isDrawCardMove } from '@gamepark/the-hunger/moves/DrawCards';
import { isPlaceInPlayingAreaMove } from '@gamepark/the-hunger/moves/PlaceInPlayingArea';
import { isFillTavern } from '@gamepark/the-hunger/moves/FillTavern';
import { isFillHuntTrackMoveView } from '@gamepark/the-hunger/moves/FillHuntTrack';
import { isDiscardCardMove } from '@gamepark/the-hunger/moves/DiscardCards';
import { getNextPendingEffect } from '@gamepark/the-hunger/utils/PendingUtils';
import { isAdditionalHunt, isFreeHunt, isHuntTrackMove, isInspiring, isTeleport, isWinBonusToken, isWinHuntTrackCard } from '@gamepark/the-hunger/utils/EffectUtils';
import { displayVampireMove } from './moves/DisplayVampire';

export type ViewChangerProps = {
  game: GameView,
  onChangeView: (view: ViewType) => void,
}

const ViewChanger: FC<ViewChangerProps> = ({ game, onChangeView }) => {
  const animation = useAnimation();
  const play = usePlay();
  const playerId = usePlayerId();

  useEffect(() => {
    if (animation) {

      if (
        isFillTavern(animation.move) ||
        isFillHuntTrackMoveView(animation.move)
      ) {
        onChangeView(ViewType.Boards);
      }

      if (game.activePlayer && playerId === game.activePlayer) {
        if (game.displayedPlayer !== game.activePlayer) {
          play(displayVampireMove(game.activePlayer), { local: true })
        }

        if (
          isDrawCardMove(animation.move) ||
          isPlaceInPlayingAreaMove(animation.move) ||
          isDiscardCardMove(animation.move)
        ) {
          onChangeView(ViewType.Player);
        }
      }
    }
    // eslint-disable-next-line
  }, [animation && animation.move, game.activePlayer]);

  useEffect(() => {
    if (game.activePlayer && playerId === game.activePlayer) {

      if (game.displayedPlayer !== game.activePlayer) {
        play(displayVampireMove(game.activePlayer), { local: true })
      }

      const player = game.players.find(p => p.vampire === playerId)!;
      const pendingEffect = getNextPendingEffect(player, true);
      if (isTeleport(pendingEffect) ||
        isInspiring(pendingEffect) ||
        isHuntTrackMove(pendingEffect) ||
        isWinHuntTrackCard(pendingEffect) ||
        isWinBonusToken(pendingEffect) ||
        isAdditionalHunt(pendingEffect) ||
        isFreeHunt(pendingEffect)) {
        onChangeView(ViewType.Boards);
      }
    }
    // eslint-disable-next-line
  }, [game.activePlayer, game.players]);

  return null;
};

export {
  ViewChanger
};
