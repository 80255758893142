/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BoardBoxEffect } from '@gamepark/the-hunger/board';
import { forwardRef } from 'react';
import { BoardEffectDescriptions } from '../../../board/BoardEffectDescriptions';
import { playerEffectSummaryBoardBubbleLeft, playerEffectSummaryBoardBubbleTop, playerEffectSummaryBoardBubbleWidth } from '../../../../utils/Style';

type BoardEffectBubbleProps = {
  effect?: BoardBoxEffect,
  index?: number,
  playerCount: number,
}

const BoardEffectBubble = forwardRef<HTMLDivElement, BoardEffectBubbleProps>(({
                                                                                effect,
                                                                                index,
                                                                                playerCount
                                                                              }, ref) => {
  if (effect === undefined || index === undefined) {
    return null;
  }

  return <div ref={ ref } key="tooltip" css={ [effectBubbleBoardCss(index, playerCount)] }>
    { BoardEffectDescriptions.get(effect as BoardBoxEffect) }
  </div>;
});


const effectBubbleBoardCss = (index: number, playerCount: number) => css`
  position: absolute;
  width: ${ playerEffectSummaryBoardBubbleWidth }%;
  font-size: 2.5em;
  z-index: 500;
  left: ${ playerEffectSummaryBoardBubbleLeft(index) }%;
  top: ${ playerEffectSummaryBoardBubbleTop(index, playerCount) }%;
  transition: 0.5s left;
  transform: translateX(-100%);
  background-color: white;
  border: 0.1em solid black;
  padding: 1em;
  border-radius: 0.4em;
  color: black;
  filter: drop-shadow(0 0 0.1em white);

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: -0.3em;
    transition: 0.5s top;
    width: 0;
    height: 0;
    border: 0.9em solid transparent;
    border-left-color: gray;
    border-right: 0;
    margin-top: 1em;
    margin-right: -0.95em;
  }
`;

export {
  BoardEffectBubble
};
