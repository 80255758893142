/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, Fragment, MouseEvent, useState } from "react";
import {
  missionCatalogGridHeight,
  missionCatalogGridLeft,
  missionCatalogGridTop,
  missionCatalogGridWidth,
  missionCatalogMissionTranslateX,
  missionCatalogMissionTranslateY,
  realMissionCatalogMissionHeight,
  realMissionCatalogMissionWidth,
} from "../../utils/Style";
import { MissionTile } from "./MissionTile";
import { Missions } from "@gamepark/the-hunger/mission";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlayerToken } from "../player/PlayerToken";
import Vampire from "@gamepark/the-hunger/player/Vampire";

export type MissionCatalogProps = {
  playerMissions?: number[];
  vampire?: Vampire;
  missions: number[];
  topComponents?: any[];
  onClose?: (event?: any) => void;
  missionScore?: (mission: number) => number | undefined;
  missionStyle?: (mission: number) => any;
  onMissionClick?: (mission: number) => void;
  portal?: boolean;
  canReduce?: boolean;
};

const MissionCatalog: FC<MissionCatalogProps> = ({ playerMissions = [], canReduce, vampire, missions, missionScore, topComponents = [], missionStyle, onMissionClick, onClose, portal = true }) => {
  const { t } = useTranslation();
  const allMissions = [...missions, ...playerMissions];
  const [reduced, setReduce] = useState(false);

  const closeMission = (event: MouseEvent) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (onClose) {
      onClose();
    }
  };

  let missionsCatalog = (
    <>
      {canReduce && (
        <button key="mission-button" css={button(portal)} onClick={() => setReduce((r) => !r)}>
          <FontAwesomeIcon icon={reduced ? faExternalLinkAlt : faTimes} />
          {reduced ? t("button.show.missions") : t("button.close.missions")}
        </button>
      )}
      {!canReduce && (
        <button key="card-button" css={button(portal, allMissions.length === 1, !!topComponents?.length)} onClick={closeMission}>
          <FontAwesomeIcon icon={faTimes} />
          {t("Close")}
        </button>
      )}
      <div key="mission-container" css={[missionChoiceContainer, notReduced, reduced && reducedStyle]} onClick={closeMission} />
      {!reduced && !!topComponents?.length && topComponents}
      <div key="mission-grid" css={[missionChoiceGrid(allMissions.length, portal), notReduced, reduced && reducedStyle]}>
        {allMissions.map((mission, index) => (
          <Fragment key={`mission-${index}`}>
            {vampire && playerMissions?.includes(mission) && <PlayerToken key={`vampire-${index}`} vampire={vampire} css={playerToken(allMissions.length, index)} />}
            <MissionTile
              key={`mission-tile-${index}`}
              css={[missionTile(allMissions.length, index)]}
              frontCss={missionStyle && missionStyle(mission)}
              score={missionScore && missionScore(mission)}
              mission={Missions[mission]}
              onClick={() => (onMissionClick ? onMissionClick(mission) : undefined)}
              missionIndex={mission}
            />
          </Fragment>
        ))}
      </div>
    </>
  );

  if (!portal) {
    return missionsCatalog;
  }

  let letterBox = document.getElementById("letterbox")!;
  if (letterBox) {
    return createPortal(missionsCatalog, letterBox);
  }

  return null;
};

const playerToken = (missionCount: number, missionIndex: number) => css`
  position: absolute;
  width: 10em;
  height: 10em;
  top: ${(missionCatalogMissionTranslateY(missionCount, missionIndex) / 100) * realMissionCatalogMissionHeight(missionCount) - 0.5}%;
  left: ${(missionCatalogMissionTranslateX(missionCount, missionIndex) / 100) * realMissionCatalogMissionWidth(missionCount) - 0.5}%;
  z-index: 100;
`;

const reducedStyle = css`
  transition: 0.2s opacity;
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
`;

const notReduced = css`
  transition: 0.2s opacity;
`;

const button = (portal: boolean, _oneMission?: boolean, hasTopComponent?: boolean) => css`
  font-weight: bold;
  color: black;
  background: rgba(255, 255, 255);
  border: 0.2em solid black;
  border-radius: 1.5em;
  padding: 0.5em 0.7em;
  position: absolute;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 0 0 0.3em 0.1em white;
  -moz-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
  font-size: 2.3em;
  left: 60.347661%;
  z-index: 100;
  text-transform: capitalize;
  top: ${_oneMission ? (portal ? 20 : 19) : hasTopComponent ? 13.4 : portal ? 9.6 : 2.8}em;

  & svg {
    margin-right: 0.3em;
  }
`;

const missionChoiceContainer = css`
  position: absolute;
  z-index: 96;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: rgba(0, 0, 0, 0.6);
`;
const missionChoiceGrid = (missionCount: number, portal: boolean) => css`
  position: absolute;
  z-index: 97;
  height: ${missionCatalogGridHeight(missionCount)}%;
  width: ${missionCatalogGridWidth(missionCount)}%;
  top: ${missionCatalogGridTop(missionCount, portal)}%;
  left: ${missionCatalogGridLeft(missionCount)}%;
  font-size: ${missionCatalogGridHeight(missionCount) / 100}em;
`;

const missionTile = (missionCount: number, missionIndex: number) => css`
  position: absolute;
  height: ${realMissionCatalogMissionHeight(missionCount)}%;
  width: ${realMissionCatalogMissionWidth(missionCount)}%;
  transform: translate(${missionCatalogMissionTranslateX(missionCount, missionIndex)}%, ${missionCatalogMissionTranslateY(missionCount, missionIndex)}%);
  font-size: ${realMissionCatalogMissionHeight(missionCount) / 100}em;
  cursor: not-allowed;
`;

export { MissionCatalog };
