import GameState from '../GameState';
import GameView from '../GameView';
import Move from '../moves/Move';
import { PlayerType, TokenSide } from '../player';
import { everyoneHasPlayed, getPlayerHandSize, hasMissionChoice } from '../utils/GameUtils';
import MoveView from '../moves/MoveView';
import { BoardBoxEffect, BoardBoxes } from '../board';
import { EffectType } from '../effect/EffectType';
import { NewRoundPhase } from '../round/NewRoundPhase';
import { getCardToDiscard } from '../utils/CardUtils';
import { getNextPendingEffect } from '../utils/PendingUtils';
import { DigestEffect } from '../effect/DigestEffect';
import { VictoryPointEffect } from '../effect/VictoryPointEffect';
import { DiscardEffect } from '../effect/DiscardEffect';
import { GregariousEffect } from '../effect/GregariousEffect';
import { GameDeck } from '../card/hunt/GameDeck';
import { DrawEffect } from '../effect/DrawEffect';
import { flipTokenMove, flipTokensMove } from '../moves/FlipToken';
import { MoveHuntTrackCardDirection, moveHuntTrackCardMove } from '../moves/MoveHuntTrackCard';
import { newRoundMove } from '../moves/NewRound';
import { discardCardsMove } from '../moves/DiscardCards';
import { nextPlayerMove } from '../moves/NextPlayer';
import { drawMissionMove } from '../moves/DrawMissions';
import { acquireBonusTokenMove } from '../moves/AcquireBonus';
import { isOver } from '../utils/IsOver';
import { SkipTurnEffect } from '../effect/SkipTurnEffect';
import { Bonus } from '../bonus/Bonus';

export const getPredictableAutomaticMoves = (state: GameState | GameView, bonuses: Bonus[], player?: PlayerType): (Move & MoveView)[] => {// PREDICTABLE

  if (state.newRoundPhase) {
    switch (state.newRoundPhase) {
      case NewRoundPhase.FlipVampires:
        return [flipTokensMove(state.players.map((p: any) => p.vampire), TokenSide.Active)];
      case NewRoundPhase.MoveHuntTrackCards:
        return [moveHuntTrackCardMove(MoveHuntTrackCardDirection.RIGHT)];
    }
  }

  if (!state.newRoundPhase) {
    if (everyoneHasPlayed(state, GameDeck, player) && !state.newRoundPhase && !isOver(state.players, state.round, state.turnOrder, bonuses)) {
      return [newRoundMove];
    }

    if (!isOver(state.players, state.round, state.turnOrder, bonuses) && player) {

      if ((player.drawCount && player.deck)
        || (player.drawCount && !player.deck && player.discard.length)) {
        return [];
      }

      if (!everyoneHasPlayed(state, GameDeck, player) && player.end && !state.newRoundPhase) {
        if (player.tokenSide === TokenSide.Active) {
          return [flipTokenMove(player.vampire, TokenSide.Resting)];
        }

        const nonPermanentCards = getCardToDiscard(player.playingArea, player.position, GameDeck);

        if (nonPermanentCards.length) {
          return [discardCardsMove(nonPermanentCards, player.vampire)];
        }
      }
      if (player.pendingEffects && player.pendingEffects.length) {

        let automaticMove = getPendingEffectPredictableAutomaticMove(player);
        if (automaticMove) {
          return [automaticMove];
        }
      }

      if (!everyoneHasPlayed(state, GameDeck, player) && player.end && !state.newRoundPhase) {
        const nonPermanentCards = getCardToDiscard(player.playingArea, player.position, GameDeck);
        // To trigger draw effect, player must have at least 3 card in hand + discard + deck
        if (player.tokenSide === TokenSide.Resting && !nonPermanentCards.length) {
          if (getPlayerHandSize(player) === 3) {
            return [nextPlayerMove];
          }
        }
      }
    }

    if (!player && state.players.every(p => !hasMissionChoice(p))) {
      return [nextPlayerMove];
    }
  }
  return []
};

export const getPendingEffectPredictableAutomaticMove = (player: PlayerType): Move & MoveView | void => {
  if (player.pendingEffects && player.pendingEffects.length) {
    let first = getNextPendingEffect(player);

    if (first && !first.optional) {
      switch (first.type) {
        case EffectType.Digest:
          if (!first.hand) {
            return DigestEffect.automaticMove(player, first, GameDeck);
          }
          break;
        case EffectType.VictoryPoint:
          return VictoryPointEffect.automaticMove(first);
        case EffectType.SkipTurn:
          return SkipTurnEffect.automaticMove();
        case EffectType.Discard:
          if (!first.allHand) {
            return DiscardEffect.automaticMove(player, first);
          }
      }
    }
  }
};

export const getPendingEffectAutomaticMove = (state: GameState | GameView, player: PlayerType): Move | void => {
  if (player.pendingEffects && player.pendingEffects.length) {
    let first = getNextPendingEffect(player);

    if (first && !first.optional) {
      switch (first.type) {
        case EffectType.Digest:
          if (first.hand) {
            return DigestEffect.automaticMove(player, first, GameDeck);
          }
          break;
        case EffectType.Draw:
          return DrawEffect.automaticMove(player, first, GameDeck);
        case EffectType.Gregarious:
          return GregariousEffect.automaticMove(state);
        case EffectType.Discard:
          if (first.allHand) {
            return DiscardEffect.automaticMove(player, first);
          }
      }
    }
  }
};

export const getBoardEffectAutomaticMove = (state: GameState, activePlayer: PlayerType): Move | void => {
  let boardBox = BoardBoxes[activePlayer.position.box];
  if (!activePlayer.hasMoved || !activePlayer.boardEffect) {
    return;
  }

  switch (activePlayer.boardEffect) {
    case BoardBoxEffect.Crypt:
      if (boardBox.missionStack && state.missions[boardBox.missionStack].length) {
        return drawMissionMove(boardBox.missionStack);
      }
      break;
    case BoardBoxEffect.Chest:
      const token = state.bonusTokens.find(b => b.position === activePlayer.position.box);
      if (token) {
        return acquireBonusTokenMove(activePlayer.vampire, token.position);
      }
  }
};
