import { PlayerType } from '../player';
import { isDiscard, isDraw, isVictoryPoint } from './EffectUtils';
import { Bonus } from '../bonus/Bonus';
import { BonusTokenType } from '../bonus/BonusTokenType';

export const canBonusBePlayed = (player: PlayerType, bonus: number, bonuses: Bonus[]) => {
  let theBonus = bonuses[bonus];

  const effect = theBonus.effects?.length ? theBonus.effects[0] : undefined;

  if (!effect) {
    return false;
  }

  if (isDiscard(effect) || isDraw(effect)) {
    return player.remainingSpeed === undefined && !isBonusUsed(player, bonus);
  }

  return !isVictoryPoint(effect) && !isBonusUsed(player, bonus);
};
export const isBonusUsed = (player: PlayerType, bonus: number) => player.usedBonuses.includes(bonus);

export const hasMultifactionToken = (player: PlayerType, bonuses: Bonus[]) =>
  player.bonusTokens.some((b) => bonuses[b].type === BonusTokenType.MultiFaction);
