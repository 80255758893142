import { PlayerType } from '../player';
import Vampire from '../player/Vampire';
import { Card } from '../card/Card';
import { canMove } from './MoveUtils';
import { canHunt } from './HuntUtils';
import { getActivatableCards } from './CardUtils';
import { computeSpeed, computeSpeedForHunt, hasMissionChoice } from './GameUtils';
import { BoardBox } from '../board';
import { hasMultifactionToken } from './BonusUtils';
import { isOver } from './IsOver';
import { Bonus } from '../bonus/Bonus';

export const isActive = (
  player: PlayerType,
  otherPlayers: Array<PlayerType>,
  playerId: Vampire,
  round: number,
  turnOrder: Array<Vampire>,
  track: number[][][],
  tavernSize: number,
  deck: Card[],
  board: BoardBox[],
  bonuses: Bonus[],
  activePlayer?: Vampire
): boolean => {
  if (!activePlayer) {
    return hasMissionChoice(player);
  }

  if (isOver([player, ...otherPlayers], round, turnOrder, bonuses)) {
    return hasMultifactionToken(player, bonuses);
  }

  if (playerId === activePlayer) {
    if (otherPlayers.some((p) => p.pendingEffects.length)) {
      let speed = computeSpeed(player, deck);
      let huntSpeed = computeSpeedForHunt(player, deck);
      return (
        canMove(player, deck, board, activePlayer, speed) ||
        canHunt(player, huntSpeed, round, track, tavernSize, deck, board) ||
        getActivatableCards(player, deck, board).length > 0
      );
    }

    return true;
  } else {
    return !!player.pendingEffects.length;
  }
};
