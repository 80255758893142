import { TutorialDescription } from '@gamepark/react-client';
import GameState from '@gamepark/the-hunger/GameState';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import Move from '@gamepark/the-hunger/moves/Move';
import { GameMode } from '@gamepark/the-hunger/GameMode';
import { moveVampireMove } from '@gamepark/the-hunger/moves/MoveVampire';
import { endTurnMove } from '@gamepark/the-hunger/moves/EndTurn';
import { chooseMissionsMove } from '@gamepark/the-hunger/moves/ChooseMission';
import { TutorialGameInitializer, TutorialOpponentMissionIndexes } from './TutorialGameInitializer';
import { huntOnTrackMove } from '@gamepark/the-hunger/moves/HuntOnTrack';

const You = Vampire.BorisPouchkine;
const Opponent = Vampire.JosephineLafayette;
const state = new TutorialGameInitializer([{ id: You }, { id: Opponent }], GameMode.Rookie).getGameState();
const TheHungerTutorial: TutorialDescription<GameState, Move, Vampire> = {
  setupTutorial: () => {
    return [state, [You, Opponent]];
  },

  expectedMoves: () => [
    chooseMissionsMove(Opponent, [TutorialOpponentMissionIndexes[0]]),
    moveVampireMove(9, You),
    chooseMissionsMove(You, [state.missions[2][0]]),
    endTurnMove,
    moveVampireMove(5, Opponent),
    endTurnMove,
    moveVampireMove(12, You),
    huntOnTrackMove(1, 1),
    endTurnMove,
    moveVampireMove(6, Opponent),
    huntOnTrackMove(0, 1),
    endTurnMove,
    moveVampireMove(28, You),
  ],
};

export default TheHungerTutorial;
