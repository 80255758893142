import { EffectDescription } from './EffectDescription';
import {
  Bagoly,
  Bo,
  Caine,
  Capra,
  Chop,
  Echo,
  Gray,
  Jahda,
  Kaa,
  Kutya,
  Lockjaw,
  Malac,
  Nanny,
  Nanoosh,
  Patcani,
  Porumbel,
  Sova,
  Teddy,
  Tyson,
  Ursa,
  WeeVlad,
  Wiggles
} from '@gamepark/the-hunger/card/hunt';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { SpeedSymbol, VictoryPointSymbol, WolfSymbol } from '../../../symbols';
import { Card } from '@gamepark/the-hunger/card/Card';

const FamiliarEffects = new Map<Card, Array<EffectDescription>>();

FamiliarEffects.set(Kutya, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.dog"
  components={[
    <strong/>,
    <SpeedSymbol value="+1" width={1.45}/>,
    <SpeedSymbol value="1" width={1.45}/>
  ]}
  values={{
    card: t('Kutya')
  }}
/>, 8, undefined, 2.4, undefined, 1.5)]);

FamiliarEffects.set(Caine, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.dog"
  components={[
    <strong/>,
    <SpeedSymbol value="+1" width={1.45}/>,
    <SpeedSymbol value="1" width={1.45}/>
  ]}
  values={{
    card: t('Caine')
  }}
/>, 8, undefined, 2.4, undefined, 1.5)]);

FamiliarEffects.set(Ursa, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.bear"
  components={[
    <strong/>,
    <SpeedSymbol value="1"/>
  ]}
  values={{
    card: t('Ursa')
  }}
/>, 8, undefined, 2.3)]);

FamiliarEffects.set(Teddy, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.bear"
  components={[
    <strong/>,
    <SpeedSymbol value="1" />
  ]}
  values={{
    card: t('Teddy')
  }}
/>, 8.2, undefined, 2.2)]);

FamiliarEffects.set(Malac, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.pig"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>
  ]}
/>, 8, undefined , 2.4)]);

FamiliarEffects.set(Chop, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.pig"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>
  ]}
/>, 8, undefined, 2.4)]);

FamiliarEffects.set(Nanoosh, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.cat"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>,
    <SpeedSymbol value="2" width={1.45}/>
  ]}
/>, 7.8)]);

FamiliarEffects.set(Lockjaw, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.cat"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>,
    <SpeedSymbol value="2" width={1.45}/>
  ]}
/>, 7.8)]);

FamiliarEffects.set(Echo, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.wolf"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>,
    <WolfSymbol />
  ]}
/>, 7.8, undefined, 2.4, undefined, 1.5)]);

FamiliarEffects.set(Jahda, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.wolf"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>,
    <WolfSymbol />
  ]}
/>, 7.8, undefined, 2.4, undefined, 1.5)]);

FamiliarEffects.set(Bo, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.wolf"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>,
    <WolfSymbol />
  ]}
  />, 7.8, undefined, 2.4, undefined, 1.5)]);

FamiliarEffects.set(Gray, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.wolf"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>,
    <WolfSymbol />
  ]}
/>, 7.8, undefined, 2.4, undefined, 1.5)]);

FamiliarEffects.set(Patcani, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.rat"
  components={[
    <strong/>,
    <SpeedSymbol value="1" width={1.595}/>,
    <VictoryPointSymbol value="1"/>,
    <VictoryPointSymbol value="2"/>
  ]}
/>, 8)]);

FamiliarEffects.set(WeeVlad, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.rat"
  components={[
    <strong/>,
    <SpeedSymbol value="1" width={1.595}/>,
    <VictoryPointSymbol value="1"/>,
    <VictoryPointSymbol value="2"/>
  ]}
/>, 8)]);

FamiliarEffects.set(Porumbel, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.dove"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>
  ]}
/>, 8)]);

FamiliarEffects.set(Tyson, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.dove"
  components={[
    <strong/>,
    <VictoryPointSymbol value="1" width={1.305}/>
  ]}
/>, 8)]);

FamiliarEffects.set(Bagoly, [
  new EffectDescription((t: TFunction) => <Trans
    defaults="card.effect.inspiring"
    components={[<strong/>]}
    values={{
      card: t('Bagoly')
    }}
  />, 12.8, undefined, 2.2, 'white', 1.3),
  new EffectDescription((_: TFunction) => <Trans
    defaults="card.effect.owl"
    components={[
      <strong/>,
      <VictoryPointSymbol value="1" width={1.3}/>
    ]}
  />, 4.2, undefined, 2.1, undefined, undefined, 56),
]);

FamiliarEffects.set(Sova, [
  new EffectDescription((t: TFunction) => <Trans
    defaults="card.effect.inspiring"
    components={[<strong/>]}
    values={{
      card: t('Sova')
    }}
  />, 12.8, undefined, 2.2, 'white', 1.3),
  new EffectDescription((_: TFunction) => <Trans
    defaults="card.effect.owl"
    components={[
      <strong/>,
      <VictoryPointSymbol value="1" width={1.3}/>
    ]}
  />, 4.2, undefined, 2.1, undefined, undefined, 56),
]);

FamiliarEffects.set(Capra, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.aries"
  components={[
    <strong/>,
    <VictoryPointSymbol value="2" width={1.305}/>
  ]}
/>, 8, undefined, 2.35)]);

FamiliarEffects.set(Nanny, [new EffectDescription((_: TFunction) => <Trans
  defaults="card.effect.aries"
  components={[
    <strong/>,
    <VictoryPointSymbol value="2" width={1.305}/>
  ]}
/>, 8, undefined, 2.35)]);

FamiliarEffects.set(Kaa, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.snake"
  components={[
    <strong/>,
    <VictoryPointSymbol value="2" width={1.305}/>
  ]}
  values={{
    card: t('Kaa')
  }}
/>, 8.2)]);

FamiliarEffects.set(Wiggles, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.snake"
  components={[
    <strong/>,
    <VictoryPointSymbol value="2" width={1.305}/>
  ]}
  values={{
    card: t('Wiggles')
  }}
/>, 8.2)]);


export {
  FamiliarEffects
};
