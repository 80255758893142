import { Angus, Anton, Bernard, Bippo, Eunice, Juri, Patricia, Reyda, Szalai, Yaga } from '@gamepark/the-hunger/card/hunt';
import { EffectDescription } from './EffectDescription';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { HumanTypeSymbol, SpeedSymbol, VictoryPointSymbol } from '../../../symbols';
import { CardType } from '@gamepark/the-hunger/card/CardType';
import { Card } from '@gamepark/the-hunger/card/Card';

const HumanVillagerEffects = new Map<Card, Array<EffectDescription>>();

HumanVillagerEffects.set(Anton, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.spicy"
  components={[<strong/>]}
  values={{
    card: t('Anton')
  }}
/>, 7.3, undefined, 2.1)]);

HumanVillagerEffects.set(Yaga, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.gregarious"
  components={[<strong/>]}
  values={{ card: t('Yaga') }}
/>, 7.4, undefined, undefined, 'white')]);

HumanVillagerEffects.set(Eunice, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.spicy"
  components={[<strong/>]}
  values={{
    card: t('Eunice')
  }}
/>, 7.3, undefined, 2.1)]);

HumanVillagerEffects.set(Angus, [new EffectDescription((t: TFunction) => t('card.effect.digest', { card: t('Angus') }), 7.8, undefined, 2.4, 'white')]);

HumanVillagerEffects.set(Bippo, [new EffectDescription(() => <Trans
  defaults="card.effect.confused"
  components={[<strong/>]}
/>, 5.5, undefined, 2.5)]);

HumanVillagerEffects.set(Juri, [new EffectDescription(() => <Trans
  defaults="card.effect.confused"
  components={[<strong/>]}
/>, 5.5, undefined, 2.5)]);

HumanVillagerEffects.set(Reyda, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.fast"
  components={[
    <strong/>,
    <SpeedSymbol width={1.595} value="+1"/>,
  ]}
  values={{
    card: t('Reyda')
  }}
/>, 5.5, undefined, 2.5, 'white')]);

HumanVillagerEffects.set(Patricia, [new EffectDescription(() => <Trans
  defaults="card.effect.slow"
  components={[
    <strong/>,
    <SpeedSymbol value="2" width={1.45}/>
  ]}
/>, 5.5, 8, undefined, 'white')]);

HumanVillagerEffects.set(Bernard, [new EffectDescription((t: TFunction) => <Trans
  defaults="card.effect.spicy"
  components={[<strong/>]}
  values={{
    card: t('Bernard')
  }}
/>, 7.3, undefined, 2.1)]);

HumanVillagerEffects.set(Szalai, [new EffectDescription(() => <Trans
  defaults="card.effect.human.hunt" components={[
  <VictoryPointSymbol value="1"/>,
  <HumanTypeSymbol category={CardType.HumanVillager}/>
]}/>, 7.5)]);

export {
  HumanVillagerEffects
};
