export const RookieBoxPosition: { top: number, left: number }[] = [
  { top: 39.7,  left: 43.6 }, // 0 ->
  { top: 46.1,  left: 40.3 }, // 1 ->
  { top: 50.45,  left: 47.04 }, // 2 ->
  { top: 50.85,  left: 56.15 }, // 3 ->
  { top: 46.9,  left: 63.1 }, // 4 ->
  { top: 40.6,  left: 64.40 }, // 5 ->
  { top: 31.9,  left: 61.45 }, // 6 ->
  { top: 24.25,  left: 55.5 }, // 7 ->
  { top: 21,  left: 47.2 }, // 8 ->
  { top: 17.55, left: 36.7 }, // 9 ->
  { top: 25.8,  left: 26.6 }, // 10 ->
  { top: 34.45,  left: 26.05 }, // 11 ->
  { top: 41.1,  left: 31.4 }, // 12 ->
  { top: 50.15, left: 29.9 }, // 13 ->
  { top: 45.2,  left: 19.6 }, // 14 ->
  { top: 51.2,  left: 12.7 }, // 15 ->
  { top: 43.55, left: 3.7 }, // 16 ->
  { top: 57.2,  left: 2 }, // 17 ->
  { top: 82.1,  left: 5.45 }, // 18 ->
  { top: 94.1,  left: 19.5 }, // 19 ->
  { top: 93.35, left: 41.2 }, // 20 ->
  { top: 94.2,  left: 63.1 }, // 21 ->
  { top: 86.65,  left: 85.7 }, // 22 ->
  { top: 78.6,  left: 92.6 }, // 23 ->
  { top: 54.55, left: 22.55 }, // 24 ->
  { top: 60.15,  left: 17.25 }, // 25 ->
  { top: 65.95,  left: 12.6 }, // 26 ->
  { top: 71.8,  left: 19.55 }, // 27 ->
  { top: 78.25,  left: 24 }, // 28 ->
  { top: 77.2,  left: 31.85 }, // 29 ->
  { top: 81.5,  left: 38.3 }, // 30 ->
  { top: 81.4,  left: 48.6 }, // 31 ->
  { top: 84,  left: 54.15 }, // 32 ->
  { top: 81.95,  left: 61.2 }, // 33 ->
  { top: 81.35,  left: 70.9 }, // 34 ->
  { top: 75.1,  left: 77.15 }, // 35 ->
  { top: 67.75,  left: 85.4 }, // 36 ->
  { top: 57.3,  left: 86.95 }, // 37 ->
  { top: 47.9, left: 85.7 }, // 38 ->
  { top: 69.0,  left: 28.25 }, // 39 ->
  { top: 70.1,  left: 36.35 }, // 40 ->
  { top: 72.2,  left: 43.7 }, // 41 ->
  { top: 72.8,  left: 54.1 }, // 42 ->
  { top: 73.3,  left: 60.7 }, // 43 ->
  { top: 71.6,  left: 70.6 }, // 44 ->
  { top: 64.0,  left: 72.5 }, // 45 ->
  { top: 56.1,  left: 72.85 }, // 46 ->
  { top: 37.45,  left: 85.65 }, // 47 ->
  { top: 29.7,  left: 89 }, // 48 ->
  { top: 20.0, left: 87.651 }, // 49 ->
  { top: 9.8,   left: 79.7 }, // 50 ->
  { top: 4.4,   left: 72.55 }, // 51 ->
  { top: 49.5,  left: 76.15 }, // 52 ->
  { top: 39.95,  left: 74.55 }, // 53 ->
  { top: 29.2,  left: 71.5 }, // 54 ->
  { top: 17.5, left: 69.4 }, // 55 ->
  { top: 11,  left: 65.6 }, // 56 ->
  { top: 5.4,   left: 58.4 }, // 57 ->
  { top: 4.4,   left: 51 }, // 58 ->
  { top: 3.9,   left: 43 }, // 59 ->
  { top: 3.9,   left: 36.8 }, // 60 ->
  { top: 10.7,  left: 32.2 }, // 61 ->
]
