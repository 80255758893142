/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { Images } from '../images/Images';
import { createPortal } from 'react-dom';

type ModalProps = {
  modalTitle?: any;
  description?: any;
  actions?: any;
  onClose?: () => void;
  portal?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Modal: FC<ModalProps> = (props) => {
  const { modalTitle, description = '', actions = [], onClose, children, portal = true, ...rest } = props;

  const modal = (
    <>
      <div css={popupStyle} {...rest}>
        <div css={titleStyle}>{modalTitle}</div>
        <div css={descriptionStyle}>{description}</div>
        <div css={actionsStyle}>{actions}</div>
        {children}
      </div>
    </>
  );

  if (!portal) {
    return modal;
  }

  let letterBox = document.getElementById('letterbox')!;
  return createPortal(modal, letterBox);
};

const titleStyle = css`
  font-size: 3.5em;
  margin-bottom: 1em;
`;

const descriptionStyle = css`
  font-size: 2.5em;
`;

const actionsStyle = css`
  margin-top: 3em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const popupStyle = css`
  position: absolute;
  text-align: center;
  z-index: 110;
  box-sizing: border-box;
  align-self: center;
  padding: 2%;
  margin: 0 2%;
  outline: none;
  border: 0.4em gold solid;
  box-shadow: 0 0 1em 0.3em black inset, 0 0 1em 0.3em black;
  background: url(${Images.PopupBackground});
  background-size: cover;
  border-radius: 2em;
  color: lightgoldenrodyellow;
  font-family: 'Mulish', sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;

  & > div:first-of-type {
    position: relative;
    //font-size: 5em;
    margin: 0 1em;
  }

  & > div:not(:last-of-type):not(:first-of-type) {
    position: relative;
    //font-size: 3em;
    white-space: break-spaces;

    strong {
      font-weight: bold;
    }
  }
`;

export { Modal };
