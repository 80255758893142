import { CardType, HumanTypes } from '../CardType';
import { Power } from './Power';
import { DiscardEffect } from '../../effect/DiscardEffect';
import { DrawEffect } from '../../effect/DrawEffect';
import { TeleportEffect } from '../../effect/TeleportEffect';
import { IgnoreEffect } from '../../effect/IgnoreEffect';
import { AdditionalHuntEffect } from '../../effect/AdditionalHuntEffect';
import { HuntTrackMoveEffect } from '../../effect/HuntTrackMoveEffect';

export const VampireWill: Power = {
  copies: 3,
  speed: 1,
  category: CardType.Power,
  effects: [
    new DiscardEffect(),
    new DrawEffect(1)
  ]
};

export const VampireWillAlt: Power = {
  speed: 0,
  category: CardType.Power,
  effects: [
    new DiscardEffect(),
    new DrawEffect(1),
    new DiscardEffect({ optional: true }),
    new DrawEffect(1)
  ]
};

export const VampireStrength: Power = {
  copies: 3,
  speed: 0,
  category: CardType.Power,
  effects: [
    new DrawEffect(1, { bonus: { drawCount: 1, huntTypes: HumanTypes} })
  ]
}

export const VampireStrengthAlt: Power = {
  speed: -2,
  category: CardType.Power,
  effects: [
    new DrawEffect(2, { bonus: { drawCount: 1, huntTypes: HumanTypes} })
  ]
}

export const VampireSpeedTwo: Power = {
  copies: 2,
  speed: 2,
  category: CardType.Power
};

export const VampireSpeedThree: Power = {
  copies: 2,
  speed: 3,
  category: CardType.Power
};

export const FormOfMist: Power = {
  copies: 2,
  speed: 1,
  category: CardType.Power,
  effects: [
    new TeleportEffect()
  ]
};

export const FormOfBat: Power = {
  copies: 2,
  speed: 1,
  category: CardType.Power,
  passiveEffects: [
    new IgnoreEffect()
  ]
};

export const VampiricStealth: Power = {
  copies: 2,
  speed: 2,
  category: CardType.Power,
  passiveEffects: [
    new AdditionalHuntEffect(1)
  ]
};

export const Hypnosis: Power = {
  copies: 2,
  speed: 1,
  category: CardType.Power,
  effects: [
    new HuntTrackMoveEffect()
  ]
}

export const Powers: Power[] = [
  VampireWill,
  VampireWillAlt,
  VampireStrength,
  VampireStrengthAlt,
  VampireSpeedTwo,
  VampireSpeedThree,
  FormOfMist,
  FormOfBat,
  VampiricStealth,
  Hypnosis
].flatMap(powerCard => Array(powerCard.copies || 1).fill(powerCard));
