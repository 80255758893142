import { PlayerType } from '../player';
import { isAdditionalHunt, isSpeedBonus } from './EffectUtils';

export const getNextPendingEffect = (player: PlayerType, withAdditionalHunt?: boolean) => player.pendingEffects.find(e => !isSpeedBonus(e) && (withAdditionalHunt || !isAdditionalHunt(e)));
export const removeNextPendingEffect = (player: PlayerType, withAdditionalHunt?: boolean) => {
  const nextPendingEffect = player.pendingEffects.findIndex(e => !isSpeedBonus(e) && (withAdditionalHunt || !isAdditionalHunt(e)));

  if (nextPendingEffect > -1) {
    player.pendingEffects.splice(nextPendingEffect, 1);
  }
};
