import GameView from '@gamepark/the-hunger/GameView';
import { ViewType } from '../ViewType';

export type DisplayView = {
  type: 'DisplayView';
  view?: ViewType;
};

export const displayViewMove = (view?: ViewType): DisplayView => ({
  type: 'DisplayView',
  view,
});

export function displayView(state: GameView, move: DisplayView) {
  state.displayedView = move.view;
}
