import { GameInitializer } from '@gamepark/the-hunger/GameInitializer';
import {
  ASoldiersLife,
  CommonTaste,
  EarlyNight,
  EatThemAll,
  Gourmet,
  HolierThanThou,
  Mission,
  Missions,
  MyBodyIsATemple,
  Romantic,
  TheCollector,
  Tipsy,
} from '@gamepark/the-hunger/mission';
import { Card } from '@gamepark/the-hunger/card/Card';
import { GameDeck } from '@gamepark/the-hunger/card/hunt/GameDeck';
import Vampire from '@gamepark/the-hunger/player/Vampire';
import GameState from '@gamepark/the-hunger/GameState';
import {
  BorisPouchkineHunger,
  BorisPouchkineVampiricSpeed2,
  BorisPouchkineVampiricSpeed3,
  BorisPouchkineVampiricSpeed4,
  BorisPouchkineVampiricStrength,
  BorisPouchkineVampiricThirst,
  JosephineLafayetteHunger,
  JosephineLafayetteVampiricSpeed2,
  JosephineLafayetteVampiricSpeed3,
  JosephineLafayetteVampiricSpeed4,
  JosephineLafayetteVampiricStrength,
  JosephineLafayetteVampiricThirst,
} from '@gamepark/the-hunger/card/vampire/Starters';
import { MissionPerStack } from '@gamepark/the-hunger/utils/GameConstants';
import { Catarina, Boo, Juri, Malac, VampireStrength, VampiricStealth } from '@gamepark/the-hunger/card/hunt';

const TutorialPublicMissions = [HolierThanThou, EarlyNight];
const TutorialFirstStackMissions = [Romantic, MyBodyIsATemple, EatThemAll, ASoldiersLife, CommonTaste, TheCollector];
const TutorialMission = CommonTaste;
const TutorialOpponentMission = [Tipsy, Gourmet];
const TutorialStartingCards = [Catarina, Boo, Juri, Malac, VampiricStealth, VampireStrength];
export const TutorialOpponentMissionIndexes = TutorialOpponentMission.map((opponentMission) =>
  Missions.findIndex((m) => opponentMission === m)
);
class TutorialGameInitializer extends GameInitializer {
  protected getAllMissions = (): [number, Mission][] => {
    return Array.from(Missions.entries()).filter((m) => TutorialMission !== m[1]);
  };

  protected getStarterCards(deck: [number, Card][]) {
    return Array.from(TutorialStartingCards.entries())
      .flatMap((startingCard) =>
        deck.splice(
          deck.findIndex((card) => card[1] === startingCard[1]),
          1
        )
      )
      .map((e) => e[0]);
  }

  getGameState(): GameState {
    const state = super.getGameState();
    const tutorialMissionIndex = Missions.findIndex((m) => TutorialMission === m);
    const tutorialOpponentMissionsIndexes = TutorialOpponentMission.map((opponentMission) =>
      Missions.findIndex((m) => opponentMission === m)
    );

    return {
      ...state,
      players: state.players.map((p) => ({
        ...p,
        hand: this.getTutorialPlayerHand(p.vampire),
        deck: this.getTutorialPlayerDeck(p.vampire),
        missionChoice:
          p.vampire === Vampire.BorisPouchkine ? { missions: [] } : { missions: tutorialOpponentMissionsIndexes },
        missions: p.vampire === Vampire.BorisPouchkine ? [{ mission: tutorialMissionIndex }] : p.missions,
      })),
      turnOrder: [Vampire.BorisPouchkine, Vampire.JosephineLafayette],
      tutorial: true,
    };
  }

  getTutorialPlayerHand = (vampire: Vampire) => {
    const cards: Card[] = [];
    if (vampire === Vampire.BorisPouchkine) {
      cards.push(BorisPouchkineVampiricSpeed2, BorisPouchkineVampiricSpeed3, BorisPouchkineVampiricSpeed4);
    } else {
      cards.push(JosephineLafayetteHunger, JosephineLafayetteVampiricSpeed2, JosephineLafayetteVampiricSpeed3);
    }

    return Array.from(GameDeck.entries())
      .filter((entry) => cards.includes(entry[1]))
      .map((entry) => entry[0]);
  };

  getTutorialPlayerDeck = (vampire: Vampire) => {
    const cards: Card[] = [];
    if (vampire === Vampire.BorisPouchkine) {
      cards.push(BorisPouchkineHunger, BorisPouchkineVampiricThirst, BorisPouchkineVampiricStrength);
    } else {
      cards.push(
        JosephineLafayetteVampiricSpeed4,
        JosephineLafayetteVampiricStrength,
        JosephineLafayetteVampiricThirst
      );
    }

    return Array.from(GameDeck.entries())
      .filter((entry) => cards.includes(entry[1]))
      .map((entry) => entry[0]);
  };

  /**
   * Initialize missions on board
   */
  initializeBoardMissions(): number[][] {
    const missions: number[][] = [];
    const entries = Array.from(Missions.entries()).filter(
      (m) => !TutorialOpponentMission.includes(m[1]) && m[1] !== TutorialMission
    );

    const publicMissionsEntries = entries.filter((c) => TutorialPublicMissions.includes(c[1]));
    const publicMissions = publicMissionsEntries.map((e) => e[0]);
    missions.push([publicMissions[0]]);
    missions.push([publicMissions[1]]);

    const firstPileMissionsEntries = entries.filter((c) => TutorialFirstStackMissions.includes(c[1]));
    missions.push(firstPileMissionsEntries.map((e) => e[0]));

    this.otherMissions = this.otherMissions
      .filter((m) => !publicMissionsEntries.find((e) => e[0] === m))
      .filter((m) => !firstPileMissionsEntries.find((e) => e[0] === m));

    MissionPerStack.slice(3).forEach((count) => {
      missions.push(this.otherMissions.splice(0, count));
    });

    return missions;
  }
}

export { TutorialGameInitializer };
