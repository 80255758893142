export enum Region {
  Forest = 1,
  Plain,
  Mountain,
  Cemetery,
  Castle
}

export const RegionTurnOrderPriority: { [region in Region]: number } = {
  [Region.Forest]: 1,
  [Region.Plain]: 2,
  [Region.Mountain]: 3,
  [Region.Cemetery]: 4,
  [Region.Castle]: 5
}
