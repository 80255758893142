import { Familiar } from './Familiar';
import { CardType, HumanTypes } from '../CardType';
import { CardTrait } from '../CardTrait';
import { BoardBoxEffect } from '../../board';
import { SpeedBonusEffect } from '../../effect/SpeedBonusEffect';
import { DiscardEffect } from '../../effect/DiscardEffect';
import { FreeHuntEffect } from '../../effect/FreeHuntEffect';
import { DrawEffect } from '../../effect/DrawEffect';
import { EffectTrigger } from '../../effect/EffectTrigger';
import { VictoryPointEffect } from '../../effect/VictoryPointEffect';
import { InspiringEffect } from '../../effect/InspiringEffect';
import { DigestEffect } from '../../effect/DigestEffect';
import { VictoryPointsPerTraitEffect } from '../../effect/VictoryPointsPerTraitEffect';
import { AdditionalHuntEffect } from '../../effect/AdditionalHuntEffect';

export const Kutya: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Dog],
  effects: [new DiscardEffect({ itself: true, auto: true }), new FreeHuntEffect({ huntColumn: 2, optional: true })],
  passiveEffects: [new SpeedBonusEffect(1, { boxEffect: BoardBoxEffect.Well, huntOnly: true })],
};

export const Caine: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Dog],
  effects: [new DiscardEffect({ itself: true, auto: true }), new FreeHuntEffect({ huntColumn: 2, optional: true })],
  passiveEffects: [new SpeedBonusEffect(1, { boxEffect: BoardBoxEffect.Well, huntOnly: true })],
};

export const Ursa: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Bear],
  effects: [
    new DiscardEffect({
      trigger: EffectTrigger.BeforePlaying,
      allHand: true,
      auto: true,
    }),
    new DrawEffect(2),
    new DiscardEffect({ itself: true, auto: true }),
    new SpeedBonusEffect(1, { trigger: EffectTrigger.BeforeMoveOrHunt }),
  ],
};

export const Teddy: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Bear],
  effects: [
    new DiscardEffect({
      trigger: EffectTrigger.BeforePlaying,
      allHand: true,
      auto: true,
    }),
    new DrawEffect(2),
    new DiscardEffect({ itself: true, auto: true }),
    new SpeedBonusEffect(1, { trigger: EffectTrigger.BeforeMoveOrHunt }),
  ],
};

export const Malac: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Pig],
  passiveEffects: [
    new AdditionalHuntEffect(1, EffectTrigger.NotMoving),
    new VictoryPointEffect(1, {
      trigger: EffectTrigger.Hunting,
      once: true,
      huntTypes: HumanTypes,
    }),
  ],
};

export const Chop: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Pig],
  passiveEffects: [
    new AdditionalHuntEffect(1, EffectTrigger.NotMoving),
    new VictoryPointEffect(1, {
      trigger: EffectTrigger.Hunting,
      once: true,
      huntTypes: HumanTypes,
    }),
  ],
};

export const Nanoosh: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Cat],
  passiveEffects: [
    new VictoryPointEffect(1, {
      trigger: EffectTrigger.PlaceInPlayingArea,
      once: true,
      huntTypes: HumanTypes,
      huntCount: 2,
    }),
    new SpeedBonusEffect(2, { huntTypes: HumanTypes, huntCount: 2 }),
  ],
};

export const Lockjaw: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Cat],
  passiveEffects: [
    new VictoryPointEffect(1, {
      trigger: EffectTrigger.PlaceInPlayingArea,
      once: true,
      huntTypes: HumanTypes,
      huntCount: 2,
    }),
    new SpeedBonusEffect(2, { huntTypes: HumanTypes, huntCount: 2 }),
  ],
};

export const Echo: Familiar = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Wolf],
  passiveEffects: [new VictoryPointsPerTraitEffect(1, CardTrait.Wolf, EffectTrigger.EndOfGame)],
};

export const Jahda: Familiar = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Wolf],
  passiveEffects: [new VictoryPointsPerTraitEffect(1, CardTrait.Wolf, EffectTrigger.EndOfGame)],
};

export const Bo: Familiar = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Wolf],
  passiveEffects: [new VictoryPointsPerTraitEffect(1, CardTrait.Wolf, EffectTrigger.EndOfGame)],
};

export const Gray: Familiar = {
  speed: 0,
  victoryPoints: '?',
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Wolf],
  passiveEffects: [new VictoryPointsPerTraitEffect(1, CardTrait.Wolf, EffectTrigger.EndOfGame)],
};

export const Patcani: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Rat],
  passiveEffects: [
    new SpeedBonusEffect(1, {
      huntTypes: HumanTypes,
      perHunt: true,
      huntLevels: [1, 2],
    }),
  ],
};

export const WeeVlad: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Rat],
  passiveEffects: [
    new SpeedBonusEffect(1, {
      huntTypes: HumanTypes,
      perHunt: true,
      huntLevels: [1, 2],
    }),
  ],
};

export const Porumbel: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Dove],
  passiveEffects: [new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfTurnWithoutHunt })],
};

export const Tyson: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Dove],
  passiveEffects: [new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfTurnWithoutHunt })],
};

export const Bagoly: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Owl],
  passiveEffects: [new InspiringEffect(), new VictoryPointEffect(1, { trigger: EffectTrigger.GainMission })],
};

export const Sova: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Owl],
  passiveEffects: [new InspiringEffect(), new VictoryPointEffect(1, { trigger: EffectTrigger.GainMission })],
};

export const Capra: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Aries],
  passiveEffects: [
    new VictoryPointEffect(2, { trigger: EffectTrigger.PushVampire }),
    new DiscardEffect({
      permanent: true,
      otherPlayer: true,
      trigger: EffectTrigger.PushVampire,
    }),
  ],
};

export const Nanny: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Aries],
  passiveEffects: [
    new VictoryPointEffect(2, { trigger: EffectTrigger.PushVampire }),
    new DiscardEffect({
      permanent: true,
      otherPlayer: true,
      trigger: EffectTrigger.PushVampire,
    }),
  ],
};

export const Kaa: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Snake],
  effects: [
    new DigestEffect({ digestCount: 1, itself: true, huntTypes: HumanTypes, auto: true }),
    new DigestEffect({ digestCount: 1, playingAreaOnly: true }),
    new VictoryPointEffect(2),
  ],
};

export const Wiggles: Familiar = {
  speed: 0,
  category: CardType.Familiar,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Snake],
  effects: [
    new DigestEffect({ digestCount: 1, itself: true, huntTypes: HumanTypes, auto: true }),
    new DigestEffect({ digestCount: 1, playingAreaOnly: true }),
    new VictoryPointEffect(2),
  ],
};

export const Familiars: Familiar[] = [
  Kutya,
  Caine,
  Ursa,
  Teddy,
  Malac,
  Chop,
  Nanoosh,
  Lockjaw,
  Echo,
  Jahda,
  Bo,
  Gray,
  Patcani,
  WeeVlad,
  Porumbel,
  Tyson,
  Bagoly,
  Sova,
  Capra,
  Nanny,
  Kaa,
  Wiggles,
];

export const FamiliarTypes: CardTrait[] = [
  CardTrait.Cat,
  CardTrait.Dog,
  CardTrait.Pig,
  CardTrait.Owl,
  CardTrait.Bear,
  CardTrait.Snake,
  CardTrait.Aries,
  CardTrait.Dove,
  CardTrait.Rat,
  CardTrait.Wolf,
];
