/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import GameView from '@gamepark/the-hunger/GameView';
import { FailuresDialog, FullscreenDialog, Menu, useGame } from '@gamepark/react-client';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5ToTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import GameDisplay from './GameDisplay';
import HeaderText from './HeaderText';
import { Images } from './images/Images';
import { Header, LoadingScreen, ImagesLoader } from '@gamepark/react-components';

export default function App() {
  const game = useGame<GameView>()
  const [isJustDisplayed, setJustDisplayed] = useState(true)
  const [isImagesLoading, setImagesLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setJustDisplayed(false), 2000)
  }, [])

  const loading = !game || isJustDisplayed || isImagesLoading
  return (
    <DndProvider options={HTML5ToTouch}>
      {!loading && game && <GameDisplay game={game}/>}
      <LoadingScreen display={loading}
                     author="Richard Garfield"
                     artist="Jocelyn Millet"
                     publisher="Origames"
                     developer="Patrick Beaumé"/>
      <Header css={ headerStyle }>
        <HeaderText loading={loading} game={game}/>
      </Header>

      <ImagesLoader images={Object.values(Images)} onImagesLoad={() => setImagesLoading(false)}/>
      <Menu/>
      <FailuresDialog/>
      <FullscreenDialog/>
    </DndProvider>
  )
}

const headerStyle = css`
  
  > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  > h1 > button {
    font-size: 0.8em;
  }
`
