import MoveType from './MoveType';
import GameState from '../GameState';
import { isOtherPlayerView } from '../player';
import GameView from '../GameView';
import { mustBePlayedFirst } from '../utils/CardUtils';
import { computePassiveEffects } from '../utils/GameUtils';
import { EffectTrigger } from '../effect/EffectTrigger';
import { GameDeck } from '../card/hunt/GameDeck';
import Move from './Move';

export type PlaceInPlayingArea = {
  type: MoveType.PlaceInPlayingArea,
  cards: number[]
}

export const isPlaceInPlayingAreaMove = (move: Move): move is PlaceInPlayingArea => move.type === MoveType.PlaceInPlayingArea;

export const placeInPlayingAreaMove = (cards: number[]): PlaceInPlayingArea => ({
  type: MoveType.PlaceInPlayingArea,
  cards
});

export const placeInPlayingArea = (state: GameState | GameView, move: PlaceInPlayingArea) => {
  const player = state.players.find(p => p.vampire === state.activePlayer)!;
  if (!isOtherPlayerView(player)) {
    player.hand = player.hand.filter(c => !move.cards.includes(c));
  } else {
    player.hand -= move.cards.length;
  }

  player.playingArea.push(...move.cards);
  player.playedCards.push(...player.playingArea.filter(c => mustBePlayedFirst(GameDeck[c])));
  computePassiveEffects(state, player, player.playingArea, [], [EffectTrigger.PlaceInPlayingArea], GameDeck);
}
