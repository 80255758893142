import { BoardBox } from './BoardBox';
import { BoardBoxEffect } from './BoardBoxEffect';
import { Region } from './Region';
import { Path } from './Path';

export const BoardBoxes: BoardBox[] = [
  { branches: [1], effect: BoardBoxEffect.Castle, region: Region.Castle }, // 0
  { branches: [0, 2], lostVictoryPoints: 5, effect: BoardBoxEffect.Cemetery, region: Region.Cemetery, path: Path.Road }, // 1
  { branches: [1, 3], lostVictoryPoints: 5, effect: BoardBoxEffect.Cemetery, region: Region.Cemetery, path: Path.Road }, // 2
  { branches: [2, 4], lostVictoryPoints: 5, effect: BoardBoxEffect.Cemetery, region: Region.Cemetery, path: Path.Road }, // 3
  { branches: [3, 5], lostVictoryPoints: 10, region: Region.Mountain, path: Path.Road }, // 4
  { branches: [4, 6], lostVictoryPoints: 10, region: Region.Mountain, path: Path.Road }, // 5
  { branches: [5, 7], lostVictoryPoints: 10, region: Region.Mountain, path: Path.Road }, // 6
  { branches: [6, 8], lostVictoryPoints: 15, region: Region.Mountain, path: Path.Road }, // 7
  { branches: [7, 9], lostVictoryPoints: 15, effect: BoardBoxEffect.Well, region: Region.Mountain, path: Path.Road }, // 8
  {
    branches: [8, 10],
    lostVictoryPoints: 15,
    effect: BoardBoxEffect.Crypt,
    region: Region.Mountain,
    path: Path.Road,
    missionStack: 2,
  }, // 9
  { branches: [9, 11], lostVictoryPoints: 20, region: Region.Mountain, path: Path.Road }, // 10
  { branches: [10, 12], lostVictoryPoints: 20, region: Region.Mountain, path: Path.Road }, // 11
  { branches: [11, 13], lostVictoryPoints: 25, effect: BoardBoxEffect.Well, region: Region.Mountain, path: Path.Road }, // 12
  { branches: [12, 14, 24], region: Region.Plain, path: Path.Railroad }, // 13
  { branches: [13, 15], region: Region.Plain, path: Path.Boat }, // 14
  { branches: [14, 16], region: Region.Plain, path: Path.Boat }, // 15
  { branches: [15, 17], effect: BoardBoxEffect.Tavern, region: Region.Plain, path: Path.Boat }, // 16
  { branches: [16, 18], effect: BoardBoxEffect.Ship, region: Region.Plain, path: Path.Boat }, // 17
  { branches: [17, 19], effect: BoardBoxEffect.Well, region: Region.Plain, path: Path.Boat }, // 18
  { branches: [18, 20], effect: BoardBoxEffect.Ship, region: Region.Plain, path: Path.Boat }, // 19
  { branches: [19, 21], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Boat }, // 20
  { branches: [20, 22], effect: BoardBoxEffect.Ship, region: Region.Plain, path: Path.Boat }, // 21
  { branches: [21, 23], effect: BoardBoxEffect.Well, region: Region.Plain, path: Path.Boat }, // 22
  { branches: [22, 36, 37], effect: BoardBoxEffect.Ship, region: Region.Plain, path: Path.Boat }, // 23
  { branches: [13, 25], region: Region.Plain, path: Path.Railroad }, // 24
  { branches: [24, 26], region: Region.Plain, path: Path.Railroad }, // 25
  { branches: [25, 27], effect: BoardBoxEffect.Well, region: Region.Plain, path: Path.Railroad }, // 26
  { branches: [26, 28, 39], region: Region.Plain, path: Path.Railroad }, // 27
  { branches: [27, 29], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 28
  { branches: [28, 30], effect: BoardBoxEffect.Crypt, region: Region.Plain, path: Path.Railroad, missionStack: 7 }, // 29
  { branches: [29, 31], effect: BoardBoxEffect.Market, region: Region.Plain, path: Path.Railroad }, // 30
  { branches: [30, 32], region: Region.Plain, path: Path.Railroad }, // 31
  { branches: [31, 33], effect: BoardBoxEffect.Well, region: Region.Plain, path: Path.Railroad }, // 32
  { branches: [32, 34], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 33
  { branches: [33, 35], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 34
  { branches: [34, 36, 44], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 35
  { branches: [35, 37, 23], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 36
  { branches: [36, 38, 23], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 37
  { branches: [37, 47], effect: BoardBoxEffect.Chest, region: Region.Plain, path: Path.Railroad }, // 38
  { branches: [27, 40], effect: BoardBoxEffect.Crypt, region: Region.Plain, path: Path.Road, missionStack: 5 }, // 39
  { branches: [39, 41], region: Region.Plain, path: Path.Road }, // 40
  { branches: [40, 42], region: Region.Plain, path: Path.Road }, // 41
  { branches: [41, 43], effect: BoardBoxEffect.Church, region: Region.Plain, path: Path.Road }, // 42
  { branches: [42, 44], effect: BoardBoxEffect.Well, region: Region.Plain, path: Path.Road }, // 43
  { branches: [43, 45, 35], region: Region.Plain, path: Path.Road }, // 44
  { branches: [44, 46], effect: BoardBoxEffect.Crypt, region: Region.Plain, path: Path.Road, missionStack: 6 }, // 45
  { branches: [45, 52], region: Region.Plain, path: Path.Road }, // 46
  { branches: [38, 48], effect: BoardBoxEffect.Chest, region: Region.Forest, path: Path.Railroad }, // 47
  { branches: [47, 49], effect: BoardBoxEffect.Well, region: Region.Forest, path: Path.Railroad }, // 48
  { branches: [48, 50], effect: BoardBoxEffect.Mansion, region: Region.Forest, path: Path.Railroad }, // 49
  { branches: [49, 51], effect: BoardBoxEffect.Crypt, region: Region.Forest, path: Path.Railroad, missionStack: 4 }, // 50
  { branches: [50, 57], effect: BoardBoxEffect.Chest, region: Region.Forest, path: Path.Railroad }, // 51
  { branches: [46, 53], region: Region.Forest, path: Path.Road }, // 52
  { branches: [52, 54], effect: BoardBoxEffect.Well, region: Region.Forest, path: Path.Road }, // 53
  { branches: [53, 55], effect: BoardBoxEffect.Barracks, region: Region.Forest, path: Path.Road }, // 54
  { branches: [54, 56], region: Region.Forest, path: Path.Road }, // 55
  { branches: [55, 57], effect: BoardBoxEffect.Crypt, region: Region.Forest, path: Path.Road, missionStack: 3 }, // 56
  { branches: [51, 56, 58], region: Region.Forest, path: Path.Road }, // 57
  { branches: [57, 59], effect: BoardBoxEffect.Well, region: Region.Forest, path: Path.Road }, // 58
  { branches: [58, 60], region: Region.Forest, path: Path.Road }, // 59
  { branches: [59, 61], effect: BoardBoxEffect.Chest, region: Region.Forest, path: Path.Road }, // 60
  { branches: [60], effect: BoardBoxEffect.Labyrinth, region: Region.Forest, path: Path.Road }, // 61
];
