/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { speedSymbolHeight, speedSymbolWidth } from '../utils/Style';
import { Images } from '../images/Images';

type SpeedSymbolProps = {
  value?: string;
  width?: number;
  light?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const SpeedSymbol: FC<SpeedSymbolProps> = ({ value, light = true, width, ...props }) => {
  return (
    <span css={mini(light, width)} {...props}>
      <span css={valueStyle}>{value}</span>
    </span>
  );
};

const mini = (light: boolean, width?: number) => css`
  height: ${speedSymbolHeight(width)}em;
  width: ${width || speedSymbolWidth}em;
  background-image: url(${light ? Images.SpeedSymbol : Images.Speed2});
  background-size: 100% 100%;
  display: inline-block;
  text-align: center;
  top: 0.65em;
  position: relative;
  margin-bottom: -0.5em;
  transform: translateY(-50%);
  -webkit-font-smoothing: subpixel-antialiased;
  image-rendering: -webkit-optimize-contrast;
`;

const valueStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1em;
  font-weight: bold;
  color: black;
  transform: translate(-50%, -50%);
`;

export { SpeedSymbol };
