import { Rose } from './Rose';
import { CardType } from '../CardType';
import { CardTrait } from '../CardTrait';
import { EffectTrigger } from '../../effect/EffectTrigger';
import { VictoryPointEffect } from '../../effect/VictoryPointEffect';

export const EternalRose: Rose = {
  speed: 1,
  category: CardType.Rose,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Unique],
  victoryPoints: 5,
  passiveEffects: [new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfTurn })],
};

export const PerfectRose: Rose = {
  speed: 0,
  category: CardType.Rose,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Unique],
  victoryPoints: 5,
  passiveEffects: [new VictoryPointEffect(2, { trigger: EffectTrigger.EndOfTurnWithoutHunt })],
};

export const DeadRose: Rose = {
  speed: 1,
  category: CardType.Rose,
  traits: [CardTrait.Permanent, CardTrait.Ready, CardTrait.Unique],
  victoryPoints: 3,
  passiveEffects: [new VictoryPointEffect(1, { trigger: EffectTrigger.EndOfTurnWithHunt })],
};

export const Roses: Rose[] = [EternalRose, PerfectRose, DeadRose];
